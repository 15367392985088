import React from 'react';
import { PropTypes } from 'prop-types';
import { formatCapitalizeFirstLetter } from 'utils/format';
import ReactModal from './react-modal';
import ConfirmationReactModal from './confirmation-react-modal';

const ApprovalRuleConfirmationModal = ({
  isOpen,
  closeHandler,
  confirmHandler,
  action,
  obj,
  objName,
  attrName,
  size,
}) => {
  const renderBody = () => {
    const message = obj ? (
      <div>
        {obj.hasApprovalRule && (
          <p className="text-warning">
            <span className="text-capitalize">{objName}</span> has active Approval Rule assigned!
          </p>
        )}
        Are you sure you want to {action} {objName}
        <span className="bold">{` ${obj[attrName]}`}</span>?
      </div>
    ) : (
      <div />
    );
    return (
      <ConfirmationReactModal
        message={message}
        onClose={closeHandler}
        warning
        onSubmit={confirmHandler}
      />
    );
  };

  return (
    <ReactModal
      isOpen={isOpen}
      title={`${formatCapitalizeFirstLetter(action)} ${formatCapitalizeFirstLetter(objName)}`}
      size={size}
      body={renderBody()}
      closeModal={closeHandler}
    />
  );
};

ApprovalRuleConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
  confirmHandler: PropTypes.func.isRequired,
  action: PropTypes.string,
  obj: PropTypes.shape(),
  objName: PropTypes.string.isRequired,
  attrName: PropTypes.string,
  size: PropTypes.string,
};

ApprovalRuleConfirmationModal.defaultProps = {
  isOpen: false,
  obj: null,
  action: 'edit',
  size: 'xs',
  attrName: 'name',
};

export default ApprovalRuleConfirmationModal;
