export default {
  sceneTitle: 'Hierarchy',
  sceneSubtitle: 'company',
  addNodeButtonText: 'Create node',
  addNodeModalTitle: 'Create New Node',
  addNodeModalConfigurationTitle: 'Create New Node Configuration',
  viewPoliciesButtonText: 'Custom Policies',
  groups: 'Groups',
  tree: 'Tree',
};
