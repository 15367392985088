import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { API_COMPANY_BILLING_ADDRESS } from 'lib/api-endpoints';
import { updateUserCompanyBillingAddress } from 'actions/auth.actions';
import BillingAddressSerializer from 'lib/serializers/BillingAddressSerializer';
import { getFieldValue } from '../../utils/forms';
import { formatCapitalizeFirstLetter } from '../../utils/format';
import BreadCrumbComponent from '../shared/breadcumb.component';
import BreadCrumbItem from '../shared/breadcrumb-item';
import SectionHeaderComponent from '../shared/section-header.component';
import TextInput from '../forms/text-input.component';
import siteManagerAPI from '../../lib/api-manager';
import SelectInput from '../forms/select-input';
import { COUNTRY_LIST, USA_STATE_CODES, CANADA_STATE_CODES } from '../../constants/values';

class BillingAddressForm extends Component {
  static propTypes = {
    updateUserCompanyBillingAddress: PropTypes.func.isRequired,
    companyBillingAddress: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      nameExtension: '',
      phone: '',
      email: '',
      street: '',
      street2: '',
      postalCode: '',
      city: '',
      region: '',
      regionCode: '',
      country: {},
      errors: {},
    };
  }

  async componentDidMount() {
    const { companyBillingAddress } = this.props;

    try {
      if (!companyBillingAddress) return;
      const country = this.mapsToSelect(
        COUNTRY_LIST.filter(
          (countryObject) => countryObject.code === companyBillingAddress.country,
        ),
      )[0];
      const regionCode = this.mapsToSelect(
        this.getRegionCode(country, companyBillingAddress.regionCode),
      )[0];
      this.setState({ ...companyBillingAddress, regionCode, country });
    } catch (error) {
      toast.error('There was en error. Please try again later');
    }
  }

  getRegionCode = (country, regionCode) => {
    const countryCode = country && country.value;

    if (countryCode === 'US') {
      return USA_STATE_CODES.filter((region) => region.code === regionCode);
    }
    if (countryCode === 'CA') {
      return CANADA_STATE_CODES.filter((region) => region.code === regionCode);
    }
    return '';
  };

  mapsToSelect = (options) => {
    if (!Array.isArray(options)) return [];
    return options.map((option) => ({
      value: option.code,
      label: option.name,
    }));
  };

  handleChange = (event) => {
    this.setState(getFieldValue(event.target));
  };

  submitSiteForm = (event) => {
    event.preventDefault();

    const { name, street, city, country } = this.state;
    const { label, value } = country;
    const errors = {};

    if (!name) errors.name = 'Name field is required';
    if (!street) errors.street = 'Street field is required';
    if (!city) errors.city = 'City field is required';
    if (!label || !value) errors.country = 'Country field is required';

    this.setState({ errors });

    if (JSON.stringify(errors) === JSON.stringify({})) {
      this.saveBillingData();
    }
  };

  saveBillingData = async () => {
    const { companyBillingAddress } = this.props;
    const {
      name,
      nameExtension,
      phone,
      email,
      street,
      street2,
      postalCode,
      city,
      region,
      regionCode,
      country,
    } = this.state;

    const billingBody = {
      name,
      name_ext: nameExtension,
      phone,
      email,
      street,
      street2,
      postal_code: postalCode,
      city,
      region,
      region_code: (regionCode && regionCode.value) || '',
      country: country.value,
      is_default_billing_address: true,
    };

    try {
      const { updateUserCompanyBillingAddress } = this.props;

      let response;
      if (!companyBillingAddress) {
        response = await siteManagerAPI.post(`${API_COMPANY_BILLING_ADDRESS}`, billingBody);
      } else {
        response = await siteManagerAPI.patch(
          `${API_COMPANY_BILLING_ADDRESS}${companyBillingAddress.id}/`,
          billingBody,
        );
      }
      updateUserCompanyBillingAddress(new BillingAddressSerializer(response.data));
      toast.success('Billing Information Saved');
    } catch (error) {
      const errorResponseData = error.response.data;
      const billingError = errorResponseData.default_billing_address;
      const nameExt = errorResponseData.name_ext;
      const nameError = errorResponseData.name;
      const errors = {};

      if (billingError) {
        if (billingError.name) {
          errors.name = formatCapitalizeFirstLetter(billingError.name[0]);
        }

        if (billingError.nameExtension) {
          errors.nameExtension = formatCapitalizeFirstLetter(billingError.name_extension[0]);
        }

        if (billingError.phone) {
          [errors.phone] = billingError.phone;
        }

        if (billingError.email) {
          [errors.email] = billingError.email;
        }
      }

      if (nameError) {
        errors.name = formatCapitalizeFirstLetter(nameError[0]);
      }

      if (nameExt) {
        errors.nameExtension = formatCapitalizeFirstLetter(nameExt[0]);
      }

      this.setState({ errors });
      toast.error('There has been an error while saving the Billing Address. Try again later');
    }
    return null;
  };

  assignCountry = (option) => {
    this.setState({ country: option, region: '', regionCode: undefined });
  };

  assignRegionCode = (option) => {
    this.setState({ regionCode: option });
  };

  renderRegions() {
    const { country, region, regionCode, errors } = this.state;
    const countryCode = country && country.value;

    if (countryCode === 'US' || countryCode === 'CA') {
      return (
        <SelectInput
          label="Region"
          name="regionCode"
          value={regionCode}
          error={errors.regionCode}
          options={countryCode === 'US' ? USA_STATE_CODES : CANADA_STATE_CODES}
          mapOptions={this.mapsToSelect}
          handleChange={this.assignRegionCode}
          placeholder="Select..."
          isSearchable
          closeMenuOnSelect
        />
      );
    }

    return (
      <TextInput
        error={errors.region}
        value={region}
        label="Region"
        name="region"
        handleChange={this.handleChange}
        maxLength={20}
      />
    );
  }

  render() {
    const {
      name,
      nameExtension,
      phone,
      email,
      street,
      street2,
      postalCode,
      city,
      country,
      errors,
    } = this.state;

    return (
      <Fragment>
        <SectionHeaderComponent subtitle="Company" sectionLabel="Billing Address" />

        <div className="content__container">
          <BreadCrumbComponent>
            <BreadCrumbItem label="Configuration" link="/configuration" />
            <BreadCrumbItem label="General Configuration" link="/configuration/general" />
            <BreadCrumbItem label="Billing Address" />
          </BreadCrumbComponent>

          <div className="container-fluid mb-3">
            <div className="row">
              <div className="form-group col-12 col-lg-4">
                <form onSubmit={this.submitSiteForm}>
                  <TextInput
                    error={errors.name}
                    value={name}
                    label="Name"
                    name="name"
                    required
                    handleChange={this.handleChange}
                    maxLength={50}
                  />

                  <TextInput
                    error={errors.nameExtension}
                    value={nameExtension}
                    label="Name Extension"
                    name="nameExtension"
                    handleChange={this.handleChange}
                    maxLength={50}
                  />

                  <TextInput
                    error={errors.phone}
                    value={phone}
                    label="Phone"
                    name="phone"
                    handleChange={this.handleChange}
                    maxLength={20}
                  />

                  <TextInput
                    error={errors.email}
                    value={email}
                    label="Email"
                    name="email"
                    handleChange={this.handleChange}
                    maxLength={50}
                  />

                  <TextInput
                    error={errors.street}
                    value={street}
                    label="Street"
                    name="street"
                    required
                    handleChange={this.handleChange}
                    maxLength={50}
                  />

                  <TextInput
                    error={errors.street2}
                    value={street2}
                    label="Street (2)"
                    name="street2"
                    handleChange={this.handleChange}
                    maxLength={50}
                  />

                  <TextInput
                    error={errors.postalCode}
                    value={postalCode}
                    label="Postal Code"
                    name="postalCode"
                    handleChange={this.handleChange}
                    maxLength={10}
                  />

                  <TextInput
                    error={errors.city}
                    value={city}
                    label="City"
                    name="city"
                    required
                    handleChange={this.handleChange}
                    maxLength={60}
                  />

                  {this.renderRegions()}

                  <SelectInput
                    label="Country"
                    name="country"
                    value={country}
                    error={errors.country}
                    options={COUNTRY_LIST}
                    mapOptions={this.mapsToSelect}
                    handleChange={this.assignCountry}
                    placeholder="Select..."
                    isSearchable
                    required
                    closeMenuOnSelect
                  />

                  <button
                    type="submit"
                    data-placement="right"
                    className="btn btn-primary float-right"
                  >
                    SAVE BILLING ADDRESS
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  companyBillingAddress: get(state, 'auth.user.company.defaultBillingAddress', null),
});

export default connect(mapStateToProps, {
  updateUserCompanyBillingAddress,
})(withRouter(BillingAddressForm));
