export default class CompanySerializer {
  constructor(rawData) {
    const data = rawData || {};

    this.name = data.name;
    this.id = data.id;
    this.name = data.name;
    this.website = data.website;
    this.mccHost = data.mcc_host;
    this.ecommerceUrl = data.ecommerce_url;
    this.slugName = data.slug_name;
  }
}
