import React from 'react';
import PropTypes from 'prop-types';

const NumberInput = (props) => {
  const {
    error,
    value,
    required,
    label,
    name,
    handleChange,
    placeHolder,
    labelClassName,
    inputClassName,
    min,
    max,
    step,
    readOnly,
  } = props;

  return (
    <div className="form-field form-group">
      <label
        className={`medium-text ${labelClassName} ${required ? 'form-field__label--required' : ''}`}
        htmlFor={`input-${name}`}
      >
        {label}
      </label>

      <input
        onChange={handleChange}
        value={value}
        name={name}
        id={`input-${name}`}
        type="number"
        readOnly={readOnly}
        className={`form-control medium-text ${inputClassName}`}
        placeholder={placeHolder || label}
        min={min}
        max={max}
        step={step}
      />

      {error && (
        <div className="form-field__error small-text">
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

NumberInput.propTypes = {
  error: PropTypes.string,
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeHolder: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  readOnly: PropTypes.bool,
};

NumberInput.defaultProps = {
  required: false,
  error: '',
  placeHolder: '',
  labelClassName: '',
  inputClassName: '',
  min: null,
  max: null,
  step: 1,
  readOnly: false,
};

export default NumberInput;
