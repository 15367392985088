import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SectionHeaderComponent from '../../components/shared/section-header.component';
import BreadCrumbComponent from '../../components/shared/breadcumb.component';
import BreadCrumbItem from '../../components/shared/breadcrumb-item';
import PaginationComponent from '../../components/shared/pagination.component';
import ReactModal from '../../components/shared/react-modal';
import CreateGroup from './components/create-group';

import GroupsTable from './components/table';
import messages from './messages';

function Groups() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showCreateGroupModal = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const hideCreateGroupModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  return (
    <React.Fragment>
      <SectionHeaderComponent
        subtitle={messages.sceneSubtitle}
        sectionLabel={messages.sceneTitle}
      />
      <BreadCrumbComponent>
        <BreadCrumbItem label={messages.breadcrumbHierarchyLabel} link="/hierarchy" />
        <BreadCrumbItem label={messages.sceneTitle} />
      </BreadCrumbComponent>
      <div className="container-fluid">
        <div className="table-tools">
          <div className="table-tools__search" />
          <div className="table-tools__btns">
            <button
              type="button"
              onClick={showCreateGroupModal}
              className="btn btn-primary table-tools__btn"
            >
              <FontAwesomeIcon icon="plus" /> {messages.addGroupButtonText}
            </button>
          </div>
        </div>
        <PaginationComponent />
        <GroupsTable />
        <PaginationComponent />
      </div>
      <ReactModal
        isOpen={isModalOpen}
        title={messages.addGroupModalTitle}
        body={<CreateGroup closeModal={hideCreateGroupModal} />}
        closeModal={hideCreateGroupModal}
      />
    </React.Fragment>
  );
}

export default Groups;
