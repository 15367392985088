import React, { Fragment } from 'react';

import SectionHeaderComponent from '../shared/section-header.component';
import BreadCrumbComponent from '../shared/breadcumb.component';
import BreadCrumbItem from '../shared/breadcrumb-item';
import ConfigurationOption from './configuration-option';

import PermissionChecker from '../shared/permission-checker';

const ConfigurationComponent = () => (
  <Fragment>
    <SectionHeaderComponent subtitle="Company" sectionLabel="Configuration" />

    <div className="content__container">
      <BreadCrumbComponent>
        <BreadCrumbItem label="Configuration" />
      </BreadCrumbComponent>

      <div className="container-fluid">
        <div className="configuration">
          <ConfigurationOption
            icon="list"
            title="General Configuration"
            description="General Company Configuration"
            redirect="/configuration/general"
          />
          <ConfigurationOption
            icon="address-card"
            title="Roles"
            description="Create, update and delete users roles"
            redirect="/configuration/roles"
          />
          <ConfigurationOption
            icon="tasks"
            title="Approvals"
            description="Create, update and delete order approvals"
            redirect="/configuration/approvals"
          />
          <ConfigurationOption
            icon="cart-arrow-down"
            title="Ecommerce Login"
            description="Define roles that can buy for sites in the ecommerce"
            redirect="/configuration/ecommerce-login"
          />
          <PermissionChecker permissionName="order-tracking-configuration">
            <ConfigurationOption
              icon="truck-loading"
              title="Order Tracking"
              description="Define roles that can view order tracking information"
              redirect="/configuration/order-tracking"
            />
          </PermissionChecker>
          <ConfigurationOption
            icon="file-upload"
            title="Upload Sites List"
            description="Create Sites from a xlsx file"
            redirect="/configuration/uploads"
          />
          <ConfigurationOption
            icon="compass"
            title="Type of Sites"
            description="Create, update and delete types of sites"
            redirect="/configuration/site-types"
          />
        </div>
      </div>
    </div>
  </Fragment>
);

export default ConfigurationComponent;
