import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CreatePolicy from '../../shared/components/create-policy';
import SectionHeaderComponent from '../../components/shared/section-header.component';
import BreadCrumbComponent from '../../components/shared/breadcumb.component';
import BreadCrumbItem from '../../components/shared/breadcrumb-item';
import ReactModal from '../../components/shared/react-modal';
import PaginationComponent from '../../components/shared/pagination.component';

import HierarchyPoliciesTable from './components/table';
import messages from './messages';

function PoliciesScene() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showCreatePolicyModal = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const hideCreatePolicyModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  return (
    <React.Fragment>
      <SectionHeaderComponent
        subtitle={messages.sceneSubtitle}
        sectionLabel={messages.sceneTitle}
      />
      <BreadCrumbComponent>
        <BreadCrumbItem label={messages.breadcrumbHierarchyLabel} link="/hierarchy" />
        <BreadCrumbItem label={messages.sceneTitle} />
      </BreadCrumbComponent>
      <div className="container-fluid">
        <div className="table-tools">
          <div className="table-tools__search" />
          <div className="table-tools__btns">
            <button
              type="button"
              onClick={showCreatePolicyModal}
              className="btn btn-primary table-tools__btn"
            >
              <FontAwesomeIcon icon="plus" /> {messages.addPolicyButtonText}
            </button>
          </div>
        </div>
        <PaginationComponent />
        <HierarchyPoliciesTable />
        <PaginationComponent />
      </div>
      <ReactModal
        isOpen={isModalOpen}
        title={messages.addPolicyModalTitle}
        body={<CreatePolicy closeModal={hideCreatePolicyModal} />}
        closeModal={hideCreatePolicyModal}
      />
    </React.Fragment>
  );
}

export default PoliciesScene;
