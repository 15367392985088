import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

const BrandModal = ({
  company,
  subtitle,
  isBgDark,
  size,
  body,
  footer,
  closeModal,
  isOpen,
  centerFooterButtons,
  centerBody,
}) => {
  const subtitleElement = subtitle && (
    <Fragment>
      <p className="modal-title--small mb-0 mt-1">{subtitle}</p>
    </Fragment>
  );

  return (
    <Modal
      className={`brand-modal${isBgDark ? ' brand-modal--bg-dark' : ''}`}
      show={isOpen}
      size={size}
      onHide={closeModal}
      centered
    >
      <Modal.Header className="bg-primary d-flex align-items-center" closeButton>
        <Modal.Title as="h4">
          {company}
          {subtitleElement}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={centerBody ? 'center-body' : ''}>{body}</Modal.Body>
      <div className="row m-0 divider-container">
        <hr className="w-100 m-0" />
      </div>
      <Modal.Footer
        className={`border-top-0 ${centerFooterButtons ? 'justify-content-center' : ''}`}
      >
        {footer}
      </Modal.Footer>
    </Modal>
  );
};

BrandModal.propTypes = {
  company: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  isBgDark: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg', 'xl']),
  body: PropTypes.element,
  footer: PropTypes.element,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  centerFooterButtons: PropTypes.bool,
  centerBody: PropTypes.bool,
};

BrandModal.defaultProps = {
  subtitle: '',
  isBgDark: true,
  size: '',
  body: null,
  footer: null,
  centerFooterButtons: true,
  centerBody: false,
};

export default connect(({ auth }) => ({
  company: auth.user.company.name,
}))(BrandModal);
