import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';

import * as hierarchyActions from 'shared/actions/hierarchy';
import * as hierarchySelectors from 'shared/selectors/hierarchy';
import { useSingleSelector } from 'shared/hooks/useSelector';
import ApprovalRuleConfirmationModal from 'components/shared/approval-rule-confirmation-modal.component';
import SectionHeaderComponent from '../../components/shared/section-header.component';
import BreadCrumbComponent from '../../components/shared/breadcumb.component';
import BreadCrumbItem from '../../components/shared/breadcrumb-item';
import useActions from '../../shared/hooks/useActions';
import NodesForm from '../../shared/components/nodes-form';
import * as nodesUtils from '../../shared/utils/nodes';

import messages from './messages';
import * as actions from './actions';

function EditNode() {
  const { updateNode, updateNodeConfig } = useActions(actions);
  const { createNodeConfig, deleteNodeConfig } = useActions(hierarchyActions);
  const currentNode = useSingleSelector(hierarchySelectors.currentNode);
  const [errors, setErrors] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [newNodeData, setNewNodeData] = useState();
  const { id } = useParams();

  const handleNodeUpdate = async (data) => {
    const { nodeConfigData, ...nodeData } = data || newNodeData;
    const node = nodesUtils.formatNodeForAPI(nodeData);

    setModalOpen(false);

    const updateErrors = await updateNode(id, node);
    if (updateErrors) {
      setErrors(updateErrors);
      return;
    }

    if (nodeConfigData) {
      const nodeConfig = nodesUtils.formatNodeConfigForAPI(nodeConfigData);
      if (currentNode.hasOwnConfig) {
        const requestErrors = await updateNodeConfig(id, nodeConfig);
        setErrors(requestErrors);
      } else {
        const requestErrors = await createNodeConfig(id, nodeConfig);
        setErrors(requestErrors);
      }
    } else if (currentNode.hasOwnConfig) {
      deleteNodeConfig(id);
    }
  };

  const handleOnSubmit = useCallback(
    async (data) => {
      await setNewNodeData(data);

      if (id && currentNode.hasApprovalRule) {
        setModalOpen(true);
      } else {
        await handleNodeUpdate(data);
      }
    },
    [id, createNodeConfig, updateNodeConfig, updateNode, deleteNodeConfig, currentNode],
  );

  return (
    <React.Fragment>
      <SectionHeaderComponent subtitle={messages.sceneTitle} sectionLabel={messages.sceneLabel} />
      <div className="content__container">
        <BreadCrumbComponent>
          <BreadCrumbItem label={messages.breadcrumbHierarchyLabel} link="/hierarchy" />
          <BreadCrumbItem label={messages.breadcrumbEditNodeLabel} />
        </BreadCrumbComponent>
        <div className="order-details__return-btn">
          <Link to={{ pathname: '/hierarchy' }}>
            <FontAwesomeIcon icon="arrow-left" /> {messages.backButtonText}
          </Link>
        </div>
        <div className="edit-node__container">
          <NodesForm
            onSubmit={handleOnSubmit}
            clearOnSubmit={false}
            currentNodeId={id}
            submitButtonText={messages.submitButtonText}
            isEditForm
            errors={errors}
          />
        </div>
        <ApprovalRuleConfirmationModal
          isOpen={isModalOpen}
          closeHandler={() => setModalOpen(false)}
          confirmHandler={() => handleNodeUpdate()}
          obj={currentNode}
          objName="node"
          action="edit"
        />
      </div>
    </React.Fragment>
  );
}

export default EditNode;
