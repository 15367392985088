import { toast } from 'react-toastify';

import siteManagerAPI from '../../../../lib/api-manager';
import { API_HIERARCHY_GROUPS } from '../../../../lib/api-endpoints';
import { HIERARCHY_SAVE_CURRENT_GROUP } from '../../../../lib/types';
import { FETCH_GROUPS_ERROR } from '../../../../constants/error-messages';

export const fetchCurrentGroup = (id) => async (dispatch) => {
  try {
    const response = await siteManagerAPI.get(`${API_HIERARCHY_GROUPS}${id}`);
    const group = response.data;
    dispatch({ type: HIERARCHY_SAVE_CURRENT_GROUP, payload: { currentGroup: group } });
  } catch (error) {
    toast.error(FETCH_GROUPS_ERROR);
  }
};
