import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { shouldDisplaySpinner as shouldDisplaySpinnerAction } from '../../actions/globals.actions';
import {
  fetchSites as fetchSitesAction,
  resetSites as resetSitesAction,
} from '../../actions/sites.actions';
import { fetchOrganizationGroups as fetchOrganizationGroupsAction } from '../../actions/hierarchy.actions';
import { GENERIC_TRY_LATER } from '../../constants/error-messages';
import { EXPORTING, NOTHING_TO_EXPORT } from '../../constants/messages';
import { API_SITES, API_SITES_V2 } from '../../lib/api-endpoints';
import { exportToExcel, sortByHeaders } from '../../utils/general';
import { UNLIMITED_LIST_LIMIT } from '../../constants/values';
import SectionHeaderComponent from '../shared/section-header.component';
import BreadCrumbComponent from '../shared/breadcumb.component';
import BreadCrumbItem from '../shared/breadcrumb-item';
import SiteListComponent from './site-list.component';
import PaginationComponent from '../shared/pagination.component';
import SiteFormComponent from '../forms/site-form.component';
import AssignUserComponent from '../forms/assign-user.component';
import SiteShuupUser from '../forms/site-shuup-user.component';
import ExportExcelComponent from '../shared/export-excel.component';
import siteManagerAPI from '../../lib/api-manager';
import SearchComponent from '../shared/search.component';
import ReactModal from '../shared/react-modal';

function SitesComponent({
  fetchOrganizationGroups,
  hasOrganizationGroups,
  hasCompanyHierarchy,
  resetSites,
  shouldDisplaySpinner,
  fetchSites,
  user,
  sitesQueryParams,
  excelHeaders,
  sites,
}) {
  const [siteData, setSiteData] = useState(null);
  const [queryParams, setQueryParams] = useState(null);
  const [hasSearch, setHasSearch] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    siteModal: false,
    assignUserModal: false,
    shuupUserModal: false,
  });

  useEffect(() => {
    if (!hasOrganizationGroups && hasCompanyHierarchy) {
      fetchOrganizationGroups();
    }

    return () => {
      if (hasSearch) {
        resetSites();
      }
    };
  }, []);

  const handleQueryParamsChange = (newQueryParams) => {
    setQueryParams(newQueryParams);
  };

  const handleSearch = async (searchStr) => {
    setHasSearch(true);

    shouldDisplaySpinner(true);
    try {
      await fetchSites(`${API_SITES_V2}?search=${searchStr}`);
    } catch (error) {
      toast.error(GENERIC_TRY_LATER);
    } finally {
      shouldDisplaySpinner(false);
    }
  };

  const loadSites = async () => {
    if (user) {
      await shouldDisplaySpinner(true);
      await fetchSites(API_SITES);
      shouldDisplaySpinner(false);
    } else {
      setTimeout(() => {
        console.log('Retrying /sites in 1500ms');
        loadSites();
      }, 1500);
    }
  };

  const resetSite = () => {
    setSiteData(null);
  };

  const openModal = (modal) => {
    const modals = {
      siteModal: false,
      assignUserModal: false,
      shuupUserModal: false,
    };

    if (!modal) {
      setIsModalOpen(modals);
    } else {
      setIsModalOpen({ ...modals, [modal]: true });
    }
  };

  const closeAllModals = () => {
    resetSite();
    openModal(null);
  };

  const onSubmitSiteModal = (goToAssign, newSiteData) => {
    setSiteData(newSiteData);

    if (goToAssign) {
      if (hasCompanyHierarchy) {
        openModal(null);
        return;
      }

      openModal('assignUserModal');
    } else {
      openModal('shuupUserModal');
    }
  };

  const exportFilteredSites = async () => {
    toast.success(EXPORTING);
    shouldDisplaySpinner(true);
    try {
      const params = {
        ...sitesQueryParams,
        limit: UNLIMITED_LIST_LIMIT,
        untrimmed: true,
        ...queryParams,
      };
      const response = await siteManagerAPI.get(`${API_SITES}`, { params });

      // These headers are always going to be included at the end of the excel file
      const mustIncludeHeaders = [
        'site_name',
        'ecommerce_user_email',
        'location_name',
        'site_type',
        'site_warehouse',
        'organization_node',
        'users',
        'userscount',
      ];

      // excelHeaders come from the rendered table
      // snake casing camel cased names in table headers, can't use lodash function because it snake-cases numbers like: k8 -> k_8
      // this also prevent duplicated headers if they exists
      // also kinder morgan headers add "Legacy" to their headers, we are removing that here
      const headers = new Set(
        excelHeaders.map(({ name }) =>
          name
            .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
            .replace(/^legacy_/i, '')
        ),
        ...mustIncludeHeaders
      );

      if (response.data.results.length === 0) {
        toast.warn(NOTHING_TO_EXPORT);
        return;
      }

      // Transform data to consumable by the excel, snake case required
      const parsedSites = response.data.results.map((site) => {
        const parsedUsers = site.users_user.map(
          ({ first_name: firstName, last_name: lastName }) =>
            `${firstName} ${lastName}`
        );
        return {
          site_name: site.name,
          ecommerce_user_email: site.ecommerce_user_email,
          location_name: site.location_name,
          site_type: site.site_type.name,
          site_warehouse: site.site_warehouse ? site.site_warehouse.name : '',
          organization_node: site.node?.name,
          users: parsedUsers.join(', '),
          userscount: site.users_count,
          ...site.groups,
        };
      });

      const sortedSites = sortByHeaders(parsedSites, [...headers]);
      exportToExcel(sortedSites, 'sites');
    } catch (error) {
      toast.error(GENERIC_TRY_LATER);
      console.log( {error} );
    }

    shouldDisplaySpinner(false);
  };

  return (
    <>
      <SectionHeaderComponent subtitle="Company" sectionLabel="Sites" />

      <div className="content__container">
        <BreadCrumbComponent>
          <BreadCrumbItem label="Sites" />
        </BreadCrumbComponent>

        <div className="container-fluid">
          <div className="table-tools">
            <div className="table-tools__search">
              <SearchComponent handleSearch={handleSearch} />
            </div>
            <div className="table-tools__btns">
              {user.isCompanyAdmin && (
                <button
                  type="button"
                  onClick={() => openModal('siteModal')}
                  className="btn btn-primary table-tools__btn"
                >
                  <FontAwesomeIcon
                    className="btn__icon"
                    icon="map-marker-alt"
                  />{' '}
                  Create Site
                </button>
              )}
              <ExportExcelComponent exportData={exportFilteredSites} />
            </div>
            <ReactModal
              isOpen={isModalOpen.siteModal}
              title="Create New Site"
              size="modal-xs"
              body={
                <SiteFormComponent
                  onSubmit={onSubmitSiteModal}
                  site={siteData}
                />
              }
              closeModal={closeAllModals}
            />
            <ReactModal
              isOpen={isModalOpen.assignUserModal}
              title="Assign Users to Site"
              size="modal-lg"
              body={
                <AssignUserComponent onSubmit={resetSite} site={siteData} />
              }
              closeModal={closeAllModals}
            />
            <ReactModal
              isOpen={isModalOpen.shuupUserModal}
              title="Create Shop User"
              size="modal-xs"
              body={
                <SiteShuupUser
                  site={siteData}
                  onGoBack={() => openModal('siteModal')}
                  onSubmit={(newSiteData) => {
                    setSiteData(newSiteData);
                    loadSites();
                    if (hasCompanyHierarchy) {
                      openModal(null);
                      return;
                    }

                    openModal('assignUserModal');
                  }}
                />
              }
              closeModal={closeAllModals}
            />
          </div>

          <PaginationComponent />

          {/* Limit the numbers of Sites to render to 20  (default pagination
            results from the end-points) to avoid try to render all the Sites when state
            was updated with them. This avoids crashing when the number of sites is high i.e KM
            */}
          <SiteListComponent
            siteList={sites.length <= 20 ? sites : []}
            handleQueryParamsChange={handleQueryParamsChange}
          />

          <PaginationComponent />
        </div>
      </div>
    </>
  );
}

SitesComponent.propTypes = {
  user: PropTypes.shape({
    isCompanyAdmin: PropTypes.bool,
  }).isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.string,
      division: PropTypes.string,
      locationName: PropTypes.string,
      businessUnit: PropTypes.string,
      region: PropTypes.string,
      ecommerceUsername: PropTypes.string,
      ecommerceEmail: PropTypes.string,
    })
  ).isRequired,
  hasOrganizationGroups: PropTypes.bool,
  hasCompanyHierarchy: PropTypes.bool.isRequired,
  shouldDisplaySpinner: PropTypes.func.isRequired,
  fetchOrganizationGroups: PropTypes.func.isRequired,
  fetchSites: PropTypes.func.isRequired,
  sitesQueryParams: PropTypes.shape(),
  resetSites: PropTypes.func.isRequired,
  excelHeaders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

SitesComponent.defaultProps = {
  sitesQueryParams: {},
  hasOrganizationGroups: null,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  sites: state.sites.sites.results,
  sitesQueryParams: _.get(
    state,
    'state.sites.sites.pagination.queryParams',
    {}
  ),
  hasOrganizationGroups: state.hierarchy && state.hierarchy.groups.length > 0,
  hasCompanyHierarchy: _.get(
    state,
    'auth.user.company.hasCompanyHierarchy',
    false
  ),
  excelHeaders: state.globals.excelHeaders,
});

export default connect(mapStateToProps, {
  fetchSites: fetchSitesAction,
  fetchOrganizationGroups: fetchOrganizationGroupsAction,
  shouldDisplaySpinner: shouldDisplaySpinnerAction,
  resetSites: resetSitesAction,
})(SitesComponent);
