import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

export default function useActions(actions) {
  const dispatch = useDispatch();
  return useMemo(() => {
    if (_.isPlainObject(actions)) {
      return _.mapValues(actions, (action) => bindActionCreators(action, dispatch));
    }
    return bindActionCreators(actions, dispatch);
  }, [dispatch, actions]);
}
