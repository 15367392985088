import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import Logo from './logo.component';

const HttpErrorComponent = ({ company }) => (
  <div className="http-error content">
    <div className="http-error__section">
      <Logo src={company.logo} alt={company.label} size="big" />
    </div>

    <div className="http-error__section http-error__section--title">
      <span className="http-error__title text-primary bold">Error</span>
      <span className="http-error__subtitle bold">Page Not Found (404)</span>
    </div>

    <div className="http-error__section">
      <span className="http-error__detail thin">
        <FontAwesomeIcon icon="info-circle" className="text-primary" />
        Unfortunately the page you were looking for does not exist.
      </span>
    </div>
  </div>
);

HttpErrorComponent.propTypes = {
  company: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  company: state.globals.company,
});

export default connect(mapStateToProps, {})(withRouter(HttpErrorComponent));
