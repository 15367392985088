import { toast } from 'react-toastify';

import siteManagerAPI from '../../lib/api-manager';
import { API_HIERARCHY_POLICIES } from '../../lib/api-endpoints';
import { POLICY_SUCCESSFULLY_UPDATED } from '../../constants/messages';
import { UPDATE_POLICY_ERROR } from '../../constants/error-messages';

export async function updatePolicy(id, body) {
  try {
    await siteManagerAPI.patch(API_HIERARCHY_POLICIES.replace('{id}', id), body);
    toast.success(POLICY_SUCCESSFULLY_UPDATED);
  } catch (error) {
    toast.error(UPDATE_POLICY_ERROR);
  }
}
