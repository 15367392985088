import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import Logo from './logo.component';

const SplashComponent = ({ company, shouldDisplay }) => {
  const { logoSplash, label } = company;

  return (
    <Fragment>
      {shouldDisplay && (
        <div className="splash">
          <Logo src={logoSplash} alt={label} size="medium" />

          <FontAwesomeIcon icon="spinner" className="fa-spin splash__spinner" />
        </div>
      )}
    </Fragment>
  );
};

SplashComponent.propTypes = {
  company: PropTypes.shape().isRequired,
  shouldDisplay: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  shouldDisplay: state.globals.shouldDisplaySplashScreen,
  company: state.globals.company,
});

export default connect(mapStateToProps, {})(SplashComponent);
