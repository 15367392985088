import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ApprovalRuleConfirmationModal from 'components/shared/approval-rule-confirmation-modal.component';
import TableComponent from '../../shared/table.component';
import TableActions from '../../shared/table-actions.component';

class RolesList extends Component {
  static propTypes = {
    roles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    fetchRolesWithParams: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      roles: props.roles,
      headers: [
        {
          text: 'Name',
          name: 'name',
          filterType: '',
          isSortable: true,
        },
        {
          text: 'Actions',
          name: 'actions',
          filterType: '',
          isSortable: false,
          class: 'text-right',
        },
      ],
      isModalOpen: false,
      modalRole: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      roles: nextProps.roles || [],
    });
  }

  onEdit = (role) => () => {
    const { handleEdit } = this.props;

    handleEdit(role);
  };

  renderRoleName = (role) => {
    return <div className="min-vw-50">{role.name}</div>;
  };

  displayDeleteModal = (role) => {
    this.setState({ isModalOpen: true, modalRole: role });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false, modalRole: null });
  };

  render() {
    const { roles, headers, isModalOpen, modalRole } = this.state;

    const parsedRoles = roles.map((role) => ({
      ...role,
      name: { customCell: true, cell: role },
      actions: (
        <TableActions edit={this.onEdit(role)} remove={() => this.displayDeleteModal(role)} />
      ),
    }));

    const { fetchRolesWithParams, handleDelete } = this.props;

    const handleDeleteConfirmation = (role) => {
      this.setState({ isModalOpen: false });
      handleDelete(role);
    };

    return (
      <React.Fragment>
        <TableComponent
          headers={headers}
          rows={parsedRoles}
          fetchTableData={fetchRolesWithParams}
          emptyMessage="Company does not have user's roles"
          renderCustomCell={(cell) => this.renderRoleName(cell)}
        />

        <ApprovalRuleConfirmationModal
          isOpen={isModalOpen}
          closeHandler={this.closeModal}
          confirmHandler={() => handleDeleteConfirmation(modalRole)}
          obj={modalRole}
          objName="role"
          action="delete"
        />
      </React.Fragment>
    );
  }
}

export default RolesList;
