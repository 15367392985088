import React, { Component, Fragment } from 'react';

import siteManagerAPI from '../../lib/api-manager';
import { API_RESET_PASSWORD } from '../../lib/api-endpoints';
import TextInput from '../forms/text-input.component';
import { getFieldValue } from '../../utils/forms';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userIdentifier: '',
      isResetPasswordLinkSend: false,
      errors: {},
    };
  }

  handleChange = (event) => {
    this.setState(getFieldValue(event.target));
  };

  submitResetPasswordForm = (event) => {
    event.preventDefault();

    const errors = {};
    const { userIdentifier } = this.state;

    if (!userIdentifier) errors.userIdentifier = 'Name or Email is required';

    this.setState({ errors });

    if (JSON.stringify(errors) === JSON.stringify({})) {
      this.resetPassword();
    }
  };

  resetPassword = async () => {
    const { userIdentifier } = this.state;
    const body = { userIdentifier };

    const errors = {};
    try {
      await siteManagerAPI.post(API_RESET_PASSWORD, body);
      this.setState({ isResetPasswordLinkSend: true, errors });
    } catch (error) {
      if (error.response.status === 404) {
        errors.userIdentifier = 'We cannot find an account with that username or email address';
      } else {
        errors.userIdentifier = error.message;
      }
      this.setState({ errors });
    }
  };

  render() {
    const { isResetPasswordLinkSend, errors, userIdentifier } = this.state;

    return isResetPasswordLinkSend ? (
      <Fragment>
        <div className="alert alert-secondary" role="alert">
          <span className="medium-text">
            We just emailed you a link you can use to reset your password
          </span>
        </div>

        <div className="row">
          <div className="col-12 text-primary text-center">
            <a className="text-primary medium-text d-block" href="/">
              Go back to login
            </a>
          </div>
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <div className="row">
          <div className="form-group col-12">
            <form onSubmit={this.submitResetPasswordForm}>
              <TextInput
                error={errors.userIdentifier}
                value={userIdentifier}
                label="Username or email address"
                name="userIdentifier"
                handleChange={this.handleChange}
                inputClassName="login__input medium-bold"
              />

              <button type="submit" className="btn btn-primary btn-round w-100 medium-text mt-2">
                Reset Password
              </button>
            </form>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <a className="text-center medium-text text-primary d-block" href="/">
              Go back to login &raquo;
            </a>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ForgotPassword;
