import _ from 'lodash';

export function removeFalsyValues(object) {
  return _.pickBy(object, _.identity);
}

export function objectToCamelCase(object) {
  return _.mapKeys(object, (value, key) => _.camelCase(key));
}

export function objectToSnakeCase(object) {
  return _.mapKeys(object, (value, key) => _.snakeCase(key));
}

export function formatErrorResponse(errorData) {
  return _.mapValues(objectToCamelCase(errorData), (value) =>
    Array.isArray(value) ? value[0] : value,
  );
}
