import React, { useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import TableFunctions from '../../../../components/shared/table-functions';
import TableActions from '../../../../components/shared/table-actions.component';
import useActions from '../../../../shared/hooks/useActions';
import { useSingleSelector } from '../../../../shared/hooks/useSelector';
import TrimmedUnorderedList from '../../../../components/shared/trimmed-unordered-list.component';
import { HIERARCHY_GROUPS } from '../../../../constants/app-routes';

import * as actions from './actions';
import * as selectors from './selectors';
import messages from './messages';
import { API_HIERARCHY_GROUPS } from '../../../../lib/api-endpoints';

const headers = [
  {
    text: messages.name,
    name: 'name',
    filterType: 'INPUT',
    isActivated: false,
  },
  {
    text: messages.nodes,
    name: 'nodes',
    component: TrimmedUnorderedList,
    isSortable: false,
  },
  {
    text: messages.actions,
    name: 'actions',
    class: 'text-right',
    isSortable: false,
  },
];

function GroupsTable() {
  const fetchTableGroups = useActions(actions.fetchTableGroups);
  const groups = useSingleSelector(selectors.groups);
  const history = useHistory();

  useEffect(() => {
    fetchTableGroups();
  }, [fetchTableGroups]);

  const buildGoToGroup = useCallback((group) => () => history.push(`${HIERARCHY_GROUPS}${group}`), [
    history,
  ]);

  const groupsRowsData = useMemo(
    () =>
      groups.map((group) => ({
        ...group,
        actions: <TableActions edit={buildGoToGroup(group.id)} />,
      })),
    [groups, buildGoToGroup],
  );

  return <TableFunctions
    headers={headers}
    rowsData={groupsRowsData}
    fetchData={(dataUrl, queryParams) => fetchTableGroups(dataUrl, queryParams)}
    dataURL={API_HIERARCHY_GROUPS}
  />;
}

export default GroupsTable;
