export default {
  selectInputPlaceholder: 'Select...',
  nameInputLabel: 'Name',
  idInputLabel: 'Node ID',
  parentNodeInputLabel: 'Parent node',
  groupInputLabel: 'Group',
  sitesInputLabel: 'Sites',
  usersInputLabel: 'Users',
  customConfigurationLabel: 'Add custom configuration',
  configurationInNextStep: 'The configuration will be created in the next step',
  createButtonText: 'Create Node',
};
