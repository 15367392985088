export default {
  sceneTitle: 'Document Repository',
  documentType: 'Document Type',
  documentName: 'Name',
  downloadFailed: 'There was an error downloading your document, try again later',
  orderIdentifier: 'Order',
  poNumber: 'PO Number',
  invoice: 'Invoice',
  afeNumber: 'AFE',
  documentDate: 'Document Date',
  actions: 'Actions',
  select: 'Select',
};
