import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'react-tooltip';

const TableActionButton = ({ tip, icon, onClick, type }) => (
  <button
    className={`btn btn-primary table-component__action table-component__action--${type} clickable ml-2`}
    data-for="table-buttons-tooltip"
    data-tip={tip}
    onClick={onClick}
    data-testid={icon}
    type="button"
  >
    <FontAwesomeIcon icon={icon} />
  </button>
);

TableActionButton.propTypes = {
  tip: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
};

TableActionButton.defaultProps = {
  type: '',
};

function TableActions(props) {
  const {
    clone,
    edit,
    view,
    approve,
    remove,
    reject,
    loginAs,
    track,
    viewAndDownload,
    documents,
    importNodes,
    importSites,
    children,
  } = props;
  return (
    <div className="table-component__actions">
      {track && <TableActionButton tip="Track order" icon="truck" onClick={track} />}
      {view && <TableActionButton tip="View" icon="search" onClick={view} />}
      {documents && <TableActionButton tip="See documents" icon="folder" onClick={documents} />}
      {edit && <TableActionButton tip="Edit" icon="edit" onClick={edit} />}
      {clone && <TableActionButton tip="Clone" icon="clone" onClick={clone} />}
      {approve && <TableActionButton tip="Approve" icon="thumbs-up" onClick={approve} />}
      {reject && <TableActionButton tip="Reject" icon="ban" onClick={reject} type="danger" />}
      {loginAs && (
        <TableActionButton tip="Login to Ecommerce As" icon="sign-in-alt" onClick={loginAs} />
      )}
      {remove && <TableActionButton tip="Delete" icon="trash-alt" onClick={remove} type="danger" />}
      {viewAndDownload && (
        <TableActionButton tip="View and Download" icon="file-download" onClick={viewAndDownload} />
      )}
      {importNodes && <TableActionButton tip="Upload Nodes" icon="upload" onClick={importNodes} />}
      {importSites && <TableActionButton tip="Upload Sites" icon="upload" onClick={importSites} />}
      {children}
      <Tooltip id="table-buttons-tooltip" />
    </div>
  );
}

TableActions.defaultProps = {
  clone: false,
  edit: false,
  view: false,
  approve: false,
  remove: false,
  reject: false,
  loginAs: false,
  track: false,
  viewAndDownload: false,
  documents: false,
  importNodes: false,
  importSites: false,
  children: null,
};

TableActions.propTypes = {
  clone: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  edit: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  view: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  approve: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  remove: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  reject: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  loginAs: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  track: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  viewAndDownload: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  documents: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  importNodes: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  importSites: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  children: PropTypes.node,
};

export default TableActions;
