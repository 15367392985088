import OrderSerializer from './OrderSerializer';
import PaginationSerializer from './PaginationSerializer';

export default class OrdersSerializer {
  constructor(data) {
    const ordersData = data || {};

    this.results = Array.isArray(ordersData.results)
      ? ordersData.results.map((order) => new OrderSerializer(order))
      : [];
    this.pagination = new PaginationSerializer(ordersData);
  }
}
