import React, { Fragment, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';

const getId = (() => {
  let currentId = -1;
  return () => {
    currentId += 1;
    return currentId;
  };
})();

const ModalComponent = ({ modal, onClose, centeredButtons }) => {
  const { size, title, jsx, component, buttons, componentProps } = modal;
  const modalClass = `modal-dialog ${size}`;
  const ModalContent = component;
  const props = componentProps || {};

  $('#shared-modal').on('hidden.bs.modal', onClose);

  useEffect(() => {
    return () => {
      $('#shared-modal').off('hidden.bs.modal', onClose);
    };
  });

  return (
    <Fragment>
      <div
        className="modal fade shared-modal"
        id="shared-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="shared-modal-label"
        aria-hidden="true"
      >
        <div className={modalClass} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {title}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <FontAwesomeIcon icon="times" />
              </button>
            </div>
            <div className="modal-body">
              {jsx && <Fragment>jsx</Fragment>}
              {ModalContent && (
                <Fragment>
                  <ModalContent {...props} />
                </Fragment>
              )}
            </div>
            <div className={`modal-footer ${centeredButtons && 'justify-content-center'}`}>
              {buttons && buttons.map((b) => <Fragment key={getId()}>{b}</Fragment>)}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ModalComponent.propTypes = {
  modal: PropTypes.shape({
    size: PropTypes.string,
    title: PropTypes.string,
    jsx: PropTypes.node,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    buttons: PropTypes.arrayOf(PropTypes.object),
    componentProps: PropTypes.object,
  }).isRequired,
  onClose: PropTypes.func,
  centeredButtons: PropTypes.bool,
};

ModalComponent.defaultProps = {
  onClose: () => {},
  centeredButtons: false,
};

const mapStateToProps = ({ globals }) => ({
  modal: globals.modal,
  onClose: globals.onClose,
  centeredButtons: globals.modal.centeredButtons,
});

export default connect(mapStateToProps)(ModalComponent);
