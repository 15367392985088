import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import { usePathname } from 'shared/hooks/useLocation';
import DocumentRepository, { OrderDocumentsRepository } from 'scenes/document-repository';
import AdminCompaniesPage from 'scenes/admin/companies';
import AdminCreateCompany from 'scenes/admin/companies/components/create-company';
import AdminCreateCompanyConfiguration from 'scenes/admin/companies/components/create-company-configuration';
import AdminEditCompanyConfiguration from 'scenes/admin/companies/components/edit-company-configuration';
import AdminEditCompany from 'scenes/admin/companies/components/edit-company';
import AdminCreateCompanyNodes from 'scenes/admin/companies/components/create-company-nodes';
import AdminCreateCompanySites from 'scenes/admin/companies/components/create-company-sites';
import HttpErrorComponent from '../../../components/shared/httperror.component';
import AnalyticsComponent from '../../../components/analytics/analytics.component';
import OrdersComponent from '../../../components/orders/orders.component';
import PendingApprovals from '../../../components/orders/pending-approvals/pending-approvals';
import OrderDetailPage from '../../../components/orders/order-detail-page.component';
import SitesComponent from '../../../components/sites/sites.component';
import AlertsComponent from '../../../components/alerts/alerts.component';
import AlertTriggersComponent from '../../../components/alerts/alert-triggers.component';
import UsersComponent from '../../../components/users/users.component';
import UserDetailComponent from '../../../components/users/user-detail.component';
import HomeComponent from '../../../components/home/home.component';
import SiteDetailsComponent from '../../../components/sites/site-details.component';
import ConfigurationComponent from '../../../components/configuration/configuration';
import SitesUploads from '../../../components/configuration/uploads/sites-uploads';
import RolesConfiguration from '../../../components/configuration/roles/roles-configuration';
import RoleEdit from '../../../components/configuration/roles/role-edit';
import ApprovalRulesConfiguration from '../../../components/configuration/approval-rules/approval-rules-configuration';
import ApprovalRuleEdit from '../../../components/configuration/approval-rules/approval-rule-edit';
import EcommerceLoginConfiguration from '../../../components/configuration/ecommerce-login/ecommerce-login-configuration';
import GeneralConfiguration from '../../../components/configuration/general-configuration';
import BillingAddressForm from '../../../components/configuration/billing-form';
import SiteTypesConfiguration from '../../../components/configuration/site-types/site-types-configuration';
import SiteTypeEdit from '../../../components/configuration/site-types/site-type-edit';
import OrderTrackingConfiguration from '../../../components/configuration/order-tracking/order-tracking-configuration';
import Hierarchy from '../../../scenes/hierarchy';
import Groups from '../../../scenes/groups';
import EditNode from '../../../scenes/edit-node';
import EditGroup from '../../../scenes/edit-group';
import Policies from '../../../scenes/policies';
import EditPolicy from '../../../scenes/edit-policy';
import Tree from '../../../scenes/hierarchy/components/tree';
import useActions from '../../hooks/useActions';
import { fetchDocuments } from '../../../actions/documents.actions';
import { API_USER_DOCUMENTS } from '../../../lib/api-endpoints';

function Router(props) {
  const {
    withAdminRoutes,
    withApprovalsRoutes,
    withHierarchyRoutes,
    withOrderTrackingRoutes,
    withMccAdminRoutes,
    withDefaultRoutes,
  } = props;

  const pathname = usePathname();

  const fetchDocumentsAction = useActions(fetchDocuments);

  useEffect(() => {
    if (!['/documents'].some((el) => pathname.startsWith(el))) {
      const queryParams = { ordering: '-order_date' };
      fetchDocumentsAction(API_USER_DOCUMENTS, queryParams, true);
    }
  });

  const renderAdminRoutes = useCallback(() => {
    if (!withAdminRoutes) return null;
    return [
      <Route key="admin_1" path="/sites/:siteId" component={SiteDetailsComponent} />,
      <Route key="admin_2" path="/users/:userId" component={UserDetailComponent} />,
      <Route key="admin_3" exact path="/users" component={UsersComponent} />,
      <Route key="admin_4" exact path="/configuration" component={ConfigurationComponent} />,
      <Route key="admin_5" exact path="/configuration/roles" component={RolesConfiguration} />,
      <Route key="admin_6" path="/configuration/roles/:roleId" component={RoleEdit} />,
      <Route key="admin_9" exact path="/configuration/general" component={GeneralConfiguration} />,
      <Route
        key="admin_10"
        exact
        path="/configuration/general/billing"
        component={BillingAddressForm}
      />,
      <Route
        key="admin_11"
        path="/configuration/ecommerce-login/"
        component={EcommerceLoginConfiguration}
      />,
      <Route key="admin_12" exact path="/configuration/uploads" component={SitesUploads} />,
      <Route
        key="admin_13"
        exact
        path="/configuration/site-types"
        component={SiteTypesConfiguration}
      />,
      <Route
        key="admin_14"
        exact
        path="/configuration/site-types/:siteTypeId"
        component={SiteTypeEdit}
      />,
    ];
  }, [withAdminRoutes]);

  const renderApprovalRoutes = useCallback(() => {
    if (!withApprovalsRoutes) return null;
    return [
      <Route
        key="approval_1"
        exact
        path="/configuration/approvals"
        component={ApprovalRulesConfiguration}
      />,
      <Route
        key="approval_2"
        path="/configuration/approvals/:approvalRuleId"
        component={ApprovalRuleEdit}
      />,
    ];
  }, [withApprovalsRoutes]);

  const renderHierarchyRoutes = useCallback(() => {
    if (!withHierarchyRoutes) return null;
    return [
      <Route key="hierarchy_1" exact path="/hierarchy" component={Hierarchy} />,
      <Route key="hierarchy_2" exact path="/hierarchy/groups" component={Groups} />,
      <Route key="hierarchy_3" exact path="/hierarchy/groups/:id" component={EditGroup} />,
      <Route key="hierarchy_4" exact path="/hierarchy/policies" component={Policies} />,
      <Route key="hierarchy_5" exact path="/hierarchy/policies/:id" component={EditPolicy} />,
      <Route key="hierarchy_6" exact path="/hierarchy/tree" component={Tree} />,
      <Route key="hierarchy_7" exact path="/hierarchy/:id" component={EditNode} />,
    ];
  }, [withHierarchyRoutes]);

  const renderOrderTrackingRoutes = useCallback(() => {
    if (!withOrderTrackingRoutes) return null;
    return [
      <Route
        key="tracking_1"
        exact
        path="/configuration/order-tracking"
        component={OrderTrackingConfiguration}
      />,
    ];
  }, [withOrderTrackingRoutes]);

  const renderMccAdminRoutes = useCallback(() => {
    if (!withMccAdminRoutes) return null;
    return [
      <Route key="mcc_admin_1" exact path="/" component={AdminCompaniesPage} />,
      <Route key="mcc_admin_2" exact path="/companies" component={AdminCompaniesPage} />,
      <Route key="mcc_admin_3" exact path="/companies/create" component={AdminCreateCompany} />,
      <Route key="mcc_admin_4" exact path="/companies/:id" component={AdminEditCompany} />,
      <Route
        key="mcc_admin_5"
        exact
        path="/companies/:companyId/configuration/create"
        component={AdminCreateCompanyConfiguration}
      />,
      <Route
        key="mcc_admin_6"
        exact
        path="/companies/:companyId/configuration/edit"
        component={AdminEditCompanyConfiguration}
      />,
      <Route
        key="mcc_admin_7"
        exact
        path="/companies/:companyId/nodes/upload"
        component={AdminCreateCompanyNodes}
      />,
      <Route
        key="mcc_admin_8"
        exact
        path="/companies/:companyId/sites/upload"
        component={AdminCreateCompanySites}
      />,
    ];
  }, [withMccAdminRoutes]);

  const renderDefaultRoutes = useCallback(() => {
    if (!withDefaultRoutes) return null;
    return [
      <Route key="default_1" exact path="/" component={HomeComponent} />,
      <Route key="default_2" path="/sites" component={SitesComponent} />,
      <Route key="default_3" exact path="/orders/:orderId" component={OrderDetailPage} />,
      <Route key="default_4" exact path="/orders" component={OrdersComponent} />,
      <Route key="default_5" path="/pending-approvals" component={PendingApprovals} />,
      <Route key="default_6" path="/alerts/configure" component={AlertTriggersComponent} />,
      <Route key="default_7" path="/alerts" component={AlertsComponent} />,
      <Route key="default_8" path="/analytics" component={AnalyticsComponent} />,
      <Route
        key="default_9"
        exact
        path="/orders/:orderId/documents"
        component={OrderDocumentsRepository}
      />,
      <Route key="default_10" exact path="/documents" component={DocumentRepository} />,
    ];
  }, [withDefaultRoutes]);

  return (
    <Switch>
      {renderAdminRoutes()}
      {renderApprovalRoutes()}
      {renderHierarchyRoutes()}
      {renderOrderTrackingRoutes()}
      {renderMccAdminRoutes()}
      {renderDefaultRoutes()}
      <Route component={HttpErrorComponent} />
    </Switch>
  );
}

export default Router;

Router.propTypes = {
  withAdminRoutes: PropTypes.bool,
  withApprovalsRoutes: PropTypes.bool,
  withHierarchyRoutes: PropTypes.bool,
  withOrderTrackingRoutes: PropTypes.bool,
  withMccAdminRoutes: PropTypes.bool,
  withDefaultRoutes: PropTypes.bool,
};

Router.defaultProps = {
  withAdminRoutes: false,
  withApprovalsRoutes: false,
  withHierarchyRoutes: false,
  withOrderTrackingRoutes: false,
  withMccAdminRoutes: false,
  withDefaultRoutes: true,
};
