import React, { useCallback } from 'react';
import ProptTypes from 'prop-types';

import PoliciesForm from '../policies-form';
import * as hierarchyActions from '../../actions/hierarchy';
import useActions from '../../hooks/useActions';

import * as actions from './actions';

function CreatePolicy(props) {
  const { closeModal } = props;
  const fetchPolicies = useActions(hierarchyActions.fetchTablePolicies);

  const handleOnSubmit = useCallback(
    async (userId, data) => {
      await actions.createPolicy(userId, data);
      closeModal();
      fetchPolicies();
    },
    [closeModal, fetchPolicies],
  );

  return <PoliciesForm onSubmit={handleOnSubmit} />;
}

CreatePolicy.propTypes = {
  closeModal: ProptTypes.func.isRequired,
};

export default CreatePolicy;
