import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { PropTypes } from 'prop-types';

import { fetchOrders } from '../../../actions/orders.actions';
import { shouldDisplaySpinner } from '../../../actions/globals.actions';
import { API_ORDERS, API_PENDING_ORDERS } from '../../../lib/api-endpoints';
import { UNLIMITED_LIST_LIMIT, ORDER_STATUS } from '../../../constants/values';
import { exportToExcel } from '../../../utils/general';
import { GENERIC_TRY_LATER } from '../../../constants/error-messages';
import { EXPORTING, NOTHING_TO_EXPORT } from '../../../constants/messages';
import siteManagerAPI from '../../../lib/api-manager';
import ExportExcelComponent from '../../shared/export-excel.component';
import SectionHeaderComponent from '../../shared/section-header.component';
import PendingApprovalsList from './pending-approvals-list';
import PaginationComponent from '../../shared/pagination.component';
import SearchComponent from '../../shared/search.component';
import BreadCrumbComponent from '../../shared/breadcumb.component';
import OrderSerializer from '../../../lib/serializers/OrderSerializer';
import BreadCrumbItem from '../../shared/breadcrumb-item';

class PendingApprovals extends Component {
  static propTypes = {
    orders: PropTypes.shape(),
    shouldDisplaySpinner: PropTypes.func.isRequired,
    fetchOrders: PropTypes.func.isRequired,
    ordersQueryParams: PropTypes.shape({ limit: PropTypes.string }),
  };

  static defaultProps = {
    orders: {},
    ordersQueryParams: {},
  };

  handleSearch = async (searchStr) => {
    const { shouldDisplaySpinner, fetchOrders } = this.props;

    shouldDisplaySpinner(true);

    try {
      await fetchOrders(
        `${API_ORDERS}?search=${searchStr}&status=${ORDER_STATUS.PENDING_APPROVAL.TEXT}`,
      );
    } catch (error) {
      toast.error(GENERIC_TRY_LATER);
    } finally {
      shouldDisplaySpinner(false);
    }
  };

  exportFilteredSites = async () => {
    const { ordersQueryParams } = this.props;

    ordersQueryParams.limit = UNLIMITED_LIST_LIMIT;
    try {
      toast.success(EXPORTING);
      const response = await siteManagerAPI.get(`${API_PENDING_ORDERS}`, {
        params: ordersQueryParams,
      });

      if (response.data.results.length === 0) {
        toast.warn(NOTHING_TO_EXPORT);
        return;
      }

      const parsedOrders = response.data.results.map((order) => {
        const orderSerialized = new OrderSerializer(order);

        return {
          site: orderSerialized.site,
          business_unit: orderSerialized.siteBU,
          order: orderSerialized.identifier,
          po: orderSerialized.po,
          status: orderSerialized.status,
          lines: orderSerialized.lineLength,
          total: orderSerialized.total,
          date: orderSerialized.created,
        };
      });

      exportToExcel(parsedOrders, 'orders', { total: true });
    } catch (error) {
      toast.error(GENERIC_TRY_LATER);
    }
  };

  render() {
    const { orders } = this.props;

    return (
      <Fragment>
        <SectionHeaderComponent subtitle="Company" sectionLabel="Pending Approvals" />

        <div className="content__container">
          <BreadCrumbComponent>
            <BreadCrumbItem label="Pending Approvals" />
          </BreadCrumbComponent>

          <div className="container-fluid">
            <div className="table-tools">
              <div className="table-tools__search">
                <SearchComponent handleSearch={this.handleSearch} />
              </div>

              <div className="table-tools__btns">
                <ExportExcelComponent exportData={this.exportFilteredSites} />
              </div>
            </div>

            <PaginationComponent />
            <PendingApprovalsList orderList={orders} />
            <PaginationComponent />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  permissions: state.auth.permissions,
  orders: state.orders.orders.results,
  ordersQueryParams: state.orders.orders.pagination.queryParams || {},
});

export default connect(mapStateToProps, {
  fetchOrders,
  shouldDisplaySpinner,
})(PendingApprovals);
