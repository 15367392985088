import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import $ from 'jquery';
import 'react-dates/initialize';

import {
  API_PENDING_ORDERS,
  API_ORDERS_SUGGESTIONS,
  API_ORDERS_APPROVE,
} from '../../../lib/api-endpoints';
import { setModalData as setModalDataAction } from '../../../actions/globals.actions';
import { fetchOrders as fetchOrdersAction } from '../../../actions/orders.actions';
import { formatFixNumber } from '../../../utils/format';
import { TABLE_FIELD_NAMES } from '../../../constants/values';
import siteManagerAPI from '../../../lib/api-manager';
import TableFunctions from '../../shared/table-functions';
import TableActions from '../../shared/table-actions.component';
import RejectOrderModal from '../reject-order-modal';
import ConfirmationModal from '../../shared/confirmation-modal.component';
import TrimmedUnorderedList from '../../shared/trimmed-unordered-list.component';

const getHeaders = (user) => {
  const headers = [
    {
      text: TABLE_FIELD_NAMES.SITE,
      name: 'site',
      filterType: 'INPUT',
      isActivated: false,
    },
    {
      text: TABLE_FIELD_NAMES.BUSINESS_UNIT_INITIALS,
      name: 'legacySiteBU',
      filterType: 'INPUT',
      isActivated: false,
    },
    {
      text: TABLE_FIELD_NAMES.ORDER,
      name: 'identifier',
      filterType: 'INPUT',
      isActivated: false,
      trimmText: true,
    },
    {
      text: TABLE_FIELD_NAMES.PO,
      name: 'po',
      filterType: 'INPUT',
      isActivated: false,
      trimmText: true,
    },
    {
      text: TABLE_FIELD_NAMES.CREATED,
      name: 'orderDate',
      filterType: 'CALENDAR',
      isActivated: false,
    },
    {
      text: TABLE_FIELD_NAMES.LINES,
      name: 'lineLength',
      filterType: '',
    },
    {
      text: TABLE_FIELD_NAMES.TOTAL,
      name: 'total',
      filterType: '',
    },
    {
      text: TABLE_FIELD_NAMES.APPROVED_BY,
      name: 'approvedBy',
      component: TrimmedUnorderedList,
      filterType: '',
      isSortable: false,
    },
  ];

  if (user.isCompanyAdmin) {
    headers.push({
      text: TABLE_FIELD_NAMES.NEXT_APPROVAL,
      name: 'currentRequiredApproval',
      filterType: '',
      isSortable: false,
    });
  }
  if (user.isApprover) {
    headers.push(
      {
        text: TABLE_FIELD_NAMES.APPROVAL_STATUS,
        name: 'approvalStatus',
        filterType: '',
        isSortable: false,
      },
      {
        text: TABLE_FIELD_NAMES.ACTIONS,
        name: 'actions',
        filterType: '',
        isSortable: false,
      }
    );
  }

  return headers;
};

function ApproveOrderModalMessage({ identifier, total }) {
  return (
    <div>
      Do you want to approve order <span className="bold">{identifier}</span>{' '}
      with total of
      <span className="bold"> ${formatFixNumber(total)}</span>?
    </div>
  );
}

ApproveOrderModalMessage.propTypes = {
  identifier: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
};

const renderRedirect = (redirect, orderId, orders, action) =>
  redirect && (
    <Redirect
      to={{
        pathname: `/orders/${orderId}`,
        order: orders.find((order) => order.identifier === orderId),
        action,
      }}
    />
  );

const parseOrder = (
  order,
  handleRedirect,
  renderApproveOrderModal,
  renderRejectOrderModal
) => ({
  ...order,
  order: order.identifier,
  total: `$${formatFixNumber(order.total)}`,
  date: order.created,
  approvedBy: {
    list: order.approvedBy.map((user) => user.username),
    count: order.approvedByCount,
  },
  approvalStatus: order.approvalStatus,
  legacySiteBU: order.siteBU,
  actions: order.approvableByCurrentUser ? (
    <TableActions
      view={() => handleRedirect(order.identifier, 'View')}
      approve={() => renderApproveOrderModal(order)}
      reject={() => renderRejectOrderModal(order)}
    />
  ) : (
    <TableActions view={() => handleRedirect(order.identifier, 'View')} />
  ),
});

function PendingApprovalsList({
  orderList,
  ordersQueryParams,
  setModalData,
  fetchOrders,
  user,
}) {
  const [orders, setOrders] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [orderIdentifier, setOrderIdentifier] = useState(null);
  const headers = getHeaders(user);
  const [action, setAction] = useState(null);

  const handleRedirect = (orderId, actionParam) => {
    setRedirect(true);
    setOrderIdentifier(orderId);
    setAction(actionParam);
  };

  const approveOrder = async (orderId) => {
    try {
      await siteManagerAPI.post(
        API_ORDERS_APPROVE.replace('{orderIdentifier}', orderId),
        {}
      );
      fetchOrders(API_PENDING_ORDERS);
      toast.success('Order approved');
    } catch (error) {
      if (error.response.status === 409) {
        toast.error('You already have approved this order');
      } else {
        toast.error('There was an error trying to approve the order');
        console.log( {error} );
      }
    }
  };

  const renderApproveOrderModal = (order) => {
    setModalData({
      size: 'modal-xs',
      title: 'Approve Order',
      jsx: null,
      component: ConfirmationModal,
      buttons: null,
      componentProps: {
        onSubmit: () => approveOrder(order.identifier),
        message: (
          <ApproveOrderModalMessage
            identifier={order.identifier}
            total={order.total}
          />
        ),
      },
    });
    $('#shared-modal').modal('show');
  };

  const renderRejectOrderModal = (order) => {
    setModalData({
      size: 'modal-xs',
      title: 'Reject Order',
      jsx: null,
      component: RejectOrderModal,
      buttons: null,
      componentProps: {
        fetchOrders: () => fetchOrders(API_PENDING_ORDERS),
        orderIdentifier: order.identifier,
        orderTotal: order.total,
      },
    });
    $('#shared-modal').modal('show');
  };

  useEffect(() => {
    if (orderList) {
      setOrders(() =>
        orderList.map((order) =>
          parseOrder(
            order,
            handleRedirect,
            renderApproveOrderModal,
            renderRejectOrderModal
          )
        )
      );
    }
  }, [orderList]);

  return (
    <TableFunctions
      headers={headers}
      rowsData={orders}
      suggestionsURL={API_ORDERS_SUGGESTIONS}
      dataURL={`${API_PENDING_ORDERS}`}
      fetchData={(dataURL, queryParams) => fetchOrders(dataURL, queryParams)}
      renderRedirect={() =>
        renderRedirect(redirect, orderIdentifier, orders, action)
      }
      tableQueryParams={ordersQueryParams}
    />
  );
}

PendingApprovalsList.propTypes = {
  orderList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  ordersQueryParams: PropTypes.shape(),
  setModalData: PropTypes.func.isRequired,
  fetchOrders: PropTypes.func.isRequired,
  user: PropTypes.shape(),
};

PendingApprovalsList.defaultProps = {
  ordersQueryParams: {},
  user: {},
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  ordersQueryParams: state.orders.orders.pagination.queryParams || {},
});

export default connect(mapStateToProps, {
  fetchOrders: fetchOrdersAction,
  setModalData: setModalDataAction,
})(PendingApprovalsList);
