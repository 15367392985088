import React from 'react';
import PropTypes from 'prop-types';


const FormWrapper = ({
  extraClassName,
  children,
}) => (
  <div
    className={"form-wrapper text-primary medium-text medium-bold " + extraClassName}
  >
    {children}
  </div>
);

FormWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  extraClassName: PropTypes.string,
};

FormWrapper.defaultProps = {
  extraClassName: "",
};

export default FormWrapper;
