import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import GoExpediLogo from '../../assets/goexpedi-logo.svg';
import Logo from './logo.component';
import { getUrlDomain, isProdEnvironment } from '../../utils/general';

function FooterComponent({ ecommerceUrl }) {
  const getCurrentYear = () => new Date().getFullYear();
  const termsConditionsUrl = isProdEnvironment()
    ? `https://www.${getUrlDomain(ecommerceUrl)}.com/terms-and-conditions/`
    : `https://goexpedi.${getUrlDomain(ecommerceUrl)}.com/terms-and-conditions/`;
  const privacyPolicyUrl = isProdEnvironment()
    ? `https://www.${getUrlDomain(ecommerceUrl)}.com/privacy-policy/`
    : `https://goexpedi.${getUrlDomain(ecommerceUrl)}.com/privacy-policy/`;

  return (
    <div className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="footer__info">
              <Logo src={GoExpediLogo} alt="GoExpedi" size="small" />

              <div className="footer__contact">
                <div>
                  <a
                    className="footer__link footer__link--email medium-bold"
                    href="mailto:info@goexpedi.com"
                  >
                    info@goexpedi.com
                  </a>
                </div>

                <div>
                  <a className="footer__link footer__link--phone semi-bold" href="tel:8333973344">
                    (833) 397-3344
                  </a>
                </div>
              </div>
              <div className="footer__links">
                <a
                  href={termsConditionsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer__information-link"
                >
                  Terms & Conditions
                </a>
                <a
                  href={privacyPolicyUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer__information-link"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col">
            <p className="footer__copyright small-text">
              &copy;
              {` ${getCurrentYear()} All rights reserved`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

FooterComponent.propTypes = {
  ecommerceUrl: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  ecommerceUrl: _.get(state, 'auth.user.company.ecommerceUrl', 'goexpedi.com'),
});

export default connect(mapStateToProps)(FooterComponent);
