import { toast } from 'react-toastify';

import siteManagerAPI from '../lib/api-manager';
import { API_COMPANY_CONFIGURATION, API_ORDERS_REMOVE_LINE } from '../lib/api-endpoints';
import OrdersSerializer from '../lib/serializers/OrdersSerializers';
import {
  ORDERS_FETCH_ORDERS_FOR_USER,
  ORDERS_APPROVALS_STATUS,
  ORDERS_REMOVE_LINE,
  GLOBALS_SET_IS_TABLE_LOADING,
} from '../lib/types';
import {
  ACTIVATE_ORDER_APPROVALS_STATUS_ERROR,
  DEACTIVATE_ORDER_APPROVALS_STATUS_ERROR,
} from '../constants/error-messages';

export const fetchOrders = (url, queryParams) => async (dispatch) => {
  let response = {};

  dispatch({
    type: GLOBALS_SET_IS_TABLE_LOADING,
    payload: true,
  });

  try {
    response = await siteManagerAPI.get(url, {
      params: queryParams,
    });
    response.data.queryParams = queryParams;
  } catch (error) {
    console.log(error);
  }
  const orders = new OrdersSerializer(response.data);

  dispatch({
    type: ORDERS_FETCH_ORDERS_FOR_USER,
    payload: orders,
  });

  dispatch({
    type: GLOBALS_SET_IS_TABLE_LOADING,
    payload: false,
  });
};

export const activateOrderApprovals = (id) => async (dispatch) => {
  try {
    await siteManagerAPI.patch(API_COMPANY_CONFIGURATION.replace('{id}', id), {
      has_order_approval: true,
    });
    dispatch({
      type: ORDERS_APPROVALS_STATUS,
      payload: { hasOrderApproval: true },
    });
  } catch (error) {
    toast.error(ACTIVATE_ORDER_APPROVALS_STATUS_ERROR);
  }
};

export const deactivateOrderApprovals = (id) => async (dispatch) => {
  try {
    await siteManagerAPI.patch(API_COMPANY_CONFIGURATION.replace('{id}', id), {
      has_order_approval: false,
    });
    dispatch({
      type: ORDERS_APPROVALS_STATUS,
      payload: { hasOrderApproval: false },
    });
  } catch (error) {
    toast.error(DEACTIVATE_ORDER_APPROVALS_STATUS_ERROR);
  }
};

export const removeLineFromOrder = (orderId, productId) => async (dispatch) => {
  try {
    await siteManagerAPI.post(API_ORDERS_REMOVE_LINE.replace('{orderIdentifier}', orderId), {
      product: productId,
    });
    dispatch({
      type: ORDERS_REMOVE_LINE,
      payload: { product: productId },
    });
    return true;
  } catch (error) {
    return false;
  }
};
