import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableComponent from '../../shared/table.component';
import SwitchInput from '../../forms/switch-input.component';

const HEADERS = [
  {
    text: 'Name',
    name: 'name',
    filterType: '',
    isSortable: true,
  },
  {
    text: 'Can View Order Tracking',
    name: 'canViewOrderTracking',
    filterType: '',
    isSortable: false,
  },
];

class RolesList extends Component {
  static propTypes = {
    roles: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })).isRequired,
    handleRoleCanViewOrderTrackingEdit: PropTypes.func.isRequired,
    fetchRolesWithParams: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      roles: props.roles,
    };
  }

  static getDerivedStateFromProps(props) {
    return {
      roles: props.roles || [],
    };
  }

  handleSwitchChange = (role) => (name, checked) => {
    const { handleRoleCanViewOrderTrackingEdit } = this.props;

    handleRoleCanViewOrderTrackingEdit({ ...role, canViewOrderTracking: checked });
  };

  renderCustomCell = (cell) => {
    const { canViewOrderTracking } = cell;

    return (
      <SwitchInput
        name={`canViewOrderTracking-${cell.id}`}
        checked={canViewOrderTracking}
        label=""
        handleChange={this.handleSwitchChange(cell)}
      />
    );
  };

  render() {
    const { roles } = this.state;

    const parsedRoles = roles.map((role) => ({
      ...role,
      canViewOrderTracking: { customCell: true, cell: role },
    }));

    const { fetchRolesWithParams } = this.props;

    return (
      <TableComponent
        headers={HEADERS}
        rows={parsedRoles}
        fetchTableData={fetchRolesWithParams}
        emptyMessage="Company does not have user's roles"
        renderCustomCell={this.renderCustomCell}
      />
    );
  }
}

export default RolesList;
