import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import SectionHeaderComponent from '../../components/shared/section-header.component';
import BreadCrumbComponent from '../../components/shared/breadcumb.component';
import BreadCrumbItem from '../../components/shared/breadcrumb-item';
import siteManagerAPI from '../../lib/api-manager';

import GroupForm from '../groups/components/group-form';
import { API_HIERARCHY_GROUPS } from '../../lib/api-endpoints';
import { GROUP_SUCCESSFULLY_UPDATED } from '../../constants/messages';
import { UPDATE_GROUP_ERROR } from '../../constants/error-messages';

import messages from './messages';

function EditGroup() {
  const { id } = useParams();

  const handleOnSubmit = useCallback(
    async (data) => {
      try {
        await siteManagerAPI.patch(`${API_HIERARCHY_GROUPS}${id}/`, data);
        toast.success(GROUP_SUCCESSFULLY_UPDATED);
      } catch (error) {
        const groupError = error.response.data;
        if (groupError) {
          throw groupError;
        } else {
          toast.error(UPDATE_GROUP_ERROR);
        }
      }
    },
    [id],
  );

  return (
    <React.Fragment>
      <SectionHeaderComponent subtitle={messages.sceneTitle} sectionLabel={messages.editGroup} />
      <div className="content__container">
        <BreadCrumbComponent>
          <BreadCrumbItem label={messages.breadcrumbHierarchyLabel} link="/hierarchy" />
          <BreadCrumbItem label={messages.breadcrumbGroupsLabel} link="/hierarchy/groups" />
          <BreadCrumbItem label={messages.editGroup} />
        </BreadCrumbComponent>
        <div className="order-details__return-btn">
          <Link to={{ pathname: '/hierarchy/groups' }}>
            <FontAwesomeIcon icon="arrow-left" /> {messages.backButtonText}
          </Link>
        </div>
        <div className="edit-node__container">
          <GroupForm
            onSubmit={handleOnSubmit}
            clearOnSubmit={false}
            currentGroupId={id}
            submitButtonText={messages.submitButtonText}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditGroup;
