import { createSelector } from 'reselect';

import * as utils from './utils';

function hierarchyStateSelector(state) {
  return state.hierarchy;
}

const tableStateSelector = createSelector(hierarchyStateSelector, (state) => state.table);

export const nodes = createSelector(tableStateSelector, (state) => utils.formatNodes(state.nodes));

export const paginationState = createSelector(tableStateSelector, (state) => state.pagination);
