import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import SectionHeader from '../shared/section-header.component';
import BreadCrumbComponent from '../shared/breadcumb.component';
import BreadCrumbItem from '../shared/breadcrumb-item';

const GeneralConfiguration = () => {
  return (
    <div>
      <SectionHeader subtitle="Configuration" sectionLabel="General" />

      <div className="content__container">
        <BreadCrumbComponent>
          <BreadCrumbItem label="Configuration" link="/configuration" />
          <BreadCrumbItem label="General" />
        </BreadCrumbComponent>

        <div className="container-fluid">
          <div>
            <Link to="/configuration/general/billing" className="btn btn-primary">
              <FontAwesomeIcon icon="file-upload" />
              <span> BILLING ADDRESS</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralConfiguration;
