import '../shared/polyfills/unicode-regex-polyfill';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';

import { ITEMS_PER_PAGE } from '../constants/values';
import siteManagerAPI from '../lib/api-manager';

const getUsernamePrefix = async (hasCompanyHierarchy, currentNode, companyId) => {
  let prefix = '';

  if (hasCompanyHierarchy && currentNode && currentNode.customConfig) {
    prefix = currentNode.customConfig.usernamePrefix;
  } else {
    try {
      const response = await siteManagerAPI.get(`/accounts/company-configurations/${companyId}/`);
      const { username_prefix: usernamePrefix } = response.data;

      prefix = usernamePrefix;
    } catch (error) {
      toast.error('There has been an error fetching company configuration');
    }
  }

  return prefix;
};

const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};

/**
 * Uses {@param headers} as a reference to filter and match the order of the keys from the values
 *
 * @param {*[]} values
 * @param {string[]} headers
 * @returns {*[]}
 */
const sortByHeaders = (values, headers) => {
  return values.reduce((accumulator, value) => {
    const newSite = {};

    /** Cycle through {@param headers} to sort the order and filter non used values */
    headers.forEach((header) => {
      if (header === 'name') {
        newSite.site_name = value.site_name;
        return;
      }

      /** header {users} come as a multiple column, this is the reason it worked this way */
      if (header === 'users') {
        newSite[header] = value[header];
        newSite.userscount = value.userscount;
        return;
      }

      newSite[header] = value[header] || '';
    });

    accumulator.push(newSite);

    return accumulator;
  }, []);
};

const exportToExcel = (dataObjects, title, currencyObjects) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Report');
  const dataProperties = Object.keys(dataObjects[0] || {});
  const columnsData = dataProperties.map((dataProperty) => {
    const columnData = {
      header: dataProperty,
      key: dataProperty,
      width: 20,
    };

    if (currencyObjects && currencyObjects[dataProperty]) {
      columnData.style = { numFmt: '"$"#,##0.00' };
    }

    return columnData;
  });

  worksheet.columns = columnsData;
  dataObjects.forEach((dataObject) => {
    const rowObject = {};

    dataProperties.forEach((property) => {
      rowObject[property] = dataObject[property];
    });
    worksheet.addRow(rowObject).commit();
  });

  const titleDate = () => {
    const d = new Date();
    return `${d.getFullYear()}-${d.getDate()}-${(d.getMonth() + 1).toString().padStart(2, '0')}`;
  };

  workbook.xlsx
    .writeBuffer()
    .then((buffer) => FileSaver.saveAs(new Blob([buffer]), `${title}_report_${titleDate()}.xlsx`));
};

const openOrderTracking = (orderId) => async () => {
  try {
    const response = await siteManagerAPI.get(`/orders/${orderId}/tracking-url/`);
    const { order_tracking_url: redirectUrl } = response.data;

    window.open(redirectUrl, '_blank');
  } catch (error) {
    toast.error('There has been an error trying to track your order. Please try again');
  }
};

const getUrlSearch = () => {
  const { search } = window.location;

  if (!search) {
    return {};
  }

  const attrs = decodeURIComponent(search.substring(1))
    .replace(/"/g, '\\"')
    .replace(/&/g, '","')
    .replace(/=/g, '":"');
  return JSON.parse(`{"${attrs}"}`);
};

const getUrlDomain = (url) => {
  const domainMatch = url && url.match(/\.(.*)\.com/);
  const urlDomain = Array.isArray(domainMatch) && domainMatch[1];
  return urlDomain || '';
};

const getOffsetPagination = (currentPage) => (currentPage - 1) * ITEMS_PER_PAGE;

const objectToSearchString = (obj) => {
  const searchStr = Object.keys(obj).map(
    (paramName) => `${encodeURIComponent(paramName)}=${encodeURIComponent(obj[paramName])}`,
  );
  return `?${searchStr.join('&')}`;
};

const isProdEnvironment = () => {
  return document.location.hostname.includes('manager.go');
};

const addLimit = ({ count }) => {
  let limit;
  try {
    limit = typeof count === 'number' ? count : parseInt(count, 10);
  } catch (error) {
    limit = 99999999;
  }

  return { params: { limit } };
};

export {
  addLimit,
  debounce,
  exportToExcel,
  sortByHeaders,
  getOffsetPagination,
  isProdEnvironment,
  openOrderTracking,
  getUrlSearch,
  getUrlDomain,
  objectToSearchString,
  getUsernamePrefix,
};
