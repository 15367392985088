import SiteSerializer from './SiteSerializer';
import PaginationSerializer from './PaginationSerializer';

export default class SitesSerializer {
  constructor(data) {
    const sitesData = data || {};

    this.results = Array.isArray(sitesData.results)
      ? sitesData.results.map((site) => new SiteSerializer(site))
      : [];
    this.pagination = new PaginationSerializer(sitesData);
  }
}
