import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import $ from 'jquery';

import {
  API_SITES,
  API_SITES_ASSIGN_USERS,
  API_USERS,
  API_USERS_SUGGESTIONS,
} from '../../lib/api-endpoints';
import { fetchUsers } from '../../actions/users.actions';
import { fetchSites } from '../../actions/sites.actions';
import { debounce } from '../../utils/general';
import { UNLIMITED_LIST_LIMIT } from '../../constants/values';
import siteManagerAPI from '../../lib/api-manager';
import TableFunctions from '../shared/table-functions';
import TrimmedUnorderedList from '../shared/trimmed-unordered-list.component';

class AssignUserComponent extends Component {
  static propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    site: PropTypes.shape().isRequired,
    fetchUsers: PropTypes.func.isRequired,
    fetchSites: PropTypes.func.isRequired,
    usersQueryParams: PropTypes.shape(),
  };

  static defaultProps = {
    usersQueryParams: {},
  };

  constructor(props) {
    super(props);
    this.handleFilterBy = debounce(this.handleFilterBy, 400);
    this.state = {
      selectedUsers: [],
      headers: [
        {
          text: 'Username',
          name: 'username',
          filterType: 'INPUT',
          isActivated: false,
          defaultAscendent: true,
        },
        {
          text: 'Title',
          name: 'title',
          filterType: 'INPUT',
          isActivated: false,
        },
        {
          text: 'Name',
          name: 'name',
          filterType: 'INPUT',
          isActivated: false,
        },
        {
          text: 'Email',
          name: 'email',
          filterType: 'INPUT',
          isActivated: false,
        },
        {
          text: 'Sites',
          name: 'sites',
          component: TrimmedUnorderedList,
          filterType: 'INPUT',
          isActivated: false,
          isSortable: false,
        },
      ],
    };
  }

  componentDidMount() {
    const { fetchUsers } = this.props;

    fetchUsers(`${API_USERS}?limit=${UNLIMITED_LIST_LIMIT}`);
  }

  parseUser = (user) => ({
    ...user,
    username: { customCell: true, cell: user },
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    sites: {
      list: user.sites.map((parsedSite) => parsedSite.name),
      count: user.sitesCount,
    },
  });

  handleUserCheck = (event) => {
    const { value, checked } = event.target;
    const { users } = this.props;
    const { selectedUsers } = this.state;
    const userId = parseInt(value, 10);

    let newSelectedUsers = [];
    if (checked) {
      const userFound = users.find((user) => user.id === userId);
      newSelectedUsers = [...selectedUsers, userFound];
    } else {
      newSelectedUsers = selectedUsers.filter((user) => user.id !== userId);
    }

    this.setState({ selectedUsers: newSelectedUsers });
  };

  submitForm = async (event) => {
    event.preventDefault();
    const { site, fetchSites } = this.props;
    const { selectedUsers } = this.state;

    const body = {
      users: selectedUsers.map((user) => user.id),
    };

    try {
      await siteManagerAPI.post(API_SITES_ASSIGN_USERS.replace('{id}', site.id), body);
      fetchSites(API_SITES);
      toast.success('Users assigned succesfully');
    } catch (error) {
      toast.error('There has been an error while assigning Users to the Site');
    } finally {
      $('#shared-modal').modal('hide');
    }
  };

  isChecked(id) {
    const { selectedUsers } = this.state;

    return selectedUsers && selectedUsers.some((user) => user.id === id);
  }

  removeUser(userId) {
    const { selectedUsers } = this.state;
    const selectedUsersRemoved = selectedUsers.filter((userList) => userList.id !== userId);

    this.setState({
      selectedUsers: selectedUsersRemoved,
    });
  }

  renderSelectCheckBox(user) {
    return (
      <div className="form-group form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id={`user_${user.id}`}
          value={user.id}
          checked={this.isChecked(user.id)}
          onChange={(event) => this.handleUserCheck(event)}
        />
        <label className="form-check-label" htmlFor={`user_${user.id}`}>
          {user.username}
        </label>
      </div>
    );
  }

  render() {
    const { users, fetchUsers, usersQueryParams } = this.props;
    const { headers, selectedUsers } = this.state;

    const parsedUsers = users.map(this.parseUser);

    return (
      <Fragment>
        <div className="text-primary">
          Selected Items <b>({selectedUsers.length})</b>
        </div>
        <div className="text-primary small-text mb-4">
          {selectedUsers.map((selected) => (
            <span className="mr-2 d-inline-block" key={selected.id}>
              {selected.username}{' '}
              <button
                type="button"
                className="close remove-button text-primary"
                onClick={() => this.removeUser(selected.id)}
              >
                <FontAwesomeIcon icon="times-circle" />{' '}
              </button>
            </span>
          ))}
        </div>

        <form onSubmit={this.submitForm}>
          <div className="assign-table">
            <TableFunctions
              headers={headers}
              rowsData={parsedUsers}
              suggestionsURL={API_USERS_SUGGESTIONS}
              dataURL={API_USERS}
              fetchData={(dataURL, queryParams) => {
                queryParams.limit = UNLIMITED_LIST_LIMIT;
                fetchUsers(dataURL, queryParams);
              }}
              tableQueryParams={usersQueryParams}
              renderCustomCell={(cell) => this.renderSelectCheckBox(cell)}
            />
          </div>

          <button type="submit" className="btn btn-primary float-right">
            <FontAwesomeIcon icon="save" />
            <span> SAVE USERS</span>
          </button>
        </form>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { results: users } = state.users.users;

  return {
    users,
    onSubmit: state.globals.onSubmit,
  };
};

export default connect(mapStateToProps, {
  fetchUsers,
  fetchSites,
})(AssignUserComponent);
