import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TableComponent from '../../shared/table.component';
import TableActions from '../../shared/table-actions.component';

class SiteTypesList extends Component {
  static propTypes = {
    siteTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    fetchSiteTypesWithParams: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      siteTypes: props.siteTypes,
      headers: [
        {
          text: 'Name',
          name: 'name',
          filterType: '',
          isSortable: true,
        },
        {
          text: 'Actions',
          name: 'actions',
          filterType: '',
          isSortable: false,
          class: 'text-right',
        },
      ],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      siteTypes: nextProps.siteTypes || [],
    });
  }

  onEdit = (siteType) => () => {
    const { handleEdit } = this.props;

    handleEdit(siteType);
  };

  onDelete = (siteType) => () => {
    const { handleDelete } = this.props;

    handleDelete(siteType);
  };

  renderSiteTypeName = (siteType) => {
    return <div className="min-vw-50">{siteType.name}</div>;
  };

  render() {
    const { siteTypes, headers } = this.state;

    const parsedSiteTypes = siteTypes.map((siteType) => ({
      ...siteType,
      name: { customCell: true, cell: siteType },
      actions: <TableActions edit={this.onEdit(siteType)} remove={this.onDelete(siteType)} />,
    }));

    const { fetchSiteTypesWithParams } = this.props;

    return (
      <TableComponent
        headers={headers}
        rows={parsedSiteTypes}
        fetchTableData={fetchSiteTypesWithParams}
        emptyMessage="Company does not have site types"
        renderCustomCell={(cell) => this.renderSiteTypeName(cell)}
      />
    );
  }
}

export default SiteTypesList;
