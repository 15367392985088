import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-dates';
import { Link } from 'react-router-dom';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import PropTypes from 'prop-types';
import AutoSuggest from 'react-autosuggest';

const CELL_MAX_LENGTH = 30;

const renderSuggestion = (suggestion) => {
  if (!suggestion) return null;
  return <div>{suggestion}</div>;
};
const shouldRenderSuggestions = (value) => value.trim().length > 0;

const getSuggestionValue = (suggestion) => suggestion.text;

class TableComponent extends Component {
  static propTypes = {
    headers: PropTypes.arrayOf(PropTypes.object).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    userQueryParams: PropTypes.object,
    fetchTableData: PropTypes.func,
    filter: PropTypes.func,
    toggleFilter: PropTypes.func,
    renderCustomCell: PropTypes.func,
    renderRedirect: PropTypes.func,
    handleRedirect: PropTypes.func,
    changeDates: PropTypes.func,
    clearSuggestions: PropTypes.func,
    emptyMessage: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    selectOptions: PropTypes.object,
    suggestions: PropTypes.arrayOf(PropTypes.string),
    shouldDisplaySuggestions: PropTypes.bool,
    isTableLoading: PropTypes.bool,
  };

  static defaultProps = {
    fetchTableData: () => {},
    filter: () => {},
    toggleFilter: () => {},
    renderCustomCell: () => {},
    renderRedirect: () => {},
    changeDates: () => {},
    clearSuggestions: () => {},
    userQueryParams: {},
    handleRedirect: null,
    emptyMessage: 'There is no information to show.',
    startDate: '',
    endDate: '',
    selectOptions: null,
    suggestions: null,
    shouldDisplaySuggestions: false,
    isTableLoading: false,
  };

  constructor(props) {
    super(props);
    this.loadSuggestions = this.loadSuggestions.bind(this);
    const { headers } = this.props;

    this.state = {
      suggestions: [],
      filterHeader: '',
      ...this.getIconsAndFiltersValues(headers),
    };
  }

  componentDidUpdate(nextProps) {
    const { headers } = this.props;

    if (nextProps.headers.length !== headers.length) {
      this.setState({ sortIcons: this.getIconsAndFiltersValues(nextProps.headers).sortIcons });
    }
  }

  getIconsAndFiltersValues = (headers) => {
    const sortIcons = {};
    const filterValues = {};

    if (!Array.isArray(headers)) {
      return null;
    }

    headers.forEach((header) => {
      if (header.defaultAscendent) {
        sortIcons[header.name] = 'chevron-up';
      } else if (header.defaultDescendent) {
        sortIcons[header.name] = 'chevron-down';
      } else {
        sortIcons[header.name] = 'chevron-right';
      }

      if (header.isActivated) {
        filterValues[header.name] = header.filterText;
      }
    });
    return {
      sortIcons,
      filterValues,
    };
  };

  onChange = (event, { newValue, method }, name) => {
    if (method === 'up' || method === 'down') {
      return null;
    }
    const { filter } = this.props;
    const { filterValues } = this.state;
    const clonedFilterValues = { ...filterValues };

    clonedFilterValues[name] = newValue;
    this.setState({
      filterValues: clonedFilterValues,
    });
    filter(newValue, name, this.loadSuggestions);
    return null;
  };

  onSuggestionSelected = (event, { suggestion }, name) => {
    const { filter } = this.props;
    const { filterValues } = this.state;
    const clonedFilterValues = { ...filterValues };

    clonedFilterValues[name] = suggestion;

    this.setState({
      filterValues: clonedFilterValues,
    });
    filter(suggestion, name);
  };

  onSuggestionsClearRequested = () => {
    const { clearSuggestions } = this.props;
    this.setState({ suggestions: [] });
    if (clearSuggestions) clearSuggestions();
  };

  loadSuggestions() {
    const { shouldDisplaySuggestions } = this.props;
    if (!shouldDisplaySuggestions) {
      return;
    }

    let { suggestions } = this.props;
    const { rows } = this.props;
    if (Array.isArray(suggestions) && !suggestions.length) {
      const { filterHeader } = this.state;
      suggestions = rows.map((row) => {
        if (typeof row[filterHeader] === 'object') {
          return row[filterHeader].text;
        }
        return row[filterHeader];
      });
    }

    suggestions = [...new Set(suggestions)];

    this.setState({ suggestions });
  }

  sortBy(name) {
    const { sortIcons } = this.state;
    const { userQueryParams, fetchTableData } = this.props;
    const { filterValues } = this.state;
    const icons = { ...sortIcons };
    let queryParams = { ...userQueryParams };

    queryParams.ordering = name;
    Object.keys(icons).forEach((key) => {
      if (key === name) {
        switch (icons[key]) {
          case 'chevron-right':
          case 'chevron-up':
            icons[key] = 'chevron-down';
            break;
          case 'chevron-down':
            icons[key] = 'chevron-up';
            break;
          default:
            break;
        }
      } else {
        icons[key] = 'chevron-right';
      }
    });

    this.setState({
      sortIcons: icons,
    });

    if (icons[name] === 'chevron-down') {
      queryParams.ordering = `-${name}`;
    }
    queryParams = Object.keys(filterValues).reduce((acc, filterName) => {
      acc[filterName] = filterValues[filterName];
      return acc;
    }, queryParams);

    fetchTableData(queryParams);
  }

  toggleFilterTable(name) {
    const { toggleFilter, headers } = this.props;
    const { filterValues } = this.state;
    const clonedFilterValues = { ...filterValues };

    headers.forEach((header) => {
      if (header.name === 'date' && header.isActivated) {
        const { startDate, endDate } = header;
        clonedFilterValues.date = {
          startDate,
          endDate,
        };
      }
    });

    clonedFilterValues[name] = '';
    toggleFilter(name, clonedFilterValues);
    this.setState({ filterHeader: name, filterValues: clonedFilterValues });
  }

  renderFilterByType(header) {
    if (!header.isActivated) {
      return null;
    }

    const {
      filter,
      startDate: propStartDate,
      endDate: propEndDate,
      changeDates,
      selectOptions,
    } = this.props;
    const { focusedInput, suggestions, filterValues } = this.state;

    const inputProps = {
      placeholder: 'Type something',
      value: filterValues[header.name] || '',
      onChange: (event, { newValue, method }) =>
        this.onChange(event, { newValue, method }, header.name),
    };
    const defaultSelectValue = filterValues[header.name] || '';
    const startDate = header.startDate || propStartDate || '';
    const endDate = header.endDate || propEndDate || '';

    switch (header.filterType) {
      case 'SELECT':
        return (
          <div>
            <select
              onChange={(event) => {
                event.persist();
                this.setState((prevState) => ({
                  filterValues: {
                    ...prevState.filterValues,
                    [header.name]: event.target.value,
                  },
                }));
                filter(event.target.value, header.name);
              }}
              defaultValue={defaultSelectValue}
              className="custom-select custom-select-sm"
            >
              <option value="">Select</option>
              {selectOptions[header.name].map((option) => (
                <option value={option.value} key={option.value}>
                  {option.displayName}
                </option>
              ))}
            </select>
          </div>
        );
      case 'INPUT':
        return (
          <div className="table-component__autosuggest-component">
            <AutoSuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={({ value }) =>
                filter(value, header.name, this.loadSuggestions)
              }
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              onSuggestionSelected={(event, suggestionValue) =>
                this.onSuggestionSelected(event, suggestionValue, header.name)
              }
              renderSuggestion={renderSuggestion}
              shouldRenderSuggestions={shouldRenderSuggestions}
              inputProps={inputProps}
              id={header.name}
              className="form-control bg-light filter-field"
            />
            <div className="input-group-append" />
          </div>
        );
      case 'CALENDAR':
        return (
          <div>
            <DateRangePicker
              startDate={startDate}
              startDateId="startDate"
              endDate={endDate}
              endDateId="endDate"
              onDatesChange={changeDates}
              focusedInput={focusedInput}
              onFocusChange={(focused) => this.setState({ focusedInput: focused })}
              anchorDirection="left"
              numberOfMonths={1}
              isOutsideRange={() => {}}
              renderCalendarInfo={() => {}}
              minimumNights={0}
              startDatePlaceholderText="Start"
              endDatePlaceholderText="End"
              displayFormat="MM/DD/YY"
              small
            />
          </div>
        );
      default:
        return null;
    }
  }

  renderHeaders() {
    const { headers } = this.props;
    const { sortIcons } = this.state;

    return headers.map((header) => {
      const headerClass = `table-component__header-cell ${header.class || ''}`;
      if (header.renderHeader) {
        return (
          <th className={headerClass} scope="col" key={header.name}>
            {header.renderHeader()}
          </th>
        );
      }

      return (
        <th className={headerClass} scope="col" key={header.name}>
          <button
            type="button"
            onClick={() => (header.isSortable === false ? '' : this.sortBy(header.name))}
            className={`btn table-component__header-button ${
              header.isSortable === false
                ? 'table-component__header-button--not-clickable'
                : 'table-component__header-button--clickable'
            }`}
          >
            <FontAwesomeIcon
              className="table-component__header-chevron"
              icon={sortIcons[header.name] || 'chevron-right'}
            />
            {` ${header.text}`}
          </button>

          {header.filterType && (
            <button
              type="button"
              onClick={() => this.toggleFilterTable(header.name)}
              className="btn table-component__header-button table-component__header-filter p-0"
            >
              <FontAwesomeIcon icon="filter" />
            </button>
          )}

          {this.renderFilterByType(header)}
        </th>
      );
    });
  }

  renderCells(row) {
    const { id, ...tableRow } = row;
    const { headers } = this.props;

    return headers.map((header, index) => {
      const tableCell = tableRow[header.name];

      if (header.component) {
        return this.renderComponentCell(header.component, tableCell, index);
      }

      if (tableCell && tableCell.isLink) {
        return this.renderLinkCell(tableCell, index);
      }

      if (tableCell && tableCell.customCell) {
        return this.renderCustomCell(tableCell, index);
      }

      return this.renderCell(tableCell, index, header);
    });
  }

  renderComponentCell = (CellComponent, data, index) => (
    <td className="table-component__data-cell" key={index}>
      <CellComponent data={data} />
    </td>
  );

  renderLinkCell = (link, index) => (
    <td className="table-component__data-cell" key={index}>
      <Link
        className="text-base table-component__link"
        to={{ pathname: link.pathName, state: link.state }}
      >
        {link.text}
      </Link>
    </td>
  );

  renderCustomCell = (customCell, index) => {
    const { renderCustomCell } = this.props;
    const { cell } = customCell;

    return (
      <td key={index} className="table-component__data-cell">
        {renderCustomCell(cell)}
      </td>
    );
  };

  renderCell(cell, index, header) {
    if (header.trimmText === true && typeof cell === 'string' && cell.length > CELL_MAX_LENGTH) {
      return (
        <td data-tip={cell} className="table-component__data-cell" key={index}>
          {`${cell.substring(0, CELL_MAX_LENGTH)}...`}
        </td>
      );
    }
    return (
      <td className="table-component__data-cell" key={index}>
        {cell}
      </td>
    );
  }

  renderTableRows() {
    const { rows, renderRedirect, handleRedirect, headers, emptyMessage } = this.props;

    if (rows.length === 0) {
      return (
        <tr className="border-top border-bottom">
          <td className="table-component__data-cell" colSpan={headers.length}>
            {emptyMessage}
          </td>
        </tr>
      );
    }

    return rows.map((tableRow) => {
      const { id, ...columns } = tableRow;
      return (
        <Fragment key={id}>
          {renderRedirect && renderRedirect()}
          <tr
            className={
              handleRedirect ? 'clickable border-top border-bottom' : 'border-top border-bottom'
            }
            onClick={() => handleRedirect && handleRedirect(id)}
          >
            {this.renderCells(columns)}
          </tr>
        </Fragment>
      );
    });
  }

  renderLoadingTBody = () => {
    const { headers } = this.props;
    const maxRows = Array.from(Array(5), (_, n) => n * 11);
    const actions = [];
    for (let i = 0; i < 3; i += 1) {
      actions.push(
        <span key={i} className="loading-row-placeholder loading-row-placeholder__circle" />,
      );
    }
    const hasActions = headers.some((header) => header.name === 'actions');
    return (
      <tbody className="bg-light small-text table-loading">
        {maxRows.map((k) => (
          <tr key={k}>
            <td colSpan={headers.length}>
              <div className="row">
                <div className="col d-flex align-items-center">
                  <span className="loading-row-placeholder loading-row-placeholder__rectangle" />
                </div>
                <div className="col-2 d-flex align-items-center justify-content-around">
                  {hasActions ? (
                    actions
                  ) : (
                    <span className="loading-row-placeholder loading-row-placeholder__rectangle" />
                  )}
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  render() {
    const { isTableLoading } = this.props;
    return (
      <table className="table table-component table-borderless">
        <thead className="medium-text">
          <tr className="text-secondary">{this.renderHeaders()}</tr>
        </thead>
        {isTableLoading ? (
          this.renderLoadingTBody()
        ) : (
          <tbody className="bg-light small-text">{this.renderTableRows()}</tbody>
        )}
      </table>
    );
  }
}

export default TableComponent;
