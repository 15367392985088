import { toast } from 'react-toastify';
import siteManagerAPI from '../lib/api-manager';
import { API_SITE_TYPE } from '../lib/api-endpoints';

import SiteTypesSerializer from '../lib/serializers/SiteTypesSerializer';
import SiteTypeSerializer from '../lib/serializers/SiteTypeSerializer';
import {
  SITE_TYPES_FETCH_COMPANY_SITE_TYPES,
  SITE_TYPES_FETCH_SITE_TYPE,
  SITE_TYPES_SET_SITE_TYPE_FORM_ERRORS,
} from '../lib/types';

import {
  FETCH_SITE_TYPES_TRY_LATER,
  CREATE_SITE_TYPE_TRY_LATER,
  UPDATE_SITE_TYPE_TRY_LATER,
  DELETE_SITE_TYPE_TRY_LATER,
  FETCH_SITE_TYPE_TRY_LATER,
} from '../constants/error-messages';

import {
  SITE_TYPE_SUCCESSFULLY_UPDATED,
  SITE_TYPE_SUCCESSFULLY_DELETED,
} from '../constants/messages';

export const fetchSiteTypes = (params) => async (dispatch) => {
  try {
    const response = await siteManagerAPI.get(API_SITE_TYPE, { params });
    const siteTypes = new SiteTypesSerializer(response.data);

    dispatch({
      type: SITE_TYPES_FETCH_COMPANY_SITE_TYPES,
      payload: siteTypes,
    });
  } catch (error) {
    toast.error(FETCH_SITE_TYPES_TRY_LATER);
  }
};

export const createSiteType = (siteType) => async (dispatch) => {
  try {
    await siteManagerAPI.post(API_SITE_TYPE, { ...siteType });

    dispatch({
      type: SITE_TYPES_SET_SITE_TYPE_FORM_ERRORS,
      payload: {},
    });

    dispatch(fetchSiteTypes());
  } catch (error) {
    const { status, data } = error.response;

    if (status === 400) {
      dispatch({
        type: SITE_TYPES_SET_SITE_TYPE_FORM_ERRORS,
        payload: data,
      });
    } else if (status > 400) {
      toast.error(CREATE_SITE_TYPE_TRY_LATER);
    }
  }
};

export const updateSiteTypeName = (id, name) => async (dispatch) => {
  try {
    const response = await siteManagerAPI.patch(`${API_SITE_TYPE}${id}/`, { name });
    const siteType = new SiteTypeSerializer(response.data);

    dispatch({
      type: SITE_TYPES_FETCH_SITE_TYPE,
      payload: siteType,
    });

    dispatch({
      type: SITE_TYPES_SET_SITE_TYPE_FORM_ERRORS,
      payload: {},
    });

    toast.success(SITE_TYPE_SUCCESSFULLY_UPDATED);
  } catch (error) {
    const { status, data } = error.response;

    if (status === 400) {
      dispatch({
        type: SITE_TYPES_SET_SITE_TYPE_FORM_ERRORS,
        payload: data,
      });
    } else if (status > 400) {
      toast.error(UPDATE_SITE_TYPE_TRY_LATER);
    }
  }
};

export const deleteSiteType = (id) => async (dispatch) => {
  try {
    await siteManagerAPI.delete(`${API_SITE_TYPE}${id}`);

    dispatch(fetchSiteTypes());
    toast.success(SITE_TYPE_SUCCESSFULLY_DELETED);
  } catch (error) {
    const { status, data } = error.response;

    if (status === 409) {
      toast.error(data.detail);
    } else if (status > 400) {
      toast.error(DELETE_SITE_TYPE_TRY_LATER);
    }
  }
};

export const fetchSiteType = (id) => async (dispatch) => {
  try {
    const response = await siteManagerAPI.get(`${API_SITE_TYPE}${id}`);
    const role = new SiteTypeSerializer(response.data);

    dispatch({
      type: SITE_TYPES_FETCH_SITE_TYPE,
      payload: role,
    });
  } catch (error) {
    toast.error(FETCH_SITE_TYPE_TRY_LATER);
  }
};

export const resetErrors = () => async (dispatch) => {
  dispatch({
    type: SITE_TYPES_SET_SITE_TYPE_FORM_ERRORS,
    payload: {},
  });
};
