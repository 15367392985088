import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const ExportExcelComponent = (props) => {
  const { exportData } = props;
  const [ isDisabled, setDisabled ] = useState(false);

  const handleClick = async () => {
    setDisabled(true);
    await exportData();
    setDisabled(false);
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className="btn btn-primary table-tools__btn"
      data-tip="Export To Excel"
      disabled={isDisabled}
    >
      <FontAwesomeIcon className="btn__icon" icon="file-download" /> Export Data
    </button>
  );
};

ExportExcelComponent.propTypes = {
  exportData: PropTypes.func.isRequired,
};

export default ExportExcelComponent;
