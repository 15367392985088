import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import messages from './messages';

function Pagination(props) {
  const { state, fetchResourceCB } = props;
  const {
    shouldDisplayPagination,
    paginationLinks,
    showingPageDown,
    showingPageUp,
    count,
    previous,
    currentPage,
    next,
    pagesToShow,
    pageCount,
  } = state;
  const showLoadingIndicator = () => null;

  const shouldRender = useMemo(() => shouldDisplayPagination && Array.isArray(paginationLinks), [
    shouldDisplayPagination,
    paginationLinks,
  ]);

  const paginationInfo = useMemo(
    () => `Showing ${showingPageDown} - ${showingPageUp} of ${count}`,
    [showingPageDown, showingPageUp, count],
  );

  const handlePaginationChange = useCallback(
    (url) => async () => {
      if (url) {
        showLoadingIndicator(true);
        await fetchResourceCB(url);
        showLoadingIndicator(false);
      }
    },
    [fetchResourceCB, showLoadingIndicator],
  );

  const renderLink = useCallback(
    (url, text) => (
      <li className={url ? 'pagination__item' : 'pagination__item--disabled'}>
        <button
          type="button"
          className={`pagination__btn ${!url ? 'btn--disabled' : ''}`.trim()}
          tabIndex="-1"
          onClick={handlePaginationChange(url)}
        >
          {text}
        </button>
      </li>
    ),
    [handlePaginationChange],
  );

  const getPageClassName = useCallback(
    (link) => {
      const baseClass = 'pagination__btn';
      const isHead = link.page === 1 && currentPage > Math.floor(pagesToShow / 2) + 1;
      const isTail =
        link.page === pageCount && currentPage < pageCount - Math.floor(pagesToShow / 2) - 1;
      if (link.active) return `${baseClass}--active`;
      if (isHead) return `${baseClass} ${baseClass}-head`;
      if (isTail) return `${baseClass} ${baseClass}-last`;
      return baseClass;
    },
    [state],
  );

  const renderNumberLink = useCallback(
    (link) => (
      <li key={link.page} className="pagination__item">
        <button
          type="button"
          onClick={handlePaginationChange(link.active ? null : link.url)}
          className={getPageClassName(link)}
        >
          {link.page}
        </button>
      </li>
    ),
    [handlePaginationChange, getPageClassName],
  );

  if (!shouldRender) return null;

  return (
    <div className="pagination-wrapper">
      <div className="pagination-info">{paginationInfo}</div>
      <ul className="pagination pagination-sm">
        {renderLink(previous, messages.prev)}
        {paginationLinks.map(renderNumberLink)}
        {renderLink(next, messages.next)}
      </ul>
    </div>
  );
}

Pagination.propTypes = {
  state: PropTypes.shape({
    paginationLinks: PropTypes.arrayOf(
      PropTypes.shape({
        page: PropTypes.number,
        active: PropTypes.bool,
        url: PropTypes.string,
      }),
    ),
    shouldDisplayPagination: PropTypes.bool,
    showingPageDown: PropTypes.number,
    showingPageUp: PropTypes.number,
    count: PropTypes.number,
    next: PropTypes.string,
    previous: PropTypes.string,
    currentPage: PropTypes.number,
    pagesToShow: PropTypes.number,
    pageCount: PropTypes.number,
  }).isRequired,
  fetchResourceCB: PropTypes.func.isRequired,
};

export default Pagination;
