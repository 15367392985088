const getFieldValue = (target) => {
  const value = target.type === 'checkbox' ? target.checked : target.value;

  const { name } = target;

  return { [name]: value };
};

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export { getFieldValue, EMAIL_REGEX };
