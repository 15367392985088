import { pick, uniqBy } from 'lodash';

export function getId(param) {
  if (param) return param.id;
  return null;
}

export function mapIds(data) {
  return data.map(getId);
}

export function formatUserName(user) {
  return {
    id: user.id,
    name: `${user.first_name || ''} ${user.last_name || ''}`.trim(),
  };
}

export function extractNodeIds(node) {
  const { name, users, sites, group, parent } = node;
  return {
    name,
    group: getId(group || {}),
    parent: getId(parent || {}),
    sites: mapIds(sites || []),
    users: mapIds(users || []),
  };
}

function formatNode(node) {
  return pick(node, ['id', 'name', 'group', 'sites', 'users']);
}

export function flattenNodes(nodes) {
  const result = [];
  nodes.forEach((node) => {
    result.push(formatNode(node));
    flattenNodes(node.children).forEach((child) => result.push(formatNode(child)));
  });
  return uniqBy(result, 'id');
}

export function mergeErrors(posibleErrors) {
  const errors = posibleErrors.filter((error) => !!error);
  return errors.length > 0
    ? errors.reduce((merged, current) => ({ ...merged, ...current }), {})
    : null;
}
