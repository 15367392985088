import axios from 'axios';
import { toast } from 'react-toastify';

import siteManagerAPI from 'lib/api-manager';
import { DOCUMENTS_FETCH, GLOBALS_SET_IS_TABLE_LOADING } from 'lib/types';
import { FETCH_DOCUMENTS_ERROR } from 'constants/error-messages';
import DocumentsSerializer from 'lib/serializers/Documents';

let cancelToken;

export const fetchDocuments =
  (url, params = {}) =>
  async (dispatch) => {
    const switchTableLoading = (value) =>
      dispatch({
        type: GLOBALS_SET_IS_TABLE_LOADING,
        payload: value,
      });

    switchTableLoading(true);

    try {
      if (cancelToken) {
        cancelToken.cancel('Request aborted due to new request.');
      }
      cancelToken = axios.CancelToken.source();

      const response = await siteManagerAPI.get(url, { params, cancelToken: cancelToken.token });
      response.data.queryParams = params;
      const serializedDocuments = new DocumentsSerializer(response.data);

      dispatch({ type: DOCUMENTS_FETCH, payload: serializedDocuments });

      switchTableLoading(false);

      cancelToken = null;
    } catch (error) {
      if (!axios.isCancel(error)) {
        switchTableLoading(false);
        toast.error(FETCH_DOCUMENTS_ERROR);
      }
    }
  };
