import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import SectionHeaderComponent from 'components/shared/section-header.component';
import BreadCrumbComponent from 'components/shared/breadcumb.component';
import BreadCrumbItem from 'components/shared/breadcrumb-item';
import { HIERARCHY_GROUPS, HIERARCHY_POLICIES, HIERARCHY_TREE } from 'constants/app-routes';

import SearchComponent from 'components/shared/search.component';
import { API_HIERARCHY_NODES } from 'lib/api-endpoints';
import { GENERIC_TRY_LATER } from 'constants/error-messages';
import useActions from 'shared/hooks/useActions';
import withCreateNode, { InjectedProps } from './withCreateNode';
import messages from './messages';
import HierarchyTable from './components/table';
import * as actions from './components/table/actions';

function Hierarchy(props) {
  const { showCreateNode } = props;
  const history = useHistory();
  const fetchTableNodes = useActions(actions.fetchTableNodes);
  const dispatch = useDispatch();

  const handleSearch = async (searchStr) => {
    try {
      dispatch({ type: 'GLOBALS_SET_IS_TABLE_LOADING', payload: true });
      await fetchTableNodes(API_HIERARCHY_NODES, { search: `${searchStr}` });
    } catch (error) {
      toast.error(GENERIC_TRY_LATER);
    } finally {
      dispatch({ type: 'GLOBALS_SET_IS_TABLE_LOADING', payload: false });
    }
  };

  const showCreateModal = () => showCreateNode();

  const goToPolicies = () => {
    history.push(HIERARCHY_POLICIES);
  };

  const goToGroups = () => {
    history.push(HIERARCHY_GROUPS);
  };

  const goToTree = () => {
    history.push(HIERARCHY_TREE);
  };

  return (
    <React.Fragment>
      <SectionHeaderComponent
        subtitle={messages.sceneSubtitle}
        sectionLabel={messages.sceneTitle}
      />
      <BreadCrumbComponent>
        <BreadCrumbItem label={messages.sceneTitle} />
      </BreadCrumbComponent>
      <div className="container-fluid">
        <div className="table-tools">
          <div className="table-tools__search">
            <SearchComponent handleSearch={handleSearch} />
          </div>
          <div className="table-tools__btns">
            <button type="button" onClick={goToPolicies} className="btn btn-link table-tools__btn">
              {messages.viewPoliciesButtonText}
            </button>
            <button type="button" onClick={goToTree} className="btn btn-primary table-tools__btn">
              {messages.tree}
            </button>
            <button type="button" onClick={goToGroups} className="btn btn-primary table-tools__btn">
              {messages.groups}
            </button>
            <button
              type="button"
              onClick={showCreateModal}
              className="btn btn-primary table-tools__btn"
            >
              <FontAwesomeIcon icon="plus" /> {messages.addNodeButtonText}
            </button>
          </div>
        </div>
        <HierarchyTable />
      </div>
    </React.Fragment>
  );
}

Hierarchy.propTypes = {
  ...InjectedProps,
};

export default withCreateNode(Hierarchy);
