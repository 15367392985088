export const defaultNode = {
  name: {
    presence: { allowEmpty: false },
    type: 'string',
  },
  group: {
    type: 'number',
  },
  parent: {
    presence: { allowEmpty: false },
    type: 'number',
  },
  sites: {
    presence: true,
    type: 'array',
  },
  users: {
    presence: true,
    type: 'array',
  },
};

export const rootNode = {
  name: {
    presence: { allowEmpty: false },
    type: 'string',
  },
  group: {
    type: 'number',
  },
  parent: {
    type: 'number',
  },
  sites: {
    presence: true,
    type: 'array',
  },
  users: {
    presence: true,
    type: 'array',
  },
};

export const customConfig = {
  usernamePrefix: {
    presence: { allowEmpty: false },
    type: 'string',
  },
  tenantGroup: {
    presence: { allowEmpty: false },
    type: 'string',
  },
  ecommerceCheckoutConfiguration: {
    isObject: { inPlace: true },
  },
};
