import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import Select from 'react-select';
import _ from 'lodash';

import siteManagerAPI from 'lib/api-manager';
import { API_USERS, API_APPROVAL_USERS } from 'lib/api-endpoints';
import { fetchRoles, resetErrors, fetchApprovalRules } from 'actions/roles';
import { fetchUsers } from 'actions/users.actions';
import { ALREADY_A_ROLE_WITH_THIS_RULE } from 'constants/error-messages';
import { getFieldValue } from 'utils/forms';
import { formatNodeToLabel, parseOptions, parseNodeOptions } from 'utils/format';
import RadioButtonGroup from 'components/forms/radio-button-group.component';
import { formatNodeWithGroupName } from '../../../shared/utils/hierarchy';
import SelectInput from '../../forms/select-input';
import NumberInput from '../../forms/number-input';
import SwitchInput from '../../forms/switch-input.component';
import { UNLIMITED_LIST_LIMIT } from '../../../constants/values';
import FormWrapper from '../../forms/form-wrapper.component';

const approvalRuleShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  role: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
  }),
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    email: PropTypes.string,
  }),
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
});

class ApprovalRuleForm extends Component {
  static propTypes = {
    rule: approvalRuleShape,
    roles: PropTypes.arrayOf(PropTypes.object).isRequired,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchRoles: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    fetchApprovalRules: PropTypes.func.isRequired,
    handleFormSubmit: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    resetErrors: PropTypes.func.isRequired,
    approvalRules: PropTypes.arrayOf(approvalRuleShape).isRequired,
  };

  static defaultProps = {
    rule: null,
  };

  constructor(props) {
    super(props);

    const inputsState = this.getInputsState(props.rule);

    this.state = {
      ...inputsState,
      ruleUpdated: false,
      errors: {},
    };
  }

  componentDidMount() {
    const {
      fetchRoles: fetchRolesAction,
      fetchUsers: fetchUsersAction,
      fetchApprovalRules: fetchApprovalRulesAction,
    } = this.props;

    fetchRolesAction();
    fetchUsersAction(API_USERS, { limit: UNLIMITED_LIST_LIMIT });
    fetchApprovalRulesAction();
  }

  componentWillReceiveProps(nextProps) {
    const { errors, ruleUpdated } = this.state;
    const nextErrors = nextProps.errors || {};
    const shouldResetApprovalRule =
      this.roleChanged(nextProps.rule) && Object.entries(nextErrors).length === 0 && !ruleUpdated;

    if (shouldResetApprovalRule) {
      this.resetApprovalRule(nextProps.rule);
    }

    if (JSON.stringify(nextErrors) !== JSON.stringify(errors)) {
      this.setState({ errors: { ...nextErrors } });
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    const { user } = this.state;

    if (user) {
      if (!prevState.user || user.value !== prevState.user.value) {
        this.fetchUserNodes(user.value);
        this.setState({ nodes: [], sites: [] });
      }
    } else if (prevState.user) {
      this.setState({ nodes: [], sites: [] });
    }
  }

  componentWillUnmount() {
    const { resetErrors: resetErrorsAction } = this.props;
    resetErrorsAction();
  }

  getInputsState = (rule) => {
    let approvalType = 'userRole';
    let roleOption = null;
    let userOption = null;
    let sitesOption = null;
    let nodesOption = null;
    const userNodes = [];
    let limit = 1000;
    let approveAll = false;
    let blanketApprover = false;

    if (rule) {
      const numberLimit = Number(rule.limit);

      if (rule.role) {
        approvalType = 'userRole';
        roleOption = {
          value: rule.role.id,
          label: rule.role.name,
        };
      } else {
        approvalType = 'user';
        userOption = {
          value: rule.user.id,
          label: rule.user.name,
        };
        this.fetchUserNodes(rule.user.id);
        sitesOption = parseOptions(rule.sites);
        nodesOption = parseNodeOptions(rule.nodes.map((node) => formatNodeWithGroupName(node)));
      }

      limit = numberLimit;
      approveAll = numberLimit === 0;
      blanketApprover = numberLimit === -1;
    }

    return {
      approvalType,
      role: roleOption,
      user: userOption,
      sites: sitesOption,
      nodes: nodesOption,
      userNodes,
      limit,
      approveAll,
      blanketApprover,
    };
  };

  roleChanged = (nextRule) => {
    const { rule, limit } = this.state; // TODO: bug??

    if (!nextRule || !rule) {
      return true;
    }

    const roleFieldsChanged = nextRule.id !== rule.value || nextRule.limit !== limit;

    return roleFieldsChanged;
  };

  resetApprovalRule = (rule) => {
    const inputsState = this.getInputsState(rule);

    this.setState(inputsState);
  };

  assignLimitValue = (approveAll, blanketApprover, limit) => {
    if (approveAll) {
      return 0;
    }
    if (blanketApprover) {
      return -1;
    }
    return limit;
  };

  submitApprovalRuleForm = async (event) => {
    event.preventDefault();

    const {
      approvalType,
      role,
      user,
      sites,
      nodes,
      limit,
      approveAll,
      blanketApprover,
    } = this.state;

    const errors = this.getErrors();
    this.setState({ errors });

    if (Object.entries(errors).length === 0) {
      const { handleFormSubmit } = this.props;
      const payload = {
        limit: this.assignLimitValue(approveAll, blanketApprover, limit),
      };

      if (approvalType === 'userRole') {
        payload.role = role.value;
      } else {
        payload.user = user.value;
        payload.sites = sites ? sites.map((site) => site.value) : [];
        payload.nodes = nodes ? nodes.map((node) => node.value) : [];
      }
      this.setState({ ruleUpdated: true }, () => {
        handleFormSubmit(payload);
      });
    }
  };

  getErrors = () => {
    const errors = {};
    const { approvalType, role, user, sites, nodes, limit, approveAll } = this.state;

    if (approvalType === 'userRole') {
      if (!role || !role.value) errors.role = 'User Role field is required';
    } else {
      if (!user || !user.value) errors.user = 'User field is required';
      if (user && user.value && (!sites || !sites.length) && (!nodes || !nodes.length)) {
        errors.nodes = 'A site or a node is required';
      }
    }

    if (!approveAll && Number.isNaN(parseInt(limit, 10)))
      errors.limit = 'Order Minimum Total field is required';

    return errors;
  };

  fetchUserNodes = async (userId) => {
    try {
      let userNodes = await siteManagerAPI.get(`${API_APPROVAL_USERS}${userId}/nodes/`);
      userNodes = userNodes.data.map((node) => formatNodeWithGroupName(node));
      this.setState({ userNodes });
    } catch (error) {
      console.error(`Error fetching nodes from user ${userId}`, error);
    }
  };

  handleChange = (event) => {
    const { name, type } = event.target;
    const value = getFieldValue(event.target);

    if (type === 'number') {
      value[name] = parseInt(value[name], 10);
      this.setState({ limit: value, errors: {} });
    }

    this.setState({ ...value });
  };

  handleSelectChange = (selectedOption) => {
    this.setState({ role: selectedOption });
  };

  handleSwitchChange = (name, checked) => {
    this.setState({ [name]: checked });
    if (name === 'blanketApprover' && checked) {
      this.setState({ approveAll: false, limit: -1, errors: {} });
    } else if (name === 'approveAll' && checked) {
      this.setState({ blanketApprover: false, limit: 0, errors: {} });
    }
  };

  handleRadioButtonChange = (event) => {
    this.setState({ approvalType: event.target.value });
  };

  handleUserChange = (newValue) => {
    this.setState({ user: newValue });
  };

  mapRolesToOptions = (optionsArray) => {
    const { approvalRules } = this.props;
    if (!Array.isArray(optionsArray)) return null;
    const rolesAlreadyAssigned = approvalRules
      .filter((approvalRule) => approvalRule.role)
      .map((approvalRule) => approvalRule.role.id);

    let parsedOptions = [];
    parsedOptions = optionsArray.map((option) => ({
      value: option.id,
      label: option.name,
      isDisabled: rolesAlreadyAssigned.includes(option.id),
    }));

    return parsedOptions;
  };

  mapNodesToSites = (optionsArray) => {
    const { rule } = this.props;

    return optionsArray.map((node) => {
      return {
        label: formatNodeToLabel(node),
        options: node.sites.map((site) => ({
          label: `${site.name}`,
          value: site.id,
          isDisabled: site.already_used && (!rule || rule.id !== site.user_approval_rule),
        })),
      };
    });
  };

  mapNodesToOptions = (optionsArray) => {
    const { rule } = this.props;

    return optionsArray.map((option) => ({
      value: option.id,
      label: formatNodeToLabel(option),
      isDisabled: option.already_used && (!rule || rule.id !== option.user_approval_rule),
    }));
  };

  render() {
    const {
      approvalType,
      role,
      user,
      userNodes,
      nodes,
      sites,
      limit,
      approveAll,
      blanketApprover,
      errors,
    } = this.state;
    const { roles, users, rule: propsRule } = this.props;
    const limitAlreadySaved = propsRule ? propsRule.limit : null;
    const approveAllError = limit === 0 && errors.limit ? ALREADY_A_ROLE_WITH_THIS_RULE : null;
    const blanketError = limit === -1 && errors.limit ? ALREADY_A_ROLE_WITH_THIS_RULE : null;
    const buttonDisabled = limit === limitAlreadySaved || errors.limit;

    return (
      <Fragment>
        <FormWrapper extraClassName="approval-rules-form">
          <form className="form-input mb-2 clearfix" onSubmit={this.submitApprovalRuleForm}>
            {!propsRule && (
              <div className="section">
                <RadioButtonGroup
                  name="approvalType"
                  label="Create By"
                  options={[
                    { label: 'User Role', value: 'userRole' },
                    { label: 'User', value: 'user' },
                  ]}
                  currentValue="userRole"
                  handleChange={this.handleRadioButtonChange}
                />
              </div>
            )}
            {approvalType === 'userRole' ? (
              <div className="section">
                <SelectInput
                  label="User Role"
                  name="role"
                  value={role}
                  error={errors.role}
                  options={roles}
                  mapOptions={this.mapRolesToOptions}
                  handleChange={this.handleSelectChange}
                  placeholder="Select User Role..."
                  isDisabled={!!propsRule}
                  isSearchable
                  closeMenuOnSelect
                  required
                />
              </div>
            ) : (
              <Fragment>
                <div className="section">
                  <SelectInput
                    label="User"
                    name="user"
                    value={user}
                    error={errors.user}
                    options={users}
                    mapOptions={(options) =>
                      options.map((option) => ({ value: option.id, label: option.fullName }))
                    }
                    handleChange={this.handleUserChange}
                    placeholder="Select User..."
                    isDisabled={!!propsRule}
                    isSearchable
                    isClearable
                    closeMenuOnSelect
                    required
                  />
                </div>
                {user && (
                  <Fragment>
                    <div className="form-field form-group section">
                      <label className="medium-text" htmlFor="input-nodes">
                        Nodes <small>(you can select multiple nodes)</small>
                      </label>
                      <Select
                        value={nodes}
                        isMulti
                        options={this.mapNodesToOptions(userNodes)}
                        onChange={(newValue) => this.setState({ nodes: newValue })}
                        placeholder="Select Nodes..."
                      />
                      {errors.nodes && (
                        <div className="form-field__error small-text">{errors.nodes}</div>
                      )}
                    </div>
                    <div className="form-field form-group">
                      <label className="medium-text" htmlFor="input-sites">
                        Sites <small>(you can select multiple sites)</small>
                      </label>
                      <Select
                        value={sites}
                        isMulti
                        options={this.mapNodesToSites(userNodes)}
                        onChange={(newValue) => this.setState({ sites: newValue })}
                        placeholder="Select Sites..."
                      />
                      {errors.sites && (
                        <div className="form-field__error small-text">{errors.sites}</div>
                      )}
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}

            <div className="section">
              <SwitchInput
                error={approveAllError}
                name="approveAll"
                checked={approveAll}
                label="Approve Every Order"
                handleChange={this.handleSwitchChange}
              />

              <SwitchInput
                error={blanketError}
                name="blanketApprover"
                checked={blanketApprover}
                label="Blanket Approver"
                handleChange={this.handleSwitchChange}
              />
            </div>

            {!approveAll && !blanketApprover && (
              <div className="section">
                <NumberInput
                  error={errors.limit}
                  value={limit}
                  label="Order Total Above"
                  name="limit"
                  handleChange={this.handleChange}
                  min={0}
                  step={1}
                  required
                />
              </div>
            )}
            {errors.general && <div className="form-field__error small-text">{errors.general}</div>}

            <div className="float-right">
              <button
                disabled={buttonDisabled}
                type="submit"
                data-placement="right"
                className="btn btn-primary"
              >
                <FontAwesomeIcon icon="address-card" />
                <span> SAVE ORDER APPROVAL RULE</span>
              </button>
            </div>
          </form>
        </FormWrapper>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.roles.roles.results,
  users: state.users.users.results,
  errors: _.get(state, 'approvalRules.errors', {}),
  approvalRules: _.get(state, 'approvalRules.list.data', []),
});

export default connect(mapStateToProps, {
  fetchRoles,
  fetchUsers,
  resetErrors,
  fetchApprovalRules,
})(ApprovalRuleForm);
