import React from 'react';
import PropTypes from 'prop-types';

function EmptyOrderApprovalsState(props) {
  const { activateOrderApprovals } = props;
  return (
    <div className="approval-rules__activate-container">
      <h4>You dont have this feature enabled</h4>
      <button
        type="button"
        onClick={activateOrderApprovals}
        className="btn btn-primary approval-rules__activate-btn"
      >
        Enable order approvals
      </button>
    </div>
  );
}

export default EmptyOrderApprovalsState;

EmptyOrderApprovalsState.propTypes = {
  activateOrderApprovals: PropTypes.func.isRequired,
};
