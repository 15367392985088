import { toast } from 'react-toastify';

import siteManagerAPI from 'lib/api-manager';
import * as hierarchyUtils from 'shared/utils/hierarchy';
import { API_HIERARCHY_NODES, API_NODE_CONFIGURATION } from 'lib/api-endpoints';
import {
  NODE_SUCCESSFULLY_UPDATED,
  NODE_CONFIGURATION_SUCCESSFULLY_UPDATED,
} from 'constants/messages';
import { UPDATE_NODES_ERROR, UPDATE_NODE_CONFIGURATION_ERROR } from 'constants/error-messages';

export const updateNode = (id, node) => async () => {
  try {
    await siteManagerAPI.patch(`${API_HIERARCHY_NODES}${id}/`, node);
    toast.success(NODE_SUCCESSFULLY_UPDATED);
    return null;
  } catch (error) {
    toast.error(UPDATE_NODES_ERROR);
    return hierarchyUtils.formatNodeError(error);
  }
};

export const updateNodeConfig = (id, configuration) => async () => {
  try {
    await siteManagerAPI.patch(API_NODE_CONFIGURATION.replace('{id}', id), configuration);
    toast.success(NODE_CONFIGURATION_SUCCESSFULLY_UPDATED);
    return null;
  } catch (error) {
    toast.error(UPDATE_NODE_CONFIGURATION_ERROR);
    return hierarchyUtils.formatNodeConfigError(error);
  }
};
