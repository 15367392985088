import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import SelectInput from 'components/forms/select-input';
import TextInput from 'components/forms/text-input.component';
import { COMPANY_CONFIGURATION_FEATURE_FLAG_OPTIONS } from 'constants/values';

// import * as actions from './actions';
import * as utils from './utils';
import messages from './messages';

const featureFlagDropdownOptions = Object.values(COMPANY_CONFIGURATION_FEATURE_FLAG_OPTIONS);

function getFlagDropdownValue(value) {
  return featureFlagDropdownOptions.filter((option) => option.value === value).pop();
}

function CompanyConfigurationForm(props) {
  const { onSubmit, clearOnSubmit, submitButtonText, cancelButtonText, initialData } = props;
  const [hasOrderTracking, setHasOrderTracking] = useState(
    COMPANY_CONFIGURATION_FEATURE_FLAG_OPTIONS.NOT_AVAILABLE,
  );
  const [hasOrderApproval, setHasOrderApproval] = useState(
    COMPANY_CONFIGURATION_FEATURE_FLAG_OPTIONS.NOT_AVAILABLE,
  );
  const [hasHierarchy, setHasHierarchy] = useState(
    COMPANY_CONFIGURATION_FEATURE_FLAG_OPTIONS.NOT_AVAILABLE,
  );
  const [catalogs, setCatalogs] = useState([]);
  const [tenantGroup, setTenantGroup] = useState('');
  const [customerERPIdentifier, setCustomerERPIdentifier] = useState('');
  // const [availableCatalogs, setAvailableCatalogs] = useState([]);
  const [errors, setErrors] = useState({});
  const history = useHistory();

  // useEffect(() => {
  //   actions.fetchCatalogs().then(setAvailableCatalogs);
  // }, []);

  useEffect(() => {
    setTenantGroup(initialData.tenantGroup);
    setCustomerERPIdentifier(initialData.customerERPIdentifier);
    setHasOrderApproval(getFlagDropdownValue(initialData.hasOrderApproval));
    setHasOrderTracking(getFlagDropdownValue(initialData.hasOrderTracking));
    setHasHierarchy(getFlagDropdownValue(initialData.hasCompanyHierarchy));
    setCatalogs(initialData.catalogs);
  }, [initialData]);

  const handleDropdownChange = useCallback((handler) => (value) => handler(value), []);

  const resetFields = useCallback(() => {
    setHasOrderTracking('');
    setHasOrderApproval('');
    setCatalogs('');
  }, []);

  // const mapNodesToSelectInput = useCallback((options) => {
  //   if (!Array.isArray(options)) return null;
  //   return options.map((option) => ({
  //     ...option,
  //     value: option.id,
  //     label: option.name,
  //   }));
  // }, []);

  const mapOptionsToSelectInput = useCallback((options) => options, []);

  const validateForm = () => {
    const errorResponse = {};

    if (!tenantGroup) {
      errorResponse.errors = {
        tenantGroup: messages.tenantGroupMissingError,
        ...errorResponse.errors,
      };
    }
    if (!customerERPIdentifier) {
      errorResponse.errors = {
        customerERPIdentifier: messages.customerERPIdentifierMissingError,
        ...errorResponse.errors,
      };
    }
    if (!hasOrderApproval) {
      errorResponse.errors = {
        hasOrderApproval: messages.orderApprovalMissingError,
        ...errorResponse.errors,
      };
    }
    if (!hasOrderTracking) {
      errorResponse.errors = {
        hasOrderTracking: messages.orderTrackingMissingError,
        ...errorResponse.errors,
      };
    }
    if (!hasHierarchy) {
      errorResponse.errors = {
        hasHierarchy: messages.hierarchyMissingError,
        ...errorResponse.errors,
      };
    }

    return errorResponse;
  };

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      let errorResponse = validateForm();

      if (!errorResponse.errors) {
        const data = utils.formatFormData({
          tenantGroup,
          customerERPIdentifier,
          hasOrderApproval,
          hasOrderTracking,
          hasHierarchy,
          // catalogs,
        });
        errorResponse = await onSubmit(data);
      }

      if (errorResponse) {
        setErrors(errorResponse.errors);
      } else if (clearOnSubmit) {
        resetFields();
      }
    },
    [
      hasOrderApproval,
      hasOrderTracking,
      hasHierarchy,
      customerERPIdentifier,
      tenantGroup,
      catalogs,
      clearOnSubmit,
      resetFields,
    ],
  );

  const handleChange = (handler) => (event) => {
    handler(event.target.value);
  };

  const goBack = () => history.goBack();

  return (
    <form onSubmit={handleSubmit} className="company-configuration-form">
      {/* <SelectInput
        handleChange={handleDropdownChange(setCatalogs)}
        value={catalogs}
        options={availableCatalogs}
        label={messages.catalogInputLabel}
        mapOptions={mapNodesToSelectInput}
        error={errors.catalogs}
        name="catalogs"
        isMulti
        isSearchable
      /> */}
      <TextInput
        error={errors.tenantGroup}
        value={tenantGroup}
        label="Tenant Group"
        name="tenantGroup"
        handleChange={handleChange(setTenantGroup)}
        required
      />
      <TextInput
        error={errors.customerERPIdentifier}
        value={customerERPIdentifier}
        label="Name Extension / Customer ERP Id"
        name="customerERPIdentifier"
        handleChange={handleChange(setCustomerERPIdentifier)}
        required
      />
      <SelectInput
        handleChange={handleDropdownChange(setHasOrderApproval)}
        value={hasOrderApproval}
        options={featureFlagDropdownOptions}
        label={messages.hasOrderApprovalInputLabel}
        mapOptions={mapOptionsToSelectInput}
        error={errors.hasOrderApproval}
        name="hasOrderApproval"
        closeMenuOnSelect
        required
      />
      <SelectInput
        handleChange={handleDropdownChange(setHasOrderTracking)}
        value={hasOrderTracking}
        options={featureFlagDropdownOptions}
        label={messages.hasOrderTrackingInputLabel}
        mapOptions={mapOptionsToSelectInput}
        error={errors.hasOrderTracking}
        name="hasOrderTracking"
        closeMenuOnSelect
        required
      />
      <SelectInput
        handleChange={handleDropdownChange(setHasHierarchy)}
        value={hasHierarchy}
        options={featureFlagDropdownOptions}
        label={messages.hasHierarchyInputLabel}
        mapOptions={mapOptionsToSelectInput}
        error={errors.hasHierarchy}
        name="hasHierarchy"
        closeMenuOnSelect
        required
      />
      <div className="company-configuration-form__actions-container">
        <button type="submit" className="btn btn-primary mr-2">
          {submitButtonText}
        </button>
        <button onClick={goBack} type="button" className="btn">
          {cancelButtonText}
        </button>
      </div>
    </form>
  );
}

CompanyConfigurationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  clearOnSubmit: PropTypes.bool,
  submitButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  initialData: PropTypes.shape({
    hasOrderTracking: PropTypes.bool,
    hasOrderApproval: PropTypes.bool,
    hasCompanyHierarchy: PropTypes.bool,
    customerERPIdentifier: PropTypes.string,
    tenantGroup: PropTypes.string,
    catalogs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }),
};

CompanyConfigurationForm.defaultProps = {
  cancelButtonText: messages.defaultCancelButtonText,
  clearOnSubmit: false,
  initialData: {},
  submitButtonText: messages.defaultMainButtonText,
};

export default CompanyConfigurationForm;
