import { toast } from 'react-toastify';

import {
  USERS_FETCH_USERS_FOR_COMPANY,
  USERS_SET_USER_FOR_USER_DETAIL_PAGE,
  USERS_RESET_FETCH_USERS,
  GLOBALS_SET_IS_TABLE_LOADING,
} from 'lib/types';
import { FETCH_REGULAR_USERS_ERROR } from 'constants/error-messages';
import siteManagerAPI from 'lib/api-manager';
import UsersSerializer from 'lib/serializers/UsersSerializer';

export const fetchUsers = (url, queryParams) => async (dispatch) => {
  let response = {};
  dispatch({
    type: GLOBALS_SET_IS_TABLE_LOADING,
    payload: true,
  });

  try {
    response = await siteManagerAPI.get(url, {
      params: queryParams,
    });
    response.data.queryParams = queryParams;
  } catch (error) {
    toast.error(FETCH_REGULAR_USERS_ERROR);
  }
  const users = new UsersSerializer(response.data);

  dispatch({
    type: USERS_FETCH_USERS_FOR_COMPANY,
    payload: users,
  });
  dispatch({
    type: GLOBALS_SET_IS_TABLE_LOADING,
    payload: false,
  });
};

export const resetUsers = () => (dispatch) => {
  dispatch({ type: USERS_RESET_FETCH_USERS });
};

export const setActiveDetailUser = (data) => (dispatch) => {
  dispatch({
    type: USERS_SET_USER_FOR_USER_DETAIL_PAGE,
    payload: data,
  });
};
