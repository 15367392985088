import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import $ from 'jquery';
import BreadCrumbComponent from '../../shared/breadcumb.component';
import BreadCrumbItem from '../../shared/breadcrumb-item';
import SectionHeader from '../../shared/section-header.component';
import RolesList from './roles-list';
import { fetchRoles, updateRoleCanImpersonate } from '../../../actions/roles';

class EcommerceLoginConfiguration extends Component {
  static propTypes = {
    roles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    fetchRoles: PropTypes.func.isRequired,
    updateRoleCanImpersonate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  componentDidMount() {
    this.fetchRolesWithParams({ order_by: 'name' });
  }

  handleRoleCanImpersonateEdit = (role) => {
    const { updateRoleCanImpersonate: updateRoleCanImpersonateAction } = this.props;

    updateRoleCanImpersonateAction(role.id, role.canImpersonate);
  };

  fetchRolesWithParams = (queryParams) => {
    const { fetchRoles: fetchRolesAction } = this.props;

    fetchRolesAction(queryParams);
  };

  render() {
    const { isModalOpen } = this.state;

    const { roles } = this.props;

    $('#shared-modal').modal(isModalOpen ? 'show' : 'hide');

    return (
      <Fragment>
        <SectionHeader subtitle="Configuration" sectionLabel="Ecommerce Login" />

        <div className="content__container">
          <BreadCrumbComponent>
            <BreadCrumbItem label="Configuration" link="/configuration" />
            <BreadCrumbItem label="Ecommerce Login" />
          </BreadCrumbComponent>

          <hr />

          <RolesList
            roles={roles}
            handleRoleCanImpersonateEdit={this.handleRoleCanImpersonateEdit}
            fetchRolesWithParams={this.fetchRolesWithParams}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.roles.roles.results,
});

export default connect(mapStateToProps, {
  fetchRoles,
  updateRoleCanImpersonate,
})(EcommerceLoginConfiguration);
