import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AdminNavigation from 'shared/components/admin-navigation';
import messages from './messages';

function AdminPageWrapper(props) {
  const { location, title, subtitle, children } = props;

  return (
    <React.Fragment>
      <div className="main-navbar">
        <Link to="/companies">
          <h3>{messages.siteName}</h3>
        </Link>
      </div>
      <AdminNavigation location={location} />
      <section className="admin-wrapper">
        <div className="admin-wrapper__title-container">
          <h1 className="admin-wrapper__title">{title}</h1>
          {subtitle && <h6 className="admin-wrapper__subtitle">{subtitle}</h6>}
        </div>
        {children}
      </section>
    </React.Fragment>
  );
}

AdminPageWrapper.propTypes = {
  location: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AdminPageWrapper.defaultProps = {
  subtitle: '',
};

export default AdminPageWrapper;
