import { toast } from 'react-toastify';
import mapValues from 'lodash/mapValues';

import ApprovalRulesSerializer from 'lib/serializers/approval-rules';
import ApprovalRuleSerializer from 'lib/serializers/approval-rule';
import siteManagerAPI from 'lib/api-manager';
import { API_ROLES, API_APPROVAL_RULES, API_APPROVAL_RULES_DETAILS } from 'lib/api-endpoints';
import RolesSerializer from 'lib/serializers/RolesSerializers';
import RoleSerializer from 'lib/serializers/RoleSerializer';
import {
  ROLES_FETCH_COMPANY_ROLES,
  ROLES_FETCH_ROLE,
  ROLES_SET_ROLE_FORM_ERRORS,
  APPROVAL_RULES_SAVE_LIST,
  APPROVAL_RULES_SAVE_CURRENT,
  APPROVAL_RULES_SAVE_ERRORS,
} from 'lib/types';
import {
  FETCH_ROLES_TRY_LATER,
  FETCH_ORDER_APPROVAL_RULES_TRY_LATER,
  CREATE_ROLE_TRY_LATER,
  UPDATE_ROLE_TRY_LATER,
  CREATE_ORDER_APPROVAL_RULE_TRY_LATER,
  UPDATE_ORDER_APPROVAL_RULE_TRY_LATER,
  DELETE_ORDER_APPROVAL_RULE_TRY_LATER,
  DELETE_ROLE_TRY_LATER,
  FETCH_ROLE_TRY_LATER,
} from 'constants/error-messages';
import {
  ORDER_APPROVAL_RULE_SUCCESSFULLY_CREATED,
  ORDER_APPROVAL_RULE_SUCCESSFULLY_UPDATED,
  ORDER_APPROVAL_RULE_SUCCESSFULLY_DELETED,
  ROLE_SUCCESSFULLY_UPDATED,
  ROLE_SUCCESSFULLY_DELETED,
} from 'constants/messages';

function handleApprovalRuleError(error, dispatch) {
  const errors = mapValues(error.response.data, (errorData) =>
    Array.isArray(errorData) ? errorData[0] : errorData,
  );

  dispatch({
    type: APPROVAL_RULES_SAVE_ERRORS,
    payload: errors,
  });
}

function resetApprovalRulesErrors(dispatch) {
  dispatch({
    type: APPROVAL_RULES_SAVE_ERRORS,
    payload: null,
  });
}

export const fetchRoles = (params) => async (dispatch) => {
  try {
    const response = await siteManagerAPI.get(API_ROLES, { params });
    const roles = new RolesSerializer(response.data);

    dispatch({
      type: ROLES_FETCH_COMPANY_ROLES,
      payload: roles,
    });
  } catch (error) {
    toast.error(FETCH_ROLES_TRY_LATER);
  }
};

export const fetchApprovalRules = (params) => async (dispatch) => {
  try {
    const response = await siteManagerAPI.get(API_APPROVAL_RULES, { params });
    const rules = new ApprovalRulesSerializer(response.data);

    dispatch({
      type: APPROVAL_RULES_SAVE_LIST,
      payload: rules,
    });
  } catch (error) {
    toast.error(FETCH_ORDER_APPROVAL_RULES_TRY_LATER);
  }
};

export const createRole = (role) => async (dispatch) => {
  try {
    await siteManagerAPI.post(API_ROLES, { ...role });

    dispatch({
      type: ROLES_SET_ROLE_FORM_ERRORS,
      payload: {},
    });

    dispatch(fetchRoles());
  } catch (error) {
    const { status, data } = error.response;

    if (status === 400) {
      dispatch({
        type: ROLES_SET_ROLE_FORM_ERRORS,
        payload: data,
      });
    } else if (status > 400) {
      toast.error(CREATE_ROLE_TRY_LATER);
    }
  }
};

const updateRole = (id, body, successMessage, errorMessage) => async (dispatch) => {
  try {
    const response = await siteManagerAPI.patch(`${API_ROLES}${id}/`, body);
    const role = new RoleSerializer(response.data);

    dispatch({
      type: ROLES_FETCH_ROLE,
      payload: role,
    });

    dispatch({
      type: ROLES_SET_ROLE_FORM_ERRORS,
      payload: {},
    });

    toast.success(successMessage);
  } catch (error) {
    const { status, data } = error.response;

    if (status === 400) {
      dispatch({
        type: ROLES_SET_ROLE_FORM_ERRORS,
        payload: data,
      });
      toast.error(errorMessage);
    } else if (status > 400) {
      toast.error(errorMessage);
    }
  }
};

export const updateRoleName = (id, name) => async (dispatch) => {
  dispatch(updateRole(id, { name }, ROLE_SUCCESSFULLY_UPDATED, UPDATE_ROLE_TRY_LATER));
};

export const updateRoleCanImpersonate = (id, canImpersonate) => async (dispatch) => {
  await updateRole(
    id,
    { can_impersonate: canImpersonate },
    ROLE_SUCCESSFULLY_UPDATED,
    UPDATE_ROLE_TRY_LATER,
  )(dispatch);
  fetchRoles({ order_by: 'name' })(dispatch);
};

export const updateRoleCanViewOrderTracking = (id, canViewOrderTracking) => async (dispatch) => {
  await updateRole(
    id,
    { can_view_order_tracking: canViewOrderTracking },
    ROLE_SUCCESSFULLY_UPDATED,
    UPDATE_ROLE_TRY_LATER,
  )(dispatch);
  fetchRoles({ order_by: 'name' })(dispatch);
};

export const fetchApprovalRule = (id) => async (dispatch) => {
  try {
    const response = await siteManagerAPI.get(API_APPROVAL_RULES_DETAILS.replace('{id}', id));
    const approvalRule = new ApprovalRuleSerializer(response.data);

    dispatch({
      type: APPROVAL_RULES_SAVE_CURRENT,
      payload: approvalRule,
    });
  } catch (error) {
    toast.warn(FETCH_ORDER_APPROVAL_RULES_TRY_LATER);
  }
};

export const createApprovalRule = (approvalRule) => async (dispatch) => {
  try {
    await siteManagerAPI.post(API_APPROVAL_RULES, approvalRule);
    toast.success(ORDER_APPROVAL_RULE_SUCCESSFULLY_CREATED);
    resetApprovalRulesErrors(dispatch);
  } catch (error) {
    handleApprovalRuleError(error, dispatch);
    toast.warn(CREATE_ORDER_APPROVAL_RULE_TRY_LATER);
  }
};

export const updateApprovalRule = (id, approvalRule) => async (dispatch) => {
  try {
    await siteManagerAPI.patch(API_APPROVAL_RULES_DETAILS.replace('{id}', id), approvalRule);
    toast.success(ORDER_APPROVAL_RULE_SUCCESSFULLY_UPDATED);
    resetApprovalRulesErrors(dispatch);
  } catch (error) {
    handleApprovalRuleError(error, dispatch);
    toast.warn(UPDATE_ORDER_APPROVAL_RULE_TRY_LATER);
  }
};

export const deleteApprovalRule = (id) => async () => {
  try {
    await siteManagerAPI.delete(API_APPROVAL_RULES_DETAILS.replace('{id}', id));
    toast.success(ORDER_APPROVAL_RULE_SUCCESSFULLY_DELETED);
  } catch (error) {
    toast.warn(DELETE_ORDER_APPROVAL_RULE_TRY_LATER);
  }
};

export const deleteRole = (id) => async (dispatch) => {
  try {
    await siteManagerAPI.delete(`${API_ROLES}${id}`);

    dispatch(fetchRoles());
    toast.success(ROLE_SUCCESSFULLY_DELETED);
  } catch (error) {
    toast.error(DELETE_ROLE_TRY_LATER);
  }
};

export const fetchRole = (id) => async (dispatch) => {
  try {
    const response = await siteManagerAPI.get(`${API_ROLES}${id}`);
    const role = new RoleSerializer(response.data);

    dispatch({
      type: ROLES_FETCH_ROLE,
      payload: role,
    });
  } catch (error) {
    toast.error(FETCH_ROLE_TRY_LATER);
  }
};

export const resetErrors = () => async (dispatch) => {
  dispatch({
    type: ROLES_SET_ROLE_FORM_ERRORS,
    payload: {},
  });
};
