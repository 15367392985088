import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ size, src, alt }) => (
  <img className={`${size ? `company-logo--${size}` : ''}`} src={src} alt={alt} />
);

Logo.propTypes = {
  size: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

Logo.defaultProps = {
  size: '',
};

export default Logo;
