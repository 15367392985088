import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ApprovalRuleConfirmationModal from 'components/shared/approval-rule-confirmation-modal.component';
import BreadCrumbComponent from '../../shared/breadcumb.component';
import BreadCrumbItem from '../../shared/breadcrumb-item';
import SectionHeader from '../../shared/section-header.component';
import RoleForm from './role-form';
import { fetchRole, updateRoleName } from '../../../actions/roles';

class RoleEdit extends Component {
  static propTypes = {
    fetchRole: PropTypes.func.isRequired,
    updateRoleName: PropTypes.func.isRequired,
    role: PropTypes.shape().isRequired,
    match: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      role: props.role,
      errors: {},
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const { fetchRole: fetchRoleAction, match } = this.props;

    fetchRoleAction(parseInt(match.params.roleId, 10));
  }

  componentWillReceiveProps(nextProps) {
    const { role } = this.props;

    if (JSON.stringify(nextProps.role) !== JSON.stringify(role)) {
      this.setState({ role: nextProps.role });
    }
  }

  handleSubmit = (role) => {
    const { role: propsRole, updateRoleName: updateRoleNameAction } = this.props;

    if (propsRole.id && propsRole.hasApprovalRule) {
      this.displayModal();
    } else {
      updateRoleNameAction(propsRole.id, role.name);
    }
  };

  handleModalConfirmation = () => {
    const { role: propsRole, updateRoleName: updateRoleNameAction } = this.props;
    const { role } = this.state;

    this.closeModal();
    updateRoleNameAction(propsRole.id, role.name);
  };

  displayModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { role, errors, isModalOpen } = this.state;

    const { role: propsRole } = this.props;

    return (
      <Fragment>
        <SectionHeader subtitle="Role" action="EDIT" sectionLabel={propsRole && propsRole.name} />

        <div className="content__container">
          <BreadCrumbComponent>
            <BreadCrumbItem label="Configuration" link="/configuration" />
            <BreadCrumbItem label="Roles" link="/configuration/roles" />
            <BreadCrumbItem label={propsRole && propsRole.name} />
          </BreadCrumbComponent>

          <div className="col-12 col-lg-4">
            <RoleForm
              role={role}
              errors={errors}
              handleFormSubmit={this.handleSubmit}
              handleChange={this.handleChange}
            />
          </div>
        </div>

        <ApprovalRuleConfirmationModal
          isOpen={isModalOpen}
          closeHandler={this.closeModal}
          confirmHandler={this.handleModalConfirmation}
          obj={role}
          objName="role"
          action="edit"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.roles.role,
});

export default connect(mapStateToProps, {
  fetchRole,
  updateRoleName,
})(RoleEdit);
