import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const BreadCrumbComponent = ({ children }) => (
  <div className="container-fluid">
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">{children}</ol>
    </nav>
  </div>
);

BreadCrumbComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withRouter(BreadCrumbComponent);
