export default {
  submitButtonText: 'Update',
  createCompanyConfigurationButtonText: 'Create Company configuration',
  editCompanyConfigurationButtonText: 'Edit Company configuration',
  backButtonText: 'Return to companies',
  title: 'Update Company',
  subtitle: 'MCC Admin',
  breadcrumbCompaniesLabel: 'Companies',
  breadcrumbUpdateCompanyLabel: 'Update Company',
};
