import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import TextInput from 'components/forms/text-input.component';
import * as hierarchyActions from 'shared/actions/hierarchy';
import * as hierarchySelectors from 'shared/selectors/hierarchy';
import { formatNodeToSelectInput, parseNodeOptions } from 'utils/format';
import NodeConfigurationFields from 'scenes/hierarchy/components/node-configuration-form/nodeConfigurationFields';
import SelectInput from '../../../components/forms/select-input';
import { useStructuredSelector, useSingleSelector } from '../../hooks/useSelector';
import useValidation from '../../hooks/useValidation';
import useActions from '../../hooks/useActions';

import messages from './messages';
import * as validations from './validations';
import * as actions from './actions';
import * as selectors from './selectors';
import * as utils from './utils';

function NodesForm(props) {
  const {
    clearOnSubmit,
    currentNodeId,
    onSubmit,
    submitButtonText,
    configurationIsTwoStep,
    onConfigurationToggle,
    isEditForm,
    errors: errorProps,
  } = props;
  const [name, setName] = useState('');
  const [parent, setParent] = useState(null);
  const [group, setGroup] = useState(null);
  const [users, setUsers] = useState([]);
  const [sites, setSites] = useState([]);
  const [errors, setErrors] = useState({});
  const [availableNodes, setAvailableNodes] = useState([]);
  const [hasCustomConfig, setHasCustomConfig] = useState(false);
  const [configData, setConfigData] = useState({});
  const { fetchNodes, fetchGroups, fetchSites, fetchUsers, fetchCurrentNode } = useActions(actions);
  const fetchCurrentNodeConfig = useActions(hierarchyActions.fetchCurrentNodeConfig);
  const {
    availableGroups,
    availableSites,
    availableNodes: unfilteredAvailableNodes,
    availableUsers,
  } = useStructuredSelector(selectors);
  const currentNode = useSingleSelector(hierarchySelectors.currentNode);
  const validator =
    currentNode && currentNode.isRoot ? validations.rootNode : validations.defaultNode;
  const validate = useValidation(validator);
  const validateCustomConfig = useValidation(validations.customConfig);

  useEffect(() => {
    fetchNodes();
    fetchGroups();
    fetchSites();
    fetchUsers();
    if (currentNodeId)
      fetchCurrentNode(currentNodeId).then(() => fetchCurrentNodeConfig(currentNodeId));
  }, [
    currentNodeId,
    fetchCurrentNode,
    fetchCurrentNodeConfig,
    fetchGroups,
    fetchNodes,
    fetchSites,
    fetchUsers,
  ]);

  useEffect(() => {
    if (currentNode && currentNode.id === Number(currentNodeId)) {
      setName(currentNode.name);
      setParent(currentNode.parent);
      setGroup(currentNode.group);
      setUsers(currentNode.users);
      setSites(currentNode.sites);
      if (currentNode.customConfig) {
        setHasCustomConfig(currentNode.hasOwnConfig);
        onConfigurationToggle(true);
        setConfigData({ ...currentNode.customConfig });
      }
    }
  }, [
    currentNodeId,
    currentNode,
    availableGroups,
    availableNodes,
    availableSites,
    availableUsers,
    onConfigurationToggle,
  ]);

  useEffect(() => {
    if (errorProps) setErrors({ ...errors, ...errorProps });
    else setErrors({});
  }, [errorProps]);

  useEffect(() => {
    if (currentNodeId && unfilteredAvailableNodes) {
      setAvailableNodes(
        unfilteredAvailableNodes.filter((node) => node.id !== Number(currentNodeId)),
      );
    } else setAvailableNodes(unfilteredAvailableNodes);
  }, [unfilteredAvailableNodes, currentNodeId]);

  const handleChange = useCallback((hanlder) => (event) => hanlder(event.target.value), []);

  const handleNodeChange = useCallback((hanlder) => (value) => hanlder(value), []);

  const handleCustomConfigCheckboxChange = useCallback(
    (event) => {
      setHasCustomConfig(event.target.checked);
      onConfigurationToggle(event.target.checked);
    },
    [setHasCustomConfig, onConfigurationToggle],
  );

  const mapOptionsToSelectInput = useCallback((options) => {
    if (!Array.isArray(options)) return null;
    return options.map((option) => ({
      ...option,
      value: option.id,
      label: option.name,
    }));
  }, []);

  const resetFields = useCallback(() => {
    setName('');
    setGroup(null);
    setParent(null);
    setUsers([]);
    setSites([]);
  }, []);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const errorsCollection = [];
      const data = utils.extractNodeIds({ name, parent, users, sites, group });
      errorsCollection.push(validate(data));
      if (hasCustomConfig && !configurationIsTwoStep) {
        errorsCollection.push(validateCustomConfig(configData));
        data.nodeConfigData = configData;
      }
      const validationErrors = utils.mergeErrors(errorsCollection);
      if (validationErrors) {
        setErrors(validationErrors);
      } else {
        onSubmit(data);
        if (clearOnSubmit) resetFields();
      }
    },
    [
      configurationIsTwoStep,
      name,
      parent,
      users,
      sites,
      group,
      onSubmit,
      clearOnSubmit,
      resetFields,
      hasCustomConfig,
      configData,
    ],
  );

  const formatOptionsToSelectInput = useCallback((node) => {
    if (!node) return null;
    return { ...node, value: node.id, label: node.name };
  }, []);

  return (
    <div className="text-primary medium-text medium-bold">
      <form onSubmit={handleSubmit}>
        {currentNodeId && (
          <TextInput value={currentNodeId} label={messages.idInputLabel} name="id" readOnly />
        )}
        <TextInput
          handleChange={handleChange(setName)}
          error={errors.name}
          value={name}
          label={messages.nameInputLabel}
          name="name"
          required
        />
        {(!currentNodeId || (currentNode && !currentNode.isRoot)) && (
          <SelectInput
            label={messages.parentNodeInputLabel}
            name="parent-node"
            value={formatNodeToSelectInput(parent)}
            error={errors.parent}
            options={availableNodes}
            mapOptions={parseNodeOptions}
            handleChange={handleNodeChange(setParent)}
            placeholder={messages.selectInputPlaceholder}
            closeMenuOnSelect
            isSearchable
            required
          />
        )}
        <SelectInput
          label={messages.groupInputLabel}
          name="group"
          value={formatOptionsToSelectInput(group)}
          error={errors.group}
          options={availableGroups}
          mapOptions={mapOptionsToSelectInput}
          handleChange={handleNodeChange(setGroup)}
          placeholder={messages.selectInputPlaceholder}
          closeMenuOnSelect
          isSearchable
          isClearable
        />
        {isEditForm && (
          <SelectInput
            label={messages.usersInputLabel}
            name="users"
            value={users}
            error={errors.users}
            options={availableUsers}
            mapOptions={mapOptionsToSelectInput}
            handleChange={handleNodeChange(setUsers)}
            placeholder={messages.selectInputPlaceholder}
            isSearchable
            isMulti
            isDisabled={isEditForm}
          />
        )}
        {isEditForm && (
          <SelectInput
            label={messages.sitesInputLabel}
            name="sites"
            value={sites}
            error={errors.sites}
            options={availableSites}
            mapOptions={mapOptionsToSelectInput}
            handleChange={handleNodeChange(setSites)}
            placeholder={messages.selectInputPlaceholder}
            isSearchable
            isMulti
            isDisabled={isEditForm}
          />
        )}
        <div className="form-group form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="hasCustomConfig"
            checked={hasCustomConfig}
            onChange={handleCustomConfigCheckboxChange}
          />
          <label className="form-check-label" htmlFor="hasCustomConfig">
            {messages.customConfigurationLabel}
          </label>
        </div>
        {hasCustomConfig && !configurationIsTwoStep && (
          <NodeConfigurationFields
            errors={errors}
            initialData={currentNode.customConfig}
            dataHandler={setConfigData}
          />
        )}
        {hasCustomConfig && configurationIsTwoStep && <p>{messages.configurationInNextStep}</p>}
        <button type="submit" className="btn btn-primary mt-2">
          {submitButtonText}
        </button>
      </form>
    </div>
  );
}

NodesForm.propTypes = {
  clearOnSubmit: PropTypes.bool,
  currentNodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  submitButtonText: PropTypes.string,
  onSubmit: PropTypes.func,
  onConfigurationToggle: PropTypes.func,
  configurationIsTwoStep: PropTypes.bool,
  isEditForm: PropTypes.bool,
  errors: PropTypes.object,
};

NodesForm.defaultProps = {
  clearOnSubmit: true,
  currentNodeId: '',
  onSubmit: () => {},
  onConfigurationToggle: () => {},
  submitButtonText: messages.createButtonText,
  configurationIsTwoStep: false,
  isEditForm: false,
  errors: null,
};

export default NodesForm;
