import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function LocationItem(props) {
  const { url, label } = props;

  return url ? (
    <Link to={url} className="admin-navigation__item">
      {label} <FontAwesomeIcon icon="chevron-right" />
    </Link>
  ) : (
    <span className="admin-navigation__item">{label}</span>
  );
}

LocationItem.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default LocationItem;
