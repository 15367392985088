import { createSelector } from 'reselect';

import * as utils from './utils';

function hierarchyStateSelector(state) {
  return state.hierarchy;
}

const tableStateSelector = createSelector(hierarchyStateSelector, (state) => state.policiesTable);

export const policies = createSelector(tableStateSelector, (state) =>
  utils.formatPolicies(state.policies),
);
