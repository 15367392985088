import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { openCloseSideBar } from '../../actions/globals.actions';
import LogOutButton from '../auth/logout.component';

class NavBarComponent extends Component {
  static propTypes = {
    userIsMccAdmin: PropTypes.bool.isRequired,
    isSideBarOpen: PropTypes.bool.isRequired,
    openCloseSideBar: PropTypes.func.isRequired,
  };

  get toggleIcon() {
    const { isSideBarOpen } = this.props;
    return isSideBarOpen ? 'times' : 'bars';
  }

  handleToggleClick = () => {
    const { openCloseSideBar } = this.props;

    openCloseSideBar();
  };

  render() {
    const { isSideBarOpen, userIsMccAdmin } = this.props;
    return userIsMccAdmin ? (
      <section className="admin-wrapper admin-wrapper--logout">
        <LogOutButton />
      </section>
    ) : (
      <div className={`navbar-wrapper ${isSideBarOpen ? '' : 'navbar-wrapper--sidebar-closed'}`}>
        <nav className="navbar navbar-light bg-white">
          <ul className="navbar-nav">
            <li className="nav-item">
              <button
                onClick={() => this.handleToggleClick()}
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <FontAwesomeIcon icon={this.toggleIcon} />
              </button>
            </li>
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item">
              <LogOutButton />
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userIsMccAdmin: state.auth.user.isMccAdmin,
  config: state.globals.config,
  isSideBarOpen: state.globals.isSideBarOpen,
});

export default connect(mapStateToProps, {
  openCloseSideBar,
})(NavBarComponent);
