import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { useSingleSelector } from 'shared/hooks/useSelector';
import useActions from 'shared/hooks/useActions';
import AdminNavigation from 'shared/components/admin-navigation';
import AdminPageWrapper from 'shared/components/admin-page-wrapper';
import * as mccAdminSelectors from 'shared/selectors/mcc-admin';
import * as mccAdminActions from 'shared/actions/mcc-admin';

import CompanyConfigurationForm from '../company-configuration-form';
import messages from './messages';
import * as actions from './actions';

function AdminEditCompanyConfiguration() {
  const { companyId } = useParams();
  const [companyConfiguration, setCompanyConfiguration] = useState({});
  const currentCompany = useSingleSelector(mccAdminSelectors.currentCompany);
  const setCurrentCompany = useActions(mccAdminActions.setCurrentCompany);

  useEffect(() => {
    mccAdminActions.fetchCompanyById(companyId).then(setCurrentCompany);
    mccAdminActions.fetchConfigurationById(companyId).then(setCompanyConfiguration);
  }, []);

  const handleOnSubmit = useCallback(
    (data) => actions.editCompanyConfiguration(companyId, data),
    [],
  );

  const location = useMemo(
    () =>
      _.compact([
        AdminNavigation.locations.companies,
        currentCompany.id && { url: `${currentCompany.id}`, label: currentCompany.name },
        { label: messages.navigationSectionLabel },
      ]),
    [currentCompany],
  );

  return (
    <AdminPageWrapper title={messages.title} subtitle={messages.subtitle} location={location}>
      <CompanyConfigurationForm
        onSubmit={handleOnSubmit}
        submitButtonText={messages.submitButtonText}
        initialData={companyConfiguration}
      />
    </AdminPageWrapper>
  );
}

export default AdminEditCompanyConfiguration;
