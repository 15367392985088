import siteManagerAPI from '../lib/api-manager';
import {
  AUTH_CURRENT_USER,
  AUTH_REMOVE_USER,
  AUTH_UPDATE_COMPANY_BILLING_ADDRESS,
} from '../lib/types';
import UserSerializer from '../lib/serializers/UserSerializer';
import { API_AUTH, API_CURRENT_USER } from '../lib/api-endpoints';

export const removeCurrentUser = () => ({
  type: AUTH_REMOVE_USER,
  payload: null,
});

export const fetchCurrentUser = (token) => async (dispatch) => {
  let response = {};

  siteManagerAPI.defaults.headers.common.Authorization = `JWT ${token}`;
  try {
    response = await siteManagerAPI.get(API_CURRENT_USER);
    response.data.token = token;
    const user = new UserSerializer(response.data);
    localStorage.setItem('am-token', token);

    dispatch({
      type: AUTH_CURRENT_USER,
      payload: {
        user,
      },
    });
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      dispatch(removeCurrentUser());
    }
  }
};

export const authUser = (username, password) => {
  const body = { username, password };

  return async (dispatch) => {
    let response = {};
    let user = null;

    try {
      response = await siteManagerAPI.post(API_AUTH, body);
      const data = response.data || {};
      user = new UserSerializer(data.user);
      user.token = data.token;
      siteManagerAPI.defaults.headers.common.Authorization = `JWT ${data.token}`;
      localStorage.setItem('am-token', data.token);
    } catch (error) {
      console.log(error);
    }

    dispatch({
      type: AUTH_CURRENT_USER,
      payload: {
        user,
      },
    });
  };
};

export const updateUserCompanyBillingAddress = (billingAddress) => (dispatch) => {
  dispatch({
    type: AUTH_UPDATE_COMPANY_BILLING_ADDRESS,
    payload: {
      defaultBillingAddress: billingAddress,
    },
  });
};
