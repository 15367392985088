import PaginationSerializer from './PaginationSerializer';
import AlertSerializer from './AlertSerializer';

export default class AlertsSerializer {
  constructor(data) {
    const alertsData = data || {};

    this.pagination = new PaginationSerializer(alertsData);
    this.results = Array.isArray(alertsData.results)
      ? alertsData.results.map((alert) => new AlertSerializer(alert))
      : [];
  }
}
