import React from 'react';

function IndicatorTooltip() {
  return (
    <div className="nci-tooltip">
      <p className="nci-tooltip__msg">GoExpedi Catalog</p>
      <svg
        className="nci-tooltip__icon"
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_313_3909)">
          <path d="M7 8L2.66987 0.500001L11.3301 0.5L7 8Z" fill="#F3F5F8" />
        </g>
        <defs>
          <filter
            id="filter0_d_313_3909"
            x="0.669922"
            y="0.5"
            width="12.6602"
            height="11.5"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_313_3909" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_313_3909"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
}

function NonCatalogIndicator() {
  return (
    <div className="non-catalog-indicator">
      <IndicatorTooltip />
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6667 2.41664H9.00008L7.33342 0.611084H2.33341C1.40841 0.611084 0.675081 1.41456 0.675081 2.41664L0.666748 13.25C0.666748 14.2521 1.40841 15.0555 2.33341 15.0555H15.6667C16.5917 15.0555 17.3334 14.2521 17.3334 13.25V4.2222C17.3334 3.22011 16.5917 2.41664 15.6667 2.41664ZM15.6667 13.25H2.33341V2.41664H6.64175L8.30841 4.2222H15.6667V13.25ZM9.00008 9.63886H10.6667V11.4444H12.3334V9.63886H14.0001V7.83331H12.3334V6.02775H10.6667V7.83331H9.00008V9.63886Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default NonCatalogIndicator;
