import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import randomize from 'randomatic';
import Select from 'react-select';
import { isValidPhoneNumber } from 'react-phone-number-input';
import _ from 'lodash';

import { getOffsetPagination, getUsernamePrefix, objectToSearchString } from 'utils/general';
import ApprovalRuleConfirmationModal from 'components/shared/approval-rule-confirmation-modal.component';
import PhoneNumberInput from 'components/forms/phone-number-input.component';
import { API_SITES, API_USER_CLONE, API_USERS } from '../../lib/api-endpoints';
import { setActiveDetailUser } from '../../actions/users.actions';
import { UNLIMITED_LIST_LIMIT } from '../../constants/values';
import { shouldDisplaySpinner } from '../../actions/globals.actions';
import { EMAIL_REGEX, getFieldValue } from '../../utils/forms';
import { formatNodeToSelectInput, parseNodeOptions, parseOptions } from '../../utils/format';
import SectionHeaderComponent from '../shared/section-header.component';
import BreadCrumbComponent from '../shared/breadcumb.component';
import UserSerializer from '../../lib/serializers/UserSerializer';
import siteManagerAPI from '../../lib/api-manager';
import TextInput from '../forms/text-input.component';
import SwitchInput from '../forms/switch-input.component';
import BreadCrumbItem from '../shared/breadcrumb-item';
import SelectInput from '../forms/select-input';
import { fetchRoles } from '../../actions/roles';
import * as hierarchyActions from '../../shared/actions/hierarchy';
import * as hierarchySelectors from '../../shared/selectors/hierarchy';
import * as hierarchyUitls from '../../shared/utils/hierarchy';

class UserDetailComponent extends Component {
  static propTypes = {
    match: PropTypes.shape().isRequired,
    shouldDisplaySpinner: PropTypes.func.isRequired,
    setActiveDetailUser: PropTypes.func.isRequired,
    user: PropTypes.shape({
      isCompanyAdmin: PropTypes.bool,
    }).isRequired,
    location: PropTypes.shape({
      action: PropTypes.string,
      user: PropTypes.shape({
        id: PropTypes.number,
      }),
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    roles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    fetchRoles: PropTypes.func.isRequired,
    queryParams: PropTypes.shape(),
    currentPage: PropTypes.number,
    hasCompanyHierarchy: PropTypes.bool.isRequired,
    fetchNodes: PropTypes.func.isRequired,
    availableNodes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
      }),
    ).isRequired,
    fetchCurrentNodeConfig: PropTypes.func.isRequired,
    currentNode: PropTypes.shape({
      customConfig: PropTypes.shape({
        usernamePrefix: PropTypes.string,
      }),
    }).isRequired,
    companyId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    currentPage: 0,
    queryParams: {},
  };

  constructor(props) {
    super(props);
    const {
      location: { action, user },
      queryParams,
      currentPage,
    } = this.props;
    queryParams.offset = getOffsetPagination(currentPage);

    this.state = {
      queryParams,
      user: {
        id: action === 'CLONE' ? null : user && user.id,
      },
      sites: [],
      selectedSitesForm: [],
      email: '',
      firstName: '',
      lastName: '',
      errors: {},
      password: '',
      sendWelcomeEmail: false,
      shouldEnableAnalytics: false,
      alertsEnabled: true,
      role: null,
      node: null,
      prefix: '',
      username: '',
      isModalOpen: false,
    };
  }

  async componentDidMount() {
    const {
      fetchRoles: fetchRolesAction,
      hasCompanyHierarchy,
      fetchNodes,
      fetchCurrentNodeConfig,
      companyId,
    } = this.props;

    await this.fetchUser();

    const { email, node } = this.state;
    if (!email) {
      await this.testFetchUser();
    }

    fetchRolesAction();

    if (hasCompanyHierarchy) {
      fetchNodes(UNLIMITED_LIST_LIMIT);
    }

    if (node) {
      await fetchCurrentNodeConfig(node.id);
    }

    const { currentNode } = this.props;
    const prefix = await getUsernamePrefix(hasCompanyHierarchy, currentNode, companyId);
    const username = this.generateUsername(prefix);
    this.setState({ prefix, username });

    try {
      if (!hasCompanyHierarchy) {
        const result = await siteManagerAPI.get(`${API_SITES}?limit=${UNLIMITED_LIST_LIMIT}`);
        this.setState({ sites: result.data.results });
      }
    } catch (error) {
      console.log(error);
    }
  }

  generateUsername = (newPrefix) => {
    const { firstName, lastName, prefix } = this.state;
    let username = '';

    if (firstName !== '' && lastName !== '') {
      username = `${newPrefix || prefix}-${firstName}.${lastName}`.toLowerCase();
    }

    return username;
  };

  handleChange = (event) => {
    this.setState(getFieldValue(event.target));
  };

  handleChangePhoneNumber = (event) => {
    this.setState({ phoneNumber: event || '' });
  };

  handleSelectChange = (selectedOption) => {
    this.setState({ role: selectedOption });
  };

  assignSite = (options) => {
    this.setState({ selectedSitesForm: options });
  };

  fetchUser = async () => {
    const {
      match,
      location,
      shouldDisplaySpinner: shouldDisplaySpinnerAction,
      setActiveDetailUser: setActiveDetailUserAction,
    } = this.props;

    shouldDisplaySpinnerAction(true);
    const { userId } = match.params;
    const response = await siteManagerAPI.get(`${API_USERS}${userId}/`);
    const user = new UserSerializer(response.data);

    this.setState({
      selectedSitesForm: this.mapsToSelect(response.data.sites),
      user: {
        id: location.action === 'CLONE' ? null : user.id,
        username: user.username,
        email: user.email,
        groups: user.groups,
        hasApprovalRule: user.hasApprovalRule,
      },
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      shouldEnableAnalytics: user.shouldEnableAnalytics,
      alertsEnabled: user.alertsEnabled,
      textMessagesEnabled: user.textMessagesEnabled,
      phoneNumber: user.phoneNumber,
      role: user.role ? { value: user.role.id, label: user.role.name } : null,
      node: formatNodeToSelectInput(user.node),
    });
    setActiveDetailUserAction(response.data);
    shouldDisplaySpinnerAction(false);
  };

  handleSiteSelected = (selectedSites) => {
    this.setState({ selectedSitesForm: selectedSites.map((site) => site.id) });
  };

  submitUserForm = async (event) => {
    event.preventDefault();

    const errors = {};
    const {
      firstName,
      lastName,
      email,
      password,
      user,
      node,
      prefix,
      phoneNumber,
      textMessagesEnabled,
    } = this.state;
    const { hasCompanyHierarchy } = this.props;
    const { id, hasApprovalRule } = user;

    const username = this.generateUsername();

    if (!username) {
      errors.username = 'Username field is required';
    } else if (!username.toLowerCase().startsWith(`${prefix.toLowerCase()}-`)) {
      errors.username = `Username prefix should start with "${prefix}-"`;
    }
    if (!firstName) errors.firstName = 'First Name field is required';
    if (!lastName) errors.lastName = 'Last Name field is required';
    if (!password && !user.id) {
      errors.password = 'Password field is required';
    }
    if (!email) {
      errors.email = 'Email field is required';
    } else if (EMAIL_REGEX.exec(email) === null) {
      errors.email = 'Incorrect email format';
    }

    if (hasCompanyHierarchy && !node) {
      errors.node = 'Node field is required';
    }

    if (textMessagesEnabled && !phoneNumber) {
      errors.phoneNumber = 'Phone Number is required when Enable Text Message Approvals is checked';
    } else if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
      errors.phoneNumber = 'Incorrect Phone Number format';
    }

    this.setState({ errors });

    if (JSON.stringify(errors) === JSON.stringify({})) {
      const updateHandler = id && hasApprovalRule ? this.displayModal : this.updateUser;
      updateHandler();
    }
  };

  updateUser = async () => {
    const {
      selectedSitesForm,
      email,
      firstName,
      lastName,
      user,
      password,
      sendWelcomeEmail,
      shouldEnableAnalytics,
      alertsEnabled,
      phoneNumber,
      textMessagesEnabled,
      role,
      node,
    } = this.state;
    const {
      shouldDisplaySpinner: shouldDisplaySpinnerAction,
      location,
      hasCompanyHierarchy,
    } = this.props;

    this.closeModal();

    const username = this.generateUsername();

    const body = {
      username,
      email,
      first_name: firstName,
      last_name: lastName,
      groups: user.groups,
      clone_triggers: true,
      enable_analytics: shouldEnableAnalytics,
      alerts_enabled: alertsEnabled,
      enable_text_messages: textMessagesEnabled,
      phone: phoneNumber,
      role_id: role ? role.value : null,
      ecommerce_username: username,
    };

    if (!hasCompanyHierarchy) {
      body.sites = selectedSitesForm ? selectedSitesForm.map((site) => site.value) : [];
    } else {
      body.node = node && node.id;
    }

    if (this.isCloneAction()) {
      body.password = password;
      body.send_welcome_email = sendWelcomeEmail;
    }

    if (password) {
      body.password = password;
    }

    try {
      await shouldDisplaySpinnerAction(true);
      if (user.id) {
        await siteManagerAPI.patch(`${API_USERS}${user.id}/`, body);
      } else {
        await siteManagerAPI.post(`${API_USER_CLONE.replace('{id}', location.user.id)}`, body);
      }
      await shouldDisplaySpinnerAction(false);
      toast.success('User saved correctly');
    } catch (error) {
      const userError = error.response.data;
      const errors = {};

      if (userError) {
        if (userError.username) {
          [errors.username] = userError.username;
        }

        if (userError.ecommerce_username) {
          [errors.username] = userError.ecommerce_username;
        }

        if (userError.email) {
          [errors.email] = userError.email;
        }

        if (userError.password) {
          [errors.password] = userError.password;
        }

        if (userError.phone) {
          [errors.phoneNumber] = userError.phone;
        }

        if (userError.role) {
          [errors.role] = userError.role;
        }

        if (userError.node) {
          [errors.node] = userError.node;
        }
      }

      this.setState({ errors });
      toast.error('There has been an error while saving the User. Try again later');
      console.error(error);
    }
    shouldDisplaySpinnerAction(false);
  };

  generatePassword = () => {
    const password = randomize('*', 10);
    this.setState({ password });
  };

  handleSwitchInputChange = (name, checked) => {
    this.setState({ [name]: checked });
  };

  isCloneAction = () => {
    const {
      location: { action },
    } = this.props;
    return action === 'CLONE';
  };

  mapsToSelect = (optionsArray) => {
    if (!Array.isArray(optionsArray)) return null;
    let parsedOptions = [];
    parsedOptions = optionsArray.map((option) => ({
      value: option.id,
      label: option.name,
    }));

    return parsedOptions;
  };

  handleNodeChange = async (node) => {
    const { fetchCurrentNodeConfig } = this.props;
    if (node) {
      await fetchCurrentNodeConfig(node.id);
      const { currentNode, hasCompanyHierarchy, companyId } = this.props;
      const prefix = await getUsernamePrefix(hasCompanyHierarchy, currentNode, companyId);
      const username = this.generateUsername(prefix);
      this.setState({ prefix, username });
    }
    this.setState({ node });
  };

  displayModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      errors,
      password,
      shouldEnableAnalytics,
      alertsEnabled,
      phoneNumber,
      textMessagesEnabled,
      sendWelcomeEmail,
      selectedSitesForm,
      sites,
      role,
      queryParams,
      node,
      username,
      isModalOpen,
      user,
    } = this.state;

    const {
      location: { action },
      isLoading,
      roles,
      hasCompanyHierarchy,
      availableNodes,
    } = this.props;

    if (isLoading) {
      return null;
    }

    const actionPrefix = action === 'CLONE' ? 'Clone' : 'Edit';

    const colourStyles = {
      control: (styles) => ({ ...styles, backgroundColor: 'white' }),
      option: (styles, { isDisabled, isSelected }) => ({
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? 'grey' : 'grey'),
          color: 'white',
          fontWeight: '500',
        },
      }),
      multiValue: (styles) => ({
        ...styles,
        backgroundColor: '#104B8C',
        color: 'white',
        fontWeight: '500',
      }),
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {
          backgroundColor: data.color,
          color: 'white',
        },
      }),
    };

    return (
      <Fragment>
        <SectionHeaderComponent subtitle="Users" sectionLabel={`${actionPrefix} User`} />

        <div className="content__container">
          <BreadCrumbComponent>
            <BreadCrumbItem label="Users" link="/users" />
            <BreadCrumbItem label={`${actionPrefix} User`} />
          </BreadCrumbComponent>

          <div className="order-details__return-btn">
            <Link to={{ pathname: '/users', search: objectToSearchString(queryParams) }}>
              <FontAwesomeIcon icon="arrow-left" /> Return to users
            </Link>
          </div>

          <div className="container-fluid">
            <form className="form-input mb-2 clearfix" onSubmit={this.submitUserForm} noValidate>
              <div className="row">
                <div className="form-group col-12 col-lg-5">
                  <TextInput
                    error={errors.firstName}
                    value={firstName}
                    label="First Name"
                    required
                    name="firstName"
                    handleChange={this.handleChange}
                  />

                  <TextInput
                    error={errors.lastName}
                    value={lastName}
                    label="Last Name"
                    required
                    name="lastName"
                    handleChange={this.handleChange}
                  />

                  <TextInput
                    error={errors.email}
                    value={email}
                    label="Email Address"
                    type="email"
                    required
                    name="email"
                    handleChange={this.handleChange}
                  />

                  <PhoneNumberInput
                    error={errors.phoneNumber}
                    value={phoneNumber || ''}
                    label="Phone Number"
                    placeHolder="Phone Number"
                    required={textMessagesEnabled}
                    name="phoneNumber"
                    handleChange={this.handleChangePhoneNumber}
                  />

                  {hasCompanyHierarchy && (
                    <SelectInput
                      label="Node"
                      name="node"
                      value={formatNodeToSelectInput(node)}
                      error={errors.node}
                      options={availableNodes}
                      mapOptions={parseNodeOptions}
                      handleChange={this.handleNodeChange}
                      closeMenuOnSelect
                      isSearchable
                      isClearable
                      required
                    />
                  )}

                  <TextInput
                    readOnly
                    error={errors.username}
                    value={username}
                    label="Username"
                    name="username"
                  />

                  <Fragment>
                    <TextInput
                      error={errors.password}
                      value={password}
                      label="Password"
                      type="password"
                      required={this.isCloneAction()}
                      name="password"
                      handleChange={this.handleChange}
                    />

                    <div className="clearfix">
                      <button
                        type="button"
                        data-placement="right"
                        onClick={() => this.generatePassword()}
                        className="btn d-block btn-primary mb-2 float-right"
                      >
                        <FontAwesomeIcon icon="key" />
                        {' Generate Password'}
                      </button>
                    </div>
                  </Fragment>

                  <SelectInput
                    label="Title"
                    name="role"
                    value={role}
                    error={errors.role}
                    options={roles}
                    mapOptions={parseOptions}
                    handleChange={this.handleSelectChange}
                    placeholder="Select..."
                    isSearchable
                    closeMenuOnSelect
                  />

                  {!hasCompanyHierarchy && (
                    <div className="form-group">
                      <label>Sites</label>
                      <Select
                        value={selectedSitesForm}
                        onChange={this.assignSite}
                        options={this.mapsToSelect(sites)}
                        closeMenuOnSelect={false}
                        styles={colourStyles}
                        isMulti
                        isSearchable
                      />
                    </div>
                  )}

                  {this.isCloneAction() && (
                    <SwitchInput
                      label="Send Welcome Email"
                      name="sendWelcomeEmail"
                      checked={sendWelcomeEmail}
                      handleChange={this.handleSwitchInputChange}
                    />
                  )}

                  <SwitchInput
                    label="Enable Analytics"
                    name="shouldEnableAnalytics"
                    checked={shouldEnableAnalytics}
                    handleChange={this.handleSwitchInputChange}
                  />

                  <SwitchInput
                    label="Enable Alerts"
                    name="alertsEnabled"
                    checked={alertsEnabled}
                    handleChange={this.handleSwitchInputChange}
                  />

                  <SwitchInput
                    label="Enable Text Message Approvals"
                    name="textMessagesEnabled"
                    checked={textMessagesEnabled}
                    handleChange={this.handleSwitchInputChange}
                  />

                  <button type="submit" className="btn btn-primary float-right">
                    SAVE
                  </button>
                </div>
              </div>
            </form>
          </div>

          <ApprovalRuleConfirmationModal
            isOpen={isModalOpen}
            closeHandler={this.closeModal}
            confirmHandler={() => this.updateUser()}
            obj={user}
            objName="user"
            attrName="username"
            action="edit"
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  userDetail: state.users.userDetail ? state.users.userDetail : new UserSerializer(null),
  shouldRedirect: state.users.users.results.length <= 0,
  isLoading: state.globals.shouldDisplaySpinner,
  roles: state.roles.roles.results,
  queryParams: state.users.users.pagination.queryParams,
  currentPage: state.users.users.pagination.currentPage,
  hasCompanyHierarchy: _.get(state, 'auth.user.company.hasCompanyHierarchy', false),
  availableNodes: hierarchySelectors
    .availableNodes(state)
    .map(hierarchyUitls.formatNodeWithGroupName),
  currentNode: hierarchySelectors.currentNode(state),
  companyId: _.get(state, 'auth.user.company.id', null),
});

export default connect(mapStateToProps, {
  setActiveDetailUser,
  shouldDisplaySpinner,
  fetchRoles,
  ...hierarchyActions,
})(withRouter(UserDetailComponent));
