function formatNode([name, id]) {
  return `${name} (ID: ${id})`;
}

function formatNodes(nodes) {
  return {
    count: nodes.length,
    list: nodes.slice(0, 4).map(formatNode),
  };
}

function formatGroup(group) {
  const { name, id, nodes } = group;
  return {
    id,
    name,
    nodes: formatNodes(nodes),
  };
}

export function formatGroups(groups) {
  if (Array.isArray(groups.results)) return groups.results.map(formatGroup);
  return [];
}
