import { shallowEqual, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

export function useSingleSelector(selector) {
  return useSelector(selector, shallowEqual);
}

export function useStructuredSelector(selector) {
  return useSelector(createStructuredSelector(selector), shallowEqual);
}
