function formatSites(sites) {
  return {
    ...sites,
    list: sites.list.map((site) => site.name),
  };
}

function formatPolicy(node) {
  const { name, sites, id } = node;
  return {
    id,
    name,
    sites: formatSites(sites),
  };
}

export function formatPolicies(nodes) {
  return nodes.map(formatPolicy);
}
