import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import TableComponent from '../../shared/table.component';
import TableActions from '../../shared/table-actions.component';

class ApprovalRulesList extends Component {
  static propTypes = {
    rules: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    fetchApprovalRulesWithParams: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      rules: props.rules,
      headers: [
        {
          text: 'Name',
          name: 'name',
          filterType: '',
          isSortable: true,
        },
        {
          text: 'Rule',
          name: 'limit',
          filterType: '',
          isSortable: true,
        },
        {
          text: 'Actions',
          name: 'actions',
          filterType: '',
          isSortable: false,
        },
      ],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      rules: nextProps.rules || [],
    });
  }

  onEdit = (rule) => () => {
    const { handleEdit } = this.props;

    handleEdit(rule);
  };

  onDelete = (rule) => () => {
    const { handleDelete } = this.props;

    handleDelete(rule);
  };

  limitLabel = (limit) => {
    const numberLimit = Number(limit);
    if (numberLimit === 0) {
      return 'Approve every order';
    }
    if (numberLimit === -1) {
      return 'Blanket approver';
    }
    return `Approve orders with total equal to or above $${limit}`;
  };

  renderRuleActions(rule) {
    if (rule.hasPendingApprovals) {
      return (
        <TableActions edit={this.onEdit(rule)}>
          <button
            className="btn table-component__action ml-2"
            data-tip="Pending approvals"
            data-testid="trash-alt"
            type="button"
          >
            <FontAwesomeIcon icon="trash-alt" />
          </button>
        </TableActions>
      );
    }

    return <TableActions edit={this.onEdit(rule)} remove={this.onDelete(rule)} />;
  }

  render() {
    const { rules, headers } = this.state;

    const filteredRules = rules.filter((rule) => !Number.isNaN(parseInt(rule.limit, 10)));

    const parsedRoles = filteredRules.map((rule) => ({
      ...rule,
      name: rule.role ? rule.role.name : rule.user.name,
      limit: this.limitLabel(rule.limit),
      actions: this.renderRuleActions(rule),
    }));

    const { fetchApprovalRulesWithParams } = this.props;

    return (
      <TableComponent
        headers={headers}
        rows={parsedRoles}
        fetchTableData={fetchApprovalRulesWithParams}
        emptyMessage="Company does not have order approval rules"
      />
    );
  }
}

export default ApprovalRulesList;
