import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './themes/goexpedi.scss';
import { connect } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';
import 'jquery/dist/jquery.slim.min';
import 'bootstrap/dist/js/bootstrap.bundle';
import PropTypes from 'prop-types';

import { shouldDisplaySplash, openCloseSideBar, setSidebarState } from './actions/globals.actions';
import { fetchOrders } from './actions/orders.actions';
import { fetchAlerts } from './actions/alerts.actions';
import { fetchSites } from './actions/sites.actions';
import { authUser, fetchCurrentUser, removeCurrentUser } from './actions/auth.actions';
import { fetchUsers } from './actions/users.actions';
import alertManager from './lib/alert-manager';
import NavBarComponent from './components/shared/navbar.component';
import SideBarComponent from './components/shared/sidebar.component';
import SplashComponent from './components/shared/splash-screen.component';
import FooterComponent from './components/shared/footer.component';
import ModalComponent from './components/shared/modal.component';
import Authentication from './components/auth/authentication.component';
import Router from './shared/components/router';

library.add(fas);

class App extends Component {
  static propTypes = {
    isSideBarOpen: PropTypes.bool.isRequired,
    company: PropTypes.object.isRequired,
    fetchCurrentUser: PropTypes.func.isRequired,
    shouldDisplaySplash: PropTypes.func.isRequired,
    removeCurrentUser: PropTypes.func.isRequired,
    user: PropTypes.object,
    alerts: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    user: {},
  };

  componentDidMount() {
    this.fetchConfigPayload();
    window.addEventListener('resize', this.resize);
    window.addEventListener('load', this.resize);
    window.addEventListener('orientationchange', this.resize);
  }

  componentDidUpdate(prevProps) {
    const { user: newUser } = this.props;
    const { user: currentUser } = prevProps;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('load', this.resize);
    window.removeEventListener('orientationchange', this.resize);
  }

  get bodyContentClass() {
    const { isSideBarOpen } = this.props;

    return isSideBarOpen ? 'body-content' : 'body-content closed';
  }

  resize = () => {
    const { setSidebarState: setSidebarStateAction } = this.props;
    setSidebarStateAction(window.innerWidth > 768);
  };

  async fetchConfigPayload() {
    const { company, user, alerts, fetchCurrentUser, shouldDisplaySplash, removeCurrentUser } =
      this.props;
    const { label } = company;

    document.title = `${label} MCC`;
    const token = localStorage.getItem('am-token');
    if (token) {
      const statusCode = await fetchCurrentUser(token);
      if (statusCode === 400 || statusCode === 403 || statusCode === 401) {
        shouldDisplaySplash(false);
        removeCurrentUser();
      }
    } else {
      removeCurrentUser();
      shouldDisplaySplash(false);
      return;
    }
    if (user) {
      shouldDisplaySplash(false);
      alertManager.init(user.token, alerts);
      alertManager.start();
    } else {
      setTimeout(() => this.fetchConfigPayload(), 2000);
    }
  }

  renderLoginOrContent() {
    const { user } = this.props;

    if (user) {
      const { isSideBarOpen } = this.props;
      return (
        <React.Fragment>
          <ModalComponent />
          <ToastContainer autoClose={3000} pauseOnHover={false} />
          <div className={this.bodyContentClass}>
            {!user.isMccAdmin && <SideBarComponent />}
            <div className="main-content">
              <NavBarComponent />
              <div
                className={`content-wrapper ${
                  isSideBarOpen ? '' : 'content-wrapper--sidebar-closed'
                } ${user.isMccAdmin ? 'mcc-admin-container' : ''}`}
              >
                <div className="content">
                  <Router
                    withAdminRoutes={user.isCompanyAdmin}
                    withApprovalsRoutes={user.isCompanyAdmin}
                    withHierarchyRoutes={
                      user.isCompanyAdmin && user.company && user.company.hasCompanyHierarchy
                    }
                    withOrderTrackingRoutes={user.isCompanyAdmin && user.company.hasOrderTracking}
                    withDefaultRoutes={!user.isMccAdmin}
                    withMccAdminRoutes={user.isMccAdmin}
                  />
                  {!user.isMccAdmin && <FooterComponent />}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
    return <Authentication />;
  }

  render() {
    const { company } = this.props;
    return (
      <BrowserRouter>
        <SplashComponent />
        {this.renderLoginOrContent()}
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  config: state.globals.config,
  isSideBarOpen: state.globals.isSideBarOpen,
  alerts: state.alerts.alerts.results,
  company: state.globals.company,
});

export default connect(mapStateToProps, {
  authUser,
  removeCurrentUser,
  fetchCurrentUser,
  fetchOrders,
  fetchAlerts,
  fetchSites,
  fetchUsers,
  shouldDisplaySplash,
  openCloseSideBar,
  setSidebarState,
})(App);
