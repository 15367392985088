import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import PropTypes from 'prop-types';

import PermissionChecker from 'components/shared/permission-checker';
import { openCloseSideBar } from '../../actions/globals.actions';
import SpinnerComponent from './spinner.component';
import Logo from './logo.component';

const SideBarOption = ({ label, icon, isActive, url, onClick }) => (
  <Link
    className={`sidebar__option sidebar__option-link ${
      isActive ? 'background-primary active' : ''
    }`}
    to={url}
    onClick={onClick}
  >
    <FontAwesomeIcon
      className={`sidebar__option-icon sidebar__icon ${
        isActive ? 'sidebar__option-icon--active' : ''
      }`}
      icon={icon}
    />
    <span
      className={`sidebar__option-text sidebar__text medium-text ${
        isActive ? 'sidebar__option-text--active' : ''
      }`}
    >
      {' '}
      {label}
    </span>
  </Link>
);

SideBarOption.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

SideBarOption.defaultProps = {
  onClick: () => {},
};

class SideBarComponent extends Component {
  static propTypes = {
    user: PropTypes.shape({
      nameOrUsername: PropTypes.string,
      title: PropTypes.string,
      fullName: PropTypes.string,
      isCompanyAdmin: PropTypes.bool,
      isApprover: PropTypes.bool,
      role: PropTypes.number,
      company: PropTypes.object,
      isMccAdmin: PropTypes.bool,
    }).isRequired,
    company: PropTypes.shape({
      logoSidebar: PropTypes.string,
      logo: PropTypes.string,
      label: PropTypes.string,
      shortName: PropTypes.string,
      hasOrderApproval: PropTypes.bool,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    isSideBarOpen: PropTypes.bool,
    isAnalyticsSectionEnabled: PropTypes.bool.isRequired,
    openCloseSideBar: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isSideBarOpen: true,
    location: {},
  };

  renderUser() {
    const { user } = this.props;

    if (user) {
      return (
        <div className="sidebar__user">
          <FontAwesomeIcon className="user__icon d-inline-block" icon="user" />
          <div className="d-inline-block sidebar__text">
            <span className="semi-bold d-block">{user.fullName}</span>
            <span className="user__title small-text d-block">{user.role && user.role.name}</span>
          </div>
        </div>
      );
    }
    return null;
  }

  renderDefaultSections = () => {
    const { user, location, isAnalyticsSectionEnabled, openCloseSideBar } = this.props;
    return (
      <React.Fragment>
        <SideBarOption label="Home" icon="home" isActive={location.pathname === '/'} url="/" />
        {user.isCompanyAdmin && user.company && user.company.hasCompanyHierarchy && (
          <SideBarOption
            label="Hierarchy"
            icon="sitemap"
            isActive={location.pathname.startsWith('/hierarchy')}
            url="/hierarchy"
          />
        )}
        <SideBarOption
          label="Sites"
          icon="map-marked-alt"
          isActive={location.pathname.startsWith('/sites')}
          url="/sites"
        />
        <PermissionChecker user={user} permissionName="is-company-admin">
          <SideBarOption
            label="Users"
            icon="users-cog"
            isActive={location.pathname.startsWith('/users')}
            url="/users"
          />
        </PermissionChecker>
        <PermissionChecker user={user} permissionName="has-order-approvals">
          <SideBarOption
            label="Pending Approvals"
            icon="archive"
            isActive={location.pathname === '/pending-approvals'}
            url="/pending-approvals"
          />
        </PermissionChecker>
        <SideBarOption
          label="Orders"
          icon="file"
          isActive={location.pathname.startsWith('/orders')}
          url="/orders"
        />
        <SideBarOption
          label="Alerts"
          icon="exclamation-triangle"
          isActive={location.pathname.startsWith('/alerts')}
          url="/alerts"
        />
        {isAnalyticsSectionEnabled && (
          <SideBarOption
            label="Analytics"
            icon="chart-bar"
            isActive={location.pathname === '/analytics'}
            url="/analytics"
            onClick={openCloseSideBar}
          />
        )}
        <SideBarOption
          label="Documents"
          icon="folder"
          isActive={location.pathname.startsWith('/documents')}
          url="/documents"
        />
        <PermissionChecker user={user} permissionName="is-company-admin">
          <SideBarOption
            label="Configuration"
            icon="cogs"
            isActive={location.pathname.startsWith('/configuration')}
            url="/configuration"
          />
        </PermissionChecker>
      </React.Fragment>
    );
  };

  renderMccAdminSections = () => {
    const { location } = this.props;
    return (
      <React.Fragment>
        <SideBarOption
          label="Companies"
          icon="building"
          isActive={location.pathname.startsWith('/companies') || location.pathname === '/'}
          url="/companies"
        />
      </React.Fragment>
    );
  };

  render() {
    const { company, isSideBarOpen, user } = this.props;
    const { logoSidebar, label } = company;

    return (
      <div className="sidebar-wrapper">
        <div className={`sidebar ${!isSideBarOpen ? 'sidebar--closed' : ''}`}>
          <div className="sidebar__logo-wrapper">
            <Logo src={logoSidebar} alt={label} size="extra-small" />
          </div>
          {this.renderUser()}
          <div className="sidebar__options">
            {user.isMccAdmin ? this.renderMccAdminSections() : this.renderDefaultSections()}
          </div>
          <SpinnerComponent />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.globals.config,
  isSideBarOpen: state.globals.isSideBarOpen,
  user: state.auth.user,
  company: state.globals.company,
  isAnalyticsSectionEnabled: state.auth.user.shouldEnableAnalytics,
});

export default connect(mapStateToProps, {
  openCloseSideBar,
})(withRouter(SideBarComponent));
