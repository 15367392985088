const getConfig = (name) => {
  // eslint-disable-next-line no-underscore-dangle
  const { [name]: value } = window.__RUNTIME_CONFIG__;
  return value;
};

// not defined in ENV on purpose
export const PUBLIC_URL = getConfig('PUBLIC_URL');

export const NODE_ENV = getConfig('NODE_ENV');

export const BE_HOST = getConfig('BE_HOST');
export const THEME_SERVICE_BE_HOST = getConfig('THEME_SERVICE_BE_HOST');
export const THEME_SERVICE_BE_TOKEN = getConfig('THEME_SERVICE_BE_TOKEN');

export default getConfig;
