// App Globals & Configuration
export const GLOBALS_IS_SIDEBAR_OPEN = 'GLOBALS_IS_SIDEBAR_OPEN';
export const GLOBALS_SET_SIDEBAR_STATE = 'GLOBALS_SET_SIDEBAR_STATE';
export const GLOBALS_SHOULD_DISPLAY_SPINNER = 'GLOBALS_SHOULD_DISPLAY_SPINNER';
export const GLOBALS_SHOULD_DISPLAY_SPLASH_SCREEN = 'GLOBALS_SHOULD_DISPLAY_SPLASH_SCREEN';
export const GLOBALS_SET_MODAL_DATA = 'GLOBALS_SET_MODAL_DATA';
export const GLOBALS_CURRENT_COMPANY = 'GLOBALS_CURRENT_COMPANY';
export const GLOBALS_SET_EXCEL_HEADERS = 'GLOBALS_SET_EXCEL_HEADERS';
export const GLOBALS_RESET = 'GLOBALS_RESET';
export const GLOBALS_SET_IS_TABLE_LOADING = 'GLOBALS_SET_IS_TABLE_LOADING';
export const GLOBALS_SET_THEME_DATA = 'GLOBALS_SET_THEME_DATA';

// Auth
export const AUTH_CURRENT_USER = 'AUTH_CURRENT_USER';
export const AUTH_REMOVE_USER = 'AUTH_REMOVE_USER';
export const AUTH_UPDATE_COMPANY_BILLING_ADDRESS = 'AUTH_UPDATE_COMPANY_BILLING_ADDRESS';

// Alerts
export const ALERTS_FETCH_ALERTS = 'ALERTS_FETCH_ALERTS';
export const ALERTS_FETCH_ALERTS_TRIGGERS = 'ALERTS_FETCH_ALERTS_TRIGGERS';

// Orders
export const ORDERS_FETCH_ORDERS_FOR_USER = 'ORDERS_FETCH_ORDERS_FOR_USER';
export const ORDERS_FETCH_ORDERS_FOR_SITE = 'ORDERS_FETCH_ORDERS_FOR_SITE';
export const ORDERS_APPROVALS_STATUS = 'ORDERS_APPROVALS_STATUS ';
export const ORDERS_REMOVE_LINE = 'ORDERS_REMOVE_LINE';

// Sites
export const SITES_FETCH_SITES_FOR_USER = 'SITES_FETCH_SITES_FOR_USER';
export const SITES_SET_DETAIL_SITE = 'SITES_SET_DETAIL_SITE';
export const SITES_FETCH_WAREHOUSE_LIST = 'SITES_FETCH_WAREHOUSE_LIST';
export const SITES_RESET_FETCH_SITES = 'SITES_RESET_FETCH_SITES';
export const SITES_FETCH_GROUPS_FOR_SITE = 'SITES_FETCH_GROUPS_FOR_SITE';

// Users
export const USERS_FETCH_USERS_FOR_COMPANY = 'USERS_FETCH_USERS_FOR_COMPANY';
export const USERS_SET_USER_FOR_USER_DETAIL_PAGE = 'USERS_SET_USER_FOR_USER_DETAIL_PAGE';
export const USERS_RESET_FETCH_USERS = 'USERS_RESET_FETCH_USERS';

export const ROLES_FETCH_COMPANY_ROLES = 'ROLES_FETCH_COMPANY_ROLES';
export const ROLES_FETCH_ROLE = 'ROLES_FETCH_ROLE';
export const ROLES_SET_ROLE_FORM_ERRORS = 'ROLES_SET_ROLE_FORM_ERRORS';

export const SITE_TYPES_FETCH_COMPANY_SITE_TYPES = 'SITE_TYPES_FETCH_COMPANY_SITE_TYPES';
export const SITE_TYPES_FETCH_SITE_TYPE = 'SITE_TYPES_FETCH_SITE_TYPE';
export const SITE_TYPES_SET_SITE_TYPE_FORM_ERRORS = 'SITE_TYPES_SET_SITE_TYPE_FORM_ERRORS';

const HIERARCHY_PREFIX = 'HIERARCHY/';
export const HIERARCHY_FETCH_GROUPS = 'HIERARCHY_FETCH_GROUPS';
export const HIERARCHY_SAVE_NODES = `${HIERARCHY_PREFIX}SAVE_NODES`;
export const HIERARCHY_SAVE_TABLE_NODES = `${HIERARCHY_PREFIX}SAVE_TABLE_NODES`;
export const HIERARCHY_SAVE_TABLE_POLICIES = `${HIERARCHY_PREFIX}SAVE_TABLE_POLICIES`;
export const HIERARCHY_SAVE_GROUPS = `${HIERARCHY_PREFIX}SAVE_GROUPS`;
export const HIERARCHY_SAVE_USERS = `${HIERARCHY_PREFIX}SAVE_USERS`;
export const HIERARCHY_SAVE_SITES = `${HIERARCHY_PREFIX}SAVE_SITES`;
export const HIERARCHY_SAVE_CURRENT_NODE = `${HIERARCHY_PREFIX}SAVE_CURRENT_NODE`;
export const HIERARCHY_UPDATE_CURRENT_NODE = `${HIERARCHY_PREFIX}UPDATE_CURRENT_NODE`;
export const HIERARCHY_SAVE_CURRENT_GROUP = `${HIERARCHY_PREFIX}SAVE_CURRENT_GROUP`;
export const HIERARCHY_FETCH_TREE = `${HIERARCHY_PREFIX}FETCH_TREE`;

const APPROVAL_RULES_PREFIX = 'APPROVAL_RULES/';
export const APPROVAL_RULES_SAVE_LIST = `${APPROVAL_RULES_PREFIX}SAVE_LIST`;
export const APPROVAL_RULES_SAVE_CURRENT = `${APPROVAL_RULES_PREFIX}SAVE_CURRENT`;
export const APPROVAL_RULES_SAVE_ERRORS = `${APPROVAL_RULES_PREFIX}SAVE_ERRORS`;

export const DOCUMENTS_FETCH = 'DOCUMENTS_FETCH';
export const ORDER_DOCUMENTS_FETCH = 'ORDER_DOCUMENTS_FETCH';
export const DOCUMENTS_PRELOADED = 'DOCUMENTS_PRELOADED';

export const ADMIN_COMPANIES_FETCH = 'ADMIN_COMPANIES_FETCH';
export const ADMIN_SET_CURRENT_COMPANY = 'ADMIN_SET_CURRENT_COMPANY';
