import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import DocumentRepositoryTable from 'scenes/document-repository/components/table';
import {
  API_ORDER_DOCUMENTS_SUGGESTIONS,
  API_USER_DOCUMENTS_SUGGESTIONS,
  API_ORDER_DOCUMENTS,
  API_USER_DOCUMENTS,
} from 'lib/api-endpoints';

import { fetchDocuments as fetchDocumentsAction } from '../../actions/documents.actions';
import { fetchOrderDocuments as fetchOrderDocumentsAction } from '../../actions/order-documents.actions';
import messages from './messages';
import { useStructuredSelector } from '../../shared/hooks/useSelector';
import * as selectors from './components/table/selectors';
import * as orderSelectors from './components/table/order-documents-selectors';

function DocumentRepository({ fetchDocuments }) {
  const { documents, paginationState, queryParams } = useStructuredSelector(selectors);
  return (
    <DocumentRepositoryTable
      documents={documents}
      paginationState={paginationState}
      documentsQueryParams={queryParams}
      suggestionsURL={API_USER_DOCUMENTS_SUGGESTIONS}
      title={messages.sceneTitle}
      subtitle={messages.sceneSubtitle}
      baseUrl={API_USER_DOCUMENTS}
      fetchDocuments={fetchDocuments}
      useRowsDataFirst
    />
  );
}

export default connect(null, {
  fetchDocuments: fetchDocumentsAction,
})(DocumentRepository);

function OrderDocumentsRepositoryBase({ fetchDocuments }) {
  const { documents, paginationState, queryParams } = useStructuredSelector(orderSelectors);
  const { orderId } = useParams();
  const baseUrl = API_ORDER_DOCUMENTS.replace('{id}', orderId);
  const suggestionsURL = API_ORDER_DOCUMENTS_SUGGESTIONS.replace('{id}', orderId);

  return (
    <DocumentRepositoryTable
      documents={documents}
      paginationState={paginationState}
      documentsQueryParams={queryParams}
      fetchDocuments={fetchDocuments}
      suggestionsURL={suggestionsURL}
      title={messages.sceneTitle}
      subtitle={messages.sceneSubtitle}
      baseUrl={baseUrl}
    />
  );
}

export const OrderDocumentsRepository = connect(null, {
  fetchDocuments: fetchOrderDocumentsAction,
})(OrderDocumentsRepositoryBase);
