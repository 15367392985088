import React, { useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';

import { useSingleSelector } from 'shared/hooks/useSelector';
import useActions from 'shared/hooks/useActions';
import AdminNavigation from 'shared/components/admin-navigation';
import AdminPageWrapper from 'shared/components/admin-page-wrapper';
import * as mccAdminSelectors from 'shared/selectors/mcc-admin';
import * as mccAdminActions from 'shared/actions/mcc-admin';

import CompanyConfigurationForm from '../company-configuration-form';
import messages from './messages';
import * as actions from './actions';

function AdminCreateCompanyConfiguration() {
  const { companyId } = useParams();
  const currentCompany = useSingleSelector(mccAdminSelectors.currentCompany);
  const setCurrentCompany = useActions(mccAdminActions.setCurrentCompany);
  const history = useHistory();

  useEffect(() => {
    if (Number(companyId) === Number(currentCompany.id)) return;
    mccAdminActions.fetchCompanyById(companyId).then(setCurrentCompany);
  }, []);

  const handleOnSubmit = async (data) => {
    const configurationData = { ...data, company: companyId };
    try {
      const response = await actions.createCompanyConfiguration(configurationData);
      if (response.errors) {
        return response;
      }
      history.push(`edit`);
      return null;
    } catch (error) {
      return error;
    }
  };

  const location = useMemo(
    () =>
      _.compact([
        AdminNavigation.locations.companies,
        currentCompany.id && { url: `${currentCompany.id}`, label: currentCompany.name },
        { label: messages.navigationSectionLabel },
      ]),
    [currentCompany],
  );

  return (
    <AdminPageWrapper title={messages.title} subtitle={messages.subtitle} location={location}>
      <CompanyConfigurationForm
        onSubmit={handleOnSubmit}
        submitButtonText={messages.submitButtonText}
        clearOnSubmit
      />
    </AdminPageWrapper>
  );
}

export default AdminCreateCompanyConfiguration;
