import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { TABLE_FIELD_NAMES } from '../../constants/values';
import siteManagerAPI from '../../lib/api-manager';
import { shouldDisplaySpinner } from '../../actions/globals.actions';
import { fetchTriggers } from '../../actions/alerts.actions';
import { API_ALERT_TRIGGERS, API_ALERT_TRIGGERS_SUGGESTIONS } from '../../lib/api-endpoints';
import TableFunctions from '../shared/table-functions';
import { debounce } from '../../utils/general';

const weeekdays = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

const ruleOptions = [
  { displayName: 'CREATION', value: 'CREATION' },
  { displayName: 'WEEKDAY', value: 'WEEKDAY' },
  { displayName: 'COUNT', value: 'COUNT' },
];

const frequencyOptions = [
  { displayName: 'INSTANTLY', value: 'INSTANTLY' },
  { displayName: 'DAILY', value: 'DAILY' },
  { displayName: 'WEEKLY', value: 'WEEKLY' },
];

const statusOptions = [
  { displayName: 'Enabled', value: 'true' },
  { displayName: 'Disabled', value: 'false' },
];

class AlertTriggersListComponent extends Component {
  static propTypes = {
    shouldDisplaySpinner: PropTypes.func.isRequired,
    fetchTriggers: PropTypes.func.isRequired,
    triggers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        rule: PropTypes.string,
        enabled: PropTypes.bool,
        frequency: PropTypes.string,
      }),
    ).isRequired,
    triggersQueryParams: PropTypes.object,
    user: PropTypes.shape({
      company: PropTypes.shape({
        hasTriggerRuleWeekdayDisabled: PropTypes.bool,
      }).isRequired,
    }).isRequired,
  };

  static defaultProps = {
    triggersQueryParams: {},
  };

  constructor(props) {
    super(props);
    this.handleFilterBy = debounce(this.handleFilterBy, 400);
    this.state = {
      triggers: props.triggers,
      headers: [
        {
          text: TABLE_FIELD_NAMES.RULE,
          name: 'rule',
          filterType: 'SELECT',
          isActivated: false,
        },
        {
          text: TABLE_FIELD_NAMES.FREQUENCY,
          name: 'frequency',
          filterType: 'SELECT',
          isActivated: false,
        },
        {
          text: TABLE_FIELD_NAMES.VALUE,
          name: 'value',
          filterType: '',
          isActivated: false,
        },
        {
          text: TABLE_FIELD_NAMES.USER,
          name: 'username',
          filterType: 'INPUT',
          isActivated: false,
        },
        {
          text: TABLE_FIELD_NAMES.SITE,
          name: 'site',
          filterType: 'INPUT',
          isActivated: false,
          defaultAscendent: true,
        },
        {
          text: TABLE_FIELD_NAMES.STATUS,
          name: 'status',
          filterType: 'SELECT',
          isActivated: false,
        },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    const { triggers } = this.props;
    if (triggers !== prevProps.triggers) {
      this.setState({ triggers });
    }
  }

  async updateTrigger(id, trigger) {
    const { shouldDisplaySpinner: shouldDisplaySpinnerAction } = this.props;

    shouldDisplaySpinner(true);
    const url = `${API_ALERT_TRIGGERS}${id}/`;
    const enabled = !trigger.enabled;
    const body = {
      id,
      enabled,
    };

    try {
      await siteManagerAPI.put(url, body);
      await shouldDisplaySpinnerAction(false);

      const { triggers } = this.state;
      const updatedTriggers = triggers.map((currentTrigger) => {
        if (currentTrigger.id === id) {
          return { ...currentTrigger, enabled };
        }
        return currentTrigger;
      });
      this.setState({ triggers: updatedTriggers });

      toast.success('Rule updated correctly');
    } catch (error) {
      toast.error('There has been an error while updating the Rule. Try again later');
    }

    $('#shared-modal').modal('hide');
  }

  filterRuleOptions = () => {
    const { user } = this.props;

    if (user.company.hasTriggerRuleWeekdayDisabled) {
      return ruleOptions.filter((ruleOption) => {
        return ruleOption.value !== 'WEEKDAY';
      });
    }

    return ruleOptions;
  }

  renderValue(alertTrigger) {
    if (alertTrigger.rule === 'WEEKDAY') {
      return `${weeekdays[alertTrigger.value]} to ${weeekdays[alertTrigger.rangeValue]}`;
    }
    return alertTrigger.value;
  }

  renderStatusCheckBox(alertTrigger) {
    return (
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id={`enable_${alertTrigger.id}`}
          checked={alertTrigger.enabled}
          onChange={() => this.updateTrigger(alertTrigger.id, alertTrigger)}
        />
        <label className="custom-control-label" htmlFor={`enable_${alertTrigger.id}`}>
          {alertTrigger.enabled ? 'Enabled' : 'Disabled'}
        </label>
      </div>
    );
  }

  render() {
    const { triggersQueryParams, fetchTriggers } = this.props;
    const { triggers, headers } = this.state;
    const triggersParsed = triggers.map((trigger) => ({
      id: trigger.id,
      rule: trigger.rule,
      frequency: trigger.frequency,
      value: this.renderValue(trigger),
      username: trigger.user.username,
      site: trigger.site.name,
      status: { customCell: true, cell: trigger },
    }));

    const selectOptions = {
      rule: this.filterRuleOptions(),
      frequency: frequencyOptions,
      status: statusOptions,
    };

    return (
      <TableFunctions
        headers={headers}
        rowsData={triggersParsed}
        suggestionsURL={API_ALERT_TRIGGERS_SUGGESTIONS}
        dataURL={API_ALERT_TRIGGERS}
        fetchData={(dataURL, queryParams) => fetchTriggers(dataURL, queryParams)}
        tableQueryParams={triggersQueryParams}
        selectOptions={selectOptions}
        renderCustomCell={(cell) => this.renderStatusCheckBox(cell)}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  triggers: state.alerts.triggers.results,
  triggersQueryParams: state.alerts.triggers.pagination.queryParams || {},
});

export default connect(mapStateToProps, {
  shouldDisplaySpinner,
  fetchTriggers,
})(AlertTriggersListComponent);
