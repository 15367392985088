
/* This object was created in order to fill labels that are different than the theme id */
export default {
    patenergy: {
      shortName: 'Patterson',
    },
    ['-chevron']: {
      label: 'GoChevron',
      shortName: 'Chevron',
    },
    xtoenergy: {
      shortName: 'Xto',
    },
    hdtf: {
      label: 'GoHalliburton',
    },
    ['-cop']: {
      label: 'GoConocoPhillips',
      shortName: 'Cop',
    },
    ['3cmidstream']: {
      label: 'GoThirdCoastMidstream',
      shortName: 'thirdCoastMidstream',
    }
}