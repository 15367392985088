import { toast } from 'react-toastify';

import siteManagerAPI from 'lib/api-manager';
import { API_ADMIN_COMPANY_CONFIGURATION } from 'lib/api-endpoints';
import { formatErrorResponse, objectToSnakeCase } from 'shared/utils/formatting';

import messages from './messages';

export async function editCompanyConfiguration(id, data) {
  try {
    const formattedData = objectToSnakeCase(data);
    const url = API_ADMIN_COMPANY_CONFIGURATION.replace('{id}', id);
    await siteManagerAPI.patch(url, formattedData);
    toast.success(messages.editCompanyConfigurationSuccess);
    return null;
  } catch (error) {
    toast.error(messages.editCompanyConfigurationError);
    return formatErrorResponse(error.response.data);
  }
}
