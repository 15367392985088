import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { setModalData, shouldDisplaySpinner } from '../../actions/globals.actions';
import { formatFixNumber } from '../../utils/format';
import { NOT_APPLICABLE } from '../../constants/values';
import { removeLineFromOrder } from '../../actions/orders.actions';
import PermissionChecker from '../shared/permission-checker';
import RemoveOrderLineModal from './remove-order-line-modal.component';
import FeeLine from './fee-line.component';
import { ORDER_REMOVE_LINE_ERROR } from '../../constants/error-messages';
import NonCatalogIndicator from './non-catalog-indicator.component';

const getId = (() => {
  let currentId = -1;
  return () => {
    currentId += 1;
    return currentId;
  };
})();

class OrderComponent extends Component {
  static propTypes = {
    order: PropTypes.shape({
      identifier: PropTypes.string,
      order: PropTypes.string,
      lines: PropTypes.arrayOf(
        PropTypes.shape({
          manufacturer: PropTypes.string,
          manufacturerSku: PropTypes.string,
          quantity: PropTypes.number,
          unitPrice: PropTypes.string,
          lineTotal: PropTypes.string,
        }),
      ),
    }).isRequired,
    subTotal: PropTypes.number.isRequired,
    tax: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    removeLineFromOrder: PropTypes.func.isRequired,
    user: PropTypes.shape({
      nameOrUsername: PropTypes.string,
      title: PropTypes.string,
      isCompanyAdmin: PropTypes.bool,
      isApprover: PropTypes.bool,
    }).isRequired,
    handleReload: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isModalSuccess: false,
      modalOrderId: null,
      modalProductId: null,
      modalError: null,
    };
  }

  removeProductFromOrder = async () => {
    const { removeLineFromOrder: action } = this.props;
    const { modalOrderId: orderId, modalProductId: productId } = this.state;

    if (await action(orderId, productId)) {
      this.setState({
        isModalOpen: true,
        isModalSuccess: true,
        modalOrderId: null,
        modalProductId: null,
      });
    } else {
      this.setState({
        isModalOpen: true,
        isModalSuccess: false,
        modalError: ORDER_REMOVE_LINE_ERROR,
      });
    }
  };

  showRemoveProductFromOrderModal = (modalOrderId, modalProductId) => {
    this.setState({
      modalOrderId,
      modalProductId,
      isModalOpen: true,
      isModalSuccess: false,
      modalError: null,
    });
  };

  hideRemoveProductFromOrderModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  renderOrderLink() {
    const { order } = this.props;
    const orderId = order.order;

    if (orderId !== NOT_APPLICABLE) {
      return (
        <Link
          className="text-primary tiny-text"
          to={{ pathname: `/orders/${orderId}`, state: { order } }}
        >
          <FontAwesomeIcon icon="chevron-circle-right" />
          {` Visit Order ${orderId}`}
        </Link>
      );
    }
    return null;
  }

  render() {
    const { order, subTotal, tax, total, user, handleReload } = this.props;
    const { isModalOpen, isModalSuccess, modalError } = this.state;
    const products = order.lines.filter((p) => p.shouldDisplay);
    const fees = order.lines.filter((p) => !p.shouldDisplay);

    return (
      <Fragment>
        <div className="col-12 order-details small-text">
          <table className="table order-details__lines">
            <thead>
              <tr className="text-primary">
                <th className="medium-bold" scope="col" />
                <th className="medium-bold" scope="col">
                  Part
                </th>
                <th className="medium-bold" scope="col">
                  SKU / Manufacturer SKU
                </th>
                <th className="medium-bold" scope="col">
                  Quantity
                </th>
                <th className="medium-bold" scope="col">
                  Unit Cost
                </th>
                <th className="medium-bold" scope="col">
                  Price
                </th>
                {products.length > 1 && (
                  <PermissionChecker user={user} permissionName="has-order-approvals">
                    <th className="medium-bold" scope="col">
                      Remove
                    </th>
                  </PermissionChecker>
                )}
              </tr>
            </thead>

            <tbody>
              {products.map((product) => (
                <tr key={getId()}>
                  <td>
                    <div
                      style={product.imgUrl ? { backgroundImage: `url('${product.imgUrl}')` } : {}}
                      className="order-details__line-img"
                    />
                  </td>
                  <td className="text-base">
                    <b>{product.text}</b>
                    {` - by ${product.manufacturer}`}
                  </td>
                  <td className="text-base">
                    <div className="order-details__sku-catalog-info">
                      {product.sku || NOT_APPLICABLE}
                      {` / ${product.manufacturerSku || NOT_APPLICABLE}`}
                      {product.isCatalogPlus && <NonCatalogIndicator />}
                    </div>
                  </td>
                  <td className="text-base">x{product.quantity}</td>
                  <td className="text-base">
                    <b>${product.unitPrice}</b>
                  </td>
                  <td className="text-base">
                    <b>${product.lineTotal}</b>
                  </td>

                  {products.length > 1 && (
                    <PermissionChecker user={user} permissionName="has-order-approvals">
                      <td className="text-base remove-line-td">
                        <button
                          type="button"
                          className="btn btn-primary rounded-circle remove-btn"
                          onClick={() =>
                            this.showRemoveProductFromOrderModal(order.identifier, product.sku)
                          }
                        >
                          <FontAwesomeIcon icon="trash-alt" />
                        </button>
                      </td>
                    </PermissionChecker>
                  )}
                </tr>
              ))}
              {fees.map((fee) => (
                <FeeLine fee={fee} key={getId()} />
              ))}
            </tbody>
          </table>

          <div className="order-details__footer medium-text">
            <span className="text-primary">
              Subtotal:
              <b> ${formatFixNumber(subTotal)}</b>
            </span>
            <span className="text-primary">
              Tax:
              <b> ${formatFixNumber(tax)}</b>
            </span>
            <span className="text-primary">
              Total:
              <b> ${formatFixNumber(total)}</b>
            </span>
          </div>
        </div>

        <PermissionChecker user={user} permissionName="has-order-approvals">
          <RemoveOrderLineModal
            isOpen={isModalOpen}
            isSuccess={isModalSuccess}
            submitHandler={() => this.removeProductFromOrder()}
            closeModal={() => this.hideRemoveProductFromOrderModal()}
            errorMessage={modalError}
            handleReload={handleReload}
          />
        </PermissionChecker>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  setModalData,
  shouldDisplaySpinner,
  removeLineFromOrder,
})(OrderComponent);
