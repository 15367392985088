import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { PropTypes } from 'prop-types';
import _ from 'lodash';

import { fetchOrders } from '../../actions/orders.actions';
import { fetchOrganizationGroups } from '../../actions/hierarchy.actions';
import { shouldDisplaySpinner } from '../../actions/globals.actions';
import { API_ORDERS } from '../../lib/api-endpoints';
import { UNLIMITED_LIST_LIMIT } from '../../constants/values';
import { exportToExcel } from '../../utils/general';
import { GENERIC_TRY_LATER } from '../../constants/error-messages';
import { EXPORTING, NOTHING_TO_EXPORT } from '../../constants/messages';
import siteManagerAPI from '../../lib/api-manager';
import ExportExcelComponent from '../shared/export-excel.component';
import SectionHeaderComponent from '../shared/section-header.component';
import OrderListComponent from './order-list.component';
import PaginationComponent from '../shared/pagination.component';
import SearchComponent from '../shared/search.component';
import BreadCrumbComponent from '../shared/breadcumb.component';
import BreadCrumbItem from '../shared/breadcrumb-item';
import OrderSerializer from '../../lib/serializers/OrderSerializer';

class OrdersComponent extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    orders: PropTypes.arrayOf(PropTypes.object).isRequired,
    param: PropTypes.shape(),
    shouldDisplaySpinner: PropTypes.func.isRequired,
    hasOrganizationGroups: PropTypes.bool,
    fetchOrders: PropTypes.func.isRequired,
    fetchOrganizationGroups: PropTypes.func.isRequired,
    ordersQueryParams: PropTypes.shape({ limit: PropTypes.string }),
    hasCompanyHierarchy: PropTypes.bool.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    user: {},
    param: {},
    hasOrganizationGroups: false,
    ordersQueryParams: {},
  };

  componentDidMount = () => {
    const { fetchOrganizationGroups, hasOrganizationGroups, hasCompanyHierarchy } = this.props;
    if (!hasOrganizationGroups && hasCompanyHierarchy) {
      fetchOrganizationGroups();
    }
  };

  componentDidUpdate = async (prevProps) => {
    const { param } = this.props;

    if (prevProps.param !== param) {
      this.loadOrders();
    }
  };

  handleSearch = async (searchStr) => {
    const { shouldDisplaySpinner, fetchOrders } = this.props;

    shouldDisplaySpinner(true);

    try {
      await fetchOrders(`${API_ORDERS}?search=${searchStr}`);
    } catch (error) {
      toast.error(GENERIC_TRY_LATER);
    } finally {
      shouldDisplaySpinner(false);
    }
  };

  loadOrders = async () => {
    const { user, shouldDisplaySpinner, fetchOrders } = this.props;

    if (user) {
      await shouldDisplaySpinner(true);
      await fetchOrders(API_ORDERS);

      shouldDisplaySpinner(false);
    } else {
      console.warn(`Retrying ${API_ORDERS} in 1500ms`);
      setTimeout(() => this.loadOrders(), 1500);
    }
  };

  exportFilteredOrders = async () => {
    const { ordersQueryParams, shouldDisplaySpinner } = this.props;
    ordersQueryParams.limit = UNLIMITED_LIST_LIMIT;

    toast.success(EXPORTING);
    shouldDisplaySpinner(true);

    try {
      const params = { ...ordersQueryParams, untrimmed: true };
      const response = await siteManagerAPI.get(`${API_ORDERS}`, { params });

      if (response.data.results.length === 0) {
        toast.warn(NOTHING_TO_EXPORT);
        return;
      }

      const parsedOrders = response.data.results.map((order) => {
        const orderSerialized = new OrderSerializer(order);
        const parsedTrackingNo = orderSerialized.trackingNumbers.join(', ');

        return {
          site: orderSerialized.site,
          location_name: orderSerialized.siteLocationName,
          business_unit: orderSerialized.siteBU,
          region: orderSerialized.siteRegion,
          ordered_by: orderSerialized.ordered_by,
          requested_by: orderSerialized.requestedBy,
          order: orderSerialized.identifier,
          po: orderSerialized.po,
          afe: orderSerialized.afe,
          tracking_numbers: parsedTrackingNo,
          status: orderSerialized.status,
          lines: orderSerialized.lineLength,
          total: orderSerialized.total,
          date: orderSerialized.orderDate,
        };
      });

      exportToExcel(parsedOrders, 'orders', { total: true });
    } catch (error) {
      toast.error(GENERIC_TRY_LATER);
    }

    shouldDisplaySpinner(false);
  };

  render() {
    const { orders, history } = this.props;

    return (
      <Fragment>
        <SectionHeaderComponent subtitle="Company" sectionLabel="Orders" />

        <div className="content__container">
          <BreadCrumbComponent>
            <BreadCrumbItem label="Orders" />
          </BreadCrumbComponent>

          <div className="container-fluid">
            <div className="table-tools">
              <div className="table-tools__search">
                <SearchComponent handleSearch={this.handleSearch} />
              </div>
              <div className="table-tools__btns">
                <ExportExcelComponent exportData={this.exportFilteredOrders} />
              </div>
            </div>
            <PaginationComponent />
            <OrderListComponent orders={orders} history={history} />
            <PaginationComponent />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  permissions: state.auth.permissions,
  orders: state.orders.orders.results,
  ordersQueryParams: state.orders.orders.pagination.queryParams || {},
  hasOrganizationGroups: state.hierarchy && state.hierarchy.groups.length > 0,
  hasCompanyHierarchy: _.get(state, 'auth.user.company.hasCompanyHierarchy', false),
});

export default connect(mapStateToProps, {
  fetchOrders,
  fetchOrganizationGroups,
  shouldDisplaySpinner,
})(OrdersComponent);
