import { ALERTS_FETCH_ALERTS, ALERTS_FETCH_ALERTS_TRIGGERS } from '../lib/types';
import AlertsSerializer from '../lib/serializers/AlertsSerializer';
import AlertsTriggerSerializer from '../lib/serializers/AlertsTriggerSerializer';

const initialState = {
  alerts: new AlertsSerializer(null),
  triggers: new AlertsTriggerSerializer(null),
};

const alertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALERTS_FETCH_ALERTS:
      return {
        ...state,
        alerts: action.payload,
      };
    case ALERTS_FETCH_ALERTS_TRIGGERS:
      return {
        ...state,
        triggers: action.payload,
      };
    default:
      return state;
  }
};

export default alertsReducer;
