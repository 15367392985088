import React from 'react';
import PropTypes from 'prop-types';

function FileInput({
  required,
  label,
  changeHandler,
  labelClassName,
  inputClassName,
  fileExtensions,
}) {
  return (
    <div className="form-field form-group">
      <label
        className={`medium-text ${labelClassName} ${required ? 'form-field__label--required' : ''}`}
        htmlFor="input-upload"
      >
        {label}
      </label>

      <input
        onChange={changeHandler}
        name="input-file"
        id="input-file"
        accept={fileExtensions}
        type="file"
        className={`form-control medium-text ${inputClassName}`}
      />
    </div>
  );
}

FileInput.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  changeHandler: PropTypes.func.isRequired,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  fileExtensions: PropTypes.string,
};

FileInput.defaultProps = {
  required: false,
  labelClassName: '',
  inputClassName: '',
  fileExtensions: '.csv',
};

export default FileInput;
