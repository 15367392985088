import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { objectToSnakeCase, removeFalsyValues } from 'shared/utils/formatting';
import TextInput from 'components/forms/text-input.component';
import { isProdEnvironment, getUrlDomain } from 'utils/general';

import messages from './messages';

const ECOMMERCE_URL = isProdEnvironment() ? 'www.{domain}.com' : 'test.{domain}.com';
const WEBSITE_URL = isProdEnvironment() ? 'manager.{domain}.com' : 'test-sm.{domain}.com';
const MCC_HOST_URL = isProdEnvironment() ? 'manager-api.{domain}.com' : 'test-sm-api.{domain}.com';

function CompanyForm(props) {
  const history = useHistory();
  const {
    onSubmit,
    clearOnSubmit,
    submitButtonText,
    formInitialData,
    createCompanyConfigurationButtonText,
    hasCompanyConfiguration,
  } = props;
  const [companyId] = useState(formInitialData.id || '');
  const [name, setName] = useState(formInitialData.name || '');
  const [domain, setDomain] = useState(getUrlDomain(formInitialData.website));
  const [slugName, setSlugName] = useState(formInitialData.slugName || '');
  const [errors, setErrors] = useState({});

  const handleChange = useCallback(
    (handler) => (event) => {
      handler(event.target.value);
    },
    [],
  );

  const resetFields = useCallback(() => {
    setName('');
    setDomain('');
    setSlugName('');
    setErrors({});
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      let errorResponse = {};
      if (name) {
        const data = {
          id: companyId,
          name,
          website: WEBSITE_URL.replace('{domain}', domain),
          mccHost: MCC_HOST_URL.replace('{domain}', domain),
          ecommerceUrl: ECOMMERCE_URL.replace('{domain}', domain),
          slugName,
        };
        const parsedData = objectToSnakeCase(removeFalsyValues(data));
        errorResponse = await onSubmit(parsedData);
      } else {
        errorResponse.errors = { name: messages.nameMissingError };
      }

      if (errorResponse) {
        setErrors(errorResponse.errors);
      } else if (clearOnSubmit) {
        resetFields();
      }
    },
    [name, domain, slugName, clearOnSubmit, resetFields],
  );

  const goToCompanyConfiguration = async () => {
    if (hasCompanyConfiguration) {
      history.push(`${companyId}/configuration/edit`);
      return;
    }
    history.push(`${companyId}/configuration/create`);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        handleChange={handleChange(setName)}
        value={name}
        label={messages.nameInputLabel}
        error={errors.name}
        name="name"
        required
      />
      <TextInput
        handleChange={handleChange(setDomain)}
        value={domain}
        error={errors.domain}
        label={messages.domainInputLabel}
        name="domain"
      />
      <TextInput
        handleChange={handleChange(setSlugName)}
        value={slugName}
        error={errors.slugName}
        label={messages.slugNameInputLabel}
        name="slugName"
      />
      <button
        type="button"
        onClick={goToCompanyConfiguration}
        className="btn btn-link table-tools__btn"
      >
        {createCompanyConfigurationButtonText}
      </button>
      <button type="submit" className="btn btn-primary mt-2">
        {submitButtonText}
      </button>
    </form>
  );
}

CompanyForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  clearOnSubmit: PropTypes.bool.isRequired,
  submitButtonText: PropTypes.string,
  createCompanyConfigurationButtonText: PropTypes.string.isRequired,
  hasCompanyConfiguration: PropTypes.bool.isRequired,
  formInitialData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    domain: PropTypes.string,
    website: PropTypes.string,
    slugName: PropTypes.string,
  }),
};

CompanyForm.defaultProps = {
  submitButtonText: 'Send',
  formInitialData: {},
};

export default CompanyForm;
