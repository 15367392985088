import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import SelectInput from '../../../components/forms/select-input';
import useValidation from '../../hooks/useValidation';

import * as actions from './actions';
import * as utils from './utils';
import messages from './messages';
import * as validations from './validations';

function mapOptionsToSelectInput(options, format) {
  return !Array.isArray(options) ? null : options.map(format);
}

function PoliciesForm(props) {
  const { clearOnSubmit, currentUserId, onSubmit, submitButtonText, isUpdate } = props;
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState(null);
  const [sites, setSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState(null);
  const [errors, setErrors] = useState({});
  const validate = useValidation(
    isUpdate ? validations.updateValidations : validations.createValidations,
  );

  useEffect(() => {
    actions.fetchSites().then(setSites);
    if (!currentUserId) actions.fetchUsersWithoutPolicies().then(setUsers);
  }, [currentUserId]);

  useEffect(() => {
    if (currentUserId)
      actions.fetchUserPolicyData(currentUserId).then((data) => {
        setSelectedSites(data.sites);
        setUsers([data.user]);
        setSelectedUser(utils.formatToSelectInput(data.user));
      });
  }, [currentUserId]);

  useEffect(() => {
    if (!sites.length) return; // prevents infinite loop

    actions
      .fetchTree()
      .then((tree) => utils.setNodesAndSitesParents(setNodes, setSites, sites, tree));
  }, [sites]);

  const resetFields = useCallback(() => {
    setSites([]);
    setSelectedSites([]);
    setUsers([]);
    setSelectedUser([]);
    setNodes([]);
    setSelectedNodes([]);
  }, []);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const sitesToSubmit = selectedSites || [];
      const validationErrors = validate({ user: selectedUser, sites: sitesToSubmit });

      if (validationErrors) {
        setErrors(validationErrors);
      } else {
        const data = utils.createApiRequestBody(sitesToSubmit);
        onSubmit(selectedUser.id, data);
        if (clearOnSubmit) resetFields();
      }
    },
    [selectedUser, selectedSites, onSubmit, clearOnSubmit, resetFields],
  );

  const handleSelectInputChange = useCallback((hanlder) => (value) => hanlder(value), []);

  return (
    <form onSubmit={handleSubmit}>
      <SelectInput
        label={messages.userInputLabel}
        name="user"
        value={selectedUser}
        options={users}
        error={errors.user}
        mapOptions={(options) => mapOptionsToSelectInput(options, utils.formatToSelectInput)}
        handleChange={handleSelectInputChange(setSelectedUser)}
        isDisabled={Boolean(currentUserId)}
        closeMenuOnSelect
        isSearchable
        isClearable
        required
      />
      <SelectInput
        label={messages.nodesInputLabel}
        name="nodes"
        value={selectedNodes}
        options={nodes}
        error={errors.nodes}
        mapOptions={(options) => mapOptionsToSelectInput(options, utils.formatNodeToSelectInput)}
        handleChange={handleSelectInputChange(setSelectedNodes)}
        isSearchable
        isMulti
      />
      <SelectInput
        label={messages.sitesInputLabel}
        name="sites"
        value={selectedSites}
        options={utils.filterSites(selectedNodes, sites)}
        error={errors.sites}
        mapOptions={(options) => mapOptionsToSelectInput(options, utils.formatToSelectInput)}
        handleChange={handleSelectInputChange(setSelectedSites)}
        isSearchable
        isMulti
        required={!isUpdate}
      />
      <button type="submit" className="btn btn-primary mt-2">
        {submitButtonText}
      </button>
    </form>
  );
}

PoliciesForm.propTypes = {
  clearOnSubmit: PropTypes.bool,
  currentUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  submitButtonText: PropTypes.string,
  onSubmit: PropTypes.func,
  isUpdate: PropTypes.bool,
};

PoliciesForm.defaultProps = {
  clearOnSubmit: true,
  currentUserId: '',
  onSubmit: () => {},
  submitButtonText: messages.createButtonText,
  isUpdate: false,
};

export default PoliciesForm;
