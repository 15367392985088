import { createSelector } from 'reselect';

import * as utils from './utils';

function hierarchyStateSelector(state) {
  return state.hierarchy;
}

const nodesStateSelector = createSelector(hierarchyStateSelector, (state) => state.nodes);

export const availableGroups = createSelector(hierarchyStateSelector, (state) => state.groups);

export const availableSites = createSelector(hierarchyStateSelector, (state) => state.sites);

export const availableNodes = createSelector(nodesStateSelector, utils.flattenNodes);

export const availableUsers = createSelector(hierarchyStateSelector, (state) =>
  state.users.map(utils.formatUserName),
);
