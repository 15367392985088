import BillingAddressSerializer from './BillingAddressSerializer';

export default class CompanySerializer {
  constructor(data) {
    const companyData = data || {};

    this.id = companyData.id;
    this.name = companyData.name;
    this.slug = companyData.slug_name;
    this.telephone = companyData.telephone;
    this.website = companyData.website;
    this.ecommerceUrl = companyData.ecommerce_url;
    this.hasOrderTracking = companyData.has_order_tracking;
    this.hasOrderApproval = companyData.has_order_approval;
    this.hasCompanyHierarchy = companyData.has_company_hierarchy;
    this.hasTriggerRuleWeekdayDisabled = companyData.has_trigger_rule_weekday_disabled;
    this.hasLocationNameRequired = companyData.has_location_name_required;
    if (companyData.default_billing_address) {
      this.defaultBillingAddress = new BillingAddressSerializer(
        companyData.default_billing_address,
      );
    } else {
      this.defaultBillingAddress = null;
    }
  }
}
