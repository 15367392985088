import { ORDERS_FETCH_ORDERS_FOR_USER } from '../lib/types';
import OrdersSerializer from '../lib/serializers/OrdersSerializers';

const initialState = {
  orders: new OrdersSerializer(null),
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDERS_FETCH_ORDERS_FOR_USER:
      return {
        ...state,
        orders: action.payload,
      };
    default:
      return state;
  }
};

export default ordersReducer;
