import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Spinner from '../shared/spinner';

const RemoveOrderLineModal = ({
  size,
  submitHandler,
  closeModal,
  isOpen,
  isSuccess,
  errorMessage,
  handleReload,
}) => {
  const [showSpinner, setShowSpinner] = useState(false);

  const handleSubmit = (e) => {
    setShowSpinner(true);
    submitHandler(e);
  };

  const handleClose = (e) => {
    setShowSpinner(false);
    closeModal(e);
    if (isSuccess) {
      handleReload();
    }
  };

  const renderBodyDanger = () => {
    return (
      <div className="remove-modal remove-modal-danger">
        <h3>Attention!</h3>
        <span>
          Are you sure you want to remove this item? Removing an item from an order cannot be
          undone.
        </span>
        {showSpinner ? (
          <Spinner />
        ) : (
          <div>
            <button type="button" onClick={handleSubmit} className="btn btn-round btn-danger">
              Yes, remove this item
            </button>
            <button type="button" onClick={handleClose} className="btn btn-link-custom">
              No, close this alert and return to the order
            </button>
          </div>
        )}
      </div>
    );
  };

  const renderBodySuccess = () => {
    return (
      <div className="remove-modal">
        <h3>Success!</h3>
        <span>Your item has been removed from the order.</span>
        <div>
          <button type="button" onClick={handleClose} className="btn btn-round btn-primary">
            Close window
          </button>
        </div>
      </div>
    );
  };

  const renderBodyError = () => {
    return (
      <div className="remove-modal remove-modal-danger">
        <h3>Error!</h3>
        <span>{errorMessage}</span>
        <div>
          <button type="button" onClick={handleClose} className="btn btn-round btn-primary">
            Close window
          </button>
        </div>
      </div>
    );
  };

  let bodyRenderer = renderBodyDanger;

  if (errorMessage) {
    bodyRenderer = renderBodyError;
  } else if (isSuccess) {
    bodyRenderer = renderBodySuccess;
  }

  return (
    <Modal show={isOpen} size={size} onHide={handleClose} className="remove-order-line-modal">
      <Modal.Body>{bodyRenderer()}</Modal.Body>
    </Modal>
  );
};

RemoveOrderLineModal.propTypes = {
  size: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
  isSuccess: PropTypes.bool,
  errorMessage: PropTypes.string,
  handleReload: PropTypes.func.isRequired,
};

RemoveOrderLineModal.defaultProps = {
  size: 'modal-xs',
  isSuccess: false,
  errorMessage: '',
};

export default RemoveOrderLineModal;
