import CompanySerializer from './CompanySerializer';
import PaginationSerializer from '../PaginationSerializer';

export default class CompaniesSerializer {
  constructor(data) {
    const companiesData = data || {};

    this.results = Array.isArray(companiesData.results)
      ? companiesData.results.map((company) => new CompanySerializer(company))
      : [];
    this.pagination = new PaginationSerializer(companiesData);
  }
}
