import axios from 'axios';

import { BE_HOST } from '../utils/runtime-config';

export default axios.create({
  baseURL:
  window.location.hostname === 'localhost'
    ? BE_HOST
    : `${window.location.protocol}//${window.location.hostname.replace(
        'manager.',
        'manager-api.',
      )}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
