import PaginationSerializer from '../../../../lib/serializers/PaginationSerializer';

import messages from './messages';

export function formatNodesListResponse(data) {
  return {
    nodes: data.results,
    pagination: new PaginationSerializer(data),
  };
}

function formatUsername(user) {
  return `${user.first_name || ''} ${user.last_name || ''}`.trim();
}

function formatUsers(users) {
  return {
    list: users.slice(0, 3).map(formatUsername),
    count: users.length,
  };
}

function formatSites(sites) {
  return {
    list: sites.slice(0, 3).map((site) => site.name),
    count: sites.length,
  };
}

function formatNode(node) {
  const { name, sites, users, id, organization_group: group, parent } = node;
  return {
    id,
    name: `${name} (ID: ${id})`,
    sites: formatSites(sites),
    users: formatUsers(users),
    type: (group && group.name) || messages.none,
    parent: (parent && `${parent.name} (ID: ${parent.id})`) || messages.none,
  };
}

export function formatNodes(nodes) {
  return nodes.map(formatNode);
}
