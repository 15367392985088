import React from 'react';
import PropTypes from 'prop-types';

const SectionHeader = ({ sectionLabel, subtitle, action }) => (
  <div className="header">
    <div className="header__message">
      <p className="header__subtitle">
        {subtitle}
        <span> {action}</span>
      </p>
    </div>
    <h2 className="header__title bold">{sectionLabel}</h2>
  </div>
);

SectionHeader.propTypes = {
  action: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  sectionLabel: PropTypes.string.isRequired,
};

SectionHeader.defaultProps = {
  action: '',
};

export default SectionHeader;
