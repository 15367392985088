import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { Redirect } from 'react-router-dom';
import BreadCrumbComponent from '../../shared/breadcumb.component';
import BreadCrumbItem from '../../shared/breadcrumb-item';
import SectionHeader from '../../shared/section-header.component';
import ConfirmationModal from '../../shared/confirmation-modal.component';
import RoleForm from './role-form';
import RolesList from './roles-list';
import { setModalData } from '../../../actions/globals.actions';
import { fetchRoles, createRole, deleteRole, resetErrors } from '../../../actions/roles';

class RolesConfiguration extends Component {
  static propTypes = {
    roles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    fetchRoles: PropTypes.func.isRequired,
    createRole: PropTypes.func.isRequired,
    deleteRole: PropTypes.func.isRequired,
    setModalData: PropTypes.func.isRequired,
    errors: PropTypes.shape().isRequired,
    resetErrors: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      redirectUrl: '',
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const { fetchRoles: fetchRolesAction } = this.props;

    fetchRolesAction();
  }

  componentWillReceiveProps(nextProps) {
    const { isModalOpen } = this.state;

    if (Object.entries(nextProps.errors).length === 0 && isModalOpen) {
      this.setState({ isModalOpen: false });
    }
  }

  componentWillUnmount() {
    const { resetErrors: resetErrorsAction } = this.props;
    resetErrorsAction();
  }

  displayCreationModal = () => {
    const { setModalData: setModalDataAction } = this.props;

    setModalDataAction(
      {
        size: 'modal-xs',
        title: 'Create New Role',
        jsx: null,
        component: RoleForm,
        componentProps: {
          handleFormSubmit: this.handleCreateSubmit,
        },
        buttons: null,
      },
      this.handleModalClose,
      null,
    );

    this.setState({ isModalOpen: true });
  };

  displayDeleteConfirmationModal = (role) => {
    const { setModalData: setModalDataAction, deleteRole: deleteRoleAction } = this.props;

    const message = (
      <div>
        Are you sure you want to delete role <span className="bold">{role.name}</span>? This will
        remove any related <span className="bold">Order Approval Rule</span>
      </div>
    );

    setModalDataAction({
      size: 'modal-xs',
      title: 'Delete Role',
      jsx: null,
      component: ConfirmationModal,
      buttons: null,
      componentProps: {
        onSubmit: () => deleteRoleAction(role.id),
        message,
      },
    });
    this.setState({ isModalOpen: true });
  };

  handleEdit = (role) => {
    const redirectUrl = `/configuration/roles/${role.id}`;
    this.setState({ redirectUrl });
  };

  handleCreateSubmit = (role) => {
    const { createRole: createRoleAction } = this.props;

    createRoleAction(role);
  };

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
  };

  fetchRolesWithParams = (queryParams) => {
    const { fetchRoles: fetchRolesAction } = this.props;

    fetchRolesAction(queryParams);
  };

  render() {
    const { redirectUrl, isModalOpen } = this.state;

    if (redirectUrl) {
      return <Redirect to={redirectUrl} />;
    }

    const { roles } = this.props;

    $('#shared-modal').modal(isModalOpen ? 'show' : 'hide');

    return (
      <Fragment>
        <SectionHeader subtitle="Configuration" sectionLabel="Roles" />

        <div className="content__container">
          <BreadCrumbComponent>
            <BreadCrumbItem label="Configuration" link="/configuration" />
            <BreadCrumbItem label="Roles" />
          </BreadCrumbComponent>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-2">
                <button
                  type="button"
                  onClick={this.displayCreationModal}
                  className="btn btn-primary"
                >
                  <FontAwesomeIcon icon="address-card" /> Create Role
                </button>
              </div>
            </div>
          </div>

          <hr />

          <RolesList
            roles={roles}
            handleEdit={this.handleEdit}
            handleDelete={this.displayDeleteConfirmationModal}
            fetchRolesWithParams={this.fetchRolesWithParams}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.roles.roles.results,
  errors: state.roles.errors,
});

export default connect(mapStateToProps, {
  setModalData,
  fetchRoles,
  createRole,
  deleteRole,
  resetErrors,
})(RolesConfiguration);
