import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { API_ADMIN_COMPANIES } from 'lib/api-endpoints';
import TableComponent from 'components/shared/table.component';
import Pagination from 'shared/components/pagination';
import { useStructuredSelector } from 'shared/hooks/useSelector';
import Spinner from 'components/shared/spinner';
import TableActions from 'components/shared/table-actions.component';

import messages from './messages';
import * as selectors from './selectors';

const headers = [
  {
    text: messages.companyName,
    name: 'name',
    isSortable: true,
  },
  {
    text: messages.actions,
    name: 'actions',
    isSortable: false,
    class: 'text-right',
  },
];

function AdminCompaniesTable(props) {
  const { fetchCompanies } = props;
  const [showSpinner, setShowSpinner] = useState(true);
  const { companies, paginationState } = useStructuredSelector(selectors);
  const history = useHistory();
  const isLoading = useSelector((state) => state.globals.isTableLoading);

  useEffect(() => {
    const fetchInitialData = async () => {
      setShowSpinner(true);
      await fetchCompanies();
      setShowSpinner(false);
    };
    fetchInitialData();
  }, []);

  const parsedCompanies = companies.map((company) => {
    const { id } = company;
    return {
      ...company,
      actions: [
        <TableActions
          edit={() => history.push(`/companies/${id}`)}
          importNodes={() => history.push(`/companies/${id}/nodes/upload`)}
          importSites={() => history.push(`/companies/${id}/sites/upload`)}
        />,
      ],
    };
  });

  return (
    <React.Fragment>
      {showSpinner ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Pagination state={paginationState} fetchResourceCB={fetchCompanies} />
          <TableComponent
            headers={headers}
            rows={parsedCompanies}
            fetchTableData={(queryParams) => fetchCompanies(API_ADMIN_COMPANIES, queryParams)}
            isTableLoading={isLoading}
          />
          <Pagination state={paginationState} fetchResourceCB={fetchCompanies} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

AdminCompaniesTable.propTypes = {
  fetchCompanies: PropTypes.func.isRequired,
};

export default AdminCompaniesTable;
