import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';

import { removeCurrentUser } from '../../actions/auth.actions';
import { resetState } from '../../actions/globals.actions';

class LogOutButton extends Component {
  static propTypes = {
    removeCurrentUser: PropTypes.func.isRequired,
    resetState: PropTypes.func.isRequired,
  };

  logout() {
    const { removeCurrentUser, resetState } = this.props;
    removeCurrentUser();
    resetState();
    localStorage.setItem('loggedOut', true);
  }

  render() {
    return (
      <Fragment>
        <button type="button" className="btn btn-logout" onClick={() => this.logout()}>
          <FontAwesomeIcon className="btn-logout__icon" icon="sign-out-alt" />
          {/* eslint-disable */}
          {' '}
          Log Out
          {/* eslint-enable */}
        </button>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  removeCurrentUser,
  resetState,
})(LogOutButton);
