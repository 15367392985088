const EXPORTING = 'Exporting file';
const NOTHING_TO_EXPORT = 'No data to export';
const SITE_SUCCESSFULLY_SAVED = 'Site successfully saved';
const ORDER_APPROVAL_RULE_SUCCESSFULLY_CREATED = 'Order Approval Rule created correctly';
const ORDER_APPROVAL_RULE_SUCCESSFULLY_UPDATED = 'Order Approval Rule updated correctly';
const ORDER_APPROVAL_RULE_SUCCESSFULLY_DELETED = 'Order Approval Rule deleted correctly';
const ROLE_SUCCESSFULLY_UPDATED = 'Role updated correctly';
const ROLE_SUCCESSFULLY_DELETED = 'Role deleted correctly';
const SITE_TYPE_SUCCESSFULLY_UPDATED = 'Site type updated correctly';
const SITE_TYPE_SUCCESSFULLY_DELETED = 'Site type deleted correctly';
const NODE_SUCCESSFULLY_CREATED = 'Organization node created correctly';
const NODE_SUCCESSFULLY_UPDATED = 'Organization node updated correctly';
const NODE_CONFIGURATION_SUCCESSFULLY_CREATED = 'Node configuration created correctly';
const NODE_CONFIGURATION_SUCCESSFULLY_DELETED = 'Node configuration deleted correctly';
const NODE_CONFIGURATION_SUCCESSFULLY_UPDATED = 'Node configuration updated correctly';
const POLICY_SUCCESSFULLY_CREATED = 'Policy created correctly';
const POLICY_SUCCESSFULLY_UPDATED = 'Policy updated correctly';
const GROUP_SUCCESSFULLY_CREATED = 'Group created correctly';
const GROUP_SUCCESSFULLY_UPDATED = 'Group updated correctly';
const ADMIN_COMPANY_SUCCESSFULLY_UPDATED = 'Company was updated';
const ADMIN_COMPANY_IMPORT_NODES_CREATED_SUCCESSFULLY = 'Nodes created successfully';
const ADMIN_COMPANY_IMPORT_SITES_SUCCESSFULLY = 'Sites created successfully';

export {
  EXPORTING,
  NOTHING_TO_EXPORT,
  SITE_SUCCESSFULLY_SAVED,
  ORDER_APPROVAL_RULE_SUCCESSFULLY_CREATED,
  ORDER_APPROVAL_RULE_SUCCESSFULLY_UPDATED,
  ORDER_APPROVAL_RULE_SUCCESSFULLY_DELETED,
  ROLE_SUCCESSFULLY_UPDATED,
  ROLE_SUCCESSFULLY_DELETED,
  SITE_TYPE_SUCCESSFULLY_UPDATED,
  SITE_TYPE_SUCCESSFULLY_DELETED,
  NODE_SUCCESSFULLY_CREATED,
  NODE_SUCCESSFULLY_UPDATED,
  POLICY_SUCCESSFULLY_CREATED,
  POLICY_SUCCESSFULLY_UPDATED,
  GROUP_SUCCESSFULLY_CREATED,
  GROUP_SUCCESSFULLY_UPDATED,
  NODE_CONFIGURATION_SUCCESSFULLY_CREATED,
  NODE_CONFIGURATION_SUCCESSFULLY_UPDATED,
  NODE_CONFIGURATION_SUCCESSFULLY_DELETED,
  ADMIN_COMPANY_SUCCESSFULLY_UPDATED,
  ADMIN_COMPANY_IMPORT_NODES_CREATED_SUCCESSFULLY,
  ADMIN_COMPANY_IMPORT_SITES_SUCCESSFULLY,
};
