import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TABLE_FIELD_NAMES } from '../../constants/values';
import { fetchAlerts } from '../../actions/alerts.actions';
import { API_ALERTS, API_ALERTS_SUGGESTIONS } from '../../lib/api-endpoints';
import { debounce } from '../../utils/general';
import TableFunctions from '../shared/table-functions';

class AlertsListComponent extends Component {
  static propTypes = {
    fetchAlerts: PropTypes.func.isRequired,
    alerts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    alertsQueryParams: PropTypes.shape(),
  };

  static defaultProps = {
    alertsQueryParams: {},
  };

  constructor(props) {
    super(props);
    this.handleFilterBy = debounce(this.handleFilterBy, 400);
    this.state = {
      alerts: props.alerts,
      headers: [
        {
          text: TABLE_FIELD_NAMES.SITE,
          name: 'site',
          filterType: 'INPUT',
          isActivated: false,
        },
        {
          text: TABLE_FIELD_NAMES.ORDER,
          name: 'order',
          filterType: 'INPUT',
          isActivated: false,
        },
        {
          text: TABLE_FIELD_NAMES.USER,
          name: 'user',
          filterType: 'INPUT',
          isActivated: false,
        },
        {
          text: TABLE_FIELD_NAMES.ALERT_EVENTS_FEED,
          name: 'content',
          filterType: 'INPUT',
          isActivated: false,
        },
        {
          text: TABLE_FIELD_NAMES.CREATED,
          name: 'created',
          filterType: '',
          isActivated: false,
          defaultDescendent: true,
        },
      ],
    };
  }

  componentWillReceiveProps(nextProps) {
    const { alerts } = nextProps;
    this.setState({ alerts });
  }

  render() {
    const { alerts, headers } = this.state;
    const { fetchAlerts, alertsQueryParams } = this.props;
    const parsedAlerts = alerts.map((alert) => {
      return {
        id: alert.id,
        site: alert.site,
        ...alert.siteGroups,
        order: {
          isLink: true,
          text: alert.order.identifier,
          pathName: `/orders/${alert.order.identifier}`,
        },
        user: alert.user,
        content: alert.content,
        created: alert.created,
      };
    });

    return (
      <TableFunctions
        headers={headers}
        rowsData={parsedAlerts}
        suggestionsURL={API_ALERTS_SUGGESTIONS}
        dataURL={API_ALERTS}
        fetchData={(dataURL, queryParams) => fetchAlerts(dataURL, queryParams)}
        selectOptions={{}}
        tableQueryParams={alertsQueryParams}
      />
    );
  }
}

const mapsStateToProps = (state) => ({
  alertsQueryParams: state.alerts.alerts.pagination.queryParams,
});

export default connect(mapsStateToProps, {
  fetchAlerts,
})(AlertsListComponent);
