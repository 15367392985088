export default class BillingAddressSerializer {
  constructor(data) {
    const billingAddresData = data || {};

    this.id = billingAddresData.id;
    this.name = billingAddresData.name;
    this.nameExtension = billingAddresData.name_ext;
    this.phone = billingAddresData.phone;
    this.email = billingAddresData.email;
    this.street = billingAddresData.street;
    this.street2 = billingAddresData.street2;
    this.postalCode = billingAddresData.postal_code;
    this.city = billingAddresData.city;
    this.region = billingAddresData.region;
    this.regionCode = billingAddresData.region_code;
    this.country = billingAddresData.country;
  }
}
