const formatFixNumber = (numberToFormat) => {
  let number = numberToFormat;

  if (typeof number === 'number') {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(number);
  }
  number = Number(number);
  return Number.isNaN(number)
    ? ''
    : new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(number);
};

const formatCapitalizeFirstLetter = (stringToCapitalize) => {
  if (typeof stringToCapitalize === 'string') {
    if (stringToCapitalize.includes('-')) {
      const themeName = stringToCapitalize.split('-')[1];
      return themeName.charAt(0).toUpperCase() + themeName.substring(1);
    }

    return stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.substring(1);
  }

  return stringToCapitalize;
};

const parseNodeOptions = (optionsArray) => {
  if (!Array.isArray(optionsArray)) return null;
  return optionsArray.map((option) => ({
    ...option,
    value: option.id,
    label: formatNodeToLabel(option),
  }));
};

const formatNodeToSelectInput = (node) => {
  if (!node) return null;
  return { ...node, value: node.id, label: formatNodeToLabel(node) };
};

const formatNodeToLabel = (node) => {
  if (!node) return '';
  return `${node.name} (ID: ${node.id})`;
};

const parseOptions = (optionsArray) => {
  if (!Array.isArray(optionsArray)) return null;
  return optionsArray.map((option) => ({
    ...option,
    value: option.id,
    label: option.name,
  }));
};

const sortDescendingText = (a, b) => {
  if (a > b) {
    return 1;
  }
  if (b > a) {
    return -1;
  }
  return 0;
};

export {
  formatCapitalizeFirstLetter,
  formatFixNumber,
  formatNodeToSelectInput,
  formatNodeToLabel,
  parseNodeOptions,
  parseOptions,
  sortDescendingText,
};
