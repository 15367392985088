import UserSerializer from './UserSerializer';
import PaginationSerializer from './PaginationSerializer';

export default class UsersSerializer {
  constructor(data) {
    const usersData = data || {};

    this.results = Array.isArray(usersData.results)
      ? usersData.results.map((user) => new UserSerializer(user))
      : [];
    this.pagination = new PaginationSerializer(usersData);
  }
}
