import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

function CounterTile({ redirect, title, iconName, subTitle, count, warning, warningTip }) {
  return (
    <Link className="counter-tile" to={redirect}>
      <div className="justify-content-center">
        <div className="counter-tile__icon">
          <FontAwesomeIcon icon={iconName} />
          {warning && (
            <Fragment>
              <div className="counter-tile--warning" data-tip={warningTip}>
                <FontAwesomeIcon className="text-danger" icon="exclamation-circle" />
              </div>
              <ReactTooltip effect="solid" />
            </Fragment>
          )}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="m-auto">
          <div className="d-inline-block">
            <h1 className="counter-tile__total bold">{count}</h1>
          </div>
          <div className="d-inline-block">
            <p className="counter-tile__title bold text-primary">{title}</p>
            <p className="counter-tile__subtitle medium-bold">{subTitle}</p>
          </div>
        </div>
      </div>
    </Link>
  );
}

CounterTile.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  redirect: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  warning: PropTypes.bool,
  warningTip: PropTypes.string,
};

CounterTile.defaultProps = {
  warning: false,
  warningTip: '',
};

export default CounterTile;
