import get from 'lodash/get';

import { createSelector } from 'reselect';

function adminCompaniesSelector(state) {
  return get(state, 'mccAdmin.companies');
}

export const paginationState = createSelector(adminCompaniesSelector, (state) =>
  get(state, 'table.pagination', {}),
);

export const companies = createSelector(adminCompaniesSelector, (state) =>
  get(state, 'table.results', []),
);
