import React, { useEffect, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';

import useActions from 'shared/hooks/useActions';
import SectionHeaderComponent from 'components/shared/section-header.component';
import BreadCrumbComponent from 'components/shared/breadcumb.component';
import BreadCrumbItem from 'components/shared/breadcrumb-item';
import Spinner from 'components/shared/spinner';
import siteManagerAPI from 'lib/api-manager';

import { API_ADMIN_COMPANY_CONFIGURATION } from 'lib/api-endpoints';
import CompanyImportForm from '../company-import-form';
import messages from './messages';
import * as actions from './actions';

function AdminCreateCompanyNodes() {
  const { createImportNodesCompany, fetchCompany } = useActions(actions);
  const { companyId } = useParams();
  const [hasCompanyHierarchy, setHasCompanyHierarchy] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const handleOnSubmit = useCallback(({ file }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('company', companyId);

    createImportNodesCompany(companyId, formData);
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      setShowSpinner(true);
      await fetchCompany(companyId);
      try {
        const { data } = await siteManagerAPI.get(
          API_ADMIN_COMPANY_CONFIGURATION.replace('{id}', companyId),
        );
        setHasCompanyHierarchy(data && data.has_company_hierarchy);
      } finally {
        setShowSpinner(false);
      }
    };
    fetchInitialData();
  }, []);

  return (
    <React.Fragment>
      <SectionHeaderComponent subtitle={messages.subtitle} sectionLabel={messages.title} />
      <div className="content__container">
        <BreadCrumbComponent>
          <BreadCrumbItem label={messages.breadcrumbCompaniesLabel} link="/companies" />
          <BreadCrumbItem label={messages.breadcrumbUpdateCompanyLabel} />
        </BreadCrumbComponent>
        <div className="order-details__return-btn">
          <Link to={{ pathname: '/companies' }}>
            <FontAwesomeIcon icon="arrow-left" /> {messages.backButtonText}
          </Link>
        </div>
        <div className="edit-node__container">
          {showSpinner ? (
            <Spinner />
          ) : (
            <CompanyImportForm
              onSubmit={handleOnSubmit}
              submitButtonText={messages.submitButtonText}
              companyHierarchy={hasCompanyHierarchy}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default AdminCreateCompanyNodes;
