import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import FileUpload from 'components/forms/file-upload.component';

function CompanyImportForm({ onSubmit, submitButtonText, companyHierarchy }) {
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState();

  const changeHandler = useCallback((event) => {
    setSelectedFile(event.target.files[0]);
    setErrors({});
  }, []);

  const resetFields = useCallback(() => {
    setErrors({});
    setSelectedFile();
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      let errorResponse = {};

      if (!selectedFile) {
        setErrors({ file: 'You need to select a file' });
        return;
      }

      errorResponse = await onSubmit({ file: selectedFile });

      if (errorResponse) {
        setErrors(errorResponse.errors);
      } else {
        resetFields();
      }
    },
    [selectedFile],
  );

  return (
    <React.Fragment>
      {companyHierarchy ? (
        <form onSubmit={handleSubmit}>
          <FileUpload changeHandler={changeHandler} label="Select a file" />
          <button type="submit" className="btn btn-primary mt-2">
            {submitButtonText}
          </button>
          {errors && errors.file ? <p className="form-field__error">{errors.file}</p> : ''}
        </form>
      ) : (
        <p>This company does not have hierarchy.</p>
      )}
    </React.Fragment>
  );
}

CompanyImportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  companyHierarchy: PropTypes.bool.isRequired,
};

CompanyImportForm.defaultProps = {
  submitButtonText: 'Send',
};

export default CompanyImportForm;
