import { toast } from 'react-toastify';

import {
  SITES_FETCH_SITES_FOR_USER,
  SITES_FETCH_GROUPS_FOR_SITE,
  SITES_SET_DETAIL_SITE,
  SITES_RESET_FETCH_SITES,
  GLOBALS_SET_IS_TABLE_LOADING,
} from 'lib/types';
import { API_SITE_GROUPS } from 'lib/api-endpoints';
import { FETCH_REGULAR_SITES_ERROR } from 'constants/error-messages';
import siteManagerAPI from 'lib/api-manager';
import SitesSerializer from 'lib/serializers/SitesSerializer';

export const fetchSites = (url, queryParams) => async (dispatch) => {
  let response = {};
  dispatch({
    type: GLOBALS_SET_IS_TABLE_LOADING,
    payload: true,
  });

  try {
    response = await siteManagerAPI.get(url, {
      params: queryParams,
    });
    response.data.queryParams = queryParams;
  } catch (error) {
    toast.error(FETCH_REGULAR_SITES_ERROR);
  }
  const sites = new SitesSerializer(response.data);
  sites.results.forEach((site, index) => dispatch(fetchSiteGroups(site, index)));

  dispatch({
    type: SITES_FETCH_SITES_FOR_USER,
    payload: sites,
  });
  dispatch({
    type: GLOBALS_SET_IS_TABLE_LOADING,
    payload: false,
  });
};

export const resetSites = () => (dispatch) => {
  dispatch({ type: SITES_RESET_FETCH_SITES });
};

export const setActiveDetailSite = (siteName) => (dispatch) => {
  dispatch({
    type: SITES_SET_DETAIL_SITE,
    payload: siteName,
  });
};

const fetchSiteGroups = (site, index) => async (dispatch) => {
  const url = API_SITE_GROUPS.replace('{id}', site.id);
  try {
    const response = await siteManagerAPI.get(url);
    dispatch({
      type: SITES_FETCH_GROUPS_FOR_SITE,
      payload: { groups: response.data, index },
    });
  } catch (error) {
    console.log(error);
  }
};
