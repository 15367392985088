import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ApprovalRuleConfirmationModal from 'components/shared/approval-rule-confirmation-modal.component';
import BrandModal from 'components/shared/brand-modal.component';

import { fetchUsers } from '../../actions/users.actions';
import { API_USER_SITES, API_USERS, API_USERS_SUGGESTIONS } from '../../lib/api-endpoints';
import { setModalData, shouldDisplaySpinner } from '../../actions/globals.actions';
import { DELETE_USER_TRY_LATER } from '../../constants/error-messages';
import siteManagerAPI from '../../lib/api-manager';
import TrimmedUnorderedList from '../shared/trimmed-unordered-list.component';
import TableActions from '../shared/table-actions.component';
import TableFunctions from '../shared/table-functions';
import { fetchRoles } from '../../actions/roles';
import { getOffsetPagination } from '../../utils/general';
import { TABLE_FIELD_NAMES } from '../../constants/values';
import { sortDescendingText } from '../../utils/format';

class UserListComponent extends Component {
  static propTypes = {
    userList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    fetchUsers: PropTypes.func.isRequired,
    shouldDisplaySpinner: PropTypes.func.isRequired,
    usersQueryParams: PropTypes.shape(),
    currentPage: PropTypes.number,
    roles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    fetchRoles: PropTypes.func.isRequired,
    hasCompanyHierarchy: PropTypes.bool.isRequired,
    filterDates: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
  };

  static defaultProps = {
    currentPage: 0,
    usersQueryParams: {},
    filterDates: {
      start: 'lastLogin_after',
      end: 'lastLogin_before',
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      action: null,
      userId: null,
      isModalOpen: false,
      modalUser: null,
      modalContent: {
        body: null,
        footer: null,
      },
      showModal: false,
      headers: [
        {
          text: TABLE_FIELD_NAMES.USERNAME,
          name: 'username',
          filterType: 'INPUT',
          isActivated: false,
          defaultAscendent: true,
        },
        {
          text: TABLE_FIELD_NAMES.TITLE,
          name: 'role',
          filterType: 'SELECT',
          isActivated: false,
        },
        {
          text: TABLE_FIELD_NAMES.NAME,
          name: 'name',
          filterType: 'INPUT',
          isActivated: false,
        },
        {
          text: TABLE_FIELD_NAMES.EMAIL,
          name: 'email',
          filterType: 'INPUT',
          isActivated: false,
        },
        {
          text: TABLE_FIELD_NAMES.LAST_LOGIN,
          name: 'lastLogin',
          filterType: 'CALENDAR',
          isActivated: false,
          isSortable: true,
        },
        {
          text: TABLE_FIELD_NAMES.SITE,
          name: 'sites',
          component: TrimmedUnorderedList,
          filterType: props.hasCompanyHierarchy ? '' : 'INPUT',
          isActivated: false,
          isSortable: false,
        },
        {
          text: TABLE_FIELD_NAMES.ALERTS,
          name: 'alertsEnabled',
          filterType: '',
          isSortable: false,
        },
        {
          text: TABLE_FIELD_NAMES.ACTIONS,
          name: 'actions',
          filterType: '',
          isSortable: false,
          class: 'text-right',
        },
      ],
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    const { fetchRoles: fetchRolesAction } = this.props;

    fetchRolesAction();
  }

  deleteUser = async () => {
    const { fetchUsers, usersQueryParams, currentPage } = this.props;
    const { modalUser } = this.state;

    try {
      await siteManagerAPI.delete(`${API_USERS}${modalUser.id}/`);
      usersQueryParams.offset = getOffsetPagination(currentPage);
      await fetchUsers(API_USERS, usersQueryParams);
      toast.success('User deleted correctly');
      this.setState({ isModalOpen: false });
    } catch (error) {
      toast.error(DELETE_USER_TRY_LATER);
    }
  };

  updateAlerts = async (user) => {
    const { fetchUsers, shouldDisplaySpinner } = this.props;
    const body = { id: user.id, alerts_enabled: !user.alertsEnabled };

    try {
      shouldDisplaySpinner(true);
      await siteManagerAPI.patch(`${API_USERS}${user.id}/`, body);
      await fetchUsers(API_USERS);
      toast.success('Alerts updated correctly');
    } catch (error) {
      toast.error('There has been an error while saving the User configuration. Try again later');
    } finally {
      shouldDisplaySpinner(false);
    }
  };

  parseUser = (user) => {
    const url = API_USER_SITES.replace('{id}', user.id);
    const fetchSites = async (params) => {
      if (!user) return { count: 0, list: [], completeList: [] };
      const r = await siteManagerAPI.get(url, params || undefined);
      const nameList = r.data.results.map((s) => s.name).sort(sortDescendingText);
      return {
        count: r.data.count,
        list: nameList,
        completeList: nameList,
      };
    };

    return {
      ...user,
      ...user.siteGroups,
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      date: user.lastLogin,
      sites: {
        fetchList: () => fetchSites({ params: { limit: 4 } }),
        fetchCompleteList: fetchSites,
        setModalData: (modalContent) => this.setState({ modalContent }),
        toggleModal: this.toggleModal,
      },
      alertsEnabled: { customCell: true, cell: user },
      role: user.role ? user.role.name : null,
      actions: (
        <TableActions
          clone={() => this.handleRedirect(user.id, 'CLONE')}
          edit={() => this.handleRedirect(user.id, 'EDIT')}
          remove={() => this.displayModal(user)}
        />
      ),
    };
  };

  displayModal = (user) => {
    this.setState({ isModalOpen: true, modalUser: user });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  toggleModal() {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  handleRedirect(userId, action) {
    this.setState({ redirect: true, userId, action });
  }

  renderRedirect = () => {
    const { userList } = this.props;
    const { redirect, userId, action } = this.state;

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: `/users/${userId}`,
            user: userList.find((user) => user.id === userId),
            action,
          }}
        />
      );
    }
    return null;
  };

  renderStatusAlerts = (user) => {
    return (
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id={`enable_${user.id}`}
          checked={user.alertsEnabled}
          onChange={() => this.updateAlerts(user)}
        />
        <label className="custom-control-label" htmlFor={`enable_${user.id}`} />
      </div>
    );
  };

  render() {
    const {
      headers,
      userId,
      action,
      isModalOpen,
      modalUser,
      modalContent: { body, footer },
      showModal,
    } = this.state;
    const { userList, usersQueryParams, fetchUsers, roles, filterDates } = this.props;

    const parsedUsers = userList.map(this.parseUser);

    const selectOptions = {
      role: roles.map((role) => ({ displayName: role.name, value: role.id })),
    };

    return (
      <React.Fragment>
        <BrandModal
          subtitle="List of all sites assigned to the user:"
          size="lg"
          body={body}
          footer={footer}
          isOpen={showModal}
          closeModal={this.toggleModal}
          centerBody
        />
        <TableFunctions
          headers={headers}
          rowsData={parsedUsers}
          suggestionsURL={API_USERS_SUGGESTIONS}
          dataURL={API_USERS}
          fetchData={(dataURL, queryParams) => {
            queryParams.untrimmed = true; // eslint-disable-line no-param-reassign
            fetchUsers(dataURL, queryParams);
          }}
          tableQueryParams={usersQueryParams}
          renderRedirect={this.renderRedirect}
          objectRedirect={{
            pathname: `/users/${userId}`,
            user: userList.find((user) => user.id === userId),
            action,
          }}
          renderCustomCell={this.renderStatusAlerts}
          selectOptions={selectOptions}
          filterDates={filterDates}
          columnsAreArrangeable
        />

        <ApprovalRuleConfirmationModal
          isOpen={isModalOpen}
          closeHandler={this.closeModal}
          confirmHandler={() => this.deleteUser()}
          obj={modalUser}
          objName="user"
          attrName="username"
          action="delete"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  usersQueryParams: state.users.users.pagination.queryParams,
  currentPage: state.users.users.pagination.currentPage,
  user: state.auth.user,
  roles: state.roles.roles.results,
  hasCompanyHierarchy: _.get(state, 'auth.user.company.hasCompanyHierarchy', false),
});

export default connect(mapStateToProps, {
  fetchUsers,
  setModalData,
  shouldDisplaySpinner,
  fetchRoles,
})(UserListComponent);
