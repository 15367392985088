import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TextArea extends Component {
  static propTypes = {
    error: PropTypes.string,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    handleChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    placeHolder: PropTypes.string,
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    maxLength: PropTypes.number,
    rows: PropTypes.number,
  };

  static defaultProps = {
    handleChange: () => {},
    required: false,
    error: '',
    readOnly: false,
    placeHolder: '',
    labelClassName: '',
    inputClassName: '',
    maxLength: 10000,
    rows: 5,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      error,
      required,
      label,
      name,
      readOnly,
      placeHolder,
      labelClassName,
      inputClassName,
      maxLength,
      handleChange,
      value,
      rows,
    } = this.props;

    return (
      <div className="form-field form-group">
        <label
          className={`medium-text ${labelClassName} ${
            required ? 'form-field__label--required' : ''
          }`}
          htmlFor={`input-${name}`}
        >
          {label}
        </label>

        <textarea
          onChange={handleChange}
          name={name}
          id={`textarea-${name}`}
          readOnly={readOnly}
          className={`form-control medium-text ${inputClassName}`}
          placeholder={placeHolder || label}
          rows={rows}
          value={value}
          maxLength={maxLength}
        />

        {error && (
          <div className="form-field__error small-text">
            <span>{error}</span>
          </div>
        )}
      </div>
    );
  }
}

export default TextArea;
