import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import _ from 'lodash';

import moment from 'moment';
import { fetchOrders as fetchOrdersAction } from '../../actions/orders.actions';
import { API_ORDERS, API_ORDERS_SUGGESTIONS } from '../../lib/api-endpoints';
import { formatFixNumber } from '../../utils/format';
import { ORDER_PAYMENT_STATUS, ORDER_STATUS, TABLE_FIELD_NAMES } from '../../constants/values';
import { openOrderTracking } from '../../utils/general';
import TableFunctions from '../shared/table-functions';
import TableActions from '../shared/table-actions.component';
import PermissionChecker from '../shared/permission-checker';
import TrimmedUnorderedList from '../shared/trimmed-unordered-list.component';

const ORDERS_HISTORY_START_DAY = '2020-03-30T06:00:00Z';

const getHeaders = ({ user, hasCompanyHierarchy }) =>
  _.compact([
    {
      text: TABLE_FIELD_NAMES.SITE,
      name: 'site',
      filterType: 'INPUT',
      isActivated: false,
    },
    user.company.hasLocationNameRequired && {
      text: TABLE_FIELD_NAMES.LOCATION_NAME,
      name: 'legacySiteLocationName',
      filterType: 'INPUT',
      isActivated: false,
    },
    !hasCompanyHierarchy && {
      text: TABLE_FIELD_NAMES.BUSINESS_UNIT_INITIALS,
      name: 'legacySiteBU',
      filterType: 'INPUT',
      isActivated: false,
    },
    !hasCompanyHierarchy && {
      text: TABLE_FIELD_NAMES.REGION,
      name: 'legacySiteRegion',
      filterType: 'INPUT',
      isActivated: false,
    },
    {
      text: TABLE_FIELD_NAMES.ORDERED_BY,
      name: 'ordered_by',
      filterType: 'INPUT',
      isActivated: false,
      trimmText: true,
    },
    {
      text: TABLE_FIELD_NAMES.REQUESTED_BY,
      name: 'requestedBy',
      filterType: 'INPUT',
      isActivated: false,
      trimmText: true,
    },
    {
      text: TABLE_FIELD_NAMES.ORDER,
      name: 'identifier',
      filterType: 'INPUT',
      isActivated: false,
      trimmText: true,
    },
    {
      text: TABLE_FIELD_NAMES.PO,
      name: 'po',
      filterType: 'INPUT',
      isActivated: false,
      trimmText: true,
    },
    {
      text: TABLE_FIELD_NAMES.TRACKING_NUMBERS,
      name: 'trackingNumbers',
      component: TrimmedUnorderedList,
      filterType: 'INPUT',
      isActivated: false,
      isSortable: false,
      trimmText: true,
    },
    {
      text: TABLE_FIELD_NAMES.AFE,
      name: 'afe',
      filterType: 'INPUT',
      isActivated: false,
      trimmText: true,
    },
    {
      text: TABLE_FIELD_NAMES.STATUS,
      name: 'status',
      filterType: 'SELECT',
      isActivated: false,
    },
    {
      text: TABLE_FIELD_NAMES.PAYMENT_STATUS,
      name: 'paymentStatus',
      filterType: 'SELECT',
      isActivated: false,
    },
    {
      text: TABLE_FIELD_NAMES.CREATED,
      name: 'orderDate',
      filterType: 'CALENDAR',
      isActivated: false,
      defaultDescendent: true,
    },
    {
      text: TABLE_FIELD_NAMES.LINES,
      name: 'lineLength',
      filterType: '',
    },
    {
      text: TABLE_FIELD_NAMES.TOTAL,
      name: 'total',
      filterType: '',
    },
    {
      text: TABLE_FIELD_NAMES.ACTIONS,
      name: 'actions',
      filterType: '',
      isSortable: false,
      class: 'text-right',
    },
  ]);

function Action({ order, handleOrderRedirect, handleDocumentsRedirect }) {
  return (
    <PermissionChecker
      permissionName="order-tracking"
      order={order}
      fallback={
        <TableActions
          view={() => handleOrderRedirect(order.identifier, 'View')}
          documents={() => handleDocumentsRedirect(order.identifier)}
        />
      }
    >
      <TableActions
        view={() => handleOrderRedirect(order.identifier, 'View')}
        documents={() => handleDocumentsRedirect(order.identifier)}
        track={
          moment(order.rawOrderDate).isSameOrAfter(ORDERS_HISTORY_START_DAY, 'day') &&
          openOrderTracking(order.identifier)
        }
      />
    </PermissionChecker>
  );
}
Action.propTypes = {
  order: PropTypes.shape().isRequired,
  handleOrderRedirect: PropTypes.func.isRequired,
  handleDocumentsRedirect: PropTypes.func.isRequired,
};

const parsedOrders = (orders = [], handleOrderRedirect, handleDocumentsRedirect) =>
  orders.map((order) => {
    const actions = (
      <Action
        order={order}
        handleOrderRedirect={handleOrderRedirect}
        handleDocumentsRedirect={handleDocumentsRedirect}
      />
    );

    return {
      ...order,
      ...order.siteGroups,
      order: order.identifier,
      legacySiteLocationName: order.siteLocationName,
      legacySiteBU: order.siteBU,
      legacySiteRegion: order.siteRegion,
      total: `$${formatFixNumber(order.total)}`,
      date: order.created,
      actions,
      trackingNumbers: {
        list: order.trackingNumbers,
        count: order.trackingNumbersCount,
      },
    };
  });

const orderOptions = [
  {
    displayName: ORDER_STATUS.ACCEPTED.TEXT,
    value: ORDER_STATUS.ACCEPTED.TEXT,
  },
  {
    displayName: ORDER_STATUS.PENDING_APPROVAL.TEXT,
    value: ORDER_STATUS.PENDING_APPROVAL.TEXT,
  },
  {
    displayName: ORDER_STATUS.PROCESSING.TEXT,
    value: ORDER_STATUS.PROCESSING.TEXT,
  },
  { displayName: ORDER_STATUS.SHIPPED.TEXT, value: ORDER_STATUS.SHIPPED.TEXT },
  {
    displayName: ORDER_STATUS.PARTIALLY_SHIPPED.TEXT,
    value: ORDER_STATUS.PARTIALLY_SHIPPED.TEXT,
  },
  {
    displayName: ORDER_STATUS.DELIVERED.TEXT,
    value: ORDER_STATUS.DELIVERED.TEXT,
  },
  {
    displayName: ORDER_STATUS.CANCELED.TEXT,
    value: ORDER_STATUS.CANCELED.TEXT,
  },
  {
    displayName: ORDER_STATUS.REJECTED.TEXT,
    value: ORDER_STATUS.REJECTED.TEXT,
  },
];

const orderPaymentStatusOptions = [
  { displayName: ORDER_PAYMENT_STATUS.PAID, value: ORDER_PAYMENT_STATUS.PAID },
  {
    displayName: ORDER_PAYMENT_STATUS.NOT_PAID,
    value: ORDER_PAYMENT_STATUS.NOT_PAID,
  },
  {
    displayName: ORDER_PAYMENT_STATUS.PARTIALLY_PAID,
    value: ORDER_PAYMENT_STATUS.PARTIALLY_PAID,
  },
];

const selectOptions = {
  status: orderOptions,
  paymentStatus: orderPaymentStatusOptions,
};

const filterDates = {
  start: 'orderDate_after',
  end: 'orderDate_before',
};

function OrderListComponent({
  orders,
  ordersQueryParams,
  fetchOrders,
  hasCompanyHierarchy,
  history,
  user,
}) {
  const headers = getHeaders({ user, hasCompanyHierarchy });
  const [redirect, setRedirect] = useState(false);
  const [redirectPathname, setRedirectPathname] = useState(null);
  const [orderIdentifier, setOrderIdentifier] = useState(null);
  const [action, setAction] = useState(null);

  const handleOrderRedirect = (orderId, actionParam) => {
    setRedirect(true);
    setRedirectPathname(`/orders/${orderId}`);
    setOrderIdentifier(orderId);
    setAction(actionParam);
  };

  const handleDocumentsRedirect = (orderId) => {
    history.push(`/orders/${orderId}/documents`);
  };

  const renderRedirect = () => {
    if (redirect) {
      const objectRedirectTo = {
        pathname: redirectPathname,
      };

      if (orderIdentifier) {
        objectRedirectTo.order = orders.find((order) => order.identifier === orderIdentifier);
        objectRedirectTo.action = action;
      }

      return (
        <Redirect
          to={{
            ...objectRedirectTo,
          }}
        />
      );
    }

    return null;
  };

  return (
    <TableFunctions
      headers={headers}
      rowsData={parsedOrders(orders, handleOrderRedirect, handleDocumentsRedirect)}
      suggestionsURL={API_ORDERS_SUGGESTIONS}
      dataURL={API_ORDERS}
      fetchData={(dataURL, queryParams) => fetchOrders(dataURL, queryParams)}
      renderRedirect={() => renderRedirect()}
      selectOptions={selectOptions}
      tableQueryParams={ordersQueryParams}
      filterDates={filterDates}
      showNodes
      columnsAreArrangeable
    />
  );
}

OrderListComponent.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  ordersQueryParams: PropTypes.shape(),
  fetchOrders: PropTypes.func.isRequired,
  hasCompanyHierarchy: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    company: PropTypes.shape({
      hasLocationNameRequired: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

OrderListComponent.defaultProps = {
  ordersQueryParams: {},
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  ordersQueryParams: state.orders.orders.pagination.queryParams,
  hasCompanyHierarchy: _.get(state, 'auth.user.company.hasCompanyHierarchy', false),
});

export default connect(mapStateToProps, {
  fetchOrders: fetchOrdersAction,
})(OrderListComponent);
