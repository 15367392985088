import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import randomize from 'randomatic';
import _ from 'lodash';

import { getUsernamePrefix } from 'utils/general';
import { formatNodeToSelectInput, parseNodeOptions, parseOptions } from 'utils/format';
import { API_SITES, API_SITES_EXISTS, API_USER_SHUUP_EXISTS } from '../../lib/api-endpoints';
import { fetchSites } from '../../actions/sites.actions';
import { EMAIL_REGEX, getFieldValue } from '../../utils/forms';
import siteManagerAPI from '../../lib/api-manager';
import TextInput from './text-input.component';
import SelectInput from './select-input';
import { fetchSiteTypes } from '../../actions/site-types';
import { fetchWarehouses } from '../../actions/warehouses.actions';
import * as hierarchyActions from '../../shared/actions/hierarchy';
import * as hierarchySelectors from '../../shared/selectors/hierarchy';
import * as hierarchyUtils from '../../shared/utils/hierarchy';
import { UNLIMITED_LIST_LIMIT } from '../../constants/values';
import FormWrapper from './form-wrapper.component';
import SwitchInput from "./switch-input.component";

const MAX_DIVISION_LENGTH = 50;
const MAX_BUSINESS_UNIT_LENGTH = 50;

class SiteFormComponent extends Component {
  static propTypes = {
    fetchSites: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    site: PropTypes.shape(),
    user: PropTypes.shape({
      company: PropTypes.shape({
        hasLocationNameRequired: PropTypes.bool,
      }).isRequired,
    }).isRequired,
    siteTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    hasCompanyHierarchy: PropTypes.bool,
    fetchSiteTypes: PropTypes.func.isRequired,
    fetchWarehouses: PropTypes.func.isRequired,
    availableNodes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
      }),
    ).isRequired,
    fetchNodes: PropTypes.func.isRequired,
    fetchCurrentNodeConfig: PropTypes.func.isRequired,
    currentNode: PropTypes.shape({
      customConfig: PropTypes.shape({
        usernamePrefix: PropTypes.string,
      }),
    }).isRequired,
    companyId: PropTypes.number.isRequired,
    warehouses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ).isRequired,
  };

  static defaultProps = {
    hasCompanyHierarchy: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      name: (props && props.site && props.site.name) || '',
      businessUnit: (props && props.site && props.site.businessUnit) || '',
      division: (props && props.site && props.site.division) || '',
      region: (props && props.site && props.site.region) || '',
      errors: (props && props.site && props.site.errors) || {},
      password: (props && props.site && props.site.password) || '',
      ecommerceEmail: (props && props.site && props.site.ecommerceEmail) || '',
      locationName: (props && props.site && props.site.locationName) || '',
      siteType: (props && props.site && props.site.siteType) || null,
      shouldEnableAnalytics: true,
      node: null,
      prefix: '',
      siteWarehouse: null,
    };
  }

  async componentDidMount() {
    const {
      fetchSiteTypes: fetchSiteTypesAction,
      fetchWarehouses: fetchWarehousesAction,
      fetchNodes,
      hasCompanyHierarchy,
      currentNode,
      companyId,
      warehouses,
    } = this.props;

    const prefix = await getUsernamePrefix(hasCompanyHierarchy, currentNode, companyId);
    this.setState({ prefix });

    fetchSiteTypesAction();
    if (!warehouses) {
      fetchWarehousesAction();
    }
    if (hasCompanyHierarchy) {
      fetchNodes(UNLIMITED_LIST_LIMIT);
    }
  }

  capitalizeWord = (word) => word.charAt(0).toUpperCase() + word.slice(1);

  buildEcommerceUsername = () => {
    const { name, siteType, prefix } = this.state;

    const adjustedName =
      name.toLowerCase().indexOf(siteType.name.toLowerCase()) === -1
        ? `${siteType.name} ${name}`
        : name;

    return `${prefix}-${adjustedName
      .split(' ')
      .map((element) => this.capitalizeWord(element))
      .join('')}`;
  };

  handleSiteTypeSelectChange = (selectedOption) => {
    this.setState({ siteType: selectedOption });
  };

  handleChange = (event) => {
    this.setState(getFieldValue(event.target));
  };

  handleWarehouseChange = (selectedWarehouse) => {
    this.setState({ siteWarehouse: selectedWarehouse });
  };

  handleSwitchInputChange = (name, checked) => {
    this.setState({ [name]: checked });
  };

  doesShuupUserExist = async () => {
    const ecommerceUsername = this.buildEcommerceUsername();

    try {
      await siteManagerAPI.post(API_USER_SHUUP_EXISTS, { username: ecommerceUsername });
      return true;
    } catch (error) {
      return false;
    }
  };

  submitSiteForm = async (event) => {
    event.preventDefault();

    const { user, hasCompanyHierarchy } = this.props;
    const {
      name,
      division,
      businessUnit,
      region,
      ecommerceEmail,
      locationName,
      siteType,
      node,
      siteWarehouse,
      shouldEnableAnalytics,
    } = this.state;
    const errors = {};

    try {
      await siteManagerAPI.post(API_SITES_EXISTS, { name });
      errors.name = 'Site with this name already exists';
    } catch (error) {
      console.log('User not found');
    }

    if (!ecommerceEmail) errors.ecommerceEmail = 'Email field is required';
    else if (EMAIL_REGEX.exec(ecommerceEmail) === null) {
      errors.ecommerceEmail = 'Incorrect email format';
    }

    if (!siteType) errors.siteType = 'Type field is required';

    if (!name) errors.name = 'Name field is required';

    if (user.company.hasLocationNameRequired && !locationName)
      errors.locationName = 'Location Name field is required';

    if (!hasCompanyHierarchy && !division) {
      errors.division = 'Division field is required';
    } else if (division.length > MAX_DIVISION_LENGTH) {
      errors.division = "Division field can't be longer than 50 characters.";
    }

    if (!hasCompanyHierarchy && !businessUnit) {
      errors.businessUnit = 'Business Unit field is required';
    } else if (businessUnit.length > MAX_BUSINESS_UNIT_LENGTH)
      errors.businessUnit = "Business Unit field can't be longer than 50 characters.";

    if (!hasCompanyHierarchy && !region) errors.region = 'Region field is required';

    if (hasCompanyHierarchy && !node) errors.node = 'Node field is required';

    if (!siteWarehouse) errors.siteWarehouse = 'Warehouse Name is required';

    this.setState({ errors });

    const { onSubmit } = this.props;

    if (Object.entries(errors).length === 0) {
      const ecommerceUsername = this.buildEcommerceUsername();
      const siteData = {
        name,
        locationName,
        division,
        businessUnit,
        region,
        ecommerceUsername,
        ecommerceEmail,
        siteType,
        node,
        siteWarehouse,
        shouldEnableAnalytics,
      };
      if (hasCompanyHierarchy) {
        siteData.node = node && node.id;
      }
      const doesShuupUserExist = await this.doesShuupUserExist();

      if (doesShuupUserExist) {
        this.createSite();
      } else {
        siteData.password = randomize('*', 10);
        onSubmit(false, siteData);
      }
    }
  };

  handleNodeChange = async (node) => {
    if (node) {
      const { fetchCurrentNodeConfig } = this.props;
      await fetchCurrentNodeConfig(node.id);
    }

    const { hasCompanyHierarchy, currentNode, companyId } = this.props;
    const prefix = await getUsernamePrefix(hasCompanyHierarchy, currentNode, companyId);

    this.setState({ prefix, node });
  };

  async createSite() {
    const {
      name,
      locationName,
      division,
      businessUnit,
      region,
      password,
      ecommerceEmail,
      siteType,
      node,
      siteWarehouse,
      shouldEnableAnalytics,
    } = this.state;
    const { hasCompanyHierarchy } = this.props;
    const ecommerceUsername = this.buildEcommerceUsername();
    const siteBody = {
      name,
      location_name: locationName,
      division,
      business_unit: businessUnit,
      region,
      ecommerce_username: ecommerceUsername,
      ecommerce_user_email: ecommerceEmail,
      site_type: siteType.value,
      siteWarehouse: siteWarehouse.value,
      enable_analytics: shouldEnableAnalytics,
    };

    if (password) {
      siteBody.password = password;
    }

    if (hasCompanyHierarchy) {
      siteBody.organization_node = node && node.id;
    }

    try {
      const response = await siteManagerAPI.post(API_SITES, siteBody);

      this.setState({
        name: '',
        businessUnit: '',
        division: '',
        region: '',
        ecommerceEmail: '',
        siteType: null,
        node: null,
        siteWarehouse: null,
        shouldEnableAnalytics: true,
      });

      const { onSubmit, fetchSites: fetchSitesAction } = this.props;

      const siteData = response.data;
      siteData.businessUnit = siteData.business_unit;
      siteData.ecommerceUsername = siteData.ecommerce_username;
      siteData.ecommerceEmail = siteData.ecommerce_user_email;

      fetchSitesAction(API_SITES);
      onSubmit(true, siteData);
    } catch (error) {
      const siteError = error.response.data;
      const errors = {};

      if (siteError) {
        if (siteError.division) {
          [errors.division] = siteError.division;
        }

        if (siteError.business_unit) {
          [errors.businessUnit] = siteError.business_unit;
        }

        if (siteError.node) {
          [errors.node] = siteError.node;
        }
      }

      this.setState({ errors });
    }
  }

  render() {
    const { user, siteTypes, warehouses, hasCompanyHierarchy, availableNodes } =
      this.props;
    const {
      errors,
      name,
      division,
      businessUnit,
      region,
      ecommerceEmail,
      locationName,
      siteWarehouse,
      siteType,
      node,
      shouldEnableAnalytics,
    } = this.state;

    return (
      <FormWrapper>
        <form
          className="form-input mb-2 clearfix"
          onSubmit={this.submitSiteForm}
          data-testid="site-form-modal"
        >
          <TextInput
            error={errors.name}
            value={name}
            label="Name"
            name="name"
            required
            handleChange={this.handleChange}
          />

          <SelectInput
            label="Type"
            name="siteType"
            value={siteType}
            error={errors.siteType}
            options={siteTypes}
            mapOptions={parseOptions}
            handleChange={this.handleSiteTypeSelectChange}
            placeholder="Select..."
            required
            isSearchable
            closeMenuOnSelect
          />

          {user.company.hasLocationNameRequired && (
            <TextInput
              error={errors.locationName}
              value={locationName}
              label="Location Name"
              name="locationName"
              required
              handleChange={this.handleChange}
            />
          )}

          <SelectInput
            label="Warehouse Name"
            name="siteWarehouse"
            value={siteWarehouse}
            error={errors.siteWarehouse}
            options={warehouses}
            mapOptions={parseOptions}
            handleChange={this.handleWarehouseChange}
            placeholder="Select..."
            required
            closeMenuOnSelect
          />

          {hasCompanyHierarchy && (
            <SelectInput
              label="Node"
              name="node"
              value={formatNodeToSelectInput(node)}
              error={errors.node}
              options={availableNodes}
              mapOptions={parseNodeOptions}
              handleChange={this.handleNodeChange}
              closeMenuOnSelect
              isSearchable
              isClearable
              required
            />
          )}

          {!hasCompanyHierarchy && (
            <TextInput
              error={errors.businessUnit}
              value={businessUnit}
              label="Business Unit"
              name="businessUnit"
              required
              handleChange={this.handleChange}
            />
          )}

          {!hasCompanyHierarchy && (
            <TextInput
              error={errors.division}
              value={division}
              label="Division"
              name="division"
              required
              handleChange={this.handleChange}
            />
          )}

          {!hasCompanyHierarchy && (
            <TextInput
              error={errors.region}
              value={region}
              label="Region"
              name="region"
              required
              handleChange={this.handleChange}
            />
          )}

          <TextInput
            error={errors.ecommerceEmail}
            value={ecommerceEmail}
            label="Email"
            name="ecommerceEmail"
            required
            handleChange={this.handleChange}
          />

          <SwitchInput
            label="Enable Analytics"
            name="shouldEnableAnalytics"
            checked={shouldEnableAnalytics}
            handleChange={this.handleSwitchInputChange}
          />

          <button type="submit" data-placement="right" className="btn btn-primary float-right">
            NEXT
          </button>
        </form>
      </FormWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, siteTypes, warehouses } = state;
  return {
    user: auth.user,
    siteTypes: siteTypes.siteTypes.results,
    warehouses: warehouses.names,
    hasCompanyHierarchy: _.get(auth, 'user.company.hasCompanyHierarchy', false),
    availableNodes: hierarchySelectors
      .availableNodes(state)
      .map(hierarchyUtils.formatNodeWithGroupName),
    currentNode: hierarchySelectors.currentNode(state),
    companyId: _.get(state, 'auth.user.company.id', null),
  };
};

export default connect(mapStateToProps, {
  fetchSites,
  fetchSiteTypes,
  fetchWarehouses,
  ...hierarchyActions,
})(SiteFormComponent);
