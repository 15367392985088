import _ from 'lodash';

export function formatNodeForAPI(node) {
  const { name, users, sites, group, parent } = node;
  return {
    name,
    organization_group: group,
    parent,
    sites,
    users,
  };
}

export function formatNodeConfigForAPI(nodeConfig) {
  return _.mapKeys(nodeConfig, (value, key) => _.snakeCase(key));
}
