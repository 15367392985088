import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';

import SectionHeaderComponent from '../../components/shared/section-header.component';
import BreadCrumbComponent from '../../components/shared/breadcumb.component';
import BreadCrumbItem from '../../components/shared/breadcrumb-item';
import PoliciesForm from '../../shared/components/policies-form';

import messages from './messages';
import * as actions from './actions';

function EditPolicy() {
  const { id } = useParams();

  const handleOnSubmit = useCallback((userId, data) => {
    actions.updatePolicy(userId, data);
  }, []);

  return (
    <React.Fragment>
      <SectionHeaderComponent subtitle={messages.sceneTitle} sectionLabel={messages.sceneLabel} />
      <div className="content__container">
        <BreadCrumbComponent>
          <BreadCrumbItem label={messages.breadcrumbHierarchyLabel} link="/hierarchy" />
          <BreadCrumbItem label={messages.breadcrumbPoliciesLabel} link="/hierarchy/policies" />
          <BreadCrumbItem label={messages.breadcrumbEditPlocyLabel} />
        </BreadCrumbComponent>
        <div className="order-details__return-btn">
          <Link to={{ pathname: '/hierarchy/policies' }}>
            <FontAwesomeIcon icon="arrow-left" /> {messages.backButtonText}
          </Link>
        </div>
        <div className="edit-node__container">
          <PoliciesForm
            onSubmit={handleOnSubmit}
            clearOnSubmit={false}
            currentUserId={id}
            submitButtonText={messages.submitButtonText}
            isUpdate
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditPolicy;
