import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { getFieldValue } from '../../utils/forms';

class SearchComponent extends Component {
  static propTypes = {
    handleSearch: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.state = {
      searchStr: '',
    };
  }

  handleChange = (event) => {
    this.setState(getFieldValue(event.target));
  };

  handleSearchSubmit = (event) => {
    event.preventDefault();

    const { handleSearch } = this.props;
    const { searchStr } = this.state;

    handleSearch(searchStr);
  };

  render() {
    const { searchStr } = this.state;

    return (
      <form onSubmit={this.handleSearchSubmit}>
        <div className="input-group search">
          <input
            value={searchStr}
            type="text"
            className="form-control search__input"
            placeholder="Search"
            name="searchStr"
            onChange={this.handleChange}
          />
          <div className="input-group-append p-0">
            <div className="input-group-text search__btn-container">
              <button type="submit" className="btn search__btn semi-bold m-0 p-1">
                <FontAwesomeIcon icon="search" />
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default SearchComponent;
