import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { fetchUsers, resetUsers } from '../../actions/users.actions';
import { shouldDisplaySpinner, setModalData } from '../../actions/globals.actions';
import { fetchOrganizationGroups } from '../../actions/hierarchy.actions';
import { API_USER_SITES, API_USERS } from '../../lib/api-endpoints';
import { UNLIMITED_LIST_LIMIT } from '../../constants/values';
import { exportToExcel } from '../../utils/general';
import { GENERIC_TRY_LATER } from '../../constants/error-messages';
import { EXPORTING, NOTHING_TO_EXPORT } from '../../constants/messages';
import siteManagerAPI from '../../lib/api-manager';
import ExportExcelComponent from '../shared/export-excel.component';
import SectionHeaderComponent from '../shared/section-header.component';
import BreadCrumbComponent from '../shared/breadcumb.component';
import BreadCrumbItem from '../shared/breadcrumb-item';
import PaginationComponent from '../shared/pagination.component';
import UserListComponent from './user-list.component';
import UserFormComponent from '../forms/user-form.component';
import AssignSiteComponent from '../forms/assign-site.component';
import SearchComponent from '../shared/search.component';
import ReactModal from '../shared/react-modal';

const getSitesNames = (sites) => {
  const formattedSiteNames = sites
    .reduce((sitesNames, site) => `${sitesNames}${site.name}, `, '')
    .slice(0, -2);

  return formattedSiteNames;
};

class UsersComponent extends Component {
  static propTypes = {
    shouldDisplaySpinner: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    user: PropTypes.shape({
      isCompanyAdmin: PropTypes.bool,
    }).isRequired,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
      }),
    ).isRequired,
    hasOrganizationGroups: PropTypes.bool,
    hasCompanyHierarchy: PropTypes.bool,
    fetchOrganizationGroups: PropTypes.func.isRequired,
    usersQueryParams: PropTypes.shape(),
    resetUsers: PropTypes.func.isRequired,
  };

  static defaultProps = {
    hasCompanyHierarchy: false,
    usersQueryParams: {},
    hasOrganizationGroups: null,
  };

  constructor() {
    super();
    this.state = {
      createdUser: null,
      isModalOpen: false,
      hasSearch: false,
    };
  }

  componentDidMount = () => {
    const { fetchOrganizationGroups, hasOrganizationGroups, hasCompanyHierarchy } = this.props;
    if (!hasOrganizationGroups && hasCompanyHierarchy) {
      fetchOrganizationGroups();
    }
  };

  componentWillUnmount() {
    const { resetUsers: resetUsersResults } = this.props;
    const { hasSearch } = this.state;

    if (hasSearch) {
      resetUsersResults();
    }
  }

  getSizeModal() {
    const { createdUser } = this.state;

    return createdUser ? 'lg' : '';
  }

  handleSearch = async (searchStr) => {
    const { shouldDisplaySpinner, fetchUsers } = this.props;
    this.setState({ hasSearch: true });

    shouldDisplaySpinner(true);
    try {
      await fetchUsers(`${API_USERS}?search=${searchStr}`);
    } catch (error) {
      toast.error(GENERIC_TRY_LATER);
    } finally {
      shouldDisplaySpinner(false);
    }
  };

  fetchUsers = async () => {
    const {
      user,
      shouldDisplaySpinner: shouldDisplaySpinnerAction,
      fetchUsers: fetchUsersAction,
    } = this.props;

    if (user) {
      await shouldDisplaySpinnerAction(true);
      await fetchUsersAction(API_USERS);
      shouldDisplaySpinnerAction(false);
    } else {
      setTimeout(() => {
        console.warn(`Retrying ${API_USERS} in 1500ms`);
        this.fetchUsers();
      }, 1500);
    }
  };

  resetUser = () => {
    this.setState({ createdUser: null, isModalOpen: false });
  };

  displayModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false, createdUser: null });
  };

  exportFilteredSites = async () => {
    const { usersQueryParams, shouldDisplaySpinner, users } = this.props;
    usersQueryParams.limit = UNLIMITED_LIST_LIMIT;

    toast.success(EXPORTING);
    shouldDisplaySpinner(true);

    try {
      if (!users) {
        toast.warn(NOTHING_TO_EXPORT);
        return;
      }

      const params = { ...usersQueryParams, untrimmed: true };

      const parsedUsers = await Promise.all(
        users.map(async ({ id, username, role, firstName, lastName, email, lastLogin }) => {
          const url = API_USER_SITES.replace('{id}', id);
          const response = await siteManagerAPI.get(`${url}`, { params });
          const { data } = response;
          const { count, results } = data;

          return {
            username,
            title: role && role.name,
            name: `${firstName} ${lastName}`,
            email,
            assignedSites: getSitesNames(results),
            assignedSitesCount: count,
            lastLogin,
          };
        }),
      );

      exportToExcel(parsedUsers, 'users');
    } catch (error) {
      toast.error(GENERIC_TRY_LATER);
    }

    shouldDisplaySpinner(false);
  };

  userCreated(user) {
    this.setState({ createdUser: user });
  }

  renderBodyModal() {
    const { createdUser } = this.state;
    const { hasCompanyHierarchy } = this.props;

    if (createdUser && !hasCompanyHierarchy) {
      return <AssignSiteComponent createdUser={createdUser} onSuccess={this.resetUser} />;
    }
    if (createdUser && hasCompanyHierarchy) {
      this.closeModal();
      return null;
    }
    return <UserFormComponent onSuccess={(user) => this.userCreated(user)} />;
  }

  render() {
    const {
      users,
      user: { isCompanyAdmin },
    } = this.props;
    const { isModalOpen } = this.state;
    return (
      <Fragment>
        <SectionHeaderComponent subtitle="Company" sectionLabel="Users" />

        <div className="content__container">
          <BreadCrumbComponent>
            <BreadCrumbItem label="Users" />
          </BreadCrumbComponent>

          <div className="container-fluid">
            <div className="table-tools">
              <div className="table-tools__search">
                <SearchComponent handleSearch={this.handleSearch} />
              </div>
              <div className="table-tools__btns">
                {isCompanyAdmin && (
                  <button
                    type="button"
                    data-testid="create-user-button"
                    onClick={this.displayModal}
                    className="btn btn-primary table-tools__btn"
                  >
                    <FontAwesomeIcon className="btn__icon" icon="user-plus" />
                    <span> Create User</span>
                  </button>
                )}
                <ReactModal
                  isOpen={isModalOpen}
                  title="Create New User"
                  size={this.getSizeModal()}
                  body={this.renderBodyModal()}
                  closeModal={this.closeModal}
                />
                <ExportExcelComponent exportData={this.exportFilteredSites} />
              </div>
            </div>

            <PaginationComponent />
            <UserListComponent userList={users} />
            <PaginationComponent />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  users: state.users.users.results,
  usersQueryParams: state.users.users.pagination.queryParams || {},
  hasOrganizationGroups: state.hierarchy && state.hierarchy.groups.length > 0,
  hasCompanyHierarchy: _.get(state, 'auth.user.company.hasCompanyHierarchy', false),
});

export default connect(mapStateToProps, {
  fetchUsers,
  fetchOrganizationGroups,
  shouldDisplaySpinner,
  setModalData,
  resetUsers,
})(UsersComponent);
