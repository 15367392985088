import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Group } from '@visx/group';
import { hierarchy, Tree } from '@visx/hierarchy';
import { LinkHorizontal } from '@visx/shape';
import { Zoom } from '@visx/zoom';
import { RectClipPath } from '@visx/clip-path';
import { useSingleSelector } from 'shared/hooks/useSelector';
import useActions from 'shared/hooks/useActions';
import { get as lodashGet } from 'lodash';
import { useHistory } from 'react-router-dom';

import * as actions from './actions';
import * as selectors from './selectors';

const minDisplayableWidth = 10;
const fontSize = 10;
const textYShift = '0.33em';
const zoomIn = { scaleX: 1.2, scaleY: 1.2 };
const zoomOut = { scaleX: 0.8, scaleY: 0.8 };
const maxXScale = 4;
const maxYScale = 4;
const minXScale = 0.25;
const minYScale = 0.25;
const defaultRectHeight = 20;
const defaultRectWidth = 100;
const defaultCircleRadious = 30;

const pixelFontRatio = 8 / 6;
const pixelsInChar = fontSize / pixelFontRatio;
const maxCharsInDefaultRect = Math.floor(defaultRectWidth / pixelsInChar);
const maxCharsInDefaultDiameter = Math.floor((defaultCircleRadious * 2) / pixelsInChar);

const initialTransform = {
  scaleX: 1,
  scaleY: 1,
  translateX: 200,
  translateY: 0,
  skewX: 0,
  skewY: 0,
};

function getTree(hierarchyTree, sizeWidth, sizeHeight, buildOnNodeClick, clickable = true) {
  function getRectWidth(chars) {
    let totalWidth = defaultRectWidth;
    if (chars > maxCharsInDefaultRect) {
      totalWidth += (chars - maxCharsInDefaultRect) * pixelsInChar;
    }
    return totalWidth;
  }

  function getRadious(chars) {
    let totalRadious = defaultCircleRadious;
    if (chars > maxCharsInDefaultDiameter) {
      totalRadious += ((chars - maxCharsInDefaultDiameter) * pixelsInChar) / 2;
    }
    return totalRadious;
  }

  function buildOnClick(node) {
    if (!clickable) {
      return null;
    }
    const nodeId = lodashGet(node, 'data.id');
    return buildOnNodeClick(nodeId);
  }

  function generateKey(prefix, index) {
    return `${prefix}-${index}-${new Date().getTime()}`;
  }

  const origin = { x: 0, y: 0 };

  return (
    <Tree
      root={hierarchy(hierarchyTree, (nodeData) =>
        nodeData.isExpanded ? null : nodeData.children,
      )}
      size={[sizeWidth, sizeHeight * 1.5]}
    >
      {(tree) => (
        <Group top={origin.y} left={origin.x}>
          {tree.links().map((link, index) => (
            <LinkHorizontal className="tree-link" key={generateKey('link', index)} data={link} />
          ))}

          {tree.descendants().map((node, index) => {
            const width = getRectWidth(lodashGet(node, 'data.name.length', 0));
            const rectOrigin = { x: -width / 2, y: -defaultRectHeight / 2 };

            return (
              <Group top={node.x} left={node.y} key={generateKey('node', index)}>
                {node.depth === 0 && (
                  <circle
                    className="tree-node"
                    r={getRadious(lodashGet(node, 'data.name.length', 0))}
                    onClick={buildOnClick(node)}
                  />
                )}
                {node.depth !== 0 && (
                  <rect
                    className="tree-node"
                    height={defaultRectHeight}
                    width={width}
                    y={rectOrigin.y}
                    x={rectOrigin.x}
                    onClick={buildOnClick(node)}
                  />
                )}
                <text
                  className="tree-node__text"
                  dy={textYShift}
                  fontSize={fontSize}
                  onClick={buildOnClick(node)}
                >
                  {lodashGet(node, 'data.name', '')}
                </text>
              </Group>
            );
          })}
        </Group>
      )}
    </Tree>
  );
}

export default function TreeChart({ width: totalWidth, height: totalHeight, margin }) {
  const innerWidth = totalWidth - margin.left - margin.right;
  const innerHeight = totalHeight - margin.top - margin.bottom;

  const sizeWidth = innerWidth;
  const sizeHeight = innerHeight;

  const clipExtents = {
    width: totalWidth * 2,
    height: totalHeight * 2,
  };
  const minimapOrigin = {
    x: initialTransform.translateX * -1,
    y: 0,
  };

  const fetchTree = useActions(actions.fetchTree);
  const hierarchyTree = useSingleSelector(selectors.tree);

  const history = useHistory();
  const buildOnNodeClick = (nodeId) => () => history.push(`/hierarchy/${nodeId}`);

  useEffect(() => {
    fetchTree();
  }, []);

  return totalWidth < minDisplayableWidth || !hierarchyTree ? null : (
    <Zoom
      width={totalWidth}
      height={totalHeight}
      scaleXMin={minXScale}
      scaleXMax={maxXScale}
      scaleYMin={minYScale}
      scaleYMax={maxYScale}
      wheelDelta={(event) => (-event.deltaY > 0 ? zoomIn : zoomOut)}
      transformMatrix={initialTransform}
    >
      {(zoom) => (
        <div>
          <div className="zoom-controls">
            <button
              className="btn btn-primary btn--spaced"
              type="button"
              onClick={() => zoom.scale(zoomOut)}
            >
              -
            </button>
            <button
              className="btn btn-primary btn--spaced"
              type="button"
              onClick={() => zoom.scale(zoomIn)}
            >
              +
            </button>
            <button className="btn btn-primary btn--spaced" type="button" onClick={zoom.reset}>
              Reset Zoom
            </button>
          </div>
          <svg width={totalWidth} height={totalHeight}>
            <RectClipPath
              id="zoom-clip"
              width={clipExtents.width}
              height={clipExtents.height}
              x={minimapOrigin.x}
            />
            <rect
              className="tree-background"
              width={totalWidth}
              height={totalHeight}
              rx={14}
              onTouchStart={zoom.dragStart}
              onTouchMove={zoom.dragMove}
              onTouchEnd={zoom.dragEnd}
              onMouseDown={zoom.dragStart}
              onMouseMove={zoom.dragMove}
              onMouseUp={zoom.dragEnd}
              onMouseLeave={() => {
                if (zoom.isDragging) zoom.dragEnd();
              }}
            />
            <Group top={margin.top} left={margin.left} transform={zoom.toString()}>
              {getTree(hierarchyTree, sizeWidth, sizeHeight, buildOnNodeClick)}
            </Group>
            <Group
              clipPath="url(#zoom-clip)"
              transform={`
              scale(0.25)
              translate(${totalWidth * 3}, ${totalHeight * 2})
            `}
            >
              <rect
                className="minimap-background"
                width={totalWidth * 2}
                height={totalHeight * 2}
                transform={`translate(${minimapOrigin.x}, 0)`}
              />
              {getTree(hierarchyTree, sizeWidth, sizeHeight, buildOnNodeClick, false)}
              <rect
                className="minimap-reference"
                width={totalWidth}
                height={totalHeight}
                transform={zoom.toStringInvert()}
              />
            </Group>
          </svg>
        </div>
      )}
    </Zoom>
  );
}

TreeChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  margin: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
  }),
};

TreeChart.defaultProps = {
  margin: { top: 30, left: 30, right: 30, bottom: 70 },
};
