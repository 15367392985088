import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { usePathname } from 'shared/hooks/useLocation';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { authUser } from '../../actions/auth.actions';
import FooterComponent from '../shared/footer.component';
import LoginComponent from './login.component';
import ForgotPassword from './forgot-password.component';
import ResetPassword from './reset-password.component';
import Logo from '../shared/logo.component';

const Authentication = (props) => {
  const { company, label, user } = props;
  const { logoLogin } = company;
  const pathname = usePathname();
  const [isLoggedOut] = useLocalStorage('loggedOut', false);
  const [showError, setShowError] = useState(false);
  const myPaths = new Set(['/', '/forgot-password', '/reset-password']);

  const GoBackToLogin = () => (
    <React.Fragment>
      <hr />

      <div className="row">
        <div className="col-12 text-primary text-center">
          <a className="text-primary medium-text" href={company.shopUrl}>
            Go back to shop &raquo;
          </a>
        </div>
      </div>
    </React.Fragment>
  );

  useEffect(() => {
    if (!myPaths.has(pathname) && !isLoggedOut) {
      setShowError(true);
    }
  }, []);

  return (
    <div className="body-content">
      <div className="main-content">
        <div className="login">
          <div className="container">
            {showError && (
              <div className="row justify-content-center">
                <p className="login__error col-12 col-sm-10 col-md-9 col-lg-8 col-xl-7">
                  Oops, something went wrong. <br /> Please log in below to access this website.
                </p>
              </div>
            )}
            <div className="row justify-content-center">
              <div className="login__screen col-12 col-sm-10 col-md-9 col-lg-8 col-xl-7 p-4">
                <div className="login__header">
                  <Logo src={logoLogin} alt={label} size="medium" />
                  <p className="login__title bold">Management Control Center</p>
                </div>

                <Switch>
                  <Route exact path="/forgot-password">
                    <ForgotPassword />
                  </Route>
                  <Route exact path="/reset-password">
                    <ResetPassword />
                    <GoBackToLogin />
                  </Route>
                  <Route path="/">
                    <LoginComponent user={user} />
                    <GoBackToLogin />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <FooterComponent />
        </div>
      </div>
    </div>
  );
};

Authentication.defaultProps = {
  user: '',
  label: '',
};

Authentication.propTypes = {
  company: PropTypes.shape({
    logoLogin: PropTypes.string.isRequired,
    shopUrl: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string,
  user: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  company: state.globals.company,
  displaySpinner: state.globals.shouldDisplaySpinner,
});

export default connect(mapStateToProps, {
  authUser,
})(Authentication);
