import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchOrders } from '../../actions/orders.actions';
import { fetchSites } from '../../actions/sites.actions';
import { fetchUsers } from '../../actions/users.actions';
import { fetchAlerts, fetchTriggers } from '../../actions/alerts.actions';
import { shouldDisplaySpinner } from '../../actions/globals.actions';
import { fetchTableNodes } from '../../scenes/hierarchy/components/table/actions';
import { fetchTableGroups } from '../../scenes/groups/components/table/actions';
import { fetchTablePolicies } from '../../shared/actions/hierarchy';

class PaginationComponent extends Component {
  static propTypes = {
    match: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
    shouldDisplaySpinner: PropTypes.func.isRequired,
    orders: PropTypes.shape().isRequired,
    fetchOrders: PropTypes.func.isRequired,
    sites: PropTypes.shape().isRequired,
    fetchSites: PropTypes.func.isRequired,
    triggers: PropTypes.shape().isRequired,
    fetchTriggers: PropTypes.func.isRequired,
    alerts: PropTypes.shape().isRequired,
    fetchAlerts: PropTypes.func.isRequired,
    users: PropTypes.shape().isRequired,
    fetchUsers: PropTypes.func.isRequired,
    nodes: PropTypes.object.isRequired,
    policies: PropTypes.object.isRequired,
    groups: PropTypes.object.isRequired,
    fetchNodes: PropTypes.func.isRequired,
    fetchGroups: PropTypes.func.isRequired,
    fetchPolicies: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      pagination: {
        next: null,
        previous: null,
        currentPage: 1,
        paginationLinks: [],
        shouldDisplayPagination: false,
        showingPageDown: 0,
        showingPageUp: 0,
      },
      fetchCB: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      match,
      fetchOrders,
      fetchSites,
      fetchTriggers,
      fetchAlerts,
      fetchUsers,
      fetchNodes,
      fetchGroups,
      fetchPolicies,
    } = this.props;
    const { path } = match;

    switch (path) {
      case '/orders':
        this.setState({
          pagination: { ...nextProps.orders.pagination },
          fetchCB: fetchOrders,
        });
        break;
      case '/sites':
        this.setState({
          pagination: { ...nextProps.sites.pagination },
          fetchCB: fetchSites,
        });
        break;
      case '/alerts/configure':
        this.setState({
          pagination: { ...nextProps.triggers.pagination },
          fetchCB: fetchTriggers,
        });
        break;
      case '/alerts':
        this.setState({
          pagination: { ...nextProps.alerts.pagination },
          fetchCB: fetchAlerts,
        });
        break;
      case '/users':
        this.setState({
          pagination: { ...nextProps.users.pagination },
          fetchCB: fetchUsers,
        });
        break;
      case '/hierarchy':
        this.setState({
          pagination: { ...nextProps.nodes.pagination },
          fetchCB: fetchNodes,
        });
        break;
      case '/hierarchy/groups':
        this.setState({
          pagination: { ...nextProps.groups.pagination },
          fetchCB: fetchGroups,
        });
        break;
      case '/hierarchy/policies':
        this.setState({
          pagination: { ...nextProps.policies.pagination },
          fetchCB: fetchPolicies,
        });
        break;
      default:
        break;
    }
  }

  renderPrevLink = () => {
    const { pagination } = this.state;
    const { previous, currentPage } = pagination;

    return (
      <li className={previous ? 'pagination__item' : 'pagination__item--disabled'}>
        <button
          type="button"
          className={`pagination__btn ${!previous ? 'btn--disabled' : ''}`}
          tabIndex="-1"
          onClick={() => this.handlePaginationChange(previous, currentPage - 1)}
        >
          Previous
        </button>
      </li>
    );
  };

  renderNextLink = () => {
    const { pagination } = this.state;
    const { next, currentPage } = pagination;

    return (
      <li className={next ? 'pagination__item' : 'pagination__item--disabled'}>
        <button
          type="button"
          className={`pagination__btn ${!next ? 'btn--disabled' : ''}`}
          onClick={() => this.handlePaginationChange(next, currentPage + 1)}
        >
          Next
        </button>
      </li>
    );
  };

  getPageClassName = (page) => {
    const { pagination } = this.state;
    const { currentPage, pagesToShow, pageCount } = pagination;
    let buttonCSS = 'pagination__btn';

    if (page === 1 && currentPage > Math.floor(pagesToShow / 2) + 1) {
      buttonCSS += ' pagination__btn-head';
      return buttonCSS;
    }

    if (page === pageCount && currentPage < pageCount - Math.floor(pagesToShow / 2) - 1) {
      buttonCSS += ' pagination__btn-last';
    }
    return buttonCSS;
  };

  async handlePaginationChange(url) {
    const { fetchCB } = this.state;
    const { user, shouldDisplaySpinner } = this.props;

    if (url && user) {
      await shouldDisplaySpinner(true);
      await fetchCB(url);
      await shouldDisplaySpinner(false);
    }
  }

  render() {
    const { pagination } = this.state;
    const { shouldDisplayPagination } = pagination;
    const { paginationLinks, showingPageDown, showingPageUp, count } = pagination;

    return (
      <Fragment>
        {shouldDisplayPagination && Array.isArray(paginationLinks) ? (
          <div className="pagination-wrapper">
            <div className="pagination-info">
              {`Showing ${showingPageDown} - ${showingPageUp} of ${count} `}
            </div>

            <ul className="pagination pagination-sm">
              {this.renderPrevLink()}

              {paginationLinks.map((paginationLink) =>
                paginationLink.active ? (
                  <li key={paginationLink.page} className="pagination__item">
                    <button type="button" className="pagination__btn--active">
                      {paginationLink.page}
                    </button>
                  </li>
                ) : (
                  <li key={paginationLink.page} className="pagination__item">
                    <button
                      type="button"
                      onClick={() => this.handlePaginationChange(paginationLink.url)}
                      className={`${this.getPageClassName(paginationLink.page)}`}
                    >
                      {paginationLink.page}
                    </button>
                  </li>
                ),
              )}

              {this.renderNextLink()}
            </ul>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  displaySpinner: state.globals.shouldDisplaySpinner,
  activeSection: state.globals.activeSection,
  orders: state.orders.orders,
  sites: state.sites.sites,
  users: state.users.users,
  alerts: state.alerts.alerts,
  triggers: state.alerts.triggers,
  nodes: state.hierarchy.table,
  policies: state.hierarchy.policiesTable,
  groups: state.hierarchy.groups,
});

export default connect(mapStateToProps, {
  fetchOrders,
  fetchSites,
  shouldDisplaySpinner,
  fetchUsers,
  fetchAlerts,
  fetchTriggers,
  fetchNodes: fetchTableNodes,
  fetchGroups: fetchTableGroups,
  fetchPolicies: fetchTablePolicies,
})(withRouter(PaginationComponent));
