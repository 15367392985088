import React, { useCallback } from 'react';
import ProptTypes from 'prop-types';
import { toast } from 'react-toastify';

import * as hierarchyActions from '../table/actions';
import useActions from '../../../../shared/hooks/useActions';
import siteManagerAPI from '../../../../lib/api-manager';
import { API_HIERARCHY_GROUPS } from '../../../../lib/api-endpoints';
import GroupForm from '../group-form/index';
import { GROUP_SUCCESSFULLY_CREATED } from '../../../../constants/messages';
import { CREATE_GROUP_ERROR } from '../../../../constants/error-messages';

function CreateGroup(props) {
  const { closeModal } = props;
  const fetchGroups = useActions(hierarchyActions.fetchTableGroups);

  const handleOnSubmit = useCallback(
    async (data) => {
      try {
        await siteManagerAPI.post(API_HIERARCHY_GROUPS, data);
        toast.success(GROUP_SUCCESSFULLY_CREATED);
      } catch (error) {
        const groupError = error.response.data;

        if (groupError) {
          throw groupError;
        } else {
          toast.error(CREATE_GROUP_ERROR);
        }
      }
      closeModal();
      fetchGroups();
    },
    [closeModal, fetchGroups],
  );

  return <GroupForm onSubmit={handleOnSubmit} />;
}

CreateGroup.propTypes = {
  closeModal: ProptTypes.func.isRequired,
};

export default CreateGroup;
