import { toast } from 'react-toastify';

import * as hierarchyUtils from 'shared/utils/hierarchy';
import siteManagerAPI from '../../../lib/api-manager';
import {
  API_HIERARCHY_NODES,
  API_HIERARCHY_GROUPS,
  API_UNASSIGNED_USERS,
  API_UNASSIGNED_SITES,
  API_NODE_CONFIGURATION,
} from '../../../lib/api-endpoints';
import { UNLIMITED_LIST_LIMIT } from '../../../constants/values';
import {
  HIERARCHY_SAVE_NODES,
  HIERARCHY_SAVE_GROUPS,
  HIERARCHY_SAVE_USERS,
  HIERARCHY_SAVE_SITES,
  HIERARCHY_SAVE_CURRENT_NODE,
  HIERARCHY_UPDATE_CURRENT_NODE,
} from '../../../lib/types';
import {
  FETCH_NODES_ERROR,
  FETCH_GROUPS_ERROR,
  FETCH_SITES_ERROR,
  FETCH_USERS_ERROR,
  FETCH_NODE_CONFIGURATION_ERROR,
} from '../../../constants/error-messages';

export const fetchNodes = () => async (dispatch) => {
  try {
    const response = await siteManagerAPI.get(`${API_HIERARCHY_NODES}?limit=1000000`);
    const nodes = response.data.results;
    dispatch({ type: HIERARCHY_SAVE_NODES, payload: { nodes } });
  } catch (error) {
    toast.error(FETCH_NODES_ERROR);
  }
};

export const fetchGroups = () => async (dispatch) => {
  try {
    // TODO: We have actions duplicated and this is starting to happen more, need to fix it
    const response = await siteManagerAPI.get(API_HIERARCHY_GROUPS);
    const groups = response.data.results;
    dispatch({ type: HIERARCHY_SAVE_GROUPS, payload: { groups } });
  } catch (error) {
    toast.error(FETCH_GROUPS_ERROR);
  }
};

export const fetchSites = () => async (dispatch) => {
  try {
    const response = await siteManagerAPI.get(
      `${API_UNASSIGNED_SITES}?limit=${UNLIMITED_LIST_LIMIT}`,
    );
    const sites = response.data.results;
    dispatch({ type: HIERARCHY_SAVE_SITES, payload: { sites } });
  } catch (error) {
    toast.error(FETCH_SITES_ERROR);
  }
};

export const fetchUsers = () => async (dispatch) => {
  try {
    const response = await siteManagerAPI.get(
      `${API_UNASSIGNED_USERS}?limit=${UNLIMITED_LIST_LIMIT}`,
    );
    const users = response.data.results;
    dispatch({ type: HIERARCHY_SAVE_USERS, payload: { users } });
  } catch (error) {
    toast.error(FETCH_USERS_ERROR);
  }
};

export const fetchCurrentNode = (id) => async (dispatch) => {
  try {
    const response = await siteManagerAPI.get(`${API_HIERARCHY_NODES}${id}`);
    const node = hierarchyUtils.formatNodeFromAPI(response.data);
    dispatch({ type: HIERARCHY_SAVE_CURRENT_NODE, payload: { node } });
  } catch (error) {
    toast.error(FETCH_NODES_ERROR);
  }
};

export const fetchCurrentNodeConfig = (id) => async (dispatch) => {
  try {
    const response = await siteManagerAPI.get(API_NODE_CONFIGURATION.replace('{id}', id));
    const customConfig = hierarchyUtils.formatNodeConfigFromAPI(response.data);
    dispatch({ type: HIERARCHY_UPDATE_CURRENT_NODE, payload: { customConfig } });
  } catch (error) {
    if (error.response.status > 400) toast.error(FETCH_NODE_CONFIGURATION_ERROR);
  }
};
