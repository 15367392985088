import React from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { last } from 'lodash';
import siteManagerAPI from './api-manager';
import { API_ALERTS } from './api-endpoints';

class AlertManager {
  constructor() {
    this.frequency = 20000;
    this.isUpdating = true;
    this.token = '';
    this.alerts = [];
    this.lastId = null;
  }

  init(token, alerts) {
    this.token = token;
    this.alerts = alerts;
  }

  start() {
    this.isUpdating = true;
    this.update();
  }

  stop() {
    this.isUpdating = false;
  }

  setToken(token) {
    this.token = token;
  }

  setLastId = (id) => {
    this.lastId = id;
  };

  setNewFrequency(frequency) {
    this.frequency = frequency;
  }

  async update() {
    let lastAlert = last(this.alerts);
    lastAlert = lastAlert === undefined ? 0 : lastAlert;
    const endpoint = `${API_ALERTS}${lastAlert.id}`;

    if (this.lastId === lastAlert.id || lastAlert.id === undefined) {
      return;
    }
    let response = {};

    try {
      response = await siteManagerAPI.get(endpoint);
    } catch (error) {
      console.log(error);
    }
    this.setLastId(response.data.id);
    toast(
      <div className="text-primary ">
        <FontAwesomeIcon icon="exclamation-circle" />
        {/* eslint-disable */}
        {' '}
        {response.data.content}
        {/* eslint-enable */}
      </div>,
    );
    if (this.isUpdating) {
      setTimeout(() => this.update(), this.frequency);
    }
  }
}

const alertManager = new AlertManager();

export default alertManager;
