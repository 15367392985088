import React, { useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import $ from 'jquery';

import { API_ORDERS_REJECT } from '../../lib/api-endpoints';
import { getFieldValue } from '../../utils/forms';
import siteManagerAPI from '../../lib/api-manager';
import { formatFixNumber } from '../../utils/format';

function RejectOrderModal({ orderIdentifier, orderTotal, fetchOrders }) {
  const [{ note }, setFormValues] = useState({
    note: '',
  });

  const submitRejectForm = async (event) => {
    event.preventDefault();

    try {
      await siteManagerAPI.post(
        API_ORDERS_REJECT.replace('{orderIdentifier}', orderIdentifier),
        {
          note,
        }
      );
      $('#shared-modal').modal('hide');
      fetchOrders();
      toast.success('Order rejected');
      $('#buttons-approve-reject-order').hide();
    } catch (error) {
      if (error.response.status === 409) {
        toast.error('You already have approved this order');
      } else {
        toast.error('There was an error trying to reject the order');
      }
    }
  };

  return (
    <form
      className="form-input text-primary text-medium mb-2 clearfix"
      onSubmit={submitRejectForm}
    >
      <span className="d-block mb-2">
        Please introduce the reason to reject order{' '}
        <span className="bold">{orderIdentifier}</span> with total of{' '}
        <span className="bold">{`$${formatFixNumber(orderTotal)}`}</span>:
      </span>
      <div>
        <textarea
          name="note"
          className="medium-text reject-modal__textarea"
          onChange={({ target }) =>
            setFormValues((currentFormValues) => ({
              ...currentFormValues,
              ...getFieldValue(target),
            }))
          }
        />
      </div>
      <button
        type="submit"
        data-placement="right"
        className="btn btn-primary float-right"
      >
        Submit
      </button>
    </form>
  );
}

RejectOrderModal.propTypes = {
  orderIdentifier: PropTypes.string.isRequired,
  orderTotal: PropTypes.number.isRequired,
  fetchOrders: PropTypes.func,
};

RejectOrderModal.defaultProps = {
  fetchOrders: () => {},
};

export default RejectOrderModal;
