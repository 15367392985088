import siteManagerAPI from '../lib/api-manager';

import {
  ALERTS_FETCH_ALERTS,
  ALERTS_FETCH_ALERTS_TRIGGERS,
  GLOBALS_SET_IS_TABLE_LOADING,
} from '../lib/types';
import AlertsSerializer from '../lib/serializers/AlertsSerializer';
import AlertsTriggerSerializer from '../lib/serializers/AlertsTriggerSerializer';

export const fetchAlerts = (url, queryParams) => async (dispatch) => {
  let response = {};

  dispatch({
    type: GLOBALS_SET_IS_TABLE_LOADING,
    payload: true,
  });

  try {
    response = await siteManagerAPI.get(url, {
      params: queryParams,
    });
    response.data.queryParams = queryParams;
  } catch (error) {
    console.log(error);
  }
  const alerts = new AlertsSerializer(response.data);

  dispatch({
    type: ALERTS_FETCH_ALERTS,
    payload: alerts,
  });

  dispatch({
    type: GLOBALS_SET_IS_TABLE_LOADING,
    payload: false,
  });
};

export const fetchTriggers = (url, queryParams) => async (dispatch) => {
  let response = {};

  dispatch({
    type: GLOBALS_SET_IS_TABLE_LOADING,
    payload: true,
  });

  try {
    response = await siteManagerAPI.get(url, {
      params: queryParams,
    });
    response.data.queryParams = queryParams;
  } catch (error) {
    console.log(error);
  }
  const triggers = new AlertsTriggerSerializer(response.data);

  dispatch({
    type: ALERTS_FETCH_ALERTS_TRIGGERS,
    payload: triggers,
  });

  dispatch({
    type: GLOBALS_SET_IS_TABLE_LOADING,
    payload: false,
  });
};
