export const updateValidations = {
  user: {
    presence: { allowEmpty: false },
    type: 'object',
  },
  sites: {
    presence: { allowEmpty: true },
    type: 'array',
  },
};

export const createValidations = {
  user: {
    presence: { allowEmpty: false },
    type: 'object',
  },
  sites: {
    presence: { allowEmpty: false },
    type: 'array',
  },
};
