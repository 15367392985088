import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useValidation from 'shared/hooks/useValidation';
import { customConfig } from 'shared/components/nodes-form/validations';
import messages from './messages';
import NodeConfigurationFields from './nodeConfigurationFields';
import FormWrapper from '../../../../components/forms/form-wrapper.component';

function NodeConfigurationForm(props) {
  const { onSubmit, errors: errorProps } = props;
  const [configData, setConfigData] = useState({});
  const [errors, setErrors] = useState({});
  const validate = useValidation(customConfig);

  useEffect(() => {
    if (errorProps) setErrors({ ...errors, ...errorProps });
    else setErrors({});
  }, [setErrors, errorProps]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validate(configData);

    if (validationErrors) setErrors(validationErrors);
    else onSubmit(configData);
  };

  return (
    <FormWrapper>
      <form onSubmit={handleSubmit}>
        <NodeConfigurationFields errors={errors} dataHandler={setConfigData} />
        <button type="submit" className="btn btn-primary mt-2">
          {messages.submitButtonText}
        </button>
      </form>
    </FormWrapper>
  );
}

NodeConfigurationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

NodeConfigurationForm.defaultProps = {
  errors: null,
};

export default NodeConfigurationForm;
