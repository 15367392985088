import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import Slider from 'rc-slider';
import { Link } from 'react-router-dom';

import SectionHeaderComponent from 'components/shared/section-header.component';
import ModalComponent from 'components/shared/modal.component';
import {
  API_ALERTS,
  API_ORDERS,
  API_SITES_COUNT,
  API_PENDING_ORDERS_REQUIRES_APPROVAL_COUNT,
  API_PENDING_ORDERS_COUNT,
} from 'lib/api-endpoints';
import siteManagerAPI from 'lib/api-manager';
import AlertsSerializer from 'lib/serializers/AlertsSerializer';
import OrdersSerializer from 'lib/serializers/OrdersSerializers';

import CounterTile from './counter-tile';
import HomeOrdersComponent from './home-orders.component';
import HomeAlertsComponent from './home-alerts.component';

const SUMMARY_LIMIT = 5;
const DEFAULT_OPTION = 2;
const SLIDER_OPTIONS = [
  { label: 'Last 7 Days', days: 7 },
  { label: 'Last 14 Days', days: 14 },
  { label: 'Last 30 Days', days: 30 },
  { label: 'Last 180 Days', days: 180 },
  { label: 'Last 365 Days', days: 365 },
  { label: 'All Time', days: null },
];
const SLIDER_STYLES = {
  width: '100px',
};

const fetch = async (url, params, isLoadingSetter) => {
  let response;

  if (isLoadingSetter) isLoadingSetter(true);

  try {
    response = await siteManagerAPI.get(url, { params });
  } catch (error) {
    console.log( {error} );
  }

  if (isLoadingSetter) isLoadingSetter(false);

  return response?.data;
};

function ViewMoreButton({ objectName }) {
  return (
    <Link to={`/${objectName}`} title={`View all ${objectName}`} className="link-underlined">
      <h3 className="summary__title">
        {`Last ${SUMMARY_LIMIT} `}
        <strong>{objectName}</strong>
      </h3>
    </Link>
  );
}
ViewMoreButton.propTypes = {
  objectName: PropTypes.string.isRequired,
};

function HomeComponent({ user }) {
  const [
    {
      orders,
      alerts,
      ordersCount,
      alertsCount,
      sitesCount,
      pendingOrdersCount,
      requiresApprovalCount,
    },
    setState,
  ] = useState({
    orders: [],
    alerts: [],
    ordersCount: 0,
    alertsCount: 0,
    sitesCount: 0,
    pendingOrdersCount: 0,
    requiresApprovalCount: 0,
  });
  const [daysOffset, setDaysOffset] = useState(SLIDER_OPTIONS[DEFAULT_OPTION].days);

  const [isLastOrdersLoading, setIsLastOrdersLoading] = useState(false);
  const [isLastAlertsLoading, setIsLastAlertsLoading] = useState(false);

  const fetchAppData = async () => {
    const orderQueryParams = {
      limit: SUMMARY_LIMIT,
    };
    const alertsQueryParams = {
      limit: SUMMARY_LIMIT,
    };
    if (daysOffset !== null) {
      const end = moment().utc().add(1, 'days');
      const start = moment().utc().subtract(daysOffset, 'days');

      const startDate = start.startOf('day').format();
      const endDate = end.endOf('day').format();

      orderQueryParams.orderDate_after = startDate;
      orderQueryParams.orderDate_before = endDate;
      orderQueryParams.ordering = '-orderDate';

      alertsQueryParams.created_after = startDate;
      alertsQueryParams.created_before = endDate;
      alertsQueryParams.ordering = '-created';
    }

    const [
      fetchedOrders,
      fetchedAlerts,
      fetchedSitesCount,
      fetchedPendingOrders,
      fetchedRequiresApproval,
    ] = await Promise.all([
      fetch(API_ORDERS, orderQueryParams),
      fetch(API_ALERTS, alertsQueryParams),
      fetch(API_SITES_COUNT),
      fetch(API_PENDING_ORDERS_COUNT),
      fetch(API_PENDING_ORDERS_REQUIRES_APPROVAL_COUNT),
    ]);

    setState((prevState) => ({
      ...prevState,
      ordersCount: fetchedOrders.count,
      alertsCount: fetchedAlerts.count,
      sitesCount: fetchedSitesCount.count,
      pendingOrdersCount: fetchedPendingOrders.count,
      requiresApprovalCount: fetchedRequiresApproval.count,
    }));
  };

  const fetchLastData = async () => {
    const defaultQueryParams = {
      limit: SUMMARY_LIMIT,
    };
    const ordersQueryParams = {
      ...defaultQueryParams,
      ordering: '-orderDate',
    };
    const alertsQueryParams = {
      ...defaultQueryParams,
      ordering: '-created',
    };

    const [fetchedLastOrders, fetchedLastAlerts] = await Promise.all([
      fetch(API_ORDERS, ordersQueryParams, setIsLastOrdersLoading),
      fetch(API_ALERTS, alertsQueryParams, setIsLastAlertsLoading),
    ]);

    const serializedOrders = new OrdersSerializer(fetchedLastOrders);
    const serializedAlerts = new AlertsSerializer(fetchedLastAlerts);

    setState((prevState) => ({
      ...prevState,
      orders: serializedOrders.results,
      alerts: serializedAlerts.results,
    }));
  };

  useEffect(() => {
    fetchAppData();
  }, [daysOffset]);

  useEffect(() => {
    fetchLastData();
  }, []);

  const handleSlider = (value) => {
    setDaysOffset(SLIDER_OPTIONS[value].days);
  };

  return (
    <>
      <SectionHeaderComponent subtitle="Welcome" sectionLabel="Home" />
      <ModalComponent />
      <div className="content__container">
        <div className="container-fluid">
          <div className="tile-container">
            <CounterTile
              iconName="map-marker-alt"
              title="COMPANY"
              subTitle="SITES"
              redirect="/sites"
              count={sitesCount}
            />
            {user.isApprover && (
              <CounterTile
                iconName="archive"
                title="PENDING"
                subTitle="APPROVALS"
                redirect="/pending-approvals"
                count={pendingOrdersCount}
                warning={requiresApprovalCount > 0}
                warningTip={`${requiresApprovalCount} Order ${
                  requiresApprovalCount > 1 ? 's' : ''
                } Requires your Approval`}
              />
            )}
            <CounterTile
              iconName="file"
              title="IN COMPANY"
              subTitle="ORDERS"
              redirect="/orders"
              count={ordersCount}
            />
            <CounterTile
              iconName="exclamation-circle"
              title="SENT"
              subTitle="ALERTS"
              redirect="/alerts"
              count={alertsCount}
            />
          </div>
        </div>

        <div className="container-fluid slider">
          <Slider
            min={0}
            max={SLIDER_OPTIONS.length - 1}
            defaultValue={DEFAULT_OPTION}
            marks={{
              ...SLIDER_OPTIONS.map((val) => ({
                ...val,
                style: SLIDER_STYLES,
              })),
            }}
            step={null}
            onChange={handleSlider}
          />
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="summary col">
              <ViewMoreButton objectName="orders" />

              <HomeOrdersComponent orders={orders} isLoading={isLastOrdersLoading} />
            </div>

            <div className="summary col">
              <ViewMoreButton objectName="alerts" />

              <HomeAlertsComponent alerts={alerts} isLoading={isLastAlertsLoading} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

HomeComponent.propTypes = {
  user: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(HomeComponent);
