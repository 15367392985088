import { toast } from 'react-toastify';

import siteManagerAPI from 'lib/api-manager';
import { API_HIERARCHY_NODES } from 'lib/api-endpoints';
import { NODE_SUCCESSFULLY_CREATED } from 'constants/messages';
import { CREATE_NODES_ERROR } from 'constants/error-messages';

export const createNode = async (node) => {
  let response = {};
  try {
    response = await siteManagerAPI.post(API_HIERARCHY_NODES, node);
    toast.success(NODE_SUCCESSFULLY_CREATED);
  } catch (error) {
    toast.error(CREATE_NODES_ERROR);
  }
  return response.data;
};
