import React from 'react';
import PropTypes from 'prop-types';

const RadioButtonGroup = ({ label, name, options, handleChange, error, currentValue }) => {
  return (
    <div className="form-group radio-button-group">
      <label className="medium-text">{label}</label>
      {options.map((option, i) => {
        return (
          <div className="radio-button" key={`key-${i + 1}`}>
            <input
              id={`radio-button-${name}-${i}`}
              type="radio"
              name={`radio-button-${name}`}
              value={option.value}
              defaultChecked={currentValue === option.value}
              onChange={handleChange}
            />
            <label className="text-base" htmlFor={`radio-button-${name}-${i}`}>
              {option.label}
            </label>
          </div>
        );
      })}
      {error && (
        <div className="form-field__error small-text">
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

RadioButtonGroup.propTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  currentValue: PropTypes.string.isRequired,
  error: PropTypes.string,
};

RadioButtonGroup.defaultProps = {
  error: '',
};

export default RadioButtonGroup;
