import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class TextInput extends Component {
  static propTypes = {
    type: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    handleChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    placeHolder: PropTypes.string,
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    maxLength: PropTypes.number,
  };

  static defaultProps = {
    handleChange: () => {},
    type: 'text',
    required: false,
    error: '',
    readOnly: false,
    placeHolder: '',
    labelClassName: '',
    inputClassName: '',
    maxLength: 255,
  };

  constructor(props) {
    super(props);
    this.state = {
      inputType: props.type,
    };
  }

  toggleField() {
    const { type } = this.props;

    if (type === 'password') {
      const { inputType } = this.state;
      this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
    }
    return type;
  }

  render() {
    const { inputType } = this.state;
    const {
      type,
      error,
      value,
      required,
      label,
      name,
      handleChange,
      readOnly,
      placeHolder,
      labelClassName,
      inputClassName,
      maxLength,
    } = this.props;

    return (
      <div className="form-field form-group">
        <label
          className={`medium-text ${labelClassName} ${
            required ? 'form-field__label--required' : ''
          }`}
          htmlFor={`input-${name}`}
        >
          {label}
        </label>

        <input
          onChange={handleChange}
          value={value}
          name={name}
          id={`input-${name}`}
          type={inputType}
          readOnly={readOnly}
          className={`form-control medium-text ${inputClassName}`}
          placeholder={placeHolder || label}
          maxLength={maxLength}
        />
        {type === 'password' && (
          <span
            role="button"
            tabIndex={0}
            onClick={() => this.toggleField()}
            onKeyDown={() => this.toggleField()}
          >
            <FontAwesomeIcon
              icon={inputType !== 'password' ? 'eye-slash' : 'eye'}
              className="float-right form-field--show-password"
            />
          </span>
        )}

        {error && (
          <div className="form-field__error small-text">
            <span>{error}</span>
          </div>
        )}
      </div>
    );
  }
}

export default TextInput;
