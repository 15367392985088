import { toast } from 'react-toastify';

import siteManagerAPI from 'lib/api-manager';
import { API_ADMIN_COMPANIES_DETAIL } from 'lib/api-endpoints';
import { ADMIN_SET_CURRENT_COMPANY } from 'lib/types';
import {
  FETCH_ADMIN_COMPANY_DETAIL_ERROR,
  UPDATE_ADMIN_COMPANY_ERROR,
} from 'constants/error-messages';
import { ADMIN_COMPANY_SUCCESSFULLY_UPDATED } from 'constants/messages';
import CompanySerializer from 'lib/serializers/mcc-admin/CompanySerializer';
import { formatErrorResponse } from 'shared/utils/formatting';

export const fetchCompany = (id, params = {}) => async (dispatch) => {
  try {
    const url = API_ADMIN_COMPANIES_DETAIL.replace('{id}', id);
    const response = await siteManagerAPI.get(url, { params });
    const serializedCompany = new CompanySerializer(response.data);

    dispatch({ type: ADMIN_SET_CURRENT_COMPANY, payload: serializedCompany });
  } catch (error) {
    toast.error(FETCH_ADMIN_COMPANY_DETAIL_ERROR);
  }
};

export const editCompany = (id, data) => async () => {
  try {
    const url = API_ADMIN_COMPANIES_DETAIL.replace('{id}', id);
    await siteManagerAPI.put(url, data);

    toast.success(ADMIN_COMPANY_SUCCESSFULLY_UPDATED);
    return null;
  } catch (error) {
    toast.error(UPDATE_ADMIN_COMPANY_ERROR);
    return formatErrorResponse(error.response.data);
  }
};
