export default class ApprovalRuleSerializer {
  constructor(rawData) {
    const data = rawData || {};

    this.id = data.id;
    this.role = data.role;
    this.user = data.user;
    this.nodes = data.nodes;
    this.sites = data.sites;
    this.hasPendingApprovals = data.has_pending_approvals;
    this.limit = data.limit;
  }
}
