import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory } from 'react-router-dom';

import useActions from 'shared/hooks/useActions';
import SectionHeaderComponent from 'components/shared/section-header.component';
import BreadCrumbComponent from 'components/shared/breadcumb.component';
import BreadCrumbItem from 'components/shared/breadcrumb-item';

import CompanySerializer from 'lib/serializers/CompanySerializer';
import CompanyForm from '../company-form';
import messages from './messages';
import * as actions from './actions';

function AdminCreateCompany() {
  const history = useHistory();
  const { createCompany } = useActions(actions);
  const handleOnSubmit = useCallback(async (data) => {
    try {
      const response = await createCompany(data);
      if (response.errors) {
        return response;
      }
      const companyData = new CompanySerializer(response);
      history.push(`/companies/${companyData.id}`);
      return null;
    } catch (error) {
      return error;
    }
  }, []);

  return (
    <React.Fragment>
      <SectionHeaderComponent subtitle={messages.subtitle} sectionLabel={messages.title} />
      <div className="content__container">
        <BreadCrumbComponent>
          <BreadCrumbItem label={messages.breadcrumbCompaniesLabel} link="/companies" />
          <BreadCrumbItem label={messages.breadcrumbCreateCompanyLabel} />
        </BreadCrumbComponent>
        <div className="order-details__return-btn">
          <Link to={{ pathname: '/companies' }}>
            <FontAwesomeIcon icon="arrow-left" /> {messages.backButtonText}
          </Link>
        </div>
        <div className="edit-node__container">
          <CompanyForm
            onSubmit={handleOnSubmit}
            submitButtonText={messages.submitButtonText}
            clearOnSubmit
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default AdminCreateCompany;
