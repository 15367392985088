import PaginationSerializer from './PaginationSerializer';
import ApprovalRuleSerializer from './approval-rule';

export default class ApprovalRulesSerializer {
  constructor(rawData) {
    const data = rawData || {};

    this.pagination = new PaginationSerializer(data);
    this.data = Array.isArray(data.results)
      ? data.results.map((approvalRule) => new ApprovalRuleSerializer(approvalRule))
      : [];
  }
}
