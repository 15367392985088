export default class AlertTriggerSerializer {
  constructor(data) {
    const alertData = data || {};

    this.id = alertData.id;
    this.rule = alertData.rule;
    this.enabled = alertData.enabled;
    this.frequency = alertData.frequency;
    this.value = alertData.value;
    this.rangeValue = alertData.range_value;
    this.user = alertData.user;
    this.site = alertData.site;
  }
}
