export default {
  submitButtonText: 'Create',
  backButtonText: 'Return to companies',
  title: 'Create Company',
  subtitle: 'MCC Admin',
  breadcrumbCompaniesLabel: 'Companies',
  breadcrumbCreateCompanyLabel: 'Create Company',
  createCompanyError: 'There was an error while creating your company, try again later',
  createCompanySuccess: 'Company created',
};
