import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import {
  API_SITES,
  API_SITES_ASSIGN,
  API_USERS,
  API_SITES_SUGGESTIONS,
} from '../../lib/api-endpoints';
import { fetchUsers } from '../../actions/users.actions';
import { fetchSites } from '../../actions/sites.actions';
import { debounce } from '../../utils/general';
import { UNLIMITED_LIST_LIMIT } from '../../constants/values';
import SitesSerializer from '../../lib/serializers/SitesSerializer';
import siteManagerAPI from '../../lib/api-manager';
import TableFunctions from '../shared/table-functions';

const getHeaders = () => {
  const headers = [
    {
      text: 'Name',
      name: 'name',
      filterType: 'INPUT',
      isActivated: false,
      defaultAscendent: true,
    },
    {
      text: 'Business Unit',
      name: 'businessUnit',
      filterType: 'INPUT',
      isActivated: false,
    },
    {
      text: 'Division',
      name: 'division',
      filterType: 'INPUT',
      isActivated: false,
    },
    {
      text: 'Region',
      name: 'region',
      filterType: 'INPUT',
      isActivated: false,
    },
  ];

  return headers;
};
class AssignSiteComponent extends Component {
  static propTypes = {
    createdUser: PropTypes.shape({
      username: PropTypes.string,
      id: PropTypes.number,
    }).isRequired,
    sites: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    fetchUsers: PropTypes.func.isRequired,
    fetchSites: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    sitesQueryParams: PropTypes.shape(),
  };

  static defaultProps = {
    onSuccess: () => {},
    sitesQueryParams: {},
  };

  constructor(props) {
    super(props);
    this.handleFilterBy = debounce(this.handleFilterBy, 400);
    this.state = {
      sites: props.sites.map((site) => ({ ...site, isAssigned: false })),
      body: {},
      selectedSites: [],
    };
    this.state.headers = getHeaders();
  }

  componentDidMount() {
    this.fetchSitesNoPagination();
  }

  componentWillReceiveProps(nextProps) {
    const { sites } = nextProps;
    this.setState({ sites });
  }

  handleSiteCheck(event) {
    const { sites, selectedSites } = this.state;
    let selectedSitesUpdated = [...selectedSites];

    if (selectedSitesUpdated.find((site) => site.id === parseInt(event.target.value, 10))) {
      selectedSitesUpdated = selectedSites.filter(
        (site) => site.id !== parseInt(event.target.value, 10),
      );
    } else {
      const siteFound = sites.find((site) => site.id === parseInt(event.target.value, 10));
      selectedSitesUpdated.push(siteFound);
    }

    this.setState({
      body: { sites_list: selectedSitesUpdated },
      selectedSites: selectedSitesUpdated,
    });
  }

  async fetchSitesNoPagination(queryParams) {
    let response = {};

    try {
      response = await siteManagerAPI.get(
        `${API_SITES}?limit=${UNLIMITED_LIST_LIMIT}`,
        queryParams,
      );
    } catch (error) {
      console.log(error);
    }
    let sites = new SitesSerializer(response.data);
    sites = sites.results.map((site) => {
      const assignedSite = { ...site, isAssigned: false, assingedRole: 'Admin' }; // All created users are site-admins as today
      return assignedSite;
    });
    this.setState({ sites });
  }

  parseAssignBody() {
    const { sites } = this.state;

    const list = sites
      .filter((site) => site.isAssigned === true)
      .map((site) => {
        const assign = {};
        assign.name = site.name;
        assign.site = site.id;
        assign.is_admin = true;
        return assign;
      });
    const body = {
      sites_list: list,
    };
    this.setState({ body, selectedSites: list });
  }

  async assignSites() {
    const { fetchUsers, createdUser, onSuccess } = this.props;
    const { selectedSites } = this.state;
    const parsedBody = {
      sites_list: selectedSites.map((site) => {
        return { site: site.id, is_admin: true };
      }),
      user: createdUser.id,
    };

    if (parsedBody) {
      try {
        await siteManagerAPI.put(API_SITES_ASSIGN, parsedBody);
        await fetchUsers(API_USERS);
        toast.success('Sites assigned succesfully');
        onSuccess();
      } catch (error) {
        toast.error('There has been an error while assigning Sites to the User');
        onSuccess();
      }
    } else {
      onSuccess();
    }
  }

  isChecked(id) {
    const { selectedSites } = this.state;

    return selectedSites.some((site) => site.id === id);
  }

  cleanSelectedSites() {
    const { body, sites } = this.state;
    const sitesList = (body && body.sites_list) || [];

    const selectedSites = sitesList.filter((siteList) =>
      sites.some((site) => site.id === siteList.site),
    );
    this.setState({ body: { sites_list: selectedSites } });
  }

  removeSite(siteId) {
    const { selectedSites } = this.state;
    const selectedSitesRemoved = selectedSites.filter((siteList) => siteList.id !== siteId);

    this.setState({
      body: { sites_list: selectedSitesRemoved },
      selectedSites: selectedSitesRemoved,
    });
  }

  renderSelectCheckBox(site) {
    return (
      <div className="form-group form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id={`site_${site.id}`}
          value={site.id}
          checked={this.isChecked(site.id)}
          onChange={(event) => this.handleSiteCheck(event)}
        />
        <label className="form-check-label" htmlFor={`site_${site.id}`}>
          {site.name}
        </label>
      </div>
    );
  }

  render() {
    const { headers, sites, selectedSites } = this.state;
    const { fetchSites, sitesQueryParams } = this.props;

    const parsedSites = sites.map((site) => {
      const parsedSite = {
        id: site.id,
        name: { customCell: true, cell: site },
        businessUnit: site.businessUnit,
        division: site.division,
        region: site.region,
      };

      return parsedSite;
    });

    return (
      <Fragment>
        <div className="text-primary">
          Selected Items <b>({selectedSites.length})</b>
        </div>
        <div className="text-primary small-text mb-4">
          {selectedSites.map((selected) => (
            <span className="mr-2 d-inline-block" key={selected.site}>
              {selected.name}{' '}
              <button
                type="button"
                className="close remove-button text-primary"
                onClick={() => this.removeSite(selected.id)}
              >
                <FontAwesomeIcon icon="times-circle" />{' '}
              </button>
            </span>
          ))}
        </div>
        <form>
          <div className="assign-table">
            <TableFunctions
              headers={headers}
              rowsData={parsedSites}
              suggestionsURL={API_SITES_SUGGESTIONS}
              dataURL={API_SITES}
              fetchData={(dataURL, queryParams) => {
                queryParams.limit = UNLIMITED_LIST_LIMIT;
                fetchSites(dataURL, queryParams);
              }}
              tableQueryParams={sitesQueryParams}
              renderCustomCell={(cell) => this.renderSelectCheckBox(cell)}
            />
          </div>
        </form>
        <button
          type="button"
          onClick={() => this.assignSites()}
          className="btn btn-primary float-right"
        >
          <FontAwesomeIcon icon="save" />
          <span> SAVE SITES</span>
        </button>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  sitesQueryParams: state.sites.sites.pagination.queryParams || {},
  sites: state.sites.sites.results,
});

export default connect(mapStateToProps, {
  fetchUsers,
  fetchSites,
})(AssignSiteComponent);
