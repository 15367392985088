import React, { Fragment, PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import Iframe from 'react-iframe';

import { API_USER_ANALYTICS } from '../../lib/api-endpoints';
import SectionHeaderComponent from '../shared/section-header.component';
import siteManagerAPI from '../../lib/api-manager';

const DOMO_CLASSES = ['total-spend', 'open-orders', 'order-status', 'service-quality'];

class AnalyticsComponent extends PureComponent {
  static propTypes = {
    user: PropTypes.string.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      analytics: null,
      activeDashboard: 0,
    };
    this.myDashbordTabs = React.createRef();
  }

  async componentDidMount() {
    const { location, user } = this.props;
    const { search } = location;
    const tabParam = new URLSearchParams(search).get('tab');

    let response;
    const data = { username: user };

    try {
      response = await siteManagerAPI.post(`${API_USER_ANALYTICS}`, data);
      this.setState({
        analytics: response.data,
      });

      if (tabParam) {
        const dashboardTabs = this.myDashbordTabs.current;
        const selectedTab = dashboardTabs.querySelector(`li:nth-child(${tabParam})`);
        const anchor = selectedTab.querySelector('a');
        anchor.click();
        selectedTab.classList.add('active');
      }
    } catch (error) {
      console.log(error);
    }
  }

  renderAnalyticsIFrame = (analytics, activeDashboard) =>
    analytics.analytics_source === 'LOOKER' ? (
      <Iframe
        url={analytics.looker_dashboard_url}
        width="100%"
        height="2198px"
        scrolling="no"
        overflow="hidden"
        styles={{ borderWidth: '100px', borderColor: 'yellow', overflow: 'hidden' }}
      />
    ) : (
      <div
        dangerouslySetInnerHTML={this.renderDomoAnalytics({
          ...analytics.domo_dashboards[activeDashboard],
          modifierClass: DOMO_CLASSES[activeDashboard],
        })}
        className="w-100"
      />
    );

  renderDomoAnalytics = ({ url, token, modifierClass }) => ({
    __html: `<iframe srcdoc='
        <html>
            <body>
                <form id="analytics_form" action="${url}" method="post">
                    <input type="hidden" name="embedToken" value="${token}">
                </form>
                <script>
                    document.getElementById("analytics_form").submit();
                </script>
            </body>
        </html>' class="m-0 p-0 w-100 border-0 overflow-hidden domo-iframe--${modifierClass}" scrolling="no"></iframe>`,
  });

  selectActiveDashboard(e, index) {
    e.preventDefault();
    this.setState({ activeDashboard: index });
  }

  renderDomoTabs = (analytics, activeDashboard) => {
    const showDomoTabs =
      // eslint-disable-next-line camelcase
      analytics?.domo_dashboards.length > 1 && analytics.analytics_source === 'DOMO';
    if (!showDomoTabs) return null;
    return (
      <div className="dashboard-tabs" ref={this.myDashbordTabs}>
        <ul className="nav nav-tabs">
          {analytics.domo_dashboards.map((dashboard, index) => (
            <li className="nav-item" key={dashboard.label}>
              <a
                className={`nav-link ${activeDashboard === index ? 'active' : ''}`}
                onClick={(e) => this.selectActiveDashboard(e, index)}
                href={`?tab=${index + 1}`}
                data-target="#"
              >
                {dashboard.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  render() {
    const { analytics, activeDashboard } = this.state;
    return (
      <Fragment>
        <SectionHeaderComponent subtitle="Company" sectionLabel="Analytics" />

        <div className="content__container">
          <div className="container-fluid">
            {this.renderDomoTabs(analytics, activeDashboard)}
            <div className="row">
              {analytics ? this.renderAnalyticsIFrame(analytics, activeDashboard) : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user.ecommerce_username,
});

export default connect(mapStateToProps)(AnalyticsComponent);
