import { toast } from 'react-toastify';

import siteManagerAPI from 'lib/api-manager';
import {
  API_HIERARCHY_NODES,
  API_HIERARCHY_GROUPS,
  API_HIERARCHY_POLICIES_LIST,
  API_NODE_CONFIGURATION,
} from 'lib/api-endpoints';
import {
  HIERARCHY_SAVE_NODES,
  HIERARCHY_SAVE_GROUPS,
  HIERARCHY_SAVE_TABLE_POLICIES,
  HIERARCHY_UPDATE_CURRENT_NODE,
} from 'lib/types';
import {
  FETCH_NODES_ERROR,
  FETCH_GROUPS_ERROR,
  FETCH_POLICIES_LIST_ERROR,
  CREATE_NODE_CONFIGURATION_ERROR,
  DELETE_NODE_CONFIGURATION_ERROR,
  FETCH_NODE_CONFIGURATION_ERROR,
} from 'constants/error-messages';
import {
  NODE_CONFIGURATION_SUCCESSFULLY_CREATED,
  NODE_CONFIGURATION_SUCCESSFULLY_DELETED,
} from 'constants/messages';

import * as utils from '../utils/hierarchy';

export const fetchNodes = (limit = null) => async (dispatch) => {
  try {
    const params = {};
    if (limit) {
      params.limit = limit;
    }
    const response = await siteManagerAPI.get(API_HIERARCHY_NODES, { params });
    const nodes = response.data.results.map(utils.formatNodeFromAPI);
    dispatch({ type: HIERARCHY_SAVE_NODES, payload: { nodes } });
  } catch (error) {
    toast.error(FETCH_NODES_ERROR);
  }
};

export const fetchGroups = () => async (dispatch) => {
  try {
    const response = await siteManagerAPI.get(API_HIERARCHY_GROUPS);
    const groups = response.data.results;
    // TODO: We have actions duplicated and this is starting to happen more, need to fix it
    dispatch({ type: HIERARCHY_SAVE_GROUPS, payload: { groups } });
  } catch (error) {
    toast.error(FETCH_GROUPS_ERROR);
  }
};

export const fetchTablePolicies = (url = API_HIERARCHY_POLICIES_LIST, params) => async (
  dispatch,
) => {
  try {
    const response = await siteManagerAPI.get(url, { params });
    const table = utils.formatPoliciesListResponse(response.data);
    dispatch({ type: HIERARCHY_SAVE_TABLE_POLICIES, payload: { table } });
  } catch (error) {
    toast.error(FETCH_POLICIES_LIST_ERROR);
  }
};

export const createNodeConfig = (id, configuration) => async () => {
  try {
    await siteManagerAPI.post(API_NODE_CONFIGURATION.replace('{id}', id), configuration);
    toast.success(NODE_CONFIGURATION_SUCCESSFULLY_CREATED);
    return null;
  } catch (error) {
    toast.error(CREATE_NODE_CONFIGURATION_ERROR);
    return utils.formatNodeConfigError(error);
  }
};

export const deleteNodeConfig = (id) => async () => {
  try {
    await siteManagerAPI.delete(API_NODE_CONFIGURATION.replace('{id}', id));
    toast.success(NODE_CONFIGURATION_SUCCESSFULLY_DELETED);
  } catch (error) {
    if (error.response.status > 400) toast.error(DELETE_NODE_CONFIGURATION_ERROR);
  }
};

export const fetchCurrentNodeConfig = (id) => async (dispatch) => {
  try {
    const response = await siteManagerAPI.get(API_NODE_CONFIGURATION.replace('{id}', id));
    const customConfig = utils.formatNodeConfigFromAPI(response.data);
    dispatch({ type: HIERARCHY_UPDATE_CURRENT_NODE, payload: { customConfig } });
  } catch (error) {
    toast.error(FETCH_NODE_CONFIGURATION_ERROR);
  }
};
