import get from 'lodash/get';

import { createSelector } from 'reselect';

function orderDocumentsStateSelector(state) {
  return state.orderDocuments;
}

export const documents = createSelector(orderDocumentsStateSelector, (state) =>
  get(state, 'table.results', []),
);

export const paginationState = createSelector(orderDocumentsStateSelector, (state) =>
  get(state, 'table.pagination', {}),
);

export const queryParams = createSelector(orderDocumentsStateSelector, (state) =>
  get(state, 'table.pagination.queryParams', {}),
);
