import DocumentSerializer from './Document';
import PaginationSerializer from './PaginationSerializer';

export default class DocumentsSerializer {
  constructor(data) {
    const documentsData = data || {};

    this.results = Array.isArray(documentsData.results)
      ? documentsData.results.map((document) => new DocumentSerializer(document))
      : [];
    this.pagination = new PaginationSerializer(documentsData);
  }
}
