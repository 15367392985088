export default class CompanyConfigurationSerializer {
  constructor(rawData) {
    const data = rawData || {};

    this.catalogs = data.catalogs;
    this.hasOrderTracking = data.has_order_tracking;
    this.hasOrderApproval = data.has_order_approval;
    this.hasCompanyHierarchy = data.has_company_hierarchy;
    this.tenantGroup = data.tenant_group;
    this.customerERPIdentifier = data.customer_erp_identifier;
    this.hasOrderApproval = data.has_order_approval;
  }
}
