const GENERIC_TRY_LATER = 'There was an error. Please try again later';
const DELETE_USER_TRY_LATER = 'There was an error trying to delete the user. Please try again';
const FETCH_ROLES_TRY_LATER = 'There has been an error fetching roles';
const FETCH_ORDER_APPROVAL_RULES_TRY_LATER =
  'There has been an error fetching order approval rules';
const CREATE_ROLE_TRY_LATER = 'There has been an error creating the role';
const UPDATE_ROLE_TRY_LATER = 'There has been an error updating the role';
const CREATE_ORDER_APPROVAL_RULE_TRY_LATER =
  'There has been an error creating the order approval rule';
const CREATE_DUPLICATED_APPROVAL_RULE_ERROR =
  'Approval rule with this configuration already exists';
const UPDATE_ORDER_APPROVAL_RULE_TRY_LATER =
  'There has been an error updating the order approval rule';
const DELETE_ORDER_APPROVAL_RULE_TRY_LATER =
  'There has been an error deleting the order approval rule';
const DELETE_ROLE_TRY_LATER = 'There has been an error deleting the role';
const FETCH_ROLE_TRY_LATER = 'There has been an error fetching the role';

const FETCH_SITE_TYPES_TRY_LATER = 'There has been an error fetching site types';
const CREATE_SITE_TYPE_TRY_LATER = 'There has been an error creating the site type';
const UPDATE_SITE_TYPE_TRY_LATER = 'There has been an error updating the site type';
const DELETE_SITE_TYPE_TRY_LATER = 'There has been an error deleting the site type';
const FETCH_SITE_TYPE_TRY_LATER = 'There has been an error fetching the site type';

const FETCH_ORDER_APPROVALS_STATUS_ERROR =
  'There has been an error fetching order approvals status';
const ACTIVATE_ORDER_APPROVALS_STATUS_ERROR = 'There has been an error activating order approvals';
const DEACTIVATE_ORDER_APPROVALS_STATUS_ERROR =
  'There has been an error deactivating order approvals';
const ORDER_REMOVE_LINE_ERROR = 'There has been an error removing order line';
const ALREADY_A_ROLE_WITH_THIS_RULE = "There's already a Role with this Rule";

const FETCH_COMPANY_ORGANIZATION_GROUPS_TRY_LATER =
  'There has been an error fetching organization groups';
const CREATE_NODES_ERROR = 'There has been an error creating organization nodes';
const UPDATE_NODES_ERROR = 'There has been an error updating organization nodes';
const FETCH_NODES_ERROR = 'There has been an error fetching organization nodes';
const CREATE_NODE_CONFIGURATION_ERROR = 'There has been an error creating the node configuration';
const UPDATE_NODE_CONFIGURATION_ERROR = 'There has been an error updating the node configuration';
const DELETE_NODE_CONFIGURATION_ERROR = 'There has been an error deleting the node configuration';
const FETCH_NODE_CONFIGURATION_ERROR = 'There has been an error fetching the node configuration';
const FETCH_GROUPS_ERROR = 'There has been an error fetching organization groups';
const FETCH_SITES_ERROR = 'There has been an error fetching available sites';
const FETCH_USERS_ERROR = 'There has been an error fetching available users';
const FETCH_POLICIES_ERROR = 'There has been an error fetching current user policies';
const FETCH_POLICIES_LIST_ERROR = 'There has been an error fetching policies';
const FETCH_DOCUMENTS_ERROR = 'There has been an error fetching documents';
const CREATE_POLICY_ERROR = 'There has been an error creating the policy';
const UPDATE_POLICY_ERROR = 'There has been an error updating the policy';
const CREATE_GROUP_ERROR = 'There has been an error creating the Group';
const UPDATE_GROUP_ERROR = 'There has been an error updating the Group';
const FETCH_TREE_ERROR = 'There has been an error retrieving the Hierarchy Tree';
const FETCH_REGULAR_SITES_ERROR = 'There was an error fetching Sites';
const FETCH_REGULAR_USERS_ERROR = 'There was an error fetching Users';
const FETCH_ADMIN_COMPANIES_ERROR = 'There was an error fetching Companies';
const FETCH_ADMIN_COMPANY_ERROR = 'There was an error fetching the Company';
const FETCH_ADMIN_COMPANY_DETAIL_ERROR = 'There was an error fetching the Company';
const UPDATE_ADMIN_COMPANY_ERROR = 'There was an error updating the Company';
const CREATE_ADMIN_COMPANY_IMPORT_NODES_ERROR = 'There was an error creating the nodes';
const ADMIN_COMPANY_IMPORT_SITES_ERROR = 'There was an error creating Sites';
const FETCH_ADMIN_COMPANY_CONFIGURATION_ERROR =
  'There was an error fetching the Company Configuration';
const FETCH_WAREHOUSE_LIST_ERROR = 'There was an error fetching Warehouses';

export {
  FETCH_REGULAR_SITES_ERROR,
  FETCH_REGULAR_USERS_ERROR,
  GENERIC_TRY_LATER,
  DELETE_USER_TRY_LATER,
  FETCH_ROLES_TRY_LATER,
  FETCH_ORDER_APPROVAL_RULES_TRY_LATER,
  CREATE_ROLE_TRY_LATER,
  UPDATE_ROLE_TRY_LATER,
  CREATE_ORDER_APPROVAL_RULE_TRY_LATER,
  UPDATE_ORDER_APPROVAL_RULE_TRY_LATER,
  DELETE_ORDER_APPROVAL_RULE_TRY_LATER,
  DELETE_ROLE_TRY_LATER,
  FETCH_ROLE_TRY_LATER,
  FETCH_SITE_TYPES_TRY_LATER,
  CREATE_SITE_TYPE_TRY_LATER,
  UPDATE_SITE_TYPE_TRY_LATER,
  DELETE_SITE_TYPE_TRY_LATER,
  FETCH_SITE_TYPE_TRY_LATER,
  FETCH_ORDER_APPROVALS_STATUS_ERROR,
  ACTIVATE_ORDER_APPROVALS_STATUS_ERROR,
  DEACTIVATE_ORDER_APPROVALS_STATUS_ERROR,
  FETCH_NODES_ERROR,
  CREATE_NODES_ERROR,
  UPDATE_NODES_ERROR,
  FETCH_GROUPS_ERROR,
  FETCH_SITES_ERROR,
  FETCH_USERS_ERROR,
  CREATE_DUPLICATED_APPROVAL_RULE_ERROR,
  ALREADY_A_ROLE_WITH_THIS_RULE,
  FETCH_COMPANY_ORGANIZATION_GROUPS_TRY_LATER,
  FETCH_POLICIES_ERROR,
  CREATE_POLICY_ERROR,
  UPDATE_POLICY_ERROR,
  FETCH_POLICIES_LIST_ERROR,
  FETCH_DOCUMENTS_ERROR,
  CREATE_GROUP_ERROR,
  UPDATE_GROUP_ERROR,
  CREATE_NODE_CONFIGURATION_ERROR,
  UPDATE_NODE_CONFIGURATION_ERROR,
  FETCH_NODE_CONFIGURATION_ERROR,
  DELETE_NODE_CONFIGURATION_ERROR,
  FETCH_TREE_ERROR,
  FETCH_ADMIN_COMPANIES_ERROR,
  FETCH_ADMIN_COMPANY_ERROR,
  FETCH_ADMIN_COMPANY_DETAIL_ERROR,
  UPDATE_ADMIN_COMPANY_ERROR,
  FETCH_ADMIN_COMPANY_CONFIGURATION_ERROR,
  CREATE_ADMIN_COMPANY_IMPORT_NODES_ERROR,
  ADMIN_COMPANY_IMPORT_SITES_ERROR,
  FETCH_WAREHOUSE_LIST_ERROR,
  ORDER_REMOVE_LINE_ERROR,
};
