import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  API_SITE_USERS,
  API_SITES,
  API_SITES_SUGGESTIONS,
  API_SITES_V2,
} from 'lib/api-endpoints';
import { TABLE_FIELD_NAMES } from 'constants/values';

import { fetchSites as fetchSitesAction } from 'actions/sites.actions';
import { setModalData } from 'actions/globals.actions';
import { getOffsetPagination } from 'utils/general';
import { fetchSiteTypes as fetchSiteTypesAction } from 'actions/site-types';
import { fetchWarehouses as fetchWarehousesAction } from 'actions/warehouses.actions';
import siteManagerAPI from 'lib/api-manager';
import BrandModal from 'components/shared/brand-modal.component';
import TableFunctions from 'components/shared/table-functions';
import TableActions from 'components/shared/table-actions.component';
import TrimmedUnorderedList from 'components/shared/trimmed-unordered-list.component';
import ApprovalRuleConfirmationModal from 'components/shared/approval-rule-confirmation-modal.component';
import { sortDescendingText } from 'utils/format';

const getHeaders = ({ user, hasCompanyHierarchy }) =>
  _.compact([
    {
      text: TABLE_FIELD_NAMES.NAME,
      name: 'name',
      filterType: 'INPUT',
      isActivated: false,
      defaultAscendent: true,
    },
    user.company.hasLocationNameRequired && {
      text: TABLE_FIELD_NAMES.LOCATION_NAME,
      name: 'legacyLocationName',
      filterType: 'INPUT',
      isActivated: false,
    },
    {
      text: TABLE_FIELD_NAMES.TYPE,
      name: 'siteType',
      filterType: 'SELECT',
      isActivated: false,
    },
    !hasCompanyHierarchy && {
      text: TABLE_FIELD_NAMES.BUSINESS_UNIT_INITIALS,
      name: 'legacyBusinessUnit',
      filterType: 'INPUT',
      isActivated: false,
    },
    !hasCompanyHierarchy && {
      text: TABLE_FIELD_NAMES.DIVISION,
      name: 'legacyDivision',
      filterType: 'INPUT',
      isActivated: false,
    },
    !hasCompanyHierarchy && {
      text: TABLE_FIELD_NAMES.REGION,
      name: 'legacyRegion',
      filterType: 'INPUT',
      isActivated: false,
    },
    {
      text: TABLE_FIELD_NAMES.USERS,
      name: 'users',
      component: TrimmedUnorderedList,
      filterType: hasCompanyHierarchy ? '' : 'INPUT',
      isActivated: false,
      isSortable: false,
    },
    (user.isCompanyAdmin || user.canImpersonate) && {
      text: TABLE_FIELD_NAMES.ACTIONS,
      name: 'actions',
      filterType: '',
      isSortable: false,
      class: 'text-right',
    },
  ]);

function SiteListComponent({
  fetchSiteTypes,
  fetchWarehouses,
  warehouses,
  fetchSites,
  sitesQueryParams,
  currentPage,
  isUserImpersonated,
  user,
  siteList,
  siteTypes,
  handleQueryParamsChange,
  hasCompanyHierarchy,
}) {
  const [redirect, setRedirect] = useState(false);
  const [siteId, setSiteId] = useState(null);
  const [action, setAction] = useState(null);
  const [modalContent, setModalContent] = useState({
    body: null,
    footer: null,
  });
  const [showModal, setShowModal] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [modalSite, setModalSite] = useState(null);
  const showModalFlag = useRef(false);

  useEffect(() => {
    fetchSiteTypes();
    if (!warehouses) {
      fetchWarehouses();
    }
  }, []);

  const handleRedirect = (newSiteId, newAction) => {
    setRedirect(true);
    setSiteId(newSiteId);
    setAction(newAction);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const deleteSite = async (newSiteId) => {
    const currentId = newSiteId || modalSite.id;

    closeConfirmationModal();

    try {
      await siteManagerAPI.delete(`${API_SITES}${currentId}/`);
      await fetchSites(API_SITES_V2, {
        ...sitesQueryParams,
        offset: getOffsetPagination(currentPage),
      });
      toast.success('Site deleted correctly');
    } catch (error) {
      toast.error(
        'There was an error trying to delete the site. Please try again'
      );
      console.log( {error} );
    }
  };

  const handleLoginAs = (newSiteId) => async () => {
    try {
      const response = await siteManagerAPI.get(
        `${API_SITES}${newSiteId}/login-as/`
      );
      const { auth_redirect_url: redirectUrl } = response.data;

      if (isUserImpersonated) {
        window.location = redirectUrl;
      } else {
        window.open(redirectUrl, '_blank');
      }
    } catch (error) {
      toast.error(
        'There has been an error trying to login to ecommerce. Please try again'
      );
      console.log(error);
    }
  };

  const toggleModal = () => {
    showModalFlag.current = !showModalFlag.current;
    setShowModal(showModalFlag.current);
  };

  const displayConfirmationModal = (site) => {
    setIsConfirmationModalOpen(true);
    setModalSite(site);
  };

  const parseSite = (site) => {
    const url = API_SITE_USERS.replace('{id}', site.id);
    const fetchUsers = async (params) => {
      if (!site) return { count: 0, list: [], completeList: [] };
      const r = await siteManagerAPI.get(url, params || undefined);
      const nameList = r.data.results
        .map((u) => u.username)
        .sort(sortDescendingText);
      return {
        count: r.data.count,
        list: nameList,
        completeList: nameList,
      };
    };
    const parsedSite = {
      ...site.groups,
      id: site.id,
      name: site.name,
      siteType: site.siteType.name,
      legacyLocationName: site.locationName,
      legacyBusinessUnit: site.businessUnit,
      legacyDivision: site.division,
      legacyRegion: site.region,
      users: {
        fetchList: () => fetchUsers({ params: { limit: 4 } }),
        fetchCompleteList: fetchUsers,
        setModalData: (newModalContent) => setModalContent(newModalContent),
        toggleModal,
      },
    };

    const tableProps = {};

    if (user.isCompanyAdmin) {
      tableProps.clone = () => handleRedirect(site.id, 'CLONE');
      tableProps.edit = () => handleRedirect(site.id, 'EDIT');
      tableProps.remove = () => displayConfirmationModal(site);
    }

    if (site.buyoutSite && user.canImpersonate && user.isCompanyAdmin) {
      tableProps.loginAs = handleLoginAs(site.id);
    } else if (!site.buyoutSite && user.canImpersonate) {
      tableProps.loginAs = handleLoginAs(site.id);
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    parsedSite.actions = <TableActions {...tableProps} />;

    return parsedSite;
  };

  const renderRedirect = () => {
    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: `/sites/${siteId}`,
            site: siteList.find((site) => site.id === siteId),
            action,
          }}
        />
      );
    }
    return null;
  };
  const parsedSites = siteList.map(parseSite);

  const selectOptions = {
    siteType: siteTypes.map((role) => ({
      displayName: role.name,
      value: role.id,
    })),
  };

  return (
    <>
      <BrandModal
        subtitle="List of all users assigned to the site:"
        size="lg"
        body={modalContent.body}
        footer={modalContent.footer}
        isOpen={showModal}
        closeModal={toggleModal}
        centerBody
      />
      <TableFunctions
        headers={getHeaders({ user, hasCompanyHierarchy })}
        rowsData={parsedSites}
        suggestionsURL={API_SITES_SUGGESTIONS}
        dataURL={API_SITES_V2}
        fetchData={(dataURL, queryParams) => {
          const queryParamsObj = { ...queryParams, untrimmed: true };
          handleQueryParamsChange(queryParamsObj);
          fetchSites(dataURL, queryParamsObj);
        }}
        renderRedirect={renderRedirect}
        tableQueryParams={sitesQueryParams}
        selectOptions={selectOptions}
        showNodes
        columnsAreArrangeable
      />
      <ApprovalRuleConfirmationModal
        isOpen={isConfirmationModalOpen}
        closeHandler={closeConfirmationModal}
        confirmHandler={() => deleteSite()}
        obj={modalSite}
        objName="site"
        action="delete"
      />
    </>
  );
}

SiteListComponent.propTypes = {
  fetchSites: PropTypes.func.isRequired,
  setModalData: PropTypes.func.isRequired,
  siteList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  user: PropTypes.shape({
    isCompanyAdmin: PropTypes.bool,
    canImpersonate: PropTypes.bool,
    username: PropTypes.string,
  }).isRequired,
  siteTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  sitesQueryParams: PropTypes.shape(),
  currentPage: PropTypes.number,
  fetchSiteTypes: PropTypes.func.isRequired,
  fetchWarehouses: PropTypes.func.isRequired,
  hasCompanyHierarchy: PropTypes.bool.isRequired,
  handleQueryParamsChange: PropTypes.func,
  warehouses: PropTypes.arrayOf(PropTypes.shape()),
  isUserImpersonated: PropTypes.bool,
};

SiteListComponent.defaultProps = {
  currentPage: 0,
  sitesQueryParams: {},
  handleQueryParamsChange: () => {},
  warehouses: null,
  isUserImpersonated: false,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  sitesQueryParams: state.sites.sites.pagination.queryParams,
  currentPage: state.sites.sites.pagination.currentPage,
  siteTypes: state.siteTypes.siteTypes.results,
  warehouses: state.warehouses.names,
  hasCompanyHierarchy: _.get(
    state,
    'auth.user.company.hasCompanyHierarchy',
    false
  ),
  isUserImpersonated: state.auth.isImpersonated,
});

export default connect(mapStateToProps, {
  fetchSites: fetchSitesAction,
  fetchSiteTypes: fetchSiteTypesAction,
  fetchWarehouses: fetchWarehousesAction,
  setModalData,
})(SiteListComponent);
