import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const SelectInput = (props) => {
  const {
    error,
    required,
    label,
    name,
    labelClassName,
    inputClassName,
    isMulti,
    isDisabled,
    isSearchable,
    handleChange,
    mapOptions,
    value,
    options,
    closeMenuOnSelect,
    placeholder,
    isClearable,
  } = props;

  return (
    <div className="form-field form-group">
      <label
        className={`medium-text ${labelClassName} ${required ? 'form-field__label--required' : ''}`}
        htmlFor={`input-${name}`}
      >
        {label}
      </label>

      <Select
        value={isMulti ? mapOptions(value) : value}
        options={mapOptions(options)}
        onChange={handleChange}
        className={inputClassName}
        closeMenuOnSelect={closeMenuOnSelect}
        isMulti={isMulti}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        isClearable={isClearable}
        placeholder={placeholder}
      />

      {error && (
        <div className="form-field__error small-text">
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

SelectInput.propTypes = {
  error: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  mapOptions: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  placeholder: PropTypes.string,
};

SelectInput.defaultProps = {
  required: false,
  error: '',
  labelClassName: '',
  inputClassName: '',
  isMulti: false,
  isDisabled: false,
  isSearchable: false,
  isClearable: false,
  closeMenuOnSelect: false,
  placeholder: 'Select...',
  value: null,
};

export default SelectInput;
