import moment from 'moment';
import { toast } from 'react-toastify';

import { NOT_APPLICABLE } from 'constants/values';

export default class DocumentSerializer {
  constructor(data) {
    const documentData = data || {};
    let orderDate = '';
    let createdDate = '';
    let modifiedDate = '';

    try {
      orderDate = moment(documentData.order_date, 'YYYY-MM-DDTHH:mm:ss').format('MMM DD YYYY');
      createdDate = moment(documentData.created_date, 'YYYY-MM-DDTHH:mm:ss').format('MMM DD');
      modifiedDate = moment(documentData.modified_date, 'YYYY-MM-DDTHH:mm:ss').format('MMM DD');
    } catch (error) {
      toast.error(error);
    }

    this.id = documentData.id;
    this.documentName = documentData.document_name;
    this.documentClass = documentData.document_class;
    this.documentClassName = documentData.document_class_name;
    this.documentType = documentData.document_type;
    this.documentTypeName = documentData.document_type_name;
    this.afeNumber = documentData.afe_number ? documentData.afe_number : NOT_APPLICABLE;
    this.poNumber = documentData.po_number ? documentData.po_number : NOT_APPLICABLE;
    this.invoiceNumber = documentData.invoice_number ? documentData.invoice_number : NOT_APPLICABLE;
    this.trackingNumber = documentData.tracking_number
      ? documentData.tracking_number
      : NOT_APPLICABLE;
    this.orderDate = orderDate;
    this.createdDate = createdDate;
    this.modifiedDate = modifiedDate;
    this.orderIdentifier = documentData.order_identifier;
    this.downloadUrl = documentData.download_url;
  }
}
