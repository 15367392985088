import { getData } from 'country-list';

const NOT_APPLICABLE = 'N.A.';

const UNLIMITED_LIST_LIMIT = '100000000000';

const ITEMS_PER_PAGE = 20;

const ORDER_STATUS = {
  ACCEPTED: {
    VALUE: 1,
    TEXT: 'Accepted',
  },
  PENDING_APPROVAL: {
    VALUE: 2,
    TEXT: 'Pending Approval',
  },
  PROCESSING: {
    VALUE: 3,
    TEXT: 'Processing',
  },
  SHIPPED: {
    VALUE: 4,
    TEXT: 'Shipped',
  },
  PARTIALLY_SHIPPED: {
    VALUE: 5,
    TEXT: 'Partially Shipped',
  },
  DELIVERED: {
    VALUE: 6,
    TEXT: 'Delivered',
  },
  CANCELED: {
    VALUE: 7,
    TEXT: 'Canceled',
  },
  REJECTED: {
    VALUE: 8,
    TEXT: 'Rejected',
  },
};

const ORDER_PAYMENT_STATUS = {
  PAID: 'Paid',
  NOT_PAID: 'Not Paid',
  PARTIALLY_PAID: 'Partially Paid',
};

const COUNTRY_LIST = getData();

const USA_STATE_CODES = [
  { code: 'AL', name: 'Alabama' },
  { code: 'AK', name: 'Alaska' },
  { code: 'AZ', name: 'Arizona' },
  { code: 'AR', name: 'Arkansas' },
  { code: 'CA', name: 'California' },
  { code: 'CO', name: 'Colorado' },
  { code: 'CT', name: 'Connecticut' },
  { code: 'DE', name: 'Delaware' },
  { code: 'DC', name: 'District of Columbia' },
  { code: 'FL', name: 'Florida' },
  { code: 'GA', name: 'Georgia' },
  { code: 'HI', name: 'Hawaii' },
  { code: 'ID', name: 'Idaho' },
  { code: 'IL', name: 'Illinois' },
  { code: 'IN', name: 'Indiana' },
  { code: 'IA', name: 'Iowa' },
  { code: 'KS', name: 'Kansas' },
  { code: 'KY', name: 'Kentucky' },
  { code: 'LA', name: 'Louisiana' },
  { code: 'ME', name: 'Maine' },
  { code: 'MD', name: 'Maryland' },
  { code: 'MA', name: 'Massachusetts' },
  { code: 'MI', name: 'Michigan' },
  { code: 'MN', name: 'Minnesota' },
  { code: 'MS', name: 'Mississippi' },
  { code: 'MO', name: 'Missouri' },
  { code: 'MT', name: 'Montana' },
  { code: 'NE', name: 'Nebraska' },
  { code: 'NV', name: 'Nevada' },
  { code: 'NH', name: 'New Hampshire' },
  { code: 'NJ', name: 'New Jersey' },
  { code: 'NM', name: 'New Mexico' },
  { code: 'NY', name: 'New York' },
  { code: 'NC', name: 'North Carolina' },
  { code: 'ND', name: 'North Dakota' },
  { code: 'OH', name: 'Ohio' },
  { code: 'OK', name: 'Oklahoma' },
  { code: 'OR', name: 'Oregon' },
  { code: 'PA', name: 'Pennsylvania' },
  { code: 'PR', name: 'Puerto Rico' },
  { code: 'RI', name: 'Rhode Island' },
  { code: 'SC', name: 'South Carolina' },
  { code: 'SD', name: 'South Dakota' },
  { code: 'TN', name: 'Tennessee' },
  { code: 'TX', name: 'Texas' },
  { code: 'UT', name: 'Utah' },
  { code: 'VT', name: 'Vermont' },
  { code: 'VA', name: 'Virginia' },
  { code: 'WA', name: 'Washington' },
  { code: 'WV', name: 'West Virginia' },
  { code: 'WI', name: 'Wisconsin' },
  { code: 'WY', name: 'Wyomin' },
];

const CANADA_STATE_CODES = [
  { code: 'AB', name: 'Alberta' },
  { code: 'BC', name: 'British Columbia' },
  { code: 'MB', name: 'Manitoba' },
  { code: 'NB', name: 'New Brunswick' },
  { code: 'NL', name: 'Newfoundland and Labrador' },
  { code: 'NS', name: 'Nova Scotia' },
  { code: 'NT', name: 'Northwest Territories' },
  { code: 'NU', name: 'Nunavut' },
  { code: 'ON', name: 'Ontario' },
  { code: 'PE', name: 'Prince Edward Island' },
  { code: 'QC', name: 'Quebec' },
  { code: 'SK', name: 'Saskatchewan' },
  { code: 'YT', name: 'Yukon Territory' },
];

const TABLE_FIELD_NAMES = {
  SITE: 'Site',
  LOCATION_NAME: 'Location Name',
  BUSINESS_UNIT_INITIALS: 'BU',
  REGION: 'Region',
  ORDER: 'Order',
  ORDERED_BY: 'Ordered By',
  REQUESTED_BY: 'Requested By',
  PO: 'PO',
  AFE: 'AFE',
  STATUS: 'Status',
  PAYMENT_STATUS: 'Payment Status',
  CREATED: 'Created',
  LINES: 'Lines',
  TOTAL: 'Total',
  ACTIONS: 'Actions',
  APPROVED_BY: 'Approved By',
  NEXT_APPROVAL: 'Next Approval',
  APPROVAL_STATUS: 'Approval Status',
  USER: 'User',
  ALERT_EVENTS_FEED: 'Alert Events Feed',
  RULE: 'Rule',
  FREQUENCY: 'Frequency',
  VALUE: 'Value',
  USERNAME: 'Username',
  TITLE: 'Title',
  NAME: 'Name',
  EMAIL: 'Email',
  ALERTS: 'Alerts',
  TYPE: 'Type',
  BUSINESS_UNIT: 'Business Unit',
  DIVISION: 'Division',
  USERS: 'Users',
  LAST_LOGIN: 'Last Login',
  TRACKING_NUMBERS: 'Tracking #',
};

const COMPANY_CONFIGURATION_FEATURE_FLAG_OPTIONS = {
  NOT_AVAILABLE: {
    value: null,
    label: 'Not available',
  },
  DISABLED: {
    value: false,
    label: 'Disabled',
  },
  ENABLED: {
    value: true,
    label: 'Enabled',
  },
};

export {
  NOT_APPLICABLE,
  UNLIMITED_LIST_LIMIT,
  ORDER_STATUS,
  ORDER_PAYMENT_STATUS,
  COUNTRY_LIST,
  USA_STATE_CODES,
  CANADA_STATE_CODES,
  ITEMS_PER_PAGE,
  TABLE_FIELD_NAMES,
  COMPANY_CONFIGURATION_FEATURE_FLAG_OPTIONS,
};
