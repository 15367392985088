export const API_AUTH = '/accounts/user-token/';
export const API_COMPANY = '/accounts/companies/';
export const API_COMPANY_CONFIGURATION = '/accounts/company-configurations/{id}/';
export const API_ORDERS = '/orders/';
export const API_ORDERS_APPROVE = '/orders/{orderIdentifier}/approve/';
export const API_ORDERS_REJECT = '/orders/{orderIdentifier}/reject/';
export const API_ORDERS_REMOVE_LINE = '/orders/{orderIdentifier}/remove_line/';
export const API_ORDERS_SUGGESTIONS = '/orders/suggestions/';
export const API_ORDER = '/order/';
export const API_PENDING_ORDERS = '/orders/pending/';
export const API_PENDING_ORDERS_COUNT = '/orders/pending/count/';
export const API_PENDING_ORDERS_REQUIRES_APPROVAL_COUNT =
  '/orders/pending/requires-approval/count/';
export const API_ORDER_AVAILABLE_STATUS = '/order/available-statuses/';
export const API_ALERTS = '/alerts/';
export const API_ALERTS_SUGGESTIONS = '/alerts/suggestions/';
export const API_ALERT_TRIGGERS = '/triggers/';
export const API_ALERT_TRIGGERS_SUGGESTIONS = '/triggers/suggestions/';
export const API_ALERT_TRIGGERS_BATCH_UPDATE = '/triggers/batchupdate/';
export const API_COMPANY_HIERARCHY_GROUPS = '/company-hierarchy/organization-groups/';
export const API_SITES = '/sites/';
export const API_SITES_V2 = '/v2/sites/';
export const API_SITES_SUGGESTIONS = '/sites/suggestions/';
export const API_SITE_CLONE = '/sites/{id}/clone/';
export const API_SITES_COUNT = '/sites/count/';
export const API_SITES_EXISTS = '/sites/exists/';
export const API_SITES_ASSIGN = '/sites/assign-user-to-sites/';
export const API_SITES_ASSIGN_USERS = '/sites/{id}/assign_users/';
export const API_SITES_BATCH_CREATE = '/sites/batch-create/';
export const API_SITES_WAREHOUSES = '/sites/list-warehouses/';
export const API_SITES_IMPORT = '/sites/import-sites/';
export const API_SITE_TYPE = '/sites/site-type/';
export const API_SITE_USERS = '/v2/sites/{id}/users/';
export const API_SITE_GROUPS = '/v2/sites/{id}/groups/';
export const API_UNASSIGNED_SITES = '/company-hierarchy/unassigned-sites/';
export const API_UNASSIGNED_USERS = '/company-hierarchy/unassigned-users/';
export const API_USERS = '/accounts/v2/users/';
export const API_USERS_SUGGESTIONS = '/accounts/users/suggestions/';
export const API_CURRENT_USER = '/accounts/v2/user/';
export const API_USER_ANALYTICS = '/accounts/user/analytics/';
export const API_USER_SHUUP_EXISTS = '/accounts/user/exists/';
export const API_CREATE_USER = '/accounts/users/';
export const API_USER_CLONE = '/accounts/users/{id}/clone/';
export const API_RESET_PASSWORD = 'accounts/password_reset/';
export const API_USER_SITES = '/accounts/v2/users/{id}/sites/';
export const API_RESET_PASSWORD_CONFIRM = 'accounts/password_reset/confirm/';
export const API_RESET_PASSWORD_VALIDATE_TOKEN = 'accounts/password_reset/validate_token/';
export const API_ROLES = '/accounts/roles/';
export const API_APPROVAL_RULES = '/approvals/rules/';
export const API_APPROVAL_USERS = '/approvals/users/';
export const API_APPROVAL_RULES_DETAILS = '/approvals/rules/{id}/';
export const API_HIERARCHY_IMPORT_NODES = '/company-hierarchy/organization-import-nodes/';
export const API_HIERARCHY_NODES = '/company-hierarchy/organization-nodes/';
export const API_HIERARCHY_GROUPS = '/company-hierarchy/organization-groups/';
export const API_HIERARCHY_POLICIES = '/accounts/users/{id}/assign-policies/';
export const API_HIERARCHY_POLICIES_LIST = '/accounts/users/?hasPolicies=true';
export const API_HIERARCHY_TREE = '/company-hierarchy/tree/';
export const API_NODE_CONFIGURATION = '/company-hierarchy/organization-nodes/{id}/configuration/';
export const API_ORDER_DOCUMENTS = '/documents/order/{id}/';
export const API_ORDER_DOCUMENTS_SUGGESTIONS = '/documents/order/{id}/suggestions/';
export const API_USER_DOCUMENTS = '/documents/';
export const API_USER_DOCUMENTS_SUGGESTIONS = '/documents/suggestions/';
export const API_ADMIN_COMPANY = '/mcc-admin/companies/{id}/';
export const API_ADMIN_COMPANIES = '/mcc-admin/companies/';
export const API_ADMIN_CATALOGS = '/mcc-admin/catalogs/';
export const API_ADMIN_COMPANY_CONFIGURATIONS = '/mcc-admin/company-configurations/';
export const API_ADMIN_COMPANY_CONFIGURATION = '/mcc-admin/company-configurations/{id}/';
export const API_COMPANY_BILLING_ADDRESS = '/accounts/addresses/';
export const API_ADMIN_COMPANIES_DETAIL = '/mcc-admin/companies/{id}/';
