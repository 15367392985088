import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const BreadCrumbItem = ({ label, link }) => (
  <li className="breadcrumb__item" data-testid="breadcrumb-item">
    {link ? (
      <Link className="breadcrumb__link" to={link}>
        {label}
      </Link>
    ) : (
      label
    )}
  </li>
);

BreadCrumbItem.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
};

BreadCrumbItem.defaultProps = {
  link: '',
};

export default BreadCrumbItem;
