import { toast } from 'react-toastify';

import { SITES_FETCH_WAREHOUSE_LIST } from 'lib/types';
import { API_SITES_WAREHOUSES } from 'lib/api-endpoints';

import { FETCH_WAREHOUSE_LIST_ERROR } from 'constants/error-messages';
import siteManagerAPI from 'lib/api-manager';

export const fetchWarehouses = () => async (dispatch) => {
  try {
    const response = await siteManagerAPI.get(API_SITES_WAREHOUSES);

    dispatch({
      type: SITES_FETCH_WAREHOUSE_LIST,
      payload: response.data.results,
    });
  } catch {
    toast.error(FETCH_WAREHOUSE_LIST_ERROR);
  }
};
