import React, { Component } from 'react';
import PropTypes from 'prop-types';

import OrderSerializer from '../../lib/serializers/OrderSerializer';
import TableComponent from '../shared/table.component';
import { NOT_APPLICABLE, TABLE_FIELD_NAMES } from '../../constants/values';

export default class HomeAlertsComponent extends Component {
  static propTypes = {
    alerts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    isLoading: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          text: TABLE_FIELD_NAMES.ALERT_EVENTS_FEED,
          name: 'content',
          filterType: '',
          isActivated: false,
          isSortable: false,
        },
        {
          text: TABLE_FIELD_NAMES.CREATED,
          name: 'created',
          filterType: '',
          isActivated: false,
          isSortable: false,
          defaultDescendent: true,
        },
      ],
    };
  }

  renderOrderLink = (order) => {
    if (!order) {
      return NOT_APPLICABLE;
    }
    return new OrderSerializer(order);
  };

  render() {
    const { headers } = this.state;
    const { alerts, isLoading } = this.props;

    const parsedAlerts = alerts.map((alert) => ({
      ...alert,
      content: alert.content.replace('was', '').replace('the', ''),
    }));

    return (
      <TableComponent
        headers={headers}
        rows={parsedAlerts}
        emptyMessage="No recent alerts."
        isTableLoading={isLoading}
      />
    );
  }
}
