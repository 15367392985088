import React, { useEffect, useRef, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-bootstrap/cjs/divWithClassName';

/**
 * @typedef {Object} HeaderDef
 * @property {boolean} name
 * @property {boolean} text
 */

const Buttons = {
  ADD: 'Add',
  REMOVE: 'Remove',
  MOVE_UP: 'Move up',
  MOVE_DOWN: 'Move down',
  REMOVE_ALL: 'Remove all',
};

const ArrangeColumnsComponent = (props) => {
  const { headers, getArrangedHeaders, arrangeComponentKey } = props;
  const initialArrangement = useRef();

  const [leftHeaders, setLeftHeaders] = useState(headers);
  const [leftSelectValues, setLeftSelectValues] = useState([]);

  const [rightHeaders, setRightHeaders] = useState([]);
  const [rightSelectValues, setRightSelectValues] = useState([]);

  const [componentKey, setComponentKey] = useState(arrangeComponentKey);

  /**
   * O(n) filtering instead of using lodash differenceBy or an intersection (nested loops)
   * This works by creating dictionary from the first array and then filtering necessary keys
   *
   * @param receivedHeaders
   * @param {String[]} headersToFilter
   * @returns {[HeaderDef[], HeaderDef[]]}
   */
  const intersection = (receivedHeaders, headersToFilter) => {
    if (!headersToFilter.length) return [receivedHeaders, headersToFilter];
    const filtered = [];
    const answer = receivedHeaders.reduce((a, v) => {
      // eslint-disable-next-line no-param-reassign
      a[v.name] = v;
      return a;
    }, {});

    headersToFilter.forEach((headerToFilter) => {
      if (Object.prototype.hasOwnProperty.call(answer, headerToFilter)) {
        filtered.push(answer[headerToFilter]);
        delete answer[headerToFilter];
      }
    });

    return [Object.values(answer), filtered];
  };

  /**
   * To slide up/down options in the right column
   *
   * @param selectedHeadersToMove
   * @param direction
   * @returns {HeaderDef[]}
   */
  const slide = (selectedHeadersToMove, direction) => {
    if (!selectedHeadersToMove.length) return [...rightHeaders];

    let position = rightHeaders.findIndex(
      (leftHeader) => selectedHeadersToMove[0] === leftHeader.name,
    );

    // Depending on direction will move the position
    position = direction === Buttons.MOVE_UP ? position - 1 : position + 1;

    // If position is out of bonds of the array length, then do nothing
    if (position <= -1 || position >= rightHeaders.length) return [...rightHeaders];

    // Separate selected options from the current options
    const [intersected, filtered] = intersection(rightHeaders, selectedHeadersToMove);
    // Append options in the calculated position
    intersected.splice(position, 0, ...filtered);
    return intersected;
  };

  const HTMLOptionsToValues = (event) => {
    const { selectedOptions } = event.target;
    return Array.from(selectedOptions, (option) => option.value);
  };

  const handleMovement = (e) => {
    const { name } = e.target;

    switch (name) {
      case Buttons.ADD: {
        const [left, right] = intersection(leftHeaders, leftSelectValues);
        setLeftHeaders(left);
        setRightHeaders([...rightHeaders, ...right]);
        setLeftSelectValues([]);
        break;
      }
      case Buttons.REMOVE: {
        const [right, left] = intersection(rightHeaders, rightSelectValues);
        setLeftHeaders([...leftHeaders, ...left]);
        setRightHeaders(right);
        setRightSelectValues([]);
        break;
      }
      case Buttons.MOVE_UP: {
        // TODO: this only works for the right select and can be solved storing in state
        //  the current focused select but there's no reason to move up/down the values
        //  in the hidden column
        setRightHeaders(slide(rightSelectValues, Buttons.MOVE_UP));
        break;
      }
      case Buttons.MOVE_DOWN: {
        setRightHeaders(slide(rightSelectValues, Buttons.MOVE_DOWN));
        break;
      }
      case Buttons.REMOVE_ALL: {
        setLeftHeaders(initialArrangement.current);
        setRightHeaders([]);
        setLeftSelectValues([]);
        setRightSelectValues([]);
        break;
      }
      default:
    }
  };

  useEffect(() => {
    // if component was called on different page, it still has old data in state, so need to clean
    if (componentKey !== arrangeComponentKey) {
      setLeftHeaders(headers);
      setRightHeaders([]);
      setComponentKey(arrangeComponentKey);
      initialArrangement.current = null;
      return;
    }

    if (!initialArrangement.current) initialArrangement.current = headers;

    // If there are no headers in the right column (visible column) then ship the initial ones (left)
    if (rightHeaders.length) {
      getArrangedHeaders(rightHeaders);
    } else {
      getArrangedHeaders(leftHeaders);
    }
  }, [leftHeaders, rightHeaders, getArrangedHeaders, headers]);

  return (
    <Fragment>
      <div className="row">
        <div className="col">
          <select
            multiple
            name="leftHeaders"
            className="form-control multiselect default-font"
            onChange={(e) => setLeftSelectValues(HTMLOptionsToValues(e))}
            value={leftSelectValues}
          >
            {leftHeaders.map(({ name, text }) => (
              <option key={name} value={name} data-text={text} className="multiselect__option">
                {text}
              </option>
            ))}
          </select>
        </div>

        <div className="col-4 d-flex flex-column btn-group-vertical">
          {Object.values(Buttons).map((button) => {
            const lastSelectedValue = rightSelectValues[rightSelectValues.length - 1];
            const lastValue = rightHeaders[rightHeaders.length - 1];
            const functionButtons = new Set([Buttons.MOVE_UP, Buttons.MOVE_DOWN, Buttons.REMOVE]);
            const moveButtons = new Set([Buttons.MOVE_UP, Buttons.MOVE_DOWN]);
            return (
              <button
                key={button}
                name={button}
                type="button"
                className="btn btn-link multiselect__button"
                onClick={handleMovement}
                disabled={
                  (button === Buttons.ADD && !leftSelectValues.length) || // Disable ADD button if there's no left option select
                  (functionButtons.has(button) && !rightSelectValues.length) || // Disable ADD, REMOVE, REMOVE_ALL, MOVE_UP, MOVE_DOWN buttons if there's no right option selected
                  (button !== Buttons.ADD && !rightHeaders.length) || // Disable ADD button if there are no left headers
                  (moveButtons.has(button) && rightHeaders.length < 2) || // Disable MOVE_UP, MOVE_DOWN buttons if there's less only one right option
                  (button === Buttons.MOVE_UP && rightSelectValues[0] === rightHeaders[0].name) || // Disable MOVE_UP button if first option in select options matches first value in right options
                  (button === Buttons.MOVE_DOWN && lastSelectedValue === lastValue.name) // Disable MOVE_DOWN button if last option in select options matches last value in right options
                }
              >
                {button === Buttons.REMOVE && (
                  <FontAwesomeIcon className="btn__icon mr-2" icon="chevron-left" />
                )}
                {button}
                {button === Buttons.ADD && (
                  <FontAwesomeIcon className="btn__icon ml-2" icon="chevron-right" />
                )}
              </button>
            );
          })}
        </div>

        <div className="col">
          <select
            multiple
            name="rightHeaders"
            className="form-control multiselect"
            onChange={(e) => setRightSelectValues(HTMLOptionsToValues(e))}
            value={rightSelectValues}
          >
            {rightHeaders.map(({ name, text }) => (
              <option key={name} value={name} data-text={text} className="multiselect__option">
                {text}
              </option>
            ))}
          </select>
        </div>
      </div>
    </Fragment>
  );
};

ArrangeColumnsComponent.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  getArrangedHeaders: PropTypes.func.isRequired,
  arrangeComponentKey: PropTypes.string.isRequired,
};

export default ArrangeColumnsComponent;
