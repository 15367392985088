import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addLimit } from '../../utils/general';

const TrimmedUnorderedList = (props) => {
  const { data } = props;
  const {
    count = 0,
    list = [],
    completeList = [],
    fetchList,
    fetchCompleteList,
    setModalData,
    toggleModal,
  } = data;
  const [innerData, setInnerData] = useState({ count, list, completeList });
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const onMount = useRef(true);
  let moreMessage = null;

  useEffect(() => {
    if (onMount.current && fetchList) {
      setIsLoading(true);
      (async () => {
        const fetched = await fetchList();
        setInnerData({
          count: fetched.count,
          list: fetched.list,
          completeList: fetched.completeList,
        });
        setIsLoading(false);
      })();

      onMount.current = false;
    }
  }, [fetchList, innerData.completeList]);

  const ModalFooter = () => (
    <button
      type="button"
      className="btn btn-primary rounded-pill bg-dark border-0 px-5 py-3"
      onClick={toggleModal}
    >
      Close
    </button>
  );

  const setModalContent = (fetchedList) => {
    const mid = Math.ceil(fetchedList.length / 2);
    const firstColumn = fetchedList.slice(0, mid);
    const secondColumn = fetchedList.slice(mid);

    setModalData({
      body: (
        <div className="row mx-0 overflow-auto pl-5">
          <div className="col-12 col-md-6">
            <ul className="list-unstyled">
              {firstColumn.map((username) => (
                <li key={username}>{username}</li>
              ))}
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <ul className="list-unstyled">
              {secondColumn.map((username) => (
                <li key={username}>{username}</li>
              ))}
            </ul>
          </div>
        </div>
      ),
      footer: <ModalFooter />,
    });
  };

  const handleClick = () => {
    if (fetchCompleteList && !isFetched) {
      setModalData({
        body: (
          <div className="d-flex justify-content-center py-4">
            <FontAwesomeIcon icon="spinner" className="fa-spin fa-3x text-primary" />
          </div>
        ),
        footer: <ModalFooter />,
      });

      (async () => {
        const fetched = await fetchCompleteList(addLimit(innerData));
        setModalContent(fetched.completeList);
        setInnerData({
          count: fetched.count,
          list: innerData.list,
          completeList: fetched.completeList,
        });
        setIsFetched(true);
      })();
    } else {
      setModalContent(innerData.completeList);
    }

    toggleModal();
  };

  if (!Array.isArray(innerData.list) || (innerData.list.length === 0 && !isLoading)) return null;

  if (innerData.list.length < innerData.count && innerData.completeList.length) {
    moreMessage = (
      <button onClick={handleClick} type="button" className="btn btn-link" disabled={isLoading}>
        + {innerData.count - innerData.list.length} <u>more</u>
      </button>
    );
  } else if (innerData.list.length < innerData.count && !innerData.completeList.length) {
    moreMessage = `+ ${innerData.count - innerData.list.length} more`;
  }

  return (
    <Fragment>
      {isLoading ? (
        <div className="loading-row-placeholder loading-row-placeholder__rectangle mt-1" />
      ) : (
        <Fragment>
          <ul className="trimmed-ul">
            {innerData.list.map((value) => (
              <li key={value}>{value}</li>
            ))}
          </ul>
          {moreMessage}
        </Fragment>
      )}
    </Fragment>
  );
};

TrimmedUnorderedList.propTypes = {
  data: PropTypes.shape({
    count: PropTypes.number,
    list: PropTypes.arrayOf(PropTypes.string),
    completeList: PropTypes.array,
    modalSubtitle: PropTypes.string,
    fetchList: PropTypes.func,
    fetchCompleteList: PropTypes.func,
    setModalData: PropTypes.func,
    toggleModal: PropTypes.func,
  }).isRequired,
};

export default TrimmedUnorderedList;
