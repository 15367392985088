import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import TextInput from 'components/forms/text-input.component';
import TextArea from 'components/forms/textarea.component';
import SwitchInput from 'components/forms/switch-input.component';
import messages from './messages';

function NodeConfigurationFields(props) {
  const { errors, dataHandler, initialData } = props;
  const [usernamePrefix, setUsernamePrefix] = useState('');
  const [tenantGroup, setTenantGroup] = useState('');
  const [ecommerceCheckoutConfiguration, setEcommerceCheckoutConfiguration] = useState({});
  const [isBuyoutNode, setIsBuyoutNode] = useState(null);

  const handleChange = (handler, key) => (event) => {
    const { value } = event.target;

    handler(value);
    dataHandler((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSwitchChange = (handler, key) => (_name, checked) => {
    handler(checked);
    dataHandler((prevState) => ({
      ...prevState,
      [key]: checked,
    }));
  };

  useEffect(() => {
    if (initialData) {
      setUsernamePrefix(initialData.usernamePrefix || '');
      setTenantGroup(initialData.tenantGroup || '');
      setEcommerceCheckoutConfiguration(
        JSON.stringify(initialData.ecommerceCheckoutConfiguration, undefined, 4) || '',
      );
      setIsBuyoutNode(initialData.isBuyoutNode);
    }
  }, [initialData]);

  return (
    <React.Fragment>
      <TextInput
        handleChange={handleChange(setUsernamePrefix, 'usernamePrefix')}
        error={errors.usernamePrefix}
        value={usernamePrefix}
        label={messages.usernamePrefixInputLabel}
        name="usernamePrefix"
        required
      />
      <TextInput
        handleChange={handleChange(setTenantGroup, 'tenantGroup')}
        error={errors.tenantGroup}
        value={tenantGroup}
        label={messages.tenantGroupInputLabel}
        name="tenantGroup"
        required
      />
      <TextArea
        handleChange={handleChange(
          setEcommerceCheckoutConfiguration,
          'ecommerceCheckoutConfiguration',
        )}
        error={errors.ecommerceCheckoutConfiguration}
        value={ecommerceCheckoutConfiguration}
        label={messages.ecommerceCheckoutConfigurationInputLabel}
        name="ecommerceCheckoutConfiguration"
        required
      />
      <SwitchInput
        error={errors.isBuyoutNode}
        name="isBuyoutNode"
        checked={isBuyoutNode}
        label={messages.isBuyoutNodeLabel}
        handleChange={handleSwitchChange(setIsBuyoutNode, 'isBuyoutNode')}
      />
    </React.Fragment>
  );
}

NodeConfigurationFields.propTypes = {
  errors: PropTypes.object,
  initialData: PropTypes.object,
  dataHandler: PropTypes.func.isRequired,
};

NodeConfigurationFields.defaultProps = {
  errors: null,
  initialData: {},
};

export default NodeConfigurationFields;
