import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import BreadCrumbComponent from '../../shared/breadcumb.component';
import BreadCrumbItem from '../../shared/breadcrumb-item';
import SectionHeader from '../../shared/section-header.component';
import SiteTypeForm from './site-type-form';
import { fetchSiteType, updateSiteTypeName } from '../../../actions/site-types';

class SiteTypeEdit extends Component {
  static propTypes = {
    fetchSiteType: PropTypes.func.isRequired,
    updateSiteTypeName: PropTypes.func.isRequired,
    siteType: PropTypes.shape().isRequired,
    match: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      siteType: props.siteType,
      errors: {},
    };
  }

  componentDidMount() {
    const { fetchSiteType: fetchSiteTypeAction, match } = this.props;

    fetchSiteTypeAction(parseInt(match.params.siteTypeId, 10));
  }

  componentWillReceiveProps(nextProps) {
    const { siteType } = this.props;

    if (JSON.stringify(nextProps.siteType) !== JSON.stringify(siteType)) {
      this.setState({ siteType: nextProps.siteType });
    }
  }

  handleSubmit = (siteType) => {
    const { siteType: propsSiteType, updateSiteTypeName: updateSiteTypeNameAction } = this.props;

    updateSiteTypeNameAction(propsSiteType.id, siteType.name);
  };

  render() {
    const { siteType, errors } = this.state;
    const { siteType: propsSiteType } = this.props;

    return (
      <Fragment>
        <SectionHeader
          subtitle="Site Type"
          action="EDIT"
          sectionLabel={propsSiteType && propsSiteType.name}
        />

        <div className="content__container">
          <BreadCrumbComponent>
            <BreadCrumbItem label="Configuration" link="/configuration" />
            <BreadCrumbItem label="Site Types" link="/configuration/site-types" />
            <BreadCrumbItem label={propsSiteType && propsSiteType.name} />
          </BreadCrumbComponent>

          <div className="col-12 col-lg-4">
            <SiteTypeForm
              siteType={siteType}
              errors={errors}
              handleFormSubmit={this.handleSubmit}
              handleChange={this.handleChange}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  siteType: state.siteTypes.siteType,
});

export default connect(mapStateToProps, {
  fetchSiteType,
  updateSiteTypeName,
})(SiteTypeEdit);
