import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { updateApprovalRule, fetchApprovalRule } from 'actions/roles';
import BreadCrumbComponent from '../../shared/breadcumb.component';
import BreadCrumbItem from '../../shared/breadcrumb-item';
import SectionHeader from '../../shared/section-header.component';
import ApprovalRuleForm from './approval-rule-form';

class ApprovalRuleEdit extends Component {
  static propTypes = {
    updateApprovalRule: PropTypes.func.isRequired,
    fetchApprovalRule: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    editApprovalRuleErrors: PropTypes.object,
    approvalRule: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      role: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
      }),
      user: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
      }),
      sites: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ),
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ),
    }),
  };

  static defaultProps = {
    editApprovalRuleErrors: null,
    approvalRule: null,
  };

  componentDidMount() {
    const { fetchApprovalRule: fetchApprovalRuleAction, match } = this.props;

    fetchApprovalRuleAction(match.params.approvalRuleId);
  }

  handleSubmit = (rule) => {
    const { approvalRule, updateApprovalRule: updateApprovalRuleAction } = this.props;
    const rulePayload = _.pick(rule, ['limit', 'nodes', 'sites']);
    updateApprovalRuleAction(approvalRule.id, rulePayload);
  };

  render() {
    const { approvalRule, editApprovalRuleErrors } = this.props;
    let roleName;

    if (approvalRule) {
      roleName = approvalRule.role ? approvalRule.role.name : approvalRule.user.name;
    } else {
      roleName = '';
    }

    return (
      <Fragment>
        <SectionHeader subtitle="Order Approval Rule" action="EDIT" sectionLabel={roleName} />

        <div className="content__container">
          <BreadCrumbComponent>
            <BreadCrumbItem label="Configuration" link="/configuration" />
            <BreadCrumbItem label="Order Approvals Rules" link="/configuration/approvals" />
            <BreadCrumbItem label={roleName} />
          </BreadCrumbComponent>

          <div className="col-12 col-lg-4">
            <ApprovalRuleForm
              rule={approvalRule}
              errors={editApprovalRuleErrors}
              handleFormSubmit={this.handleSubmit}
              handleChange={this.handleChange}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  approvalRule: state.approvalRules.current,
  editApprovalRuleErrors: state.approvalRules.errors,
});

export default connect(mapStateToProps, {
  updateApprovalRule,
  fetchApprovalRule,
})(ApprovalRuleEdit);
