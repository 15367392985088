import React, { Fragment } from 'react';
import { ParentSize } from '@visx/responsive';

import SectionHeaderComponent from 'components/shared/section-header.component';
import BreadCrumbComponent from 'components/shared/breadcumb.component';
import BreadCrumbItem from 'components/shared/breadcrumb-item';
import TreeChart from './tree-chart';
import messages from './messages';

export default function Tree() {
  return (
    <Fragment>
      <SectionHeaderComponent subtitle={messages.company} sectionLabel={messages.hierarchy} />
      <BreadCrumbComponent>
        <BreadCrumbItem label={messages.hierarchy} link="/hierarchy" />
        <BreadCrumbItem label={messages.tree} />
      </BreadCrumbComponent>
      <ParentSize>{({ width, height }) => <TreeChart width={width} height={height} />}</ParentSize>
    </Fragment>
  );
}
