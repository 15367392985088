import { toast } from 'react-toastify';

import siteManagerAPI from 'lib/api-manager';
import { API_ADMIN_COMPANY_CONFIGURATIONS } from 'lib/api-endpoints';
import { formatErrorResponse, objectToSnakeCase } from 'shared/utils/formatting';

import messages from './messages';

export async function createCompanyConfiguration(data) {
  try {
    const formattedData = objectToSnakeCase(data);
    const response = await siteManagerAPI.post(API_ADMIN_COMPANY_CONFIGURATIONS, formattedData);
    toast.success(messages.createCompanyConfigurationSuccess);
    return response.data;
  } catch (error) {
    toast.error(messages.createCompanyConfigurationError);
    return { errors: formatErrorResponse(error.response.data) };
  }
}
