import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Pagination from 'shared/components/pagination';
import { useStructuredSelector } from 'shared/hooks/useSelector';
import TableFunctions from 'components/shared/table-functions';
import TableActions from 'components/shared/table-actions.component';
import TrimmedUnorderedList from 'components/shared/trimmed-unordered-list.component';
import useActions from 'shared/hooks/useActions';
import { API_HIERARCHY_NODES } from 'lib/api-endpoints';
import * as actions from './actions';
import * as selectors from './selectors';
import messages from './messages';

const headers = [
  {
    text: messages.name,
    name: 'name',
    filterType: 'INPUT',
  },
  {
    text: messages.parent,
    name: 'parent',
  },
  {
    text: messages.type,
    name: 'type',
  },
  {
    text: messages.sites,
    name: 'sites',
    isSortable: false,
    component: TrimmedUnorderedList,
  },
  {
    text: messages.users,
    name: 'users',
    isSortable: false,
    component: TrimmedUnorderedList,
  },
  {
    text: messages.actions,
    name: 'actions',
    isSortable: false,
    class: 'text-right',
  },
];

function HierarchyTable() {
  const fetchTableNodes = useActions(actions.fetchTableNodes);
  const { nodes, paginationState } = useStructuredSelector(selectors);
  const tableQueryParams = useSelector((state) => state.hierarchy.table.pagination.queryParams);
  const history = useHistory();

  useEffect(() => {
    fetchTableNodes();
  }, [fetchTableNodes]);

  const nodesRowsData = useMemo(
    () =>
      nodes.map((node) => ({
        ...node,
        actions: <TableActions edit={() => history.push(`/hierarchy/${node.id}`)} />,
      })),
    [nodes, history],
  );

  return (
    <React.Fragment>
      <Pagination state={paginationState} fetchResourceCB={fetchTableNodes} />
      <TableFunctions
        headers={headers}
        rowsData={nodesRowsData}
        dataURL={API_HIERARCHY_NODES}
        fetchData={(dataURL, queryParams) => {
          fetchTableNodes(API_HIERARCHY_NODES, queryParams);
        }}
        tableQueryParams={tableQueryParams}
        shouldDisplaySuggestions={false}
        columnsAreArrangeable
      />
      <Pagination state={paginationState} fetchResourceCB={fetchTableNodes} />
    </React.Fragment>
  );
}

export default HierarchyTable;
