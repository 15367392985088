import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import PermissionChecker from '../shared/permission-checker';

const FeeLine = ({ fee, user, key }) => {
  if (fee.unitPrice <= 0) return null;

  return (
    <tr key={key}>
      <td />
      <td className="text-base">
        <b>{fee.text}</b>
      </td>
      <td colSpan={3} />
      <td className="text-base">
        <b>${fee.lineTotal}</b>
      </td>
      <PermissionChecker user={user} permissionName="has-order-approvals">
        <td />
      </PermissionChecker>
    </tr>
  );
};

FeeLine.propTypes = {
  fee: PropTypes.shape({
    text: PropTypes.string,
    unitPrice: PropTypes.string,
    lineTotal: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape().isRequired,
  key: PropTypes.number,
};

FeeLine.defaultProps = {
  key: 0,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(FeeLine);
