import { createSelector } from 'reselect';

import * as utils from '../utils/hierarchy';

function hierarchyStateSelector(state) {
  return state.hierarchy;
}

const nodesStateSelector = createSelector(hierarchyStateSelector, (state) => state.nodes);

export const availableNodes = createSelector(nodesStateSelector, utils.flattenNodes);

export const currentNode = createSelector(hierarchyStateSelector, (state) => ({
  ...state.currentNode,
  users:
    state.currentNode &&
    state.currentNode.users &&
    state.currentNode.users.map(utils.formatUserName),
}));
