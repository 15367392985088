import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import * as hierarchyActions from 'shared/actions/hierarchy';
import * as nodesUtils from 'shared/utils/nodes';
import NodesForm from 'shared/components/nodes-form';
import useActions from 'shared/hooks/useActions';
import ReactModal from 'components/shared/react-modal';

import NodeConfigurationForm from './components/node-configuration-form';
import messages from './messages';
import * as actions from './actions';
import * as tableActions from './components/table/actions';

export const InjectedProps = {
  showCreateNode: PropTypes.func.isRequired,
};

export default function withCreateNode(WrappedComponent) {
  return function WithCreateNode(props) {
    const fetchTableNodes = useActions(tableActions.fetchTableNodes);
    const createNodeConfig = useActions(hierarchyActions.createNodeConfig);
    const [nodeModalOpen, setNodeModalOpen] = useState(false);
    const [nodeConfigModalOpen, setNodeConfigModalOpen] = useState(false);
    const [isConfigActive, setIsConfigActive] = useState(false);
    const [createdNodeId, setCreateNodeId] = useState(false);
    const [errors, setErrors] = useState(false);

    const showCreateNode = () => setNodeModalOpen(true);
    const hideCreateNode = () => setNodeModalOpen(false);
    const showCreateNodeConfig = () => setNodeConfigModalOpen(true);
    const hideCreateNodeConfig = () => setNodeConfigModalOpen(false);
    const handleConfigurationToggle = useCallback((active) => setIsConfigActive(active), []);

    const handleOnSubmit = useCallback(
      async (data) => {
        const { nodeConfigData, ...nodeData } = data;
        const node = nodesUtils.formatNodeForAPI(nodeData);
        const createdNode = await actions.createNode(node);
        if (isConfigActive) {
          setCreateNodeId(createdNode.id);
          showCreateNodeConfig();
        }
        fetchTableNodes();
        hideCreateNode();
      },
      [isConfigActive, fetchTableNodes],
    );

    const handleOnSubmitConfiguration = useCallback(
      async (data) => {
        const configuration = nodesUtils.formatNodeConfigForAPI(data);
        const responseErrors = await createNodeConfig(createdNodeId, configuration);
        if (responseErrors) {
          setErrors(responseErrors);
          return;
        }
        hideCreateNodeConfig();
      },
      [createdNodeId, createNodeConfig],
    );

    return (
      <React.Fragment>
        <WrappedComponent showCreateNode={showCreateNode} {...props} />
        <ReactModal
          isOpen={nodeModalOpen}
          title={messages.addNodeModalTitle}
          body={
            <NodesForm
              onSubmit={handleOnSubmit}
              onConfigurationToggle={handleConfigurationToggle}
              configurationIsTwoStep
            />
          }
          closeModal={hideCreateNode}
        />
        <ReactModal
          isOpen={nodeConfigModalOpen}
          title={messages.addNodeModalConfigurationTitle}
          body={<NodeConfigurationForm onSubmit={handleOnSubmitConfiguration} errors={errors} />}
          closeModal={hideCreateNodeConfig}
        />
      </React.Fragment>
    );
  };
}
