import validate from 'validate.js';

export default function useValidation(shape) {
  validate.validators.presence.message = 'field is required';
  validate.validators.isObject = isObjectValidator;

  return (values) => {
    const validateErrors = validate(values, shape);
    if (!validateErrors) return null;
    return Object.keys(validateErrors).reduce((errors, key) => {
      if (!validateErrors[key]) return errors;
      return { ...errors, [key]: validateErrors[key][0] };
    }, {});
  };
}

const isObjectValidator = (value, options, key, attributes) => {
  if (!(value instanceof Object)) {
    try {
      const parsedValue = JSON.parse(value);

      if (options.inPlace) {
        attributes[key] = parsedValue;
      }
    } catch (error) {
      return options.message || 'is not a valid JSON';
    }
  }
};
