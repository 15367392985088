import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';

import { openOrderTracking, objectToSearchString, getOffsetPagination } from 'utils/general';
import { setModalData as setModalDataAction } from '../../actions/globals.actions';
import { API_ORDERS, API_ORDERS_APPROVE } from '../../lib/api-endpoints';
import { ORDER_STATUS } from '../../constants/values';
import SectionHeaderComponent from '../shared/section-header.component';
import BreadCrumbComponent from '../shared/breadcumb.component';
import BreadCrumbItem from '../shared/breadcrumb-item';
import OrderComponent from './order.component';
import siteManagerAPI from '../../lib/api-manager';
import OrderSerializer from '../../lib/serializers/OrderSerializer';
import RejectOrderModal from './reject-order-modal';
import ConfirmationModal from '../shared/confirmation-modal.component';
import { formatFixNumber } from '../../utils/format';
import PermissionChecker from '../shared/permission-checker';

function OrderDetailPage({ user, queryParamsProp, currentPage, setModalData, location, match }) {
  const queryParams = {
    ...queryParamsProp,
    offset: getOffsetPagination(currentPage),
  };
  const [order, setOrder] = useState(location?.state?.order || null);

  const reloadOrder = async () => {
    const { orderId } = match.params;

    try {
      const response = await siteManagerAPI.get(`${API_ORDERS}${orderId}/`);
      const orderSerialized = new OrderSerializer(response.data);
      setOrder(orderSerialized);
    } catch (error) {
      toast.error('There was an error trying to load this order');
    }
  };

  const approveOrder = async (orderIdentifier) => {
    try {
      await siteManagerAPI.post(
        API_ORDERS_APPROVE.replace('{orderIdentifier}', orderIdentifier),
        {},
      );
      toast.success('Order approved');
      $('#buttons-approve-reject-order').hide();
    } catch (error) {
      if (error?.response?.status === 409) {
        toast.error('You already have approved this order');
      } else {
        toast.error('There was an error trying to approve the order');
      }
    }
  };

  const renderModalIfUserCanTakeAction = (renderModal) => {
    const isUserInApprovals = order.approvedBy.some(
      (approval) => user.nameOrUsername === approval.username,
    );

    if (isUserInApprovals) {
      toast.error('You already took action on this order');
    } else {
      renderModal();
    }
  };

  const renderApproveOrderModal = () => {
    const message = (
      <div>
        Do you want to approve order <span className="bold">{order.identifier}</span> with total of{' '}
        <span className="bold">${formatFixNumber(order.total)}</span>?
      </div>
    );

    setModalData({
      size: 'modal-xs',
      title: 'Approve Order',
      jsx: null,
      component: ConfirmationModal,
      buttons: null,
      componentProps: {
        onSubmit: () => approveOrder(order.identifier),
        message,
      },
    });
    $('#shared-modal').modal('show');
  };

  const renderRejectOrderModal = () => {
    setModalData({
      size: 'modal-xs',
      title: 'Reject Order',
      jsx: null,
      component: RejectOrderModal,
      buttons: null,
      componentProps: {
        orderIdentifier: order.identifier,
        orderTotal: order.total,
      },
    });
    $('#shared-modal').modal('show');
  };

  useEffect(() => {
    if (!order) {
      reloadOrder();
    }
  }, []);

  return (
    <React.Fragment>
      <SectionHeaderComponent subtitle="Orders" sectionLabel="Order Details" />

      <div className="content__container">
        <BreadCrumbComponent>
          <BreadCrumbItem label="Orders" link="/orders" />
          <BreadCrumbItem label="Order Details" />
        </BreadCrumbComponent>

        <div className="order-details__return-btn">
          <Link
            to={{
              pathname: '/orders',
              search: objectToSearchString(queryParams),
            }}
          >
            <FontAwesomeIcon icon="arrow-left" /> Return to orders
          </Link>
        </div>

        <div className="content__container order-details__header mb-3 text-primary medium-text flex-column flex-sm-row">
          <div className="order-details__basic-information mt-2 mt-sm-0">
            <div className="mb-2">
              Identifier: <b>{order && order.identifier}</b>
            </div>
            <div className="mb-2">
              Ordered by: <b>{order && order.ordered_by}</b>
            </div>
            <div className="mb-2">
              Requested by: <b>{order && order.requested_by}</b>
            </div>
            <div className="mb-2">
              Status: <b>{order && order.status}</b>
            </div>

            <div className="mb-2">
              Payment Status: <b>{order && order.paymentStatus}</b>
            </div>

            <div className="mb-2">
              Site: <b>{order && order.site}</b>
            </div>

            <div className="mb-2">
              BU: <b>{order && order.siteBU}</b>
            </div>

            <div className="mb-2">
              Region: <b>{order && order.siteRegion}</b>
            </div>

            <div className="mb-2">
              Division: <b>{order && order.siteDivision}</b>
            </div>

            <div className="mb-2">
              Tracking numbers: <b>{order && order.trackingNumbers.join(', ')}</b>
            </div>

            <div className="mb-2">
              Ordered: <b>{order && order.orderDate}</b>
            </div>

            {order &&
              Object.keys(order.customCheckoutData).map((key) => {
                if (order.customCheckoutData[key] && order.customCheckoutData[key].value) {
                  return (
                    <div className="mb-2" key={key}>
                      {order.customCheckoutData[key].label}:{' '}
                      <b>{order.customCheckoutData[key].value}</b>
                    </div>
                  );
                }
                return null;
              })}

            {order &&
              Object.entries(order.customconfirmData).map(([key, value]) => (
                <div className="mb-2">
                  {key}: <b>{value}</b>
                </div>
              ))}
          </div>

          <PermissionChecker permissionName="order-tracking" order={order}>
            <div className="order-details__track-btn">
              {order && (
                <button
                  type="button"
                  className="btn btn-primary float-right"
                  onClick={openOrderTracking(order.identifier)}
                >
                  <FontAwesomeIcon icon="truck" />
                  TRACK ORDER
                </button>
              )}
            </div>
          </PermissionChecker>

          {order && order.rejectedBy && order.status === ORDER_STATUS.REJECTED.TEXT && (
            <div className="text-danger order-details__approval-actions">
              <div>
                <span>Rejected By: </span>
                <span>
                  <b>{order.rejectedBy.username}</b>
                </span>
              </div>
            </div>
          )}

          {user.isApprover &&
            order?.approvableByCurrentUser &&
            order?.status === ORDER_STATUS.PENDING_APPROVAL.TEXT && (
              <div className="order-details__approval-actions align-items-sm-end align-items-start mt-2 mt-sm-0">
                {order?.approvedBy.length === 0 && !order?.rejectedBy && (
                  <div className="d-flex flex-column flex-sm-row">
                    <div id="buttons-approve-reject-order">
                      <button
                        type="button"
                        onClick={() => renderModalIfUserCanTakeAction(renderApproveOrderModal)}
                        className="btn btn-success mr-sm-1"
                      >
                        Approve Order
                      </button>

                      <button
                        type="button"
                        onClick={() => renderModalIfUserCanTakeAction(renderRejectOrderModal)}
                        className="btn btn-danger mt-3 mt-sm-0"
                      >
                        Reject Order
                      </button>
                    </div>
                  </div>
                )}
                {order.approvedBy.length > 0 && order.status !== ORDER_STATUS.REJECTED.TEXT && (
                  <div>
                    Approved By:{' '}
                    <span className="order__approvers">
                      {order.approvedBy.map((approver) => (
                        <span className="" key={approver.username}>
                          <b>{approver.username}</b>
                        </span>
                      ))}
                    </span>
                  </div>
                )}
              </div>
            )}
        </div>

        {order && (
          <OrderComponent
            order={order}
            total={order.total}
            subTotal={order.subTotal}
            tax={order.tax}
            handleReload={reloadOrder}
          />
        )}

        {order && order.rejectedBy && order.status === ORDER_STATUS.REJECTED.TEXT && (
          <div className="row mb-3 medium-text">
            <div className="col-12">
              <div>
                <div className="text-primary">
                  Approved By:{' '}
                  <span className="order__approvers">
                    {order.approvedBy.map((approver) => (
                      <span className="" key={approver.username}>
                        <b>{approver.username}</b>
                      </span>
                    ))}
                  </span>
                </div>
                <div className="text-danger">
                  <span>Rejection Reason: </span>
                  <span>
                    <b>{order.rejectedBy.note}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

OrderDetailPage.propTypes = {
  user: PropTypes.shape({
    nameOrUsername: PropTypes.string,
    title: PropTypes.string,
    isCompanyAdmin: PropTypes.bool,
    isApprover: PropTypes.bool,
  }).isRequired,
  location: PropTypes.shape().isRequired,
  match: PropTypes.shape({ params: PropTypes.shape() }).isRequired,
  setModalData: PropTypes.func.isRequired,
  queryParamsProp: PropTypes.shape(),
  currentPage: PropTypes.number,
};

OrderDetailPage.defaultProps = {
  currentPage: 0,
  queryParamsProp: {},
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  queryParams: state.orders.orders.pagination.queryParams,
  currentPage: state.orders.orders.pagination.currentPage,
});

export default connect(mapStateToProps, {
  setModalData: setModalDataAction,
})(withRouter(OrderDetailPage));
