import React from 'react';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';

const ReactModal = (props) => {
  const { size, body, footer, title, closeModal, isOpen } = props;

  return (
    <Modal show={isOpen} size={size} onHide={closeModal}>
      <Modal.Header>
        <h5 className="modal-title">{title}</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <FontAwesomeIcon icon="times-circle" />
        </button>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  );
};

ReactModal.propTypes = {
  size: PropTypes.string,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  body: PropTypes.element,
  footer: PropTypes.element,
  closeModal: PropTypes.func.isRequired,
};

ReactModal.defaultProps = {
  size: '',
  body: null,
  footer: null,
};

export default ReactModal;
