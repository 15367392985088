import axios from 'axios';
import { toast } from 'react-toastify';

import siteManagerAPI from 'lib/api-manager';
import { GLOBALS_SET_IS_TABLE_LOADING, ORDER_DOCUMENTS_FETCH } from 'lib/types';
import { FETCH_DOCUMENTS_ERROR } from 'constants/error-messages';
import DocumentsSerializer from 'lib/serializers/Documents';

let cancelToken;

export const fetchOrderDocuments =
  (url, params = {}) =>
  async (dispatch) => {
    dispatch({
      type: GLOBALS_SET_IS_TABLE_LOADING,
      payload: true,
    });

    try {
      if (cancelToken) {
        cancelToken.cancel('Request aborted due to new request.');
      }
      cancelToken = axios.CancelToken.source();

      const response = await siteManagerAPI.get(url, { params });
      response.data.queryParams = params;
      const serializedDocuments = new DocumentsSerializer(response.data);

      dispatch({ type: ORDER_DOCUMENTS_FETCH, payload: serializedDocuments });

      cancelToken = null;
    } catch (error) {
      if (!axios.isCancel(error)) {
        toast.error(FETCH_DOCUMENTS_ERROR);
      }
    }

    dispatch({
      type: GLOBALS_SET_IS_TABLE_LOADING,
      payload: false,
    });
  };
