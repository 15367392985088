import get from 'lodash/get';

import { createSelector } from 'reselect';

function adminCompaniesSelector(state) {
  return get(state, 'mccAdmin.companies');
}

export const currentCompany = createSelector(
  adminCompaniesSelector,
  (state) => state.currentCompany,
);
