import { toast } from 'react-toastify';

import siteManagerAPI from 'lib/api-manager';
import { API_ADMIN_COMPANY, API_ADMIN_COMPANY_CONFIGURATION } from 'lib/api-endpoints';
import { ADMIN_SET_CURRENT_COMPANY } from 'lib/types';
import {
  FETCH_ADMIN_COMPANY_ERROR,
  FETCH_ADMIN_COMPANY_CONFIGURATION_ERROR,
} from 'constants/error-messages';
import CompanySerializer from 'lib/serializers/mcc-admin/CompanySerializer';
import CompanyConfigurationSerializer from 'lib/serializers/mcc-admin/company-configuration';

export const setCurrentCompany = (company) => async (dispatch) => {
  dispatch({ type: ADMIN_SET_CURRENT_COMPANY, payload: company });
};

export async function fetchCompanyById(id) {
  try {
    const response = await siteManagerAPI.get(API_ADMIN_COMPANY.replace('{id}', id));
    return new CompanySerializer(response.data);
  } catch (error) {
    toast.error(FETCH_ADMIN_COMPANY_ERROR);
    return {};
  }
}

export async function fetchConfigurationById(id) {
  try {
    const response = await siteManagerAPI.get(API_ADMIN_COMPANY_CONFIGURATION.replace('{id}', id));
    return new CompanyConfigurationSerializer(response.data);
  } catch (error) {
    toast.error(FETCH_ADMIN_COMPANY_CONFIGURATION_ERROR);
    return {};
  }
}
