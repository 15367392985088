import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Spinner() {
  return (
    <div className="spinner">
      <span className="text-primary">
        <FontAwesomeIcon icon="spinner" className="fa-spin" />
      </span>
    </div>
  );
}

export default Spinner;
