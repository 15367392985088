import SiteTypeSerializer from './SiteTypeSerializer';
import PaginationSerializer from './PaginationSerializer';

export default class SitesSerializer {
  constructor(data) {
    const siteTypesData = data || {};

    this.pagination = new PaginationSerializer(siteTypesData);
    this.results = Array.isArray(siteTypesData.results)
      ? siteTypesData.results.map((role) => new SiteTypeSerializer(role))
      : [];
  }
}
