import { toast } from 'react-toastify';

import siteManagerAPI from 'lib/api-manager';
import { API_HIERARCHY_TREE } from 'lib/api-endpoints';
import { FETCH_TREE_ERROR } from 'constants/error-messages';
import { HIERARCHY_FETCH_TREE } from 'lib/types';
import TreeSerializer from 'lib/serializers/HierarchyTreeSerializer';

export const fetchTree = () => async (dispatch) => {
  try {
    const response = await siteManagerAPI.get(API_HIERARCHY_TREE);
    const tree = TreeSerializer(response.data);

    dispatch({
      type: HIERARCHY_FETCH_TREE,
      payload: tree,
    });
  } catch (error) {
    toast.error(FETCH_TREE_ERROR);
  }
};
