import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { Redirect } from 'react-router-dom';

import BreadCrumbComponent from '../../shared/breadcumb.component';
import BreadCrumbItem from '../../shared/breadcrumb-item';
import SectionHeader from '../../shared/section-header.component';
import ConfirmationModal from '../../shared/confirmation-modal.component';
import SiteTypeForm from './site-type-form';
import SiteTypesList from './site-types-list';
import { setModalData } from '../../../actions/globals.actions';
import {
  fetchSiteTypes,
  createSiteType,
  deleteSiteType,
  resetErrors,
} from '../../../actions/site-types';

class SiteTypesConfiguration extends Component {
  static propTypes = {
    siteTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    fetchSiteTypes: PropTypes.func.isRequired,
    createSiteType: PropTypes.func.isRequired,
    deleteSiteType: PropTypes.func.isRequired,
    setModalData: PropTypes.func.isRequired,
    errors: PropTypes.shape().isRequired,
    resetErrors: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      redirectUrl: '',
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const { fetchSiteTypes: fetchSiteTypesAction } = this.props;

    fetchSiteTypesAction();
  }

  componentWillReceiveProps(nextProps) {
    const { isModalOpen } = this.state;

    if (Object.entries(nextProps.errors).length === 0 && isModalOpen) {
      this.setState({ isModalOpen: false });
    }
  }

  componentWillUnmount() {
    const { resetErrors: resetErrorsAction } = this.props;
    resetErrorsAction();
  }

  displayCreationModal = () => {
    const { setModalData: setModalDataAction } = this.props;

    setModalDataAction(
      {
        size: 'modal-xs',
        title: 'Create New Site Type',
        jsx: null,
        component: SiteTypeForm,
        componentProps: {
          handleFormSubmit: this.handleCreateSubmit,
        },
        buttons: null,
      },
      this.handleModalClose,
      null,
    );

    this.setState({ isModalOpen: true });
  };

  displayDeleteConfirmationModal = (siteType) => {
    const { setModalData: setModalDataAction, deleteSiteType: deleteSiteTypeAction } = this.props;

    const message = (
      <div>
        Are you sure you want to delete site type <span className="bold">{siteType.name}</span>?
      </div>
    );

    setModalDataAction({
      size: 'modal-xs',
      title: 'Delete Site Type',
      jsx: null,
      component: ConfirmationModal,
      buttons: null,
      componentProps: {
        onSubmit: () => deleteSiteTypeAction(siteType.id),
        message,
      },
    });
    this.setState({ isModalOpen: true });
  };

  handleEdit = (siteType) => {
    const redirectUrl = `/configuration/site-types/${siteType.id}`;
    this.setState({ redirectUrl });
  };

  handleCreateSubmit = (siteType) => {
    const { createSiteType: createSiteTypeAction } = this.props;

    createSiteTypeAction(siteType);
  };

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
  };

  fetchSiteTypesWithParams = (queryParams) => {
    const { fetchSiteTypes: fetchSiteTypesAction } = this.props;

    fetchSiteTypesAction(queryParams);
  };

  render() {
    const { redirectUrl, isModalOpen } = this.state;

    if (redirectUrl) {
      return <Redirect to={redirectUrl} />;
    }

    const { siteTypes } = this.props;

    $('#shared-modal').modal(isModalOpen ? 'show' : 'hide');

    return (
      <Fragment>
        <SectionHeader subtitle="Configuration" sectionLabel="Site Types" />

        <div className="content__container">
          <BreadCrumbComponent>
            <BreadCrumbItem label="Configuration" link="/configuration" />
            <BreadCrumbItem label="Site Types" />
          </BreadCrumbComponent>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-2">
                <button
                  type="button"
                  onClick={this.displayCreationModal}
                  className="btn btn-primary"
                >
                  <FontAwesomeIcon icon="address-card" /> Create Site Type
                </button>
              </div>
            </div>
          </div>

          <hr />

          <SiteTypesList
            siteTypes={siteTypes}
            handleEdit={this.handleEdit}
            handleDelete={this.displayDeleteConfirmationModal}
            fetchSiteTypesWithParams={this.fetchSiteTypesWithParams}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  siteTypes: state.siteTypes.siteTypes.results,
  errors: state.siteTypes.errors,
});

export default connect(mapStateToProps, {
  setModalData,
  fetchSiteTypes,
  createSiteType,
  deleteSiteType,
  resetErrors,
})(SiteTypesConfiguration);
