export default {
  catalogInputLabel: 'Catalog',
  hasOrderApprovalInputLabel: 'Order Approval status',
  hasOrderTrackingInputLabel: 'Order Tracking status',
  hasHierarchyInputLabel: 'Hierarchy status',
  defaultMainButtonText: 'Create',
  defaultCancelButtonText: 'Cancel',
  fetchCatalogsError: 'Error fetching catalogs',
  tenantGroupMissingError: 'Tenant Group is required',
  customerERPIdentifierMissingError: 'Name Extension / Customer ERP Id is required',
  orderApprovalMissingError: 'Order Approvals configuration is required',
  orderTrackingMissingError: 'Order Tracking is required',
  hierarchyMissingError: 'Hierarchy configuration is required',
};
