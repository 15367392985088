import PaginationSerializer from './PaginationSerializer';
import RoleSerializer from './RoleSerializer';

export default class RolesSerializer {
  constructor(data) {
    const rolesData = data || {};

    this.pagination = new PaginationSerializer(rolesData);
    this.results = Array.isArray(rolesData.results)
      ? rolesData.results.map((role) => new RoleSerializer(role))
      : [];
  }
}
