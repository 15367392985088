import { toast } from 'react-toastify';

import siteManagerAPI from 'lib/api-manager';
import { API_ADMIN_COMPANIES_DETAIL, API_SITES_IMPORT } from 'lib/api-endpoints';
import { ADMIN_SET_CURRENT_COMPANY } from 'lib/types';
import {
  ADMIN_COMPANY_IMPORT_SITES_ERROR,
  FETCH_ADMIN_COMPANY_DETAIL_ERROR,
} from 'constants/error-messages';
import { ADMIN_COMPANY_IMPORT_SITES_SUCCESSFULLY } from 'constants/messages';
import CompanySerializer from 'lib/serializers/mcc-admin/CompanySerializer';
import { formatErrorResponse } from 'shared/utils/formatting';

export const fetchCompany = (id, params = {}) => async (dispatch) => {
  try {
    const url = API_ADMIN_COMPANIES_DETAIL.replace('{id}', id);
    const response = await siteManagerAPI.get(url, { params });
    const serializedCompany = new CompanySerializer(response.data);
    dispatch({ type: ADMIN_SET_CURRENT_COMPANY, payload: serializedCompany });
  } catch {
    toast.error(FETCH_ADMIN_COMPANY_DETAIL_ERROR);
  }
};

export const createImportSitesCompany = (id, data) => async () => {
  try {
    const url = API_SITES_IMPORT.replace('{id}', id);
    siteManagerAPI.defaults.headers.common.Accept = 'multipart/form-data';
    siteManagerAPI.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    await siteManagerAPI.post(url, data);

    toast.success(ADMIN_COMPANY_IMPORT_SITES_SUCCESSFULLY);
    return null;
  } catch (error) {
    toast.error(ADMIN_COMPANY_IMPORT_SITES_ERROR);
    return formatErrorResponse(error);
  }
};
