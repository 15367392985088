import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { getFieldValue } from '../../../utils/forms';
import TextInput from '../../forms/text-input.component';
import { resetErrors } from '../../../actions/roles';
import FormWrapper from '../../forms/form-wrapper.component';

class RoleForm extends Component {
  static propTypes = {
    role: PropTypes.shape({
      name: PropTypes.string,
    }),
    handleFormSubmit: PropTypes.func.isRequired,
    errors: PropTypes.shape().isRequired,
    resetErrors: PropTypes.func.isRequired,
  };

  static defaultProps = {
    role: {
      name: '',
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      name: props.role.name,
      errors: { ...props.errors },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { errors, role } = this.state;

    const roleChanged = JSON.stringify(nextProps.role) !== JSON.stringify(role);

    if (roleChanged && Object.entries(nextProps.errors).length === 0) {
      this.resetRole(nextProps.role);
    }
    if (JSON.stringify(nextProps.errors) !== JSON.stringify(errors)) {
      this.setState({ errors: { ...nextProps.errors } });
    }
  }

  componentWillUnmount() {
    const { resetErrors: resetErrorsAction } = this.props;
    resetErrorsAction();
  }

  resetRole = (role) => {
    this.setState({ name: role.name });
  };

  submitRoleForm = async (event) => {
    event.preventDefault();

    const { name } = this.state;

    const errors = {};

    if (!name) errors.name = 'Name field is required';

    this.setState({ errors });

    if (Object.entries(errors).length === 0) {
      const { handleFormSubmit } = this.props;

      handleFormSubmit({ name });
    }
  };

  handleChange = (event) => {
    this.setState({ ...getFieldValue(event.target) });
  };

  render() {
    const { name, errors } = this.state;

    return (
      <Fragment>
        <FormWrapper>
          <form className="form-input mb-2 clearfix" onSubmit={this.submitRoleForm}>
            <TextInput
              error={errors.name}
              value={name}
              label="Name"
              name="name"
              required
              handleChange={this.handleChange}
            />

            <div className="float-right">
              <button type="submit" data-placement="right" className="btn btn-primary">
                <FontAwesomeIcon icon="address-card" />
                <span> SAVE ROLE</span>
              </button>
            </div>
          </form>
        </FormWrapper>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.roles.errors,
});

export default connect(mapStateToProps, {
  resetErrors,
})(RoleForm);
