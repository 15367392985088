import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { API_ORDERS } from '../../lib/api-endpoints';
import TableComponent from '../shared/table.component';
import { formatFixNumber } from '../../utils/format';
import { TABLE_FIELD_NAMES } from '../../constants/values';

export default class HomeOrdersComponent extends Component {
  static propTypes = {
    orders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    isLoading: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          text: TABLE_FIELD_NAMES.SITE,
          name: 'site',
          filterType: '',
          isActivated: false,
          isSortable: false,
        },
        {
          text: TABLE_FIELD_NAMES.ORDER,
          name: 'order',
          filterType: '',
          isActivated: false,
          isSortable: false,
        },
        {
          text: TABLE_FIELD_NAMES.STATUS,
          name: 'status',
          filterType: '',
          isActivated: false,
          isSortable: false,
        },
        {
          text: TABLE_FIELD_NAMES.CREATED,
          name: 'orderDate',
          filterType: '',
          isActivated: false,
          isSortable: false,
          defaultDescendent: true,
        },
        {
          text: TABLE_FIELD_NAMES.TOTAL,
          name: 'total',
          filterType: '',
          isActivated: false,
          isSortable: false,
        },
      ],
    };
  }

  render() {
    const { headers } = this.state;
    const { orders, isLoading } = this.props;

    const parsedOrders = orders.map((order) => ({
      ...order,
      total: `$${formatFixNumber(order.total)}`,
      order: {
        isLink: true,
        text: order.identifier,
        pathName: `${API_ORDERS}${order.identifier}`,
      },
    }));

    return (
      <TableComponent
        headers={headers}
        rows={parsedOrders}
        emptyMessage="No recent orders."
        isTableLoading={isLoading}
      />
    );
  }
}
