import moment from 'moment-timezone';

import ProductSerializer from './ProductSerializer';
import { NOT_APPLICABLE } from '../../constants/values';

export default class OrderSerializer {
  constructor(data) {
    const orderData = data || {};
    let date = '';

    try {
      date = moment.utc(orderData.order_date).local().format('MMM DD YYYY h:mm A');
    } catch (error) {
      console.log(error);
    }
    this.id = orderData.id;
    this.identifier = orderData.identifier;
    this.po = orderData.po_number ? orderData.po_number : NOT_APPLICABLE;
    this.afe = orderData.afe_number ? orderData.afe_number : NOT_APPLICABLE;
    this.user = orderData.creator;
    this.creator = orderData.creator;
    this.ordered_by = orderData.ordered_by;
    this.requestedBy = orderData.requested_by || '';
    this.total = orderData.taxful_total_price_value;
    this.subTotal = orderData.taxless_total_price_value;
    this.tax = orderData.taxful_total_price_value - orderData.taxless_total_price_value;
    this.status = orderData.status ? orderData.status : NOT_APPLICABLE;
    this.paymentStatus = orderData.payment_status ? orderData.payment_status : NOT_APPLICABLE;
    this.products = orderData.products;
    this.site = orderData.site.name;
    this.siteGroups = orderData.site.groups || {};
    this.siteLocationName = orderData.site.location_name;
    this.siteBU = orderData.site.business_unit;
    this.siteRegion = orderData.site.region;
    this.siteDivision = orderData.site.division;
    this.approvedBy = orderData.approved_by;
    this.approvedByCount = orderData.approved_by_count;
    this.approvalStatus = orderData.approval_status;
    this.currentRequiredApproval =
      orderData.current_required_approval && orderData.current_required_approval.name;
    this.rejectedBy = Array.isArray(orderData.rejected_by) ? orderData.rejected_by[0] : null;
    this.lineLength = orderData.lines.filter((order) => order.product !== null).length;
    this.lines =
      orderData.lines &&
      orderData.lines
        .map((product) => new ProductSerializer(product))
        .sort((a, b) => parseInt(b.ordering, 10) - parseInt(a.ordering, 10));
    this.orderDate = date;
    this.rawOrderDate = orderData.order_date;
    this.trackingNumbers = orderData.tracking_numbers || [];
    this.trackingNumbersCount = orderData.tracking_numbers_count;
    this.customCheckoutData = orderData.custom_checkout_data || {};
    this.approvableByCurrentUser = orderData.approvable_by_current_user || false;
    this.customconfirmData = orderData.custom_confirm_data || {};
  }
}
