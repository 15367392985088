import React from 'react';
import PropTypes from 'prop-types';

const SwitchInput = ({ name, checked, label, handleChange, error }) => {
  const toggleCheck = () => {
    handleChange(name, !checked);
  };

  return (
    <div className="form-group custom-control custom-switch">
      <input
        type="checkbox"
        className="custom-control-input"
        id={`checkbox-${name}`}
        checked={checked}
        onChange={toggleCheck}
      />
      <label className="custom-control-label" htmlFor={`checkbox-${name}`}>
        {label}
      </label>

      {error && (
        <div className="form-field__error small-text">
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

SwitchInput.propTypes = {
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  error: PropTypes.string,
};

SwitchInput.defaultProps = {
  checked: false,
  error: '',
};

export default SwitchInput;
