import { formatFixNumber } from '../../utils/format';

export default class ProductSerializer {
  constructor(data) {
    const productData = data || {};

    this.shouldDisplay = !!productData.product;
    this.product = productData.product;
    this.text = productData.text;
    this.quantity = productData.quantity;
    this.sku = productData.sku;
    this.manufacturerSku = productData.manufacturer_sku;
    this.imgUrl = productData.image_url ? productData.image_url : null;
    this.type = productData.type;
    this.manufacturer = productData.manufacturer ? productData.manufacturer : '';
    this.unitPrice = formatFixNumber(productData.base_unit_price_value);
    this.discountUnitPrice = formatFixNumber(productData.discount_amount_value);
    this.taxLessPrice = formatFixNumber(productData.taxless_price);
    this.taxAmount = formatFixNumber(productData.tax_amount);
    this.lineTotal = formatFixNumber(productData.line_total_price);
    this.isCatalogPlus = productData.is_catalog_plus;
  }
}
