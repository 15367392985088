import React from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import useActions from 'shared/hooks/useActions';
import AdminCompaniesTable from 'scenes/admin/companies/components/table';
import SectionHeaderComponent from 'components/shared/section-header.component';
import BreadCrumbComponent from 'components/shared/breadcumb.component';
import BreadCrumbItem from 'components/shared/breadcrumb-item';
import { ADMIN_CREATE_COMPANY } from 'constants/app-routes';
import { API_ADMIN_COMPANIES } from 'lib/api-endpoints';
import { toast } from 'react-toastify';
import { GENERIC_TRY_LATER } from 'constants/error-messages';
import SearchComponent from 'components/shared/search.component';
import * as actions from './actions';
import messages from './messages';

function AdminPage() {
  const fetchCompanies = useActions(actions.fetchAdminCompanies);
  const dispatch = useDispatch();
  const history = useHistory();

  const goToCreateCompany = () => {
    history.push(ADMIN_CREATE_COMPANY);
  };

  const handleSearch = async (searchStr) => {
    try {
      dispatch({ type: 'GLOBALS_SET_IS_TABLE_LOADING', payload: true });
      await fetchCompanies(API_ADMIN_COMPANIES, { search: `${searchStr}` });
    } catch (error) {
      toast.error(GENERIC_TRY_LATER);
    } finally {
      dispatch({ type: 'GLOBALS_SET_IS_TABLE_LOADING', payload: false });
    }
  };

  return (
    <React.Fragment>
      <SectionHeaderComponent subtitle={messages.subtitle} sectionLabel={messages.title} />
      <div className="content__container">
        <BreadCrumbComponent>
          <BreadCrumbItem label={messages.title} />
        </BreadCrumbComponent>
        <div className="table-tools">
          <div className="table-tools__search">
            <SearchComponent handleSearch={handleSearch} />
          </div>
          <div className="table-tools__btns">
            <button
              type="button"
              onClick={goToCreateCompany}
              className="btn btn-primary table-tools__btn"
            >
              {messages.createCompanyButton}
            </button>
          </div>
        </div>
        <div className="container-fluid">
          <AdminCompaniesTable fetchCompanies={fetchCompanies} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default AdminPage;
