import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { authUser } from '../../actions/auth.actions';
import { shouldDisplaySpinner } from '../../actions/globals.actions';
import { getFieldValue } from '../../utils/forms';
import TextInput from '../forms/text-input.component';

class LoginComponent extends Component {
  static propTypes = {
    authUser: PropTypes.func.isRequired,
    user: PropTypes.object,
  };

  static defaultProps = {
    user: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      userIdentifier: '',
      password: '',
      errors: {},
    };
  }

  handleChange = (event) => {
    this.setState(getFieldValue(event.target));
  };

  submitLoginForm = (event) => {
    event.preventDefault();

    const errors = {};
    const { userIdentifier, password } = this.state;

    if (!userIdentifier) errors.userIdentifier = 'Please provide a valid username or email address';
    if (!password) errors.password = 'Please provide a valid password';

    this.setState({ errors });

    if (JSON.stringify(errors) === JSON.stringify({})) {
      this.loginUser();
    }
  };

  loginUser = async () => {
    const { authUser, user } = this.props;
    const { userIdentifier, password } = this.state;

    await authUser(userIdentifier, password);

    if (!user) {
      const errors = {};

      errors.password =
        'Please enter a correct username or email address, and password. Note that both fields may be case-sensitive.';
      this.setState({ errors });
    }
  };

  render() {
    const { userIdentifier, password, errors } = this.state;
    return (
      <Fragment>
        <div className="row">
          <div className="form-group col-12">
            <form onSubmit={this.submitLoginForm}>
              <TextInput
                error={errors.userIdentifier}
                value={userIdentifier}
                label="Username or email address"
                name="userIdentifier"
                placeHolder="Please provide a valid username or email address"
                handleChange={this.handleChange}
                inputClassName="login__input medium-bold"
              />

              <TextInput
                error={errors.password}
                value={password}
                label="Password"
                name="password"
                type="password"
                placeHolder="Please provide a valid password"
                handleChange={this.handleChange}
                inputClassName="login__input medium-bold"
              />

              <button type="submit" className="btn btn-primary btn-round w-100 medium-text mt-2">
                Log in
              </button>
            </form>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <a
              className="text-primary text-center text-primary medium-text d-block"
              href="/forgot-password"
            >
              Forgot your password?
            </a>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  displaySpinner: state.globals.shouldDisplaySpinner,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  authUser,
  shouldDisplaySpinner,
})(LoginComponent);
