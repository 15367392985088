import queryString from 'query-string';
import floor from 'lodash/floor';
import ceil from 'lodash/ceil';
import reverse from 'lodash/reverse';
import uniqBy from 'lodash/uniqBy';

export default class PaginationSerializer {
  constructor(data) {
    this.initializeVariables(data);
    this.currentPage = this.calculateCurrentPage();
    this.url = this.getUrl();

    if (!this.currentPage || !this.url) {
      return;
    }

    this.showingPageDown = (this.currentPage - 1) * this.limit + 1;
    this.showingPageUp =
      this.currentPage * this.limit > this.count ? this.count : this.currentPage * this.limit;
    this.shouldDisplayPagination = this.count > 20;
    this.paginationLinks = this.getPaginationLinks();
  }

  initializeVariables = (data) => {
    this.next = data ? data.next : null;
    this.previous = data ? data.previous : null;
    this.currentUrl = data ? data.current_url : null;
    this.nextParsedUrl = this.next ? queryString.parseUrl(this.next).query : null;
    this.prevParsedUrl = this.previous ? queryString.parseUrl(this.previous).query : null;
    this.parsedCurrentUrl = this.currentUrl ? queryString.parseUrl(this.currentUrl).query : null;
    this.count = (data && data.count) || 0;
    this.limit = parseInt(
      (this.prevParsedUrl && this.prevParsedUrl.limit) ||
        (this.nextParsedUrl && this.nextParsedUrl.limit) ||
        0,
      10,
    );
    this.pageCount = ceil(this.count / this.limit);
    this.pagesToShow = 5;
    this.counterPages = 1;
    const queryObject = data
      ? {
          ...this.parsedCurrentUrl,
          ...this.nextParsedUrl,
          ...this.prevParsedUrl,
          ...data.queryParams,
        }
      : { ...this.nextParsedUrl, ...this.prevParsedUrl };
    delete queryObject.limit;
    delete queryObject.offset;
    this.queryParams = queryObject;
  };

  getUrl = () => {
    if (this.next) {
      return queryString.parseUrl(this.next).url;
    }
    if (this.previous) {
      return queryString.parseUrl(this.previous).url;
    }
    return null;
  };

  calculateCurrentPage = () => {
    let currentPage = null;
    if (this.prevParsedUrl && this.prevParsedUrl.offset) {
      currentPage = floor(parseInt(this.prevParsedUrl.offset, 10) / this.limit);
    }
    if (this.nextParsedUrl && this.nextParsedUrl.offset) {
      currentPage = floor(parseInt(this.nextParsedUrl.offset, 10) / this.limit);
    }
    if (!this.next) {
      currentPage = this.pageCount;
    }

    return currentPage;
  };

  getPaginationLinks = () => {
    const prevLinks = [];
    const nextLinks = [];
    const current = {
      page: this.currentPage,
      active: true,
      url: null,
    };
    const queryParams = queryString.stringify(this.queryParams);

    const remainingPages = this.pageCount - this.currentPage;
    const halfOfPagesToShow = Math.ceil(this.pagesToShow / 2);
    const rangePages = remainingPages > halfOfPagesToShow ? halfOfPagesToShow : remainingPages;
    for (let i = this.currentPage; i >= 1; i -= 1) {
      if (this.counterPages > this.pagesToShow - rangePages) {
        break;
      }
      if (i > this.currentPage - this.pagesToShow && i !== this.currentPage && i !== 1) {
        prevLinks.push({
          page: i,
          active: false,
          url: `${this.url}?limit=${this.limit}&offset=${this.limit * (i - 1)}&${queryParams}`,
        });
      }
      this.counterPages += 1;
    }
    if (prevLinks.length < Math.floor(this.pagesToShow / 2) - 1) {
      this.counterPages -= 1;
    }

    for (let i = this.currentPage; i <= this.count; i += 1) {
      if (this.counterPages > this.pagesToShow) {
        break;
      }
      if (
        i < this.currentPage + this.pagesToShow &&
        i !== this.currentPage &&
        i <= this.pageCount &&
        i !== this.pageCount
      ) {
        nextLinks.push({
          page: i,
          active: false,
          url: `${this.url}?limit=${this.limit}&offset=${this.limit * (i - 1)}&${queryParams}`,
        });
      }
      this.counterPages += 1;
    }

    prevLinks.push({
      page: 1,
      active: this.currentPage === 1,
      url: `${this.url}?limit=${this.limit}&offset=0&${queryParams}`,
    });
    nextLinks.push({
      page: this.pageCount,
      active: false,
      url: `${this.url}?limit=${this.limit}&offset=${
        this.limit * (this.pageCount - 1)
      }&${queryParams}`,
    });

    return uniqBy([...reverse(prevLinks), current, ...nextLinks], 'page');
  };
}
