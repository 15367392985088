import { toast } from 'react-toastify';

import siteManagerAPI from 'lib/api-manager';
import { API_ADMIN_COMPANIES } from 'lib/api-endpoints';
import { ADMIN_COMPANIES_FETCH } from 'lib/types';
import { FETCH_ADMIN_COMPANIES_ERROR } from 'constants/error-messages';
import CompaniesSerializer from 'lib/serializers/mcc-admin/CompaniesSerializer';

export const fetchAdminCompanies =
  (url = API_ADMIN_COMPANIES, params = {}) =>
  async (dispatch) => {
    try {
      const response = await siteManagerAPI.get(url, { params });
      const serializedCompanies = new CompaniesSerializer(response.data);

      dispatch({ type: ADMIN_COMPANIES_FETCH, payload: serializedCompanies });
    } catch (error) {
      toast.error(FETCH_ADMIN_COMPANIES_ERROR);
    }
  };
