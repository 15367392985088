function getValue(data) {
  return data.value;
}

export function formatFormData(data) {
  return {
    tenantGroup: data.tenantGroup,
    customerERPIdentifier: data.customerERPIdentifier,
    hasOrderApproval: getValue(data.hasOrderApproval),
    hasOrderTracking: getValue(data.hasOrderTracking),
    hasCompanyHierarchy: getValue(data.hasHierarchy),
    // catalogs: data.catalogs.map((catalog) => catalog.id),
  };
}
