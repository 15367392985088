import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import { setModalData } from '../../actions/globals.actions';
import { API_SITES } from '../../lib/api-endpoints';
import { getFieldValue } from '../../utils/forms';
import siteManagerAPI from '../../lib/api-manager';
import TextInput from './text-input.component';
import FormWrapper from "./form-wrapper.component";

class SiteShuupUser extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    site: PropTypes.shape({
      name: PropTypes.string,
      locationName: PropTypes.string,
      businessUnit: PropTypes.string,
      division: PropTypes.string,
      region: PropTypes.string,
      ecommerceUsername: PropTypes.string,
      ecommerceEmail: PropTypes.string,
      password: PropTypes.string,
      siteType: PropTypes.shape(),
      node: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      siteWarehouse: PropTypes.shape(),
    }),
    onGoBack: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const {
      site: {
        name,
        locationName,
        businessUnit,
        division,
        region,
        ecommerceUsername,
        ecommerceEmail,
        password,
        siteType,
        node,
        siteWarehouse,
        shouldEnableAnalytics,
      },
    } = props;

    this.state = {
      name,
      locationName,
      businessUnit,
      division,
      region,
      ecommerceUsername,
      ecommerceEmail,
      password,
      siteType,
      errors: {},
      node,
      siteWarehouse,
      shouldEnableAnalytics,
    };
  }

  validateForm = () => {
    const errors = {};
    const { ecommerceUsername, password } = this.state;

    if (!ecommerceUsername) errors.ecommerceUsername = 'Shop field is required';
    if (!password) errors.password = 'Password field is required';

    this.setState({ errors });

    return errors;
  };

  handleChange = (event) => {
    this.setState(getFieldValue(event.target));
  };

  createShuupUser = async (event) => {
    event.preventDefault();
    const { onSubmit } = this.props;

    const {
      name,
      locationName,
      division,
      businessUnit,
      region,
      ecommerceUsername,
      ecommerceEmail,
      password,
      siteType,
      node,
      siteWarehouse,
      shouldEnableAnalytics,
    } = this.state;

    const siteBody = {
      name,
      location_name: locationName,
      division,
      business_unit: businessUnit,
      region,
      ecommerce_username: ecommerceUsername,
      ecommerce_user_email: ecommerceEmail,
      site_type: siteType.value,
      organization_node: node,
      site_warehouse: siteWarehouse.id,
      enable_analytics: shouldEnableAnalytics,
    };

    const errors = this.validateForm();

    if (Object.entries(errors).length !== 0) {
      return;
    }

    if (password) {
      siteBody.password = password;
    }

    try {
      const response = await siteManagerAPI.post(API_SITES, siteBody);

      this.setState({
        name: '',
        locationName: '',
        businessUnit: '',
        division: '',
        region: '',
        ecommerceUsername: '',
        ecommerceEmail: '',
        siteType: null,
        siteWarehouse: null,
        shouldEnableAnalytics: true,
      });

      const siteData = response.data;
      siteData.ecommerceUsername = siteData.ecommerce_username;
      delete siteData.ecommerce_username;
      siteData.businessUnit = siteData.business_unit;
      delete siteData.business_unit;
      siteData.ecommerceEmail = siteData.ecommerce_user_email;
      delete siteData.ecommerce_user_email;

      toast.success('Site successfully created');
      onSubmit(siteData);
    } catch (error) {
      const siteError = error.response.data;

      if (siteError) {
        if (siteError.division) {
          [errors.division] = siteError.division;
        }

        if (siteError.business_unit) {
          [errors.businessUnit] = siteError.business_unit;
        }
      }

      toast.error('There has been an error. Try again later');
      this.setState({ errors });
    }
  };

  render() {
    const { ecommerceUsername, password, errors } = this.state;

    const { onGoBack } = this.props;

    return (
      <Fragment>
        <FormWrapper>
          <form className="form-input mb-2 clearfix" onSubmit={this.createShuupUser}>
            <span className="d-block mb-2">
              A user will be created in the Shop for this site with the information below:
            </span>
            <TextInput
              error={errors.ecommerceUsername}
              value={ecommerceUsername}
              label="Shop Username"
              name="ecommerceUsername"
              required
              readOnly
              handleChange={this.handleChange}
            />

            <TextInput
              error={errors.password}
              value={password}
              label="Password"
              type="password"
              required
              name="password"
              handleChange={this.handleChange}
            />

            <div className="float-right">
              <button
                type="button"
                data-placement="right"
                className="btn btn-link mr-2"
                onClick={onGoBack}
              >
                BACK
              </button>
              <button type="submit" data-placement="right" className="btn btn-primary">
                <FontAwesomeIcon icon="map-marker-alt" />
                <span> SAVE SITE</span>
              </button>
            </div>
          </form>
        </FormWrapper>
      </Fragment>
    );
  }
}

export default SiteShuupUser;
