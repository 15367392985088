import { toast } from 'react-toastify';

import siteManagerAPI from '../../../lib/api-manager';
import { API_HIERARCHY_POLICIES } from '../../../lib/api-endpoints';
import { POLICY_SUCCESSFULLY_CREATED } from '../../../constants/messages';
import { CREATE_POLICY_ERROR } from '../../../constants/error-messages';

export async function createPolicy(id, body) {
  try {
    await siteManagerAPI.patch(API_HIERARCHY_POLICIES.replace('{id}', id), body);
    toast.success(POLICY_SUCCESSFULLY_CREATED);
  } catch (error) {
    toast.error(CREATE_POLICY_ERROR);
  }
}
