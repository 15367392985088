import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import PropTypes from 'prop-types';

const ConfigurationOption = ({ icon, title, description, redirect }) => (
  <Link className="configuration-option" to={redirect}>
    <FontAwesomeIcon className="configuration-option__icon" icon={icon} />

    <h3 className="configuration-option__title bold">{title}</h3>

    <p className="configuration-option__description text-base small-text">{description}</p>
  </Link>
);

ConfigurationOption.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  redirect: PropTypes.string.isRequired,
};

export default ConfigurationOption;
