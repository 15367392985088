import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SpinnerComponent = ({ displaySpinner }) => (
  <React.Fragment>
    {displaySpinner && (
      <div className="spinner">
        <span className="text-primary alternative-color-spinner">
          <FontAwesomeIcon icon="spinner" className="fa-spin" />
        </span>
      </div>
    )}
  </React.Fragment>
);

SpinnerComponent.propTypes = {
  displaySpinner: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  displaySpinner: state.globals.shouldDisplaySpinner,
});

export default connect(mapStateToProps, {})(SpinnerComponent);
