export default {
  usernamePrefixInputLabel: 'Username Prefix',
  submitButtonText: 'Create Node Configuration',
  tenantGroupInputLabel: 'Tenant Group',
  customerERPIdentifierInputLabel: 'Customer ERP Identifier',
  ecommerceCheckoutConfigurationInputLabel: 'Ecommerce Checkout Configuration',
  ecommerceAnalyticsConfigurationInputLabel: 'Ecommerce Analytics Configuration',
  hasValveConfiguratorLabel: 'Has Valve Configurator',
  hasMudPumpConfiguratorLabel: 'Has Mud Pump Configurator',
  hasProductRecommendationsLabel: 'Has Product Recommendations',
  hasExpediteShippingLabel: 'Has Expedite Shipping',
  isBuyoutNodeLabel: 'Is Buyout Node',
};
