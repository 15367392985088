import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
  objectToSearchString,
  getOffsetPagination,
  getUsernamePrefix,
} from 'utils/general';
import ApprovalRuleConfirmationModal from 'components/shared/approval-rule-confirmation-modal.component';
import { API_SITES, API_USERS, API_SITE_CLONE } from '../../lib/api-endpoints';
import { getFieldValue, EMAIL_REGEX } from '../../utils/forms';
import {
  formatCapitalizeFirstLetter,
  formatNodeToSelectInput,
  parseNodeOptions,
} from '../../utils/format';
import { SITE_SUCCESSFULLY_SAVED } from '../../constants/messages';
import { UNLIMITED_LIST_LIMIT } from '../../constants/values';
import BreadCrumbComponent from '../shared/breadcumb.component';
import SectionHeaderComponent from '../shared/section-header.component';
import siteManagerAPI from '../../lib/api-manager';
import SiteSerializer from '../../lib/serializers/SiteSerializer';
import TextInput from '../forms/text-input.component';
import BreadCrumbItem from '../shared/breadcrumb-item';
import SelectInput from '../forms/select-input';
import { fetchSiteTypes as fetchSiteTypesAction } from '../../actions/site-types';
import { fetchWarehouses as fetchWarehousesAction } from '../../actions/warehouses.actions';
import * as hierarchyActions from '../../shared/actions/hierarchy';
import * as hierarchySelectors from '../../shared/selectors/hierarchy';
import * as hierarchyUitls from '../../shared/utils/hierarchy';
import SwitchInput from "../forms/switch-input.component";

const capitalizeWord = (word) => word.charAt(0).toUpperCase() + word.slice(1);
const mapUsersToSelect = (options) => {
  if (!Array.isArray(options)) return null;

  return options.map((option) => ({
    ...option,
    value: option.id,
    label: option.username,
  }));
};
const formatSite = (site) => {
  if (!site) return {};
  return {
    id: site.id,
    name: site.name,
    locationName: site.locationName,
    division: site.division,
    businessUnit: site.businessUnit,
    region: site.region,
    username: site.eCommerceUser,
    email: site.eCommerceEmail,
    users: mapUsersToSelect(site.users),
    siteType: site.siteType
      ? {
          ...site.siteType,
          value: site.siteType.id,
          label: site.siteType.name,
        }
      : null,
    siteWarehouse: site.siteWarehouse
      ? {
          ...site.siteWarehouse,
          value: site.siteWarehouse.id,
          label: site.siteWarehouse.name,
        }
      : null,
    hasApprovalRule: site.hasApprovalRule,
    enableAnalytics: site.enableAnalytics,
  };
};
const mapSiteTypesToSelect = (options) => {
  if (!Array.isArray(options)) return null;

  return options.map((option) => ({
    value: option.id,
    label: option.name,
  }));
};

function SiteDetailsComponent({
  location,
  queryParams: queryParamsProp,
  currentPage,
  hasCompanyHierarchy,
  fetchCurrentNodeConfig,
  companyId,
  fetchSiteTypes,
  fetchWarehouses,
  fetchNodes,
  match,
  currentNode,
  warehouses,
  companyShortName,
  availableNodes,
  siteTypes,
  user,
}) {
  const queryParams = {
    ...queryParamsProp,
    offset: getOffsetPagination(currentPage),
  };
  const [site, setSite] = useState(formatSite(location.site));
  const [id, setId] = useState(
    location.action === 'CLONE' ? null : location.site && location.site.id
  );
  const [errors, setErrors] = useState({});
  const [node, setNode] = useState(null);
  const [prefix, setPrefix] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldEnableAnalytics, setShouldEnableAnalytics] = useState(true);
  const triggerBuildEcommerceUsername = useRef(false);

  const buildEcommerceUsername = () => {
    const { name, siteType } = site;
    if (!name || !siteType) return;
    const adjustedName =
      name.toLowerCase().indexOf(siteType.label.toLowerCase()) === -1
        ? `${siteType.label} ${name}`
        : name;
    const username = `${prefix}-${adjustedName
      .split(' ')
      .map((element) => capitalizeWord(element))
      .join('')}`;

    setSite((currentSite) => ({ ...currentSite, username }));
  };

  useEffect(() => {
    if (triggerBuildEcommerceUsername.current) {
      buildEcommerceUsername();
    }

    triggerBuildEcommerceUsername.current = false;
  }, [triggerBuildEcommerceUsername.current]);

  const fetchData = async () => {
    try {
      let allUsersArray = [];
      if (!hasCompanyHierarchy) {
        const usersResponse = await siteManagerAPI.get(
          `${API_USERS}?limit=${UNLIMITED_LIST_LIMIT}`
        );
        allUsersArray = mapUsersToSelect(usersResponse.data.results);
      }
      const { siteId } = match.params;
      const response = await siteManagerAPI.get(`${API_SITES}${siteId}/`);
      const newSite = new SiteSerializer(response.data);
      if (newSite.node && newSite.node.id)
        await fetchCurrentNodeConfig(newSite.node.id);
      setSite(formatSite(newSite));
      setShouldEnableAnalytics(newSite.enableAnalytics);
      setNode(formatNodeToSelectInput(newSite.node));
      setId(location.action === 'CLONE' ? null : siteId);
      setAllUsers(allUsersArray);
    } catch (error) {
      console.log( {error} );
    }

    const newPrefix = await getUsernamePrefix(
      hasCompanyHierarchy,
      currentNode,
      companyId
    );
    setPrefix(newPrefix);
    triggerBuildEcommerceUsername.current = true;

    fetchSiteTypes();
    if (!warehouses) {
      fetchWarehouses();
    }

    if (hasCompanyHierarchy) {
      fetchNodes(UNLIMITED_LIST_LIMIT);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = ({ target }) => {
    setSite((currentSite) => ({ ...currentSite, ...getFieldValue(target) }));
  };

  const displayModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const updateSite = async () => {
    const {
      name,
      locationName,
      division,
      businessUnit,
      region,
      email,
      users,
      siteType,
      siteWarehouse,
      username,
    } = site;

    const siteBody = {
      name,
      location_name: locationName,
      division,
      business_unit: businessUnit,
      region,
      ecommerce_username: username,
      ecommerce_user_email: email,
      site_type: siteType.value,
      site_warehouse: siteWarehouse.value,
      enable_analytics: shouldEnableAnalytics,
    };

    closeModal();

    if (!hasCompanyHierarchy) {
      siteBody.users = users.map((user) => user.value);
    } else {
      siteBody.organization_node = node && node.id;
    }

    try {
      if (id) {
        await siteManagerAPI.patch(`${API_SITES}${id}/`, siteBody);
      } else {
        await siteManagerAPI.post(
          `${API_SITE_CLONE.replace('{id}', location.site.id)}`,
          siteBody
        );
      }
      toast.success(SITE_SUCCESSFULLY_SAVED);
    } catch (error) {
      const siteError = error.response.data;
      const errorsObj = {};

      if (siteError) {
        if (siteError.name) {
          errorsObj.name = formatCapitalizeFirstLetter(siteError.name[0]);
        }

        if (siteError.locationName) {
          errorsObj.locationName = formatCapitalizeFirstLetter(
            siteError.locationName[0]
          );
        }

        if (siteError.division) {
          [errorsObj.division] = siteError.division;
        }

        if (siteError.business_unit) {
          [errorsObj.businessUnit] = siteError.business_unit;
        }

        if (siteError.node) {
          [errorsObj.node] = siteError.node;
        }
      }
      setErrors(errorsObj);
      toast.error(
        'There has been an error while saving the Site. Try again later'
      );
    }
    return null;
  };

  const submitSiteForm = async (event) => {
    event.preventDefault();

    const {
      name,
      locationName,
      division,
      businessUnit,
      region,
      username,
      email,
      siteType,
      hasApprovalRule,
    } = site;
    const errorsObj = {};

    if (!username) {
      errorsObj.username = 'Shop Username field is required';
    } else if (!username.toLowerCase().startsWith(`${prefix.toLowerCase()}-`)) {
      errorsObj.username = `Shop Username prefix should start with "${prefix}-"`;
    }

    if (!siteType) errorsObj.siteType = 'Type field is required';

    if (user.company.hasLocationNameRequired && !locationName) {
      errorsObj.locationName = 'Location Name field is required';
    }

    if (!email) errorsObj.email = 'Email field is required';
    else if (EMAIL_REGEX.exec(email) === null)
      errorsObj.email = 'Incorrect email format';

    if (!name) errorsObj.name = 'Name field is required';

    if (!hasCompanyHierarchy) {
      if (!division) errorsObj.division = 'Division field is required';
      if (!businessUnit)
        errorsObj.businessUnit = 'Business Unit field is required';
      if (!region) errorsObj.region = 'Region field is required';
    } else if (!node) errorsObj.node = 'Node field is required';

    setErrors(errorsObj);

    if (JSON.stringify(errorsObj) === JSON.stringify({})) {
      const updateHandler = id && hasApprovalRule ? displayModal : updateSite;
      updateHandler();
    }
  };

  const handleNodeChange = async (nodeOption) => {
    if (nodeOption) {
      await fetchCurrentNodeConfig(nodeOption.id);
      const newPrefix = await getUsernamePrefix(
        hasCompanyHierarchy,
        currentNode,
        companyId
      );
      setPrefix(newPrefix);
      triggerBuildEcommerceUsername.current = true;
    }
    setNode(nodeOption);
  };

  const handleNameChange = ({ target }) => {
    setSite((currentSite) => ({ ...currentSite, name: target.value }));
    triggerBuildEcommerceUsername.current = true;
  };

  const assignSite = (users) => {
    setSite((currentSite) => ({ ...currentSite, users }));
  };

  const handleSiteTypeSelectChange = (siteType) => {
    setSite((currentSite) => ({ ...currentSite, siteType }));
    triggerBuildEcommerceUsername.current = true;
  };

  const handleSwitchInputChange = (_, checked) => {
    setShouldEnableAnalytics(checked);
  };

  const handleWarehouseChange = (siteWarehouse) => {
    setSite((currentSite) => ({ ...currentSite, siteWarehouse }));
  };
  const {
    name,
    locationName,
    division,
    businessUnit,
    region,
    email,
    users,
    siteType,
    siteWarehouse,
    hasApprovalRule,
    username,
  } = site;

  const actionPrefix = location.action === 'CLONE' ? 'Clone' : 'Edit';

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { isDisabled, isSelected }) => ({
      ...styles,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'grey' : 'grey'),
        color: 'white',
        fontWeight: '500',
      },
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: '#104B8C',
      color: 'white',
      fontWeight: '500',
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };

  return (
    <>
      <SectionHeaderComponent
        subtitle="Sites"
        sectionLabel={`${actionPrefix} Site`}
      />

      <div className="content__container">
        <BreadCrumbComponent>
          <BreadCrumbItem label="Sites" link="/sites" />
          <BreadCrumbItem label={`${actionPrefix} Site`} />
        </BreadCrumbComponent>

        <div className="order-details__return-btn">
          <Link
            to={{
              pathname: '/sites',
              search: objectToSearchString(queryParams),
            }}
          >
            <FontAwesomeIcon icon="arrow-left" /> Return to sites
          </Link>
        </div>

        <div className="container-fluid mb-3">
          <div className="row">
            <div className="form-group col-12 col-lg-4">
              <form onSubmit={submitSiteForm}>
                <TextInput
                  error={errors.name}
                  value={name}
                  label="Name"
                  name="name"
                  required
                  handleChange={handleNameChange}
                />
                <SelectInput
                  label="Type"
                  name="role"
                  value={siteType}
                  error={errors.siteType}
                  options={siteTypes}
                  mapOptions={mapSiteTypesToSelect}
                  handleChange={handleSiteTypeSelectChange}
                  placeholder="Select..."
                  required
                  isSearchable
                  closeMenuOnSelect
                />
                {user.company.hasLocationNameRequired && (
                  <TextInput
                    error={errors.locationName}
                    value={locationName}
                    label="Location Name"
                    name="locationName"
                    required
                    handleChange={handleChange}
                  />
                )}
                <SelectInput
                  label="Warehouse Name"
                  name="siteWarehouse"
                  value={siteWarehouse}
                  error={errors.siteWarehouse}
                  options={warehouses}
                  mapOptions={mapSiteTypesToSelect}
                  handleChange={handleWarehouseChange}
                  placeholder="Select..."
                  required
                  closeMenuOnSelect
                />
                {hasCompanyHierarchy && (
                  <SelectInput
                    label="Node"
                    name="node"
                    value={formatNodeToSelectInput(node)}
                    error={errors.node}
                    options={availableNodes}
                    mapOptions={parseNodeOptions}
                    handleChange={handleNodeChange}
                    closeMenuOnSelect
                    isSearchable
                    isClearable
                    required
                  />
                )}
                {!hasCompanyHierarchy && (
                  <TextInput
                    error={errors.businessUnit}
                    value={businessUnit}
                    label="Business Unit"
                    name="businessUnit"
                    required
                    handleChange={handleChange}
                  />
                )}
                {!hasCompanyHierarchy && (
                  <TextInput
                    error={errors.division}
                    value={division}
                    label="Division"
                    name="division"
                    required
                    handleChange={handleChange}
                  />
                )}
                {!hasCompanyHierarchy && (
                  <TextInput
                    error={errors.region}
                    value={region}
                    label="Region"
                    name="region"
                    required
                    handleChange={handleChange}
                  />
                )}
                <TextInput
                  error={errors.username}
                  value={username}
                  label="Shop Username"
                  name="username"
                  required
                  handleChange={handleChange}
                />
                <TextInput
                  error={errors.email}
                  value={email}
                  label="Email"
                  name="email"
                  required
                  handleChange={handleChange}
                />
                {!hasCompanyHierarchy && (
                  <div className="form-group">
                    <label htmlFor="users-selector">Users</label>
                    <Select
                      value={users}
                      onChange={assignSite}
                      options={allUsers}
                      closeMenuOnSelect={false}
                      styles={colourStyles}
                      inputId="users-selector"
                      isMulti
                      isSearchable
                    />
                  </div>
                )}

                <SwitchInput
                  label="Enable Analytics"
                  name="shouldEnableAnalytics"
                  checked={shouldEnableAnalytics}
                  handleChange={handleSwitchInputChange}
                />

                <button
                  type="submit"
                  data-placement="right"
                  className="btn btn-primary float-right"
                >
                  SAVE SITE
                </button>
              </form>
            </div>
          </div>
        </div>

        <ApprovalRuleConfirmationModal
          isOpen={isModalOpen}
          closeHandler={closeModal}
          confirmHandler={() => updateSite()}
          obj={{ name, hasApprovalRule }}
          objName="site"
          action="edit"
        />
      </div>
    </>
  );
}

SiteDetailsComponent.propTypes = {
  location: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  companyShortName: PropTypes.string.isRequired,
  queryParams: PropTypes.shape(),
  currentPage: PropTypes.number,
  siteTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  warehouses: PropTypes.arrayOf(PropTypes.shape()),
  hasCompanyHierarchy: PropTypes.bool,
  fetchSiteTypes: PropTypes.func.isRequired,
  fetchWarehouses: PropTypes.func.isRequired,
  availableNodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ).isRequired,
  fetchNodes: PropTypes.func.isRequired,
  fetchCurrentNodeConfig: PropTypes.func.isRequired,
  currentNode: PropTypes.shape({
    customConfig: PropTypes.shape({
      usernamePrefix: PropTypes.string,
    }),
  }).isRequired,
  companyId: PropTypes.number.isRequired,
  user: PropTypes.shape({
    company: PropTypes.shape({
      hasLocationNameRequired: PropTypes.bool,
    }).isRequired,
  }).isRequired,
};

SiteDetailsComponent.defaultProps = {
  currentPage: 0,
  queryParams: {},
  hasCompanyHierarchy: false,
};

const mapStateToProps = (state) => ({
  companyShortName: state.globals.company.shortName,
  queryParams: state.sites.sites.pagination.queryParams,
  currentPage: state.sites.sites.pagination.currentPage,
  siteTypes: state.siteTypes.siteTypes.results,
  hasCompanyHierarchy: _.get(
    state,
    'auth.user.company.hasCompanyHierarchy',
    false
  ),
  availableNodes: hierarchySelectors
    .availableNodes(state)
    .map(hierarchyUitls.formatNodeWithGroupName),
  currentNode: hierarchySelectors.currentNode(state),
  companyId: _.get(state, 'auth.user.company.id', null),
  warehouses: state.warehouses.names,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  fetchSiteTypes: fetchSiteTypesAction,
  fetchWarehouses: fetchWarehousesAction,
  ...hierarchyActions,
})(SiteDetailsComponent);
