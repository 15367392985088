import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../../../../components/forms/text-input.component';
import useValidation from '../../../../shared/hooks/useValidation';
import messages from './messages';
import useActions from '../../../../shared/hooks/useActions';

import * as validations from './validations';
import * as actions from './actions';
import * as selectors from './selectors';
import { useStructuredSelector } from '../../../../shared/hooks/useSelector';

function GroupForm(props) {
  const { clearOnSubmit, onSubmit, submitButtonText, currentGroupId } = props;
  const [name, setName] = useState('');
  const [errors, setErrors] = useState({});
  const { fetchCurrentGroup } = useActions(actions);
  const validate = useValidation(validations.createValidations);
  const { currentGroup } = useStructuredSelector(selectors);

  useEffect(() => {
    if (currentGroupId) fetchCurrentGroup(currentGroupId);
  }, [currentGroupId, fetchCurrentGroup]);

  useEffect(() => {
    if (currentGroup && currentGroup.id === Number(currentGroupId)) {
      setName(currentGroup.name);
    }
  }, [currentGroupId, currentGroup]);

  const resetFields = useCallback(() => {
    setName('');
  }, []);

  const handleTextInputChange = useCallback(
    (handler) => (event) => handler(event.target.value),
    [],
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setErrors([]);
      const nameToSubmit = name || '';
      const validationErrors = validate({ name: nameToSubmit });

      if (validationErrors) {
        setErrors(validationErrors);
      } else {
        const data = { name: nameToSubmit };
        try {
          await onSubmit(data);
          if (clearOnSubmit) resetFields();
        } catch (error) {
          setErrors(error);
        }
      }
    },
    [name, onSubmit, clearOnSubmit, resetFields],
  );

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        error={errors.name}
        value={name}
        label="Name"
        name="name"
        required
        handleChange={handleTextInputChange(setName)}
      />
      <button type="submit" className="btn btn-primary mt-2">
        {submitButtonText}
      </button>
    </form>
  );
}

GroupForm.propTypes = {
  clearOnSubmit: PropTypes.bool,
  submitButtonText: PropTypes.string,
  currentGroupId: PropTypes.string,
  onSubmit: PropTypes.func,
};

GroupForm.defaultProps = {
  clearOnSubmit: true,
  currentGroupId: null,
  onSubmit: () => {},
  submitButtonText: messages.createButtonText,
};

export default GroupForm;
