import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { shouldDisplaySpinner } from 'actions/globals.actions';
import { fetchAlerts } from '../../actions/alerts.actions';
import { fetchOrganizationGroups } from '../../actions/hierarchy.actions';
import { API_ALERTS } from '../../lib/api-endpoints';
import { UNLIMITED_LIST_LIMIT } from '../../constants/values';
import { exportToExcel } from '../../utils/general';
import { GENERIC_TRY_LATER } from '../../constants/error-messages';
import { EXPORTING, NOTHING_TO_EXPORT } from '../../constants/messages';
import siteManagerAPI from '../../lib/api-manager';
import ExportExcelComponent from '../shared/export-excel.component';
import SectionHeaderComponent from '../shared/section-header.component';
import BreadCrumbComponent from '../shared/breadcumb.component';
import BreadCrumbItem from '../shared/breadcrumb-item';
import AlertsListComponent from './alerts-list.component';
import PaginationComponent from '../shared/pagination.component';
import AlertSerializer from '../../lib/serializers/AlertSerializer';

class AlertsComponent extends Component {
  static propTypes = {
    user: PropTypes.shape({ token: PropTypes.string }).isRequired,
    hasOrganizationGroups: PropTypes.bool,
    fetchOrganizationGroups: PropTypes.func.isRequired,
    alertsQueryParams: PropTypes.shape(),
    alerts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    hasCompanyHierarchy: PropTypes.bool.isRequired,
    shouldDisplaySpinner: PropTypes.func.isRequired,
  };

  static defaultProps = {
    alertsQueryParams: {},
    hasOrganizationGroups: false,
  };

  exportFilteredSites = async () => {
    const { alertsQueryParams, shouldDisplaySpinner } = this.props;
    alertsQueryParams.limit = UNLIMITED_LIST_LIMIT;

    shouldDisplaySpinner(true);
    toast.success(EXPORTING);

    try {
      const response = await siteManagerAPI.get(`${API_ALERTS}`, { params: alertsQueryParams });

      if (response.data.results.length === 0) {
        toast.warn(NOTHING_TO_EXPORT);
        return;
      }

      const parsedAlerts = response.data.results.map((alert) => {
        const alertSerialized = new AlertSerializer(alert);

        return {
          site: alertSerialized.site,
          order: alertSerialized.order.identifier,
          user: alertSerialized.user,
          alert_events_feed: alertSerialized.content,
          created: alertSerialized.created,
        };
      });

      exportToExcel(parsedAlerts, 'alerts');
    } catch (error) {
      toast.error(GENERIC_TRY_LATER);
    }

    shouldDisplaySpinner(false);
  };

  componentDidMount = () => {
    const { fetchOrganizationGroups, hasOrganizationGroups, hasCompanyHierarchy } = this.props;
    if (!hasOrganizationGroups && hasCompanyHierarchy) {
      fetchOrganizationGroups();
    }
  };

  render() {
    const { alerts } = this.props;

    return (
      <Fragment>
        <SectionHeaderComponent subtitle="Company" sectionLabel="Alerts" />

        <div className="content__container">
          <BreadCrumbComponent>
            <BreadCrumbItem label="Alerts" />
          </BreadCrumbComponent>

          <div className="container-fluid">
            <div className="table-tools">
              <div className="table-tools__search">
                <Link to="/alerts/configure" className="btn btn-primary">
                  <FontAwesomeIcon className="btn__icon" icon="cog" />
                  <span> Configure Rules</span>
                </Link>
              </div>
              <div className="table-tools__btns">
                <ExportExcelComponent exportData={this.exportFilteredSites} />
              </div>
            </div>
            <PaginationComponent />
            <AlertsListComponent alerts={alerts} />
            <PaginationComponent />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  displaySpinner: state.globals.shouldDisplaySpinner,
  user: state.auth.user,
  alerts: state.alerts.alerts.results,
  alertsQueryParams: state.alerts.alerts.pagination.queryParams || {},
  hasOrganizationGroups: state.hierarchy && state.hierarchy.groups.length > 0,
  hasCompanyHierarchy: _.get(state, 'auth.user.company.hasCompanyHierarchy', false),
});

export default connect(mapStateToProps, {
  shouldDisplaySpinner,
  fetchOrganizationGroups,
  fetchAlerts,
})(AlertsComponent);
