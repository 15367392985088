import get from 'lodash/get';

import { createSelector } from 'reselect';

function documentsStateSelector(state) {
  return state.documents;
}

export const documents = createSelector(documentsStateSelector, (state) =>
  get(state, 'table.results', []),
);

function authStateSelector(state) {
  return state.auth;
}

export const userId = createSelector(authStateSelector, (state) => get(state, 'user.id', -1));

export const paginationState = createSelector(documentsStateSelector, (state) =>
  get(state, 'table.pagination', {}),
);

export const queryParams = createSelector(documentsStateSelector, (state) =>
  get(state, 'table.pagination.queryParams', {}),
);
