import moment from 'moment';

import SiteSerializer from './SiteSerializer';
import CompanySerializer from './CompanySerializer';

export default class UserSerializer {
  constructor(data) {
    const user = data || null;
    if (user) {
      try {
        const lastLoginMoment = moment(user.last_login);
        this.lastLogin = lastLoginMoment.isValid()
          ? lastLoginMoment.format('MMM DD YYYY h:mm A')
          : '';
      } catch (error) {
        console.log(error);
      }
    }
    this.id = user ? user.id : 0;
    this.username = user ? user.username : '';
    this.ecommerce_username = user ? user.ecommerce_username : '';
    this.firstName = user ? user.first_name : '';
    this.lastName = user ? user.last_name : '';
    this.email = user ? user.email : '';
    this.isCompanyAdmin = user ? user.is_company_admin : null;
    this.isApprover = user ? user.is_approver : null;
    this.canImpersonate = user ? user.can_impersonate : null;
    this.canViewOrderTracking = user ? user.can_view_order_tracking : null;
    this.token = user && user.token ? data.token : null;
    this.title = user && user.title ? data.title : '';
    this.sites = [];
    if (user?.sites) {
      this.sites = (user?.sites?.length > 4 ? user.sites.slice(0, 4) : user.sites).map(
        (site) => new SiteSerializer(site),
      );
    }
    this.allSites = user?.sites?.length ? user.sites.map((site) => new SiteSerializer(site)) : [];
    this.sitesCount = user ? user.sites_count : 0;
    this.siteGroups = (user && user.site && user.site.groups) || {};
    this.shouldEnableAnalytics = user && user.enable_analytics;
    this.alertsEnabled = user && user.alerts_enabled;
    this.ecommerceUsername = user && user.ecommerce_username ? user.ecommerce_username : '';
    this.role = user ? user.role : null;
    this.company = user && user.company ? new CompanySerializer(user.company) : null;
    this.fullName = `${this.firstName} ${this.lastName}`;
    this.node = user && user.node;
    this.isMccAdmin = user && user.is_mcc_admin;
    this.hasApprovalRule = user && user.has_approval_rule;
    this.textMessagesEnabled = user && user.enable_text_messages;
    this.phoneNumber = user && user.phone;
  }

  get nameOrUsername() {
    if (this.firstName.length > 0 || this.lastName.length > 0) {
      return `${this.firstName} ${this.lastName}`;
    }
    return this.username;
  }
}
