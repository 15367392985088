import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import moment from 'moment-timezone';

import siteManagerAPI from 'lib/api-manager';
import TableFunctions from 'components/shared/table-functions';
import TableActions from 'components/shared/table-actions.component';
import SectionHeaderComponent from 'components/shared/section-header.component';
import BreadCrumbComponent from 'components/shared/breadcumb.component';
import BreadCrumbItem from 'components/shared/breadcrumb-item';
import Pagination from 'shared/components/pagination';
import { setModalData as setModalDataAction } from 'actions/globals.actions';
import useForceUpdate from 'shared/hooks/use-force-update';

import messages from './messages';

function getFileExtensionFromUrl(url) {
  const fileNameRegExp = /.*\/[\w-]+\.([.\w-]+)/;
  const match = url.match(fileNameRegExp);

  if (match && match.length > 1) {
    return match[1];
  }

  return 'pdf';
}

const headers = [
  {
    text: messages.select,
    name: 'selected',
    isSortable: false,
  },
  {
    text: messages.documentName,
    name: 'document_name',
    filterType: 'INPUT',
    isSortable: true,
    isActivated: false,
  },
  {
    text: messages.documentType,
    name: 'document_type',
    filterType: 'INPUT',
    isSortable: true,
    isActivated: false,
  },
  {
    text: messages.orderIdentifier,
    name: 'order_identifier',
    filterType: 'INPUT',
    isSortable: true,
    isActivated: false,
  },
  {
    text: messages.poNumber,
    name: 'po_number',
    filterType: 'INPUT',
    isSortable: true,
    isActivated: false,
  },
  {
    text: messages.afeNumber,
    name: 'afe_number',
    filterType: 'INPUT',
    isSortable: true,
    isActivated: false,
  },
  {
    text: messages.documentDate,
    name: 'order_date',
    defaultDescendent: true,
    isActivated: true,
  },
  {
    text: messages.actions,
    name: 'actions',
    isSortable: false,
  },
];

function DocumentRepositoryTable(props) {
  const {
    documents,
    documentsQueryParams,
    paginationState,
    fetchDocuments,
    suggestionsURL,
    baseUrl,
    title,
    subtitle,
    setModalData,
    useRowsDataFirst,
  } = props;
  const [selectedDocuments, setSelectedDocuments] = useState({});
  const [isMasterChecked, setIsMasterChecked] = useState(false);
  const isMasterCheckedRef = useRef(isMasterChecked);
  const documentsRef = useRef(documents);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    documentsRef.current = documents;
    isMasterCheckedRef.current = isMasterChecked;
    forceUpdate();
  }, [documents, isMasterChecked]);

  const downloadFile = async (fileName, url) => {
    const fileExtension = getFileExtensionFromUrl(url);
    const parsedUrl = new URL(url);
    // add timestamp to avoid chromim based browsers from using cache
    parsedUrl.searchParams.append('mcc_timestamp', Date.now());
    try {
      const response = await axios({
        url: parsedUrl.href,
        method: 'GET',
        responseType: 'blob',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', `${fileName}.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      toast.error(messages.downloadFailed);
    }
  };

  const updateMasterCheck = (updatedSelectedDocuments) => {
    const selectedCount = Object.values(updatedSelectedDocuments).filter((selected) => selected)
      .length;

    setIsMasterChecked(documentsRef.current.length === selectedCount);
  };

  const toggleDocument = (documentId) => {
    const prevValue = selectedDocuments;
    const wasSelected = prevValue[documentId] || false;
    const newSelectedDocuments = { ...prevValue, [documentId]: !wasSelected };
    setSelectedDocuments(newSelectedDocuments);

    updateMasterCheck(newSelectedDocuments);
  };

  const renderCustomCell = (document) => {
    return (
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="form-group form-check"
          id={`select_document_${document.id}`}
          checked={selectedDocuments[document.id] || false}
          onChange={() => toggleDocument(document.id)}
        />
      </div>
    );
  };

  const getFailedDocumentsList = (modalProps) =>
    modalProps.errors.map((error) => (
      <div className="text-primary medium-text mb-2">
        <span className="d-block">
          <b>{error}</b>
        </span>
      </div>
    ));

  const downloadSingleFile = async () => {
    try {
      const [selectedId] = Object.keys(selectedDocuments);
      const selectedFile = documents.find((document) => selectedId === `${document.id}`);
      downloadFile(selectedFile.documentName, selectedFile.downloadUrl);
    } catch (error) {
      toast.error(messages.downloadFailed);
    }
  };

  const downloadDocuments = async () => {
    const documentsToDownload = Object.keys(selectedDocuments).filter(
      (selectedDocumentId) => selectedDocuments[selectedDocumentId],
    );
    const params = {
      document_ids: documentsToDownload.join(','),
    };

    try {
      const response = await siteManagerAPI.get('/documents/download', {
        params,
      });
      const responseData = response.data;
      if (responseData.has_errors) {
        setModalData({
          size: 'modal-xs',
          title: 'the following documents failed to download',
          jsx: null,
          buttons: null,
          component: getFailedDocumentsList,
          componentProps: {
            errors: responseData.errors,
          },
        });
        $('#shared-modal').modal('show');
      }
      const fileName = moment().local().format('YYYY-MM-DD_HH-mm-ss');
      downloadFile(fileName, responseData.link);
    } catch (error) {
      toast.error(messages.downloadFailed);
    }
  };

  const countSelectedDocuments = () =>
    Object.values(selectedDocuments).reduce(
      (counter, isSelected) => (isSelected ? counter + 1 : counter),
      0,
    );

  const onDownloadButtonClicked = async () => {
    const numberOfDocuments = countSelectedDocuments();
    if (numberOfDocuments === 1) {
      await downloadSingleFile();
    } else {
      await downloadDocuments();
    }
  };

  const parsedDocuments = useMemo(
    () =>
      documents.map((document) => ({
        ...document,
        document_name: document.documentName,
        document_type: document.documentType,
        order_identifier: document.orderIdentifier,
        po_number: document.poNumber,
        invoice_number: document.invoiceNumber,
        afe_number: document.afeNumber,
        order_date: document.orderDate,
        actions: (
          <TableActions viewAndDownload={() => window.open(document.downloadUrl, '_blank')} />
        ),
        selected: { customCell: true, cell: document },
      })),
    [documents],
  );

  const filterDates = {
    start: 'orderDate_after',
    end: 'orderDate_before',
  };

  const selectedDocumentsCount = countSelectedDocuments();

  const toggleAll = (event) => {
    const toggleValue = event.target.checked;
    setIsMasterChecked(toggleValue);

    const newSelectedDocuments = {};
    documentsRef.current.forEach((document) => {
      newSelectedDocuments[document.id] = toggleValue;
    });
    setSelectedDocuments(newSelectedDocuments);
  };

  headers[0].renderHeader = () => (
    <React.Fragment>
      <div className="table-component__header-cell custom-control custom-switch">
        <input
          type="checkbox"
          className="form-group form-check table-component__header-checkbox"
          id="select_document_master"
          checked={isMasterCheckedRef.current}
          onChange={toggleAll}
        />
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <SectionHeaderComponent subtitle={subtitle} sectionLabel={title} />
      <div className="content__container">
        <BreadCrumbComponent>
          <BreadCrumbItem label={title} />
        </BreadCrumbComponent>
        <div className="container-fluid">
          <React.Fragment>
            <div className="table-tools">
              <div className="table-tools__search" />
              <div className="table-tools__btns">
                <button
                  type="button"
                  onClick={onDownloadButtonClicked}
                  className="btn btn-primary table-tools__btn"
                  disabled={selectedDocumentsCount === 0}
                >
                  <FontAwesomeIcon className="btn__icon" icon="file-download" /> Download{' '}
                  {selectedDocumentsCount} Document(s)
                </button>
              </div>
            </div>
            <Pagination state={paginationState} fetchResourceCB={fetchDocuments} />
            <TableFunctions
              headers={headers}
              suggestionsURL={suggestionsURL}
              dataURL={baseUrl}
              fetchData={fetchDocuments}
              tableQueryParams={documentsQueryParams}
              filterDates={filterDates}
              rowsData={parsedDocuments}
              useRowsDataFirst={useRowsDataFirst}
              isSnakeCaseParameters
              shouldDisplaySuggestions={false}
              renderCustomCell={renderCustomCell}
            />
            <Pagination state={paginationState} fetchResourceCB={fetchDocuments} />
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  );
}

DocumentRepositoryTable.propTypes = {
  fetchDocuments: PropTypes.func.isRequired,
  suggestionsURL: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  setModalData: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

export default connect(null, {
  setModalData: setModalDataAction,
})(DocumentRepositoryTable);
