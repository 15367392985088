import { createSelector } from 'reselect';

function adminStateSelector(state) {
  return state.mccAdmin;
}

export const currentCompany = createSelector(
  adminStateSelector,
  (state) => state.companies.currentCompany || {},
);
