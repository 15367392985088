import { toast } from 'react-toastify';

import siteManagerAPI from '../../../../lib/api-manager';
import { API_HIERARCHY_GROUPS } from '../../../../lib/api-endpoints';
import { HIERARCHY_SAVE_GROUPS } from '../../../../lib/types';
import { FETCH_GROUPS_ERROR } from '../../../../constants/error-messages';
import PaginationSerializer from '../../../../lib/serializers/PaginationSerializer';

export const fetchTableGroups = (url = API_HIERARCHY_GROUPS, params) => async (dispatch) => {
  try {
    const response = await siteManagerAPI.get(url, { params });
    const groups = {
      results: response.data.results,
      pagination: new PaginationSerializer(response.data),
    };
    // TODO: We have actions duplicated and this is starting to happen more, need to fix it
    dispatch({ type: HIERARCHY_SAVE_GROUPS, payload: { groups } });
  } catch (error) {
    toast.error(FETCH_GROUPS_ERROR);
  }
};
