import moment from 'moment-timezone';
import { NOT_APPLICABLE } from '../../constants/values';

export default class AlertSerializer {
  constructor(data) {
    const alertData = data || {};
    let date;

    try {
      date = moment.utc(alertData.created_on).local().format('MMM DD YYYY h:mm A');
    } catch (error) {
      date = NOT_APPLICABLE;
    }
    let site;
    try {
      site = alertData.trigger.site.name;
    } catch (error) {
      site = NOT_APPLICABLE;
    }
    this.id = alertData.id;
    this.content = alertData.content;
    this.created = date;
    this.site = site;
    this.siteGroups = (alertData.site && alertData.site.groups) || {};
    this.user = (alertData.user && alertData.user.email) || NOT_APPLICABLE;
    this.order = alertData.order;
  }
}
