import React from 'react';
import PhoneInput from 'react-phone-number-input/input-mobile';
import TextInput from './text-input.component';

class PhoneNumberInput extends TextInput {
  render() {
    const {
      error,
      value,
      required,
      label,
      name,
      handleChange,
      readOnly,
      placeHolder,
      labelClassName,
      inputClassName,
    } = this.props;

    return (
      <div className="form-field form-group">
        <label
          className={`medium-text ${labelClassName} ${
            required ? 'form-field__label--required' : ''
          }`}
          htmlFor={`input-${name}`}
        >
          {label}
        </label>

        <PhoneInput
          onChange={handleChange}
          value={value}
          defaultCountry="US"
          name={name}
          id={`input-${name}`}
          readOnly={readOnly}
          className={`form-control medium-text ${inputClassName}`}
          placeholder={placeHolder || label}
        />

        {error && (
          <div className="form-field__error small-text">
            <span>{error}</span>
          </div>
        )}
      </div>
    );
  }
}

export default PhoneNumberInput;
