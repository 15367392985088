import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import moment from 'moment';
import _ from 'lodash';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import randomize from 'randomatic';

import { debounce, getUrlSearch } from '../../utils/general';
import { setExcelHeaders, setModalData } from '../../actions/globals.actions';
import siteManagerAPI from '../../lib/api-manager';
import TableComponent from './table.component';
import ArrangeColumnsComponent from '../forms/arrange-columns.component';

const parseGroupsToHeaders = (groups, showNodes) => {
  if (!groups || !showNodes) {
    return [];
  }

  return groups.map((group) => ({
    header: {
      text: group.name,
      name: group.cleaned_name,
      filterType: 'SELECT',
      isActivated: false,
      isSortable: false,
      isFromHierarchy: true,
      depth: group.id,
    },
    groupOptions: group.nodes.map(([displayName, value]) => ({
      displayName,
      value,
    })),
  }));
};

class TableFunctions extends Component {
  static propTypes = {
    renderRedirect: PropTypes.func,
    fetchData: PropTypes.func,
    renderCustomCell: PropTypes.func,
    customCellFunction: PropTypes.func,
    rowsData: PropTypes.arrayOf(PropTypes.object),
    useRowsDataFirst: PropTypes.bool,
    tableQueryParams: PropTypes.object,
    redirectObject: PropTypes.object,
    headers: PropTypes.arrayOf(PropTypes.object),
    selectOptions: PropTypes.object,
    visibleGroups: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        cleaned_name: PropTypes.string,
        nodes: PropTypes.array,
      }),
    ),
    isSnakeCaseParameters: PropTypes.bool,
    dataURL: PropTypes.string,
    showNodes: PropTypes.bool,
    suggestionsURL: PropTypes.string,
    filterDates: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
    hasCompanyHierarchy: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      company: PropTypes.shape({
        hasLocationNameRequired: PropTypes.bool,
      }).isRequired,
    }).isRequired,
    shouldDisplaySuggestions: PropTypes.bool,
    columnsAreArrangeable: PropTypes.bool,
    setModalData: PropTypes.func.isRequired,
    setExcelHeaders: PropTypes.func.isRequired,
    isTableLoading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    fetchData: () => {},
    renderRedirect: () => {},
    renderCustomCell: () => {},
    isSnakeCaseParameters: false,
    tableQueryParams: {},
    redirectObject: {},
    rowsData: {},
    useRowsDataFirst: false,
    headers: [],
    selectOptions: {},
    dataURL: '',
    suggestionsURL: '',
    visibleGroups: null,
    customCellFunction: null,
    showNodes: false,
    filterDates: {
      start: '',
      end: '',
    },
    shouldDisplaySuggestions: true,
    columnsAreArrangeable: false,
  };

  constructor(props) {
    super(props);
    this.handleFilterBy = debounce(this.handleFilterBy, 400);
    const urlSearch = getUrlSearch();
    this.state = {
      urlSearch,
      suggestions: [],
      orderStartDate: '',
      orderEndDate: '',
      arrangeComponentKey: null,
    };
    this.state.groupHeaders = parseGroupsToHeaders(props.visibleGroups, props.showNodes);
    const { groupHeaders } = this.state;
    const headersTitle = groupHeaders.map((groupHeader) => groupHeader.header);
    this.state.headers = this.initHeaders(headersTitle.concat(props.headers), urlSearch);
    this.state.arrangeComponentKey = randomize('Aa0', 7);
  }

  componentDidMount() {
    const { dataURL, fetchData, headers, rowsData, useRowsDataFirst } = this.props;
    const { urlSearch } = this.state;

    headers.forEach((header) => {
      if (header.defaultAscendent) {
        urlSearch.ordering = this.getQueryParamsName(header.name);
      } else if (header.defaultDescendent) {
        urlSearch.ordering = `-${this.getQueryParamsName(header.name)}`;
      }
    });

    if (!useRowsDataFirst || !rowsData.length) {
      fetchData(dataURL, urlSearch);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      visibleGroups,
      showNodes,
      hasCompanyHierarchy,
      setExcelHeaders: setExcelHeadersAction,
    } = this.props;
    const { headers, urlSearch } = this.state;

    if (prevProps.visibleGroups.length !== visibleGroups.length) {
      const groupHeaders = parseGroupsToHeaders(visibleGroups, showNodes);
      const hierarchyHeaders = hasCompanyHierarchy
        ? groupHeaders.map((groupHeader) => groupHeader.header)
        : [];
      const initialHeaders = this.initHeaders(hierarchyHeaders.concat(headers), urlSearch);
      this.setState(
        {
          groupHeaders,
          headers: initialHeaders,
        },
        () => setExcelHeadersAction(initialHeaders),
      );
    }
  }

  getQueryParamsName(name) {
    const { isSnakeCaseParameters } = this.props;
    return isSnakeCaseParameters ? _.snakeCase(name) : name;
  }

  initHeaders = (headers, search) => {
    const sortedHeaders = this.sortHeaders(headers);
    return sortedHeaders.map((header) => {
      const initializedHeader = { ...header };

      if (initializedHeader.name === 'date') {
        const gte = search.orderDate_after;
        const lte = search.orderDate_before;
        if (gte && lte) {
          initializedHeader.isActivated = true;
          initializedHeader.startDate = moment(gte);
          initializedHeader.endDate = moment(lte);
        }
      } else {
        const filterName = this.getQueryParamsName(initializedHeader.name);
        const filterText = search[filterName];
        if (filterText) {
          initializedHeader.isActivated = true;
          initializedHeader.filterText = filterText;
        }
      }

      return initializedHeader;
    });
  };

  sortHeaders = (stateHeaders) => {
    const { hasCompanyHierarchy, user } = this.props;
    const { hierarchyHeaders = [], headers = [] } = _.groupBy(stateHeaders, (header) =>
      header.isFromHierarchy ? 'hierarchyHeaders' : 'headers',
    );
    if (hasCompanyHierarchy) {
      const sortedHierarchyHeaders = _.sortBy(hierarchyHeaders, 'depth');
      if (user.company.hasLocationNameRequired) {
        headers.splice(2, 0, ...sortedHierarchyHeaders);
      } else {
        headers.splice(1, 0, ...sortedHierarchyHeaders);
      }
    }
    return headers;
  };

  clearSuggestions = () => {
    this.setState({ suggestions: [] });
  };

  onChangeDates = ({ startDate, endDate }) => {
    const { tableQueryParams, dataURL, fetchData, filterDates } = this.props;
    const queryParams = { ...tableQueryParams } || {};

    this.setState({ orderStartDate: startDate, orderEndDate: endDate });
    if (startDate && endDate) {
      const endDateCloned = endDate.clone();
      startDate.startOf('day');
      endDateCloned.endOf('day');
      queryParams[filterDates.start] = startDate.format();
      queryParams[filterDates.end] = endDateCloned.format();
      fetchData(dataURL, queryParams);
    }
  };

  handleFilterBy = async (stringToSearch, filterName, callback) => {
    const {
      fetchData,
      dataURL,
      suggestionsURL,
      tableQueryParams,
      customCellFunction,
      shouldDisplaySuggestions,
    } = this.props;
    const { headers } = this.state;
    const queryParams = { ...tableQueryParams };
    const filter = filterName;

    if (stringToSearch) {
      queryParams[filter] = stringToSearch;
    } else {
      headers.forEach((header) => {
        if (header.name === filterName) {
          if (filterName === 'date') {
            delete queryParams.orderDate_after;
            delete queryParams.orderDate_before;
            this.setState({ orderStartDate: '', orderEndDate: '' });
          } else {
            delete queryParams[filter];
          }
        }
      });
    }

    await fetchData(dataURL, queryParams);
    queryParams.field = filterName;
    if (stringToSearch && shouldDisplaySuggestions) {
      const response = await siteManagerAPI.get(suggestionsURL, { params: queryParams });
      this.setState({ suggestions: response.data.results });
    }
    if (customCellFunction) customCellFunction();
    if (callback) callback();
  };

  displayColumnArrangementModal = () => {
    const { setModalData, setExcelHeaders: setExcelHeadersAction } = this.props;
    const { headers, arrangeComponentKey } = this.state;
    let reArrangedHeaders;

    setModalData(
      {
        size: 'modal-xs',
        title: 'Reorganize Columns',
        centeredButtons: true,
        component: ArrangeColumnsComponent,
        componentProps: {
          getArrangedHeaders: (arranged) => {
            reArrangedHeaders = arranged;
          },
          headers,
          arrangeComponentKey,
        },
        buttons: [
          <button
            type="button"
            onClick={() => {
              $('#shared-modal').modal('hide');
            }}
            className="btn btn-primary btn--round"
          >
            Close
          </button>,
        ],
      },
      () => {
        if (reArrangedHeaders.length) {
          this.setState({ headers: reArrangedHeaders });
          setExcelHeadersAction(reArrangedHeaders);
        }
      },
      null,
    );

    $('#shared-modal').modal('show');
  };

  toggleFilter(filterName, filters) {
    const { headers } = this.state;
    const clonedHeaders = [...headers];

    clonedHeaders.forEach((header) => {
      if (header.name === filterName) {
        header.isActivated = !header.isActivated;
      } else if (filters[header.name]) {
        header.isActivated = true;
        if (header.name === 'date') {
          header.startDate = filters[header.name].startDate;
          header.endDate = filters[header.name].endDate;
        }
      }
    });
    this.handleFilterBy('', filterName);
    this.setState({ headers: clonedHeaders });
  }

  renderRedirect() {
    const { redirectObject } = this.props;
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={redirectObject} />;
    }
    return null;
  }

  render() {
    const {
      rowsData,
      fetchData,
      dataURL,
      renderRedirect,
      renderCustomCell,
      selectOptions,
      visibleGroups,
      showNodes,
      isSnakeCaseParameters,
      shouldDisplaySuggestions,
      columnsAreArrangeable,
      isTableLoading,
    } = this.props;
    const { suggestions, orderStartDate, orderEndDate, headers } = this.state;

    const groupHeaders = parseGroupsToHeaders(visibleGroups, showNodes);
    const selectOptionsCloned = { ...selectOptions };
    groupHeaders.forEach((groupHeader) => {
      selectOptionsCloned[groupHeader.header.name] = groupHeader.groupOptions;
    });

    const parsedHeaders = isSnakeCaseParameters
      ? headers.map((header) => ({
          ...header,
          name: this.getQueryParamsName(header.name),
        }))
      : headers;

    return (
      <React.Fragment>
        {columnsAreArrangeable && (
          <div className="btn-group-sm">
            <button
              type="button"
              onClick={this.displayColumnArrangementModal}
              className="btn btn-primary table-tools__btn"
            >
              <FontAwesomeIcon className="btn__icon" icon="exchange-alt" />
              Reorganize Columns
            </button>
          </div>
        )}
        <TableComponent
          headers={parsedHeaders}
          rows={rowsData}
          suggestions={suggestions}
          fetchTableData={(queryParams) => fetchData(dataURL, queryParams)}
          renderRedirect={() => renderRedirect()}
          clearSuggestions={() => this.clearSuggestions()}
          filter={(value, filterName, callback) => this.handleFilterBy(value, filterName, callback)}
          toggleFilter={(name, filters) => this.toggleFilter(name, filters)}
          selectOptions={selectOptionsCloned}
          startDate={orderStartDate}
          endDate={orderEndDate}
          changeDates={({ startDate, endDate }) => this.onChangeDates({ startDate, endDate })}
          renderCustomCell={(cell) => renderCustomCell(cell)}
          shouldDisplaySuggestions={shouldDisplaySuggestions}
          isTableLoading={isTableLoading}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  visibleGroups: _.get(state, 'hierarchy.visibleGroups.results', []),
  hasCompanyHierarchy: _.get(state, 'auth.user.company.hasCompanyHierarchy', false),
  isTableLoading: state.globals.isTableLoading,
});

export default connect(mapStateToProps, { setModalData, setExcelHeaders })(TableFunctions);
