import { toast } from 'react-toastify';

import { HIERARCHY_FETCH_GROUPS } from '../lib/types';
import { API_COMPANY_HIERARCHY_GROUPS } from '../lib/api-endpoints';
import { FETCH_COMPANY_ORGANIZATION_GROUPS_TRY_LATER } from '../constants/error-messages';
import siteManagerAPI from '../lib/api-manager';
import PaginationSerializer from '../lib/serializers/PaginationSerializer';

export const fetchOrganizationGroups = () => async (dispatch) => {
  let response = {};
  const params = {
    tree_visibility: true,
  };

  try {
    response = await siteManagerAPI.get(API_COMPANY_HIERARCHY_GROUPS, { params });

    const visibleGroups = {
      results: (response && response.data && response.data.results) || [],
      pagination: new PaginationSerializer(response.data),
    };

    dispatch({
      type: HIERARCHY_FETCH_GROUPS,
      payload: { visibleGroups },
    });
  } catch (error) {
    toast.error(FETCH_COMPANY_ORGANIZATION_GROUPS_TRY_LATER);
  }
};
