import _ from 'lodash';
import PaginationSerializer from '../../lib/serializers/PaginationSerializer';

export function getId(param) {
  if (param) return param.id;
  return null;
}

export function mapIds(data) {
  return data.map(getId);
}

export function formatUserName(user) {
  return {
    id: user.id,
    name: `${user.first_name || ''} ${user.last_name || ''}`.trim(),
  };
}

export function extractNodeIds(node) {
  const { name, users, sites, group, parent } = node;
  return {
    name,
    group: getId(group || {}),
    parent: getId(parent || {}),
    sites: mapIds(sites || []),
    users: mapIds(users || []),
  };
}

export function formatNodeFromAPI(node) {
  const {
    id,
    name,
    ecommerce_catalog: ecommerceCatalog,
    mcc_domain: mccDomain,
    ecommerce_domain: ecommerceDomain,
    organization_group: group,
    is_root: isRoot,
    has_own_configuration: hasOwnConfig,
    parent,
    company,
    users,
    sites,
    children,
    has_approval_rule: hasApprovalRule,
  } = node;
  return {
    id,
    name,
    ecommerceCatalog,
    mccDomain,
    ecommerceDomain,
    group,
    isRoot,
    parent,
    company,
    users,
    sites,
    children,
    hasOwnConfig,
    hasApprovalRule,
  };
}

function formatNode(node) {
  return _.pick(node, ['id', 'name', 'group', 'sites', 'users']);
}

export function flattenNodes(nodes) {
  const result = [];
  nodes.forEach((node) => {
    result.push(formatNode(node));
    flattenNodes(node.children).forEach((child) => result.push(formatNode(child))); // TODO: Not good to flatten on frontend
  });
  return _.uniqBy(result, 'id');
}

function formatNodeName(node) {
  const groupNameSuffix = node.group ? ` (${node.group.name})` : '';
  return `${node.name}${groupNameSuffix}`;
}

export function formatNodeWithGroupName(node) {
  return {
    ...node,
    name: formatNodeName(node),
  };
}

function formatPolicyFromAPI(policy) {
  const { first_name: firstName, last_name: lastName, id, sites, sites_count: count } = policy;
  return {
    id,
    name: `${firstName} ${lastName}`.trim(),
    sites: {
      list: sites,
      count,
    },
  };
}

export function formatPoliciesListResponse(data) {
  return {
    policies: data.results.map(formatPolicyFromAPI),
    pagination: new PaginationSerializer(data),
  };
}

export function formatNodeConfigFromAPI(nodeConfig) {
  return _.mapKeys(nodeConfig, (value, key) => _.camelCase(key));
}

export function formatNodeError(error) {
  return _.mapValues(formatNodeFromAPI(error.response.data), (err) =>
    Array.isArray(err) ? err[0] : err,
  );
}

export function formatNodeConfigError(error) {
  return _.mapValues(formatNodeConfigFromAPI(error.response.data), (err) =>
    Array.isArray(err) ? err[0] : err,
  );
}
