import { toast } from 'react-toastify';

import siteManagerAPI from 'lib/api-manager';
import { API_ADMIN_COMPANIES } from 'lib/api-endpoints';
import { formatErrorResponse } from 'shared/utils/formatting';

import messages from './messages';

export const createCompany = (companyData = {}) => async () => {
  try {
    const response = await siteManagerAPI.post(API_ADMIN_COMPANIES, companyData);
    toast.success(messages.createCompanySuccess);
    return response.data;
  } catch (error) {
    toast.error(messages.createCompanyError);
    return { errors: formatErrorResponse(error.response.data) };
  }
};
