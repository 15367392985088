import {
  GLOBALS_IS_SIDEBAR_OPEN,
  GLOBALS_SHOULD_DISPLAY_SPINNER,
  GLOBALS_SHOULD_DISPLAY_SPLASH_SCREEN,
  GLOBALS_SET_MODAL_DATA,
  GLOBALS_RESET,
  GLOBALS_SET_EXCEL_HEADERS,
  GLOBALS_SET_SIDEBAR_STATE,
  GLOBALS_SET_THEME_DATA,
} from '../lib/types';
import { formatCapitalizeFirstLetter } from '../utils/format';
import SPECIAL_THEMES from '../constants/themes-special-cases';

export const resetState = () => (dispatch, getState) => {
  dispatch({
    type: GLOBALS_RESET,
    payload: { company: { ...getState().globals.company } },
  });
};

export const openCloseSideBar = () => ({
  type: GLOBALS_IS_SIDEBAR_OPEN,
  payload: null,
});

export const setSidebarState = (isOpen) => ({
  type: GLOBALS_SET_SIDEBAR_STATE,
  payload: isOpen,
});

export const shouldDisplaySpinner = (shouldDisplay) => (dispatch) => {
  dispatch({
    type: GLOBALS_SHOULD_DISPLAY_SPINNER,
    payload: shouldDisplay,
  });
};

export const shouldDisplaySplash = (shouldDisplay) => (dispatch) => {
  dispatch({
    type: GLOBALS_SHOULD_DISPLAY_SPLASH_SCREEN,
    payload: shouldDisplay,
  });
};

export const setModalData = (component, onClose, onSubmit) => (dispatch) => {
  const payload = {
    component,
  };

  if (onClose) {
    payload.onClose = onClose;
  }

  if (onSubmit) {
    payload.onSubmit = onSubmit;
  }

  dispatch({
    type: GLOBALS_SET_MODAL_DATA,
    payload,
  });
};

export const setExcelHeaders = (headers) => (dispatch) => {
  dispatch({
    type: GLOBALS_SET_EXCEL_HEADERS,
    payload: headers,
  });
};

const DEFAULT_ELEMENTS = {
  label: 'GoExpedi',
  shortName: 'Expedi',
  shopUrl: 'https://shop.goexpedi.com/',
};

export const setThemeFromApi = (theme) => (dispatch) => {
  try {
    const { theme_id: themeId, logo_url: logoUrl, mcc_theme_url: mccThemeUrl } = theme;
    const capitalizedUpperCase = formatCapitalizeFirstLetter(themeId);

    const themeIdElements =
      themeId === 'default'
        ? DEFAULT_ELEMENTS
        : {
            label: `Go${capitalizedUpperCase}`,
            shortName: capitalizedUpperCase,
            shopUrl: `https://go${themeId}.com/`,
          };

    let payload = {
      logo: logoUrl,
      logoLogin: logoUrl,
      logoSidebar: logoUrl,
      logoSplash: logoUrl,
      themeUrl: mccThemeUrl,
      ...themeIdElements,
    };

    if (themeId in SPECIAL_THEMES) {
      payload = {
        ...payload,
        ...SPECIAL_THEMES[themeId],
      };
    }

    dispatch({
      type: GLOBALS_SET_THEME_DATA,
      payload,
    });
  } catch (error) {
    console.error('Error setting theme data...', error);
  }
};
