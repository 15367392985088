import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import TableFunctions from '../../../../components/shared/table-functions';
import TableActions from '../../../../components/shared/table-actions.component';
import TrimmedUnorderedList from '../../../../components/shared/trimmed-unordered-list.component';
import useActions from '../../../../shared/hooks/useActions';
import { useSingleSelector } from '../../../../shared/hooks/useSelector';
import * as APP_ROUTES from '../../../../constants/app-routes';
import * as actions from '../../../../shared/actions/hierarchy';

import * as selectors from './selectors';
import messages from './messages';

const headers = [
  {
    text: messages.name,
    name: 'name',
    isSortable: false,
  },
  {
    text: messages.sites,
    name: 'sites',
    component: TrimmedUnorderedList,
    isSortable: false,
  },
  {
    text: messages.actions,
    name: 'actions',
    class: 'text-right',
    isSortable: false,
  },
];

function HierarchyPoliciesTable() {
  const fetchTablePolicies = useActions(actions.fetchTablePolicies);
  const policies = useSingleSelector(selectors.policies);
  const history = useHistory();

  useEffect(() => {
    fetchTablePolicies();
  }, [fetchTablePolicies]);

  const policiesRowsData = useMemo(
    () =>
      policies.map((policy) => ({
        ...policy,
        actions: (
          <TableActions edit={() => history.push(`${APP_ROUTES.HIERARCHY_POLICIES}${policy.id}`)} />
        ),
      })),
    [policies, history],
  );

  return <TableFunctions headers={headers} rowsData={policiesRowsData} />;
}

export default HierarchyPoliciesTable;
