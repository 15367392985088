import React, { Component } from 'react';
import '../../../shared/polyfills/unicode-regex-polyfill';
import ExcelJS from 'exceljs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { isEmpty as lodashIsEmpty } from 'lodash';

import SectionHeader from '../../shared/section-header.component';
import { setModalData } from '../../../actions/globals.actions';
import { fetchUsers } from '../../../actions/users.actions';
import { fetchSites } from '../../../actions/sites.actions';
import BreadCrumbComponent from '../../shared/breadcumb.component';
import BreadCrumbItem from '../../shared/breadcrumb-item';
import { debounce } from '../../../utils/general';
import TrimmedUnorderedList from '../../shared/trimmed-unordered-list.component';
import {
  API_USERS,
  API_USERS_SUGGESTIONS,
  API_SITES_BATCH_CREATE,
} from '../../../lib/api-endpoints';
import { UNLIMITED_LIST_LIMIT } from '../../../constants/values';
import TableFunctions from '../../shared/table-functions';
import siteManagerAPI from '../../../lib/api-manager';

const sitePropertiesMap = {
  Name: 'name',
  Division: 'division',
  'Business Unit': 'business_unit',
  Region: 'region',
  Email: 'ecommerce_user_email',
  'Shop Username': 'ecommerce_username',
  Password: 'password',
  'Location Name': 'location_name',
};

const createSiteObject = (rawSite, siteProperties) => {
  const siteObject = {};
  for (let i = 1; i < siteProperties.length; i += 1) {
    const propertyName = siteProperties[i];
    const apiPropertyName = sitePropertiesMap[propertyName];
    const propertyValue = rawSite[i];
    if (apiPropertyName) {
      siteObject[apiPropertyName] = propertyValue;
    }
  }

  return siteObject;
};

function renderErrors(errors) {
  return errors.siteErrors.map((siteError) => (
    <div className="text-primary medium-text mb-2">
      Site <b>{siteError.site}</b> was not {siteError.operation}d, reason:{' '}
      <span className="d-block">
        <b>{JSON.stringify(siteError.errors).replace(/[[\]]/g, '')}</b>
      </span>
    </div>
  ));
}

class SitesUploads extends Component {
  static propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    fetchUsers: PropTypes.func.isRequired,
    usersQueryParams: PropTypes.shape(),
    setModalData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    usersQueryParams: {},
  };

  constructor(props) {
    super(props);
    this.handleFilterBy = debounce(this.handleFilterBy, 400);
    this.state = {
      selectedUsers: [],
      sites: [],
      headers: [
        {
          text: 'Username',
          name: 'username',
          filterType: 'INPUT',
          isActivated: false,
          defaultAscendent: true,
        },
        {
          text: 'Title',
          name: 'title',
          filterType: 'INPUT',
          isActivated: false,
        },
        {
          text: 'Name',
          name: 'name',
          filterType: 'INPUT',
          isActivated: false,
        },
        {
          text: 'Email',
          name: 'email',
          filterType: 'INPUT',
          isActivated: false,
        },
        {
          text: 'Sites',
          name: 'sites',
          component: TrimmedUnorderedList,
          filterType: 'INPUT',
          isActivated: false,
          isSortable: false,
        },
      ],
      isUpsert: false,
    };
  }

  componentDidMount() {
    const { fetchUsers } = this.props;

    fetchUsers(`${API_USERS}?limit=${UNLIMITED_LIST_LIMIT}`);
  }

  handleUserCheck = (event) => {
    const { value, checked } = event.target;
    const { selectedUsers } = this.state;
    const userId = parseInt(value, 10);

    let newSelectedUsers = [];
    if (checked) {
      newSelectedUsers = [...selectedUsers, userId];
    } else {
      newSelectedUsers = selectedUsers.filter((selectedUserId) => selectedUserId !== userId);
    }

    this.setState({ selectedUsers: newSelectedUsers });
  };

  handleCheck = (event) => {
    const { checked, name } = event.target;
    this.setState({
      [name]: checked,
    });
  };

  submitForm = async (event) => {
    event.preventDefault();
    const { sites, selectedUsers } = this.state;
    const { setModalData } = this.props;
    const body = {
      sites,
      users: selectedUsers,
    };

    if (sites.length === 0) {
      toast.error('Please select a valid file');
      return;
    }

    try {
      const { isUpsert } = this.state;
      let params = {};
      if (isUpsert) {
        params = {
          is_upsert: 'true',
        };
      }
      const response = await siteManagerAPI.post(API_SITES_BATCH_CREATE, body, { params });
      const createResponse = response.data[0];
      const updateResponse = response.data[1];

      const siteErrors = [];

      if (createResponse.has_errors) {
        siteErrors.push(...createResponse.details);
      }
      if (updateResponse && updateResponse.has_errors) {
        siteErrors.push(...updateResponse.details);
      }

      if (siteErrors.length > 0) {
        setModalData({
          size: 'modal-xs',
          title: 'Site errors',
          jsx: null,
          component: renderErrors,
          buttons: null,
          componentProps: {
            siteErrors,
          },
        });
        $('#shared-modal').modal('show');
      } else {
        toast.success('Sites created succesfully');
      }
    } catch (error) {
      toast.error('There has been an error while saving Sites. Please try again later');
    }
  };

  parseUser = (user) => ({
    ...user,
    username: { customCell: true, cell: user },
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    sites: {
      list: user.sites.map((parsedSite) => parsedSite.name),
      count: user.sitesCount,
    },
  });

  isChecked(id) {
    const { selectedUsers } = this.state;
    return selectedUsers && selectedUsers.some((userId) => userId === id);
  }

  cleanSelectedUsers() {
    const { users } = this.props;
    const { selectedUsers } = this.state;

    const filteredSelectedUsers = selectedUsers.filter((userId) =>
      users.some((user) => user.id === userId),
    );
    this.setState({ selectedUsers: filteredSelectedUsers });
  }

  renderSelectCheckBox(user) {
    return (
      <div className="form-group form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id={`user_${user.id}`}
          value={user.id}
          checked={this.isChecked(user.id)}
          onChange={(event) => this.handleUserCheck(event)}
        />
        <label className="form-check-label" htmlFor={`user_${user.id}`}>
          {user.username}
        </label>
      </div>
    );
  }

  render() {
    const { users, fetchUsers, usersQueryParams } = this.props;
    const { headers, isUpsert } = this.state;

    const parsedUsers = users.map(this.parseUser);

    const handleChange = (selectorFiles) => {
      const sites = [];
      const file = selectorFiles['0'];
      const reader = new FileReader();
      const workbook = new ExcelJS.Workbook();

      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const buffer = reader.result;
        workbook.xlsx.load(buffer).then(() => {
          const worksheet = workbook.worksheets[0];
          const siteProperties = worksheet.getRow(1).values;
          worksheet.eachRow((row, rowNumber) => {
            if (rowNumber !== 1) {
              const createdSite = createSiteObject(row.values, siteProperties);
              if (!lodashIsEmpty(createdSite)) {
                sites.push(createdSite);
              }
            }
          });
          this.setState({ sites });
        });
      };
    };

    return (
      <div>
        <SectionHeader subtitle="Configuration" sectionLabel="Upload Sites" />
        <div className="content__container">
          <BreadCrumbComponent>
            <BreadCrumbItem label="Configuration" link="/configuration" />
            <BreadCrumbItem label="Upload Sites" />
          </BreadCrumbComponent>
          <div className="container-fluid">
            <div>
              <div className="mb-1">
                <label>
                  <input
                    name="isUpsert"
                    type="checkbox"
                    checked={isUpsert}
                    onChange={this.handleCheck}
                  />
                  &nbsp;Update site if already exists (based on name)
                </label>
              </div>
              <div className="mb-1">
                Please upload the file that you want to use for the creation of the sites
              </div>
              <div>
                <input type="file" onChange={(e) => handleChange(e.target.files)} />
                <form onSubmit={this.submitForm}>
                  <button type="submit" className="btn btn-primary mb-2 mt-2 float-none">
                    <FontAwesomeIcon icon="file-upload" />
                    <span> UPLOAD SITES</span>
                  </button>
                  <div className="assign-table input-group">
                    <span className="d-block text-primary">Assign users to Sites (optional)</span>
                    <TableFunctions
                      headers={headers}
                      rowsData={parsedUsers}
                      suggestionsURL={API_USERS_SUGGESTIONS}
                      dataURL={API_USERS}
                      fetchData={(dataURL, queryParams) =>
                        fetchUsers(dataURL, { ...queryParams, limit: UNLIMITED_LIST_LIMIT })
                      }
                      tableQueryParams={usersQueryParams}
                      renderCustomCell={(cell) => this.renderSelectCheckBox(cell)}
                      customCellFunction={() => this.cleanSelectedUsers()}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { results: users } = state.users.users;

  return {
    users,
    onSubmit: state.globals.onSubmit,
  };
};

export default connect(mapStateToProps, {
  fetchUsers,
  fetchSites,
  setModalData,
})(SitesUploads);
