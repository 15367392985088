import { toast } from 'react-toastify';

import siteManagerAPI from '../../../../lib/api-manager';
import { API_HIERARCHY_NODES } from '../../../../lib/api-endpoints';
import { GLOBALS_SET_IS_TABLE_LOADING, HIERARCHY_SAVE_TABLE_NODES } from '../../../../lib/types';
import { FETCH_NODES_ERROR } from '../../../../constants/error-messages';

import * as utils from './utils';

export const fetchTableNodes =
  (url = API_HIERARCHY_NODES, params) =>
  async (dispatch) => {
    dispatch({
      type: GLOBALS_SET_IS_TABLE_LOADING,
      payload: true,
    });

    try {
      const response = await siteManagerAPI.get(url, { params });
      const table = utils.formatNodesListResponse(response.data);
      dispatch({ type: HIERARCHY_SAVE_TABLE_NODES, payload: { table } });
    } catch (error) {
      toast.error(FETCH_NODES_ERROR);
    }

    dispatch({
      type: GLOBALS_SET_IS_TABLE_LOADING,
      payload: false,
    });
  };
