function getId(param) {
  if (param) return param.id;
  return null;
}

function mapIds(data) {
  return data.map(getId);
}

export function createApiRequestBody(sites = []) {
  return {
    policy_sites: mapIds(sites),
  };
}

function formatPoliciesSite(site) {
  return {
    id: site.id,
    name: site.name,
  };
}

export function formatPoliciesSites(sites) {
  return sites.map(formatPoliciesSite);
}

export function formatUser(user) {
  return {
    id: user.id,
    name: `${user.first_name || ''} ${user.last_name || ''}`.trim(),
  };
}

export function formatUsers(users) {
  return users.map(formatUser);
}

export function formatToSelectInput(option) {
  return {
    ...option,
    value: option.id,
    label: option.name,
  };
}

export function formatNodeToSelectInput({ id, name }) {
  return {
    id,
    name,
    value: id,
    label: `${name} (ID: ${id})`,
  };
}

function buildNodesAndSitesStructures(node, nodes, sites) {
  // Preorder Tree Traversal to organize the nodes
  nodes.push({ id: node.id, name: node.name });

  node.sites.forEach((site) => {
    sites[site.id] = node.id;
  });

  node.children.forEach((child) => {
    buildNodesAndSitesStructures(child, nodes, sites);
  });
}

export function setNodesAndSitesParents(setNodes, setSites, sites, tree) {
  if (!tree) return; // In case of error when fetching the tree

  const newNodes = [];
  const sitesNodesRelationship = {}; // siteId : node.id

  buildNodesAndSitesStructures(tree, newNodes, sitesNodesRelationship);

  // Add property of parent node id to the list of sites
  sites.forEach((site) => {
    site.node = sitesNodesRelationship[site.id];
  });

  setNodes(newNodes);
  setSites(sites);
}

export function filterSites(selectedNodes, sites) {
  // If there are no selected nodes, all sites should be available to select
  if (!selectedNodes) return sites;

  // Make nodes a set for faster lookup
  const nodesLookup = new Set(selectedNodes.map((node) => node.id));

  return sites.filter((site) => nodesLookup.has(site.node));
}
