import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import $ from 'jquery';

import SectionHeaderComponent from '../shared/section-header.component';
import BreadCrumbComponent from '../shared/breadcumb.component';
import BreadCrumbItem from '../shared/breadcrumb-item';
import AlertTriggersListComponent from './alert-triggers-list.component';
import { fetchTriggers } from '../../actions/alerts.actions';
import { shouldDisplaySpinner, setModalData } from '../../actions/globals.actions';
import siteManagerAPI from '../../lib/api-manager';
import { API_ALERT_TRIGGERS, API_ALERT_TRIGGERS_BATCH_UPDATE } from '../../lib/api-endpoints';
import PaginationComponent from '../shared/pagination.component';
import SwitchInput from '../forms/switch-input.component';
import ConfirmationModal from '../shared/confirmation-modal.component';

class AlertTriggersComponent extends Component {
  static propTypes = {
    triggers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        rule: PropTypes.string,
        enabled: PropTypes.bool,
        frequency: PropTypes.string,
      }),
    ).isRequired,
    shouldDisplaySpinner: PropTypes.func.isRequired,
    fetchTriggers: PropTypes.func.isRequired,
    setModalData: PropTypes.func.isRequired,
    triggerQueryParams: PropTypes.object,
  };

  static defaultProps = {
    triggerQueryParams: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      triggers: props.triggers,
      enableFilteredTriggers: true,
    };
  }

  componentDidMount() {
    this.fetchAlertTriggers();
  }

  updateFilteredTriggers = async () => {
    const {
      shouldDisplaySpinner: shouldDisplaySpinnerAction,
      fetchTriggers: fetchTriggersAction,
      triggerQueryParams,
    } = this.props;
    const { enableFilteredTriggers } = this.state;
    const activeFilterTriggers = !enableFilteredTriggers;
    const queryParams = triggerQueryParams;
    try {
      shouldDisplaySpinnerAction(true);
      await siteManagerAPI.put(
        API_ALERT_TRIGGERS_BATCH_UPDATE,
        { enableFilters: activeFilterTriggers },
        { params: queryParams },
      );
      shouldDisplaySpinnerAction(false);
      this.setState({ enableFilteredTriggers: activeFilterTriggers });
      toast.success('Rules updated correctly');
    } catch (error) {
      shouldDisplaySpinnerAction(false);
      toast.error('There has been an error while updating the Rules. Try again later');
    }

    try {
      shouldDisplaySpinnerAction(true);
      await fetchTriggersAction(API_ALERT_TRIGGERS, queryParams);
      shouldDisplaySpinnerAction(false);
    } catch (error) {
      shouldDisplaySpinnerAction(false);
      toast.error('There has been an error while retrieving data. Try again later');
    }
  };

  displayDisableAllAlertsConfirmation = () => {
    const { setModalData: setModalDataAction } = this.props;
    const { enableFilteredTriggers } = this.state;

    const message = (
      <div>
        Are you sure you want to
        {enableFilteredTriggers ? ' disable ' : ' enable '}
        all filtered alerts?
      </div>
    );

    setModalDataAction({
      size: 'modal-xs',
      title: `${enableFilteredTriggers ? 'Disable' : 'Enable'} All Triggers`,
      jsx: null,
      component: ConfirmationModal,
      buttons: null,
      componentProps: {
        onSubmit: this.updateFilteredTriggers,
        message,
        warning: true,
      },
    });
    $('#shared-modal').modal('show');
  };

  async fetchAlertTriggers() {
    const {
      shouldDisplaySpinner: shouldDisplaySpinnerAction,
      fetchTriggers: fetchTriggersAction,
    } = this.props;
    await shouldDisplaySpinnerAction(true);
    await fetchTriggersAction(API_ALERT_TRIGGERS);
    shouldDisplaySpinnerAction(false);
  }

  render() {
    const { triggers, enableFilteredTriggers } = this.state;
    return (
      <Fragment>
        <SectionHeaderComponent subtitle="Alerts" sectionLabel="Configure Rules" />

        <div className="content__container">
          <BreadCrumbComponent>
            <BreadCrumbItem label="Alerts" link="/alerts" />
            <BreadCrumbItem label="Configure" />
          </BreadCrumbComponent>

          <div className="row">
            <div className="col-12 col-md-12">
              <PaginationComponent />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-12">
              <div className="float-right text-primary medium-text medium-bold">
                <SwitchInput
                  label={
                    enableFilteredTriggers
                      ? 'Disable all filtered alerts'
                      : 'Enable all filtered alerts'
                  }
                  name="enable_filter"
                  checked={enableFilteredTriggers}
                  handleChange={this.displayDisableAllAlertsConfirmation}
                />
              </div>
            </div>
          </div>

          <AlertTriggersListComponent
            alertTriggers={triggers}
            changeTriggerStatus={this.handleChange}
          />

          <div className="row">
            <div className="col-12 col-md-12">
              <PaginationComponent />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ alerts, auth }) => ({
  triggers: alerts.triggers.results,
  triggerQueryParams: alerts.triggers.pagination.queryParams,
  user: auth.user,
});

export default connect(mapStateToProps, {
  fetchTriggers,
  shouldDisplaySpinner,
  setModalData,
})(AlertTriggersComponent);
