import SiteTypeSerializer from './SiteTypeSerializer';
import { NOT_APPLICABLE } from '../../constants/values';

export default class SiteSerializer {
  constructor(data) {
    const siteData = data || {};

    this.id = siteData.id;
    this.name = siteData.name;
    this.groups = {};
    this.siteType = new SiteTypeSerializer(siteData.site_type);
    this.locationName = siteData.location_name;
    this.address = siteData.address;
    this.businessUnit = siteData.business_unit;
    this.division = siteData.division;
    this.region = siteData.region ? siteData.region : NOT_APPLICABLE;
    this.users = [];
    this.allUsers = [];
    this.usersCount = [];
    this.eCommerceUser = siteData.ecommerce_username;
    this.eCommerceEmail = siteData.ecommerce_user_email;
    this.node = siteData.node;
    this.siteWarehouse = new SiteTypeSerializer(siteData.site_warehouse);
    this.hasApprovalRule = siteData.has_approval_rule;
    this.buyoutSite = siteData.buyout_site;
    this.enableAnalytics = siteData.enable_analytics;
  }
}
