import AlertTriggerSerializer from './AlertTriggerSerializer';
import PaginationSerializer from './PaginationSerializer';

class AlertsTriggerSerializer {
  constructor(data, page) {
    const alertsTriggersData = data || {};

    this.count = alertsTriggersData ? alertsTriggersData.count : 0;
    this.next = alertsTriggersData ? alertsTriggersData.next : null;
    this.previous = alertsTriggersData ? alertsTriggersData.previous : null;
    this.page = page;
    this.results = Array.isArray(alertsTriggersData.results)
      ? alertsTriggersData.results.map((alert) => new AlertTriggerSerializer(alert))
      : [];
    this.pagination = new PaginationSerializer(alertsTriggersData);
  }
}

export default AlertsTriggerSerializer;
