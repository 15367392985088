import { createSelector } from 'reselect';

import * as utils from './utils';

function hierarchyStateSelector(state) {
  return state.hierarchy;
}

export const groups = createSelector(hierarchyStateSelector, (state) => {
  return utils.formatGroups(state.groups);
});
