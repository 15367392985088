import React, { Component, Fragment } from 'react';
import queryString from 'query-string';

import siteManagerAPI from '../../lib/api-manager';
import {
  API_RESET_PASSWORD_CONFIRM,
  API_RESET_PASSWORD_VALIDATE_TOKEN,
} from '../../lib/api-endpoints';
import TextInput from '../forms/text-input.component';
import { getFieldValue } from '../../utils/forms';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    const queryParamaters = queryString.parse(window.location.search);

    this.state = {
      password: '',
      confirmPassword: '',
      errors: {},
      token: queryParamaters.token,
      isValidToken: false,
      isPasswordCreated: false,
    };
  }

  async componentDidMount() {
    const { token } = this.state;
    const body = {
      token,
    };

    try {
      await siteManagerAPI.post(API_RESET_PASSWORD_VALIDATE_TOKEN, body);
      this.setState({ isValidToken: true });
    } catch (error) {
      console.log(error);
    }
  }

  handleChange = (event) => {
    this.setState(getFieldValue(event.target));
  };

  submitResetPasswordForm = (event) => {
    event.preventDefault();

    const errors = {};
    const { confirmPassword, password } = this.state;

    if (!password) errors.password = 'Please provide new password';
    if (!confirmPassword) errors.confirmPassword = 'Please confirm new password';

    if (password && password !== confirmPassword)
      errors.password = 'Password and confirmation password does not match';

    this.setState({ errors });

    if (JSON.stringify(errors) === JSON.stringify({})) {
      this.resetPassword();
    }
  };

  async resetPassword() {
    const { password, token } = this.state;

    const body = {
      password,
      token,
    };

    try {
      await siteManagerAPI.post(API_RESET_PASSWORD_CONFIRM, body);
      this.setState({ isPasswordCreated: true });
    } catch (error) {
      const resetPasswordError = error.response.data;

      const errors = {};

      [errors.password] = resetPasswordError.password;

      this.setState({ errors });
    }
  }

  renderCreatePassword() {
    const { isPasswordCreated, errors, password, confirmPassword } = this.state;

    if (isPasswordCreated) {
      return (
        <div className="alert alert-secondary medium-text" role="alert">
          The password has been succesfully set
        </div>
      );
    }

    return (
      <div className="row">
        <div className="form-group col-12">
          <form onSubmit={this.submitResetPasswordForm}>
            <TextInput
              error={errors.password}
              value={password}
              label="Password"
              name="password"
              type="password"
              handleChange={this.handleChange}
              inputClassName="login__input medium-bold"
            />

            <TextInput
              error={errors.confirmPassword}
              value={confirmPassword}
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              placeHolder="Please provide a valid password"
              handleChange={this.handleChange}
              inputClassName="login__input medium-bold"
            />

            <button type="submit" className="btn btn-primary btn-round medium-text w-100 mt-2">
              Create Password
            </button>
          </form>
        </div>
      </div>
    );
  }

  renderInvalidToken = () => (
    <div className="alert alert-danger medium-text" role="alert">
      Token is not valid or has expired
    </div>
  );

  render() {
    const { isValidToken } = this.state;

    return (
      <Fragment>
        {isValidToken ? this.renderCreatePassword() : this.renderInvalidToken()}

        <div className="row">
          <div className="col-12">
            <a className="text-primary text-primary text-center medium-text d-block" href="/">
              Go back to login &raquo;
            </a>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ResetPassword;
