import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ORDER_STATUS } from '../../constants/values';

const checkOrderTrackingPermission = (user, company, order) => {
  return (
    user.canViewOrderTracking &&
    company.hasOrderTracking &&
    order &&
    order.status !== ORDER_STATUS.REJECTED.TEXT &&
    order.status !== ORDER_STATUS.PENDING_APPROVAL.TEXT &&
    order.status !== ORDER_STATUS.CANCELED.TEXT
  );
};

const PermissionChecker = ({ permissionName, fallback, user, order, children }) => {
  const company = user.company || {};
  const permissionRules = {
    'order-tracking': checkOrderTrackingPermission(user, company, order),
    'order-tracking-configuration': company.hasOrderTracking,
    'is-company-admin': user.isCompanyAdmin,
    'has-order-approvals': user.company && user.company.hasOrderApproval,
  };

  const hasPermission = permissionRules[permissionName] || false;

  if (hasPermission) {
    return children;
  }

  return fallback;
};

PermissionChecker.propTypes = {
  permissionName: PropTypes.string.isRequired,
  user: PropTypes.shape({
    canImpersonate: PropTypes.bool.isRequired,
  }).isRequired,
  fallback: PropTypes.node,
  children: PropTypes.node.isRequired,
  order: PropTypes.shape(),
};

PermissionChecker.defaultProps = {
  fallback: null,
  order: null,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(PermissionChecker);
