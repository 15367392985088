import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getFieldValue } from '../../../utils/forms';
import TextInput from '../../forms/text-input.component';
import { resetErrors } from '../../../actions/site-types';
import FormWrapper from '../../forms/form-wrapper.component';

class SiteTypeForm extends Component {
  static propTypes = {
    siteType: PropTypes.shape({
      name: PropTypes.string,
    }),
    handleFormSubmit: PropTypes.func.isRequired,
    errors: PropTypes.shape().isRequired,
    resetErrors: PropTypes.func.isRequired,
  };

  static defaultProps = {
    siteType: {
      name: '',
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      name: props.siteType.name,
      errors: { ...props.errors },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { errors, siteType } = this.state;

    const siteTypeChanged = JSON.stringify(nextProps.siteType) !== JSON.stringify(siteType);

    if (siteTypeChanged && Object.entries(nextProps.errors).length === 0) {
      this.resetSiteType(nextProps.siteType);
    }
    if (JSON.stringify(nextProps.errors) !== JSON.stringify(errors)) {
      this.setState({ errors: { ...nextProps.errors } });
    }
  }

  componentWillUnmount() {
    const { resetErrors: resetErrorsAction } = this.props;
    resetErrorsAction();
  }

  resetSiteType = (siteType) => {
    this.setState({ name: siteType.name });
  };

  submitSiteTypeForm = async (event) => {
    event.preventDefault();

    const { name } = this.state;
    const errors = {};

    if (!name) errors.name = 'Name field is required';

    this.setState({ errors });

    if (Object.entries(errors).length === 0) {
      const { handleFormSubmit } = this.props;

      handleFormSubmit({ name });
    }
  };

  handleChange = (event) => {
    this.setState({ ...getFieldValue(event.target) });
  };

  render() {
    const { name, errors } = this.state;

    return (
      <FormWrapper>
        <form className="form-input mb-2 clearfix" onSubmit={this.submitSiteTypeForm}>
          <TextInput
            error={errors.name}
            value={name}
            label="Name"
            name="name"
            required
            handleChange={this.handleChange}
          />

          <div className="float-right">
            <button type="submit" data-placement="right" className="btn btn-primary">
              <FontAwesomeIcon icon="address-card" /> SAVE SITE TYPE
            </button>
          </div>
        </form>
      </FormWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.siteTypes.errors,
});

export default connect(mapStateToProps, {
  resetErrors,
})(SiteTypeForm);
