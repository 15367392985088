import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

import BreadCrumbComponent from '../../shared/breadcumb.component';
import BreadCrumbItem from '../../shared/breadcrumb-item';
import SectionHeader from '../../shared/section-header.component';
import ConfirmationModal from '../../shared/confirmation-modal.component';
import ApprovalRuleForm from './approval-rule-form';
import ApprovalRulesList from './approval-rules-list';
import { setModalData } from '../../../actions/globals.actions';
import {
  activateOrderApprovals,
  deactivateOrderApprovals,
  fetchOrders,
} from '../../../actions/orders.actions';
import {
  fetchApprovalRules,
  createApprovalRule,
  deleteApprovalRule,
  resetErrors,
} from '../../../actions/roles';
import { API_PENDING_ORDERS } from '../../../lib/api-endpoints';

import EmptyState from './empty-state';
import PendingApprovalsModal from './pending-approvals-modal';

class ApprovalRulesConfiguration extends Component {
  static propTypes = {
    approvalsActive: PropTypes.bool.isRequired,
    hasPendingApprovals: PropTypes.bool.isRequired,
    activateOrderApprovals: PropTypes.func.isRequired,
    deactivateOrderApprovals: PropTypes.func.isRequired,
    fetchOrders: PropTypes.func.isRequired,
    fetchApprovalRules: PropTypes.func.isRequired,
    createApprovalRule: PropTypes.func.isRequired,
    deleteApprovalRule: PropTypes.func.isRequired,
    resetErrors: PropTypes.func.isRequired,
    setModalData: PropTypes.func.isRequired,
    approvalRules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        role: PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      }),
    ).isRequired,
    company: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      redirectUrl: '',
      isModalOpen: false,
    };
  }

  componentDidMount() {
    this.fetchApprovalRulesWithParams({ order_by: 'limit' });

    const { fetchOrders: fetchOrdersAction } = this.props;
    fetchOrdersAction(API_PENDING_ORDERS);
  }

  componentWillReceiveProps(nextProps) {
    const { isModalOpen } = this.state;
    const { approvalRules } = this.props;
    const approvalRuleCreated =
      isModalOpen && approvalRules.length < nextProps.approvalRules.length;

    if (approvalRuleCreated) {
      this.setState({ isModalOpen: false });
    }
  }

  componentWillUnmount() {
    const { resetErrors: resetErrorsAction } = this.props;
    resetErrorsAction();
    this.cleanModal();
  }

  displayCreationModal = () => {
    const { setModalData: setModalDataAction } = this.props;

    setModalDataAction(
      {
        size: 'modal-xs',
        title: 'Create New Order Approval Rule',
        jsx: null,
        component: ApprovalRuleForm,
        componentProps: {
          handleFormSubmit: this.handleCreateSubmit,
        },
        buttons: null,
      },
      this.handleModalClose,
      null,
    );

    this.setState({ isModalOpen: true });
  };

  handleDisableOrderApprovals = () => {
    const { setModalData: setModalDataAction, hasPendingApprovals } = this.props;

    if (hasPendingApprovals) {
      setModalDataAction(
        {
          size: 'modal-xs',
          title: 'Deactivate order approvals',
          jsx: null,
          component: PendingApprovalsModal,
          buttons: [
            <button
              type="button"
              onClick={() =>
                this.setState({ redirectUrl: '/pending-approvals', isModalOpen: false })
              }
              className="btn btn-primary"
            >
              Take me there
            </button>,
          ],
        },
        this.handleModalClose,
        null,
      );
      this.setState({ isModalOpen: true });
    } else {
      const { deactivateOrderApprovals: deactivateOrderApprovalsAction, company } = this.props;

      deactivateOrderApprovalsAction(company.id);
    }
  };

  displayDeleteConfirmationModal = (rule) => {
    const {
      setModalData: setModalDataAction,
      deleteApprovalRule: deleteApprovalRuleAction,
    } = this.props;

    const { limit, user, role } = rule;
    const roleLabel = role ? 'role ' : '';
    const roleUserName = role ? role.name : user.name;

    let message = '';

    if (limit > 0) {
      message = (
        <div>
          Are you sure you want to delete the order approval rule below?
          <br />
          User {roleLabel}
          <span className="bold">{roleUserName}</span>, approves orders with total equal to or above{' '}
          <span className="bold">{`$ ${limit} `}</span>
        </div>
      );
    } else {
      message = (
        <div>
          Are you sure you want to delete the order approval rule below?
          <br />
          User {roleLabel}
          <span className="bold">{roleUserName}</span>, approves
          <span className="bold"> Every Order </span>
        </div>
      );
    }

    setModalDataAction({
      size: 'modal-xs',
      title: 'Delete Order Approval Rule',
      jsx: null,
      component: ConfirmationModal,
      buttons: null,
      componentProps: {
        onSubmit: async () => {
          await deleteApprovalRuleAction(rule.id);
          this.fetchApprovalRulesWithParams({ order_by: 'limit' });
          this.setState({ isModalOpen: false });
        },
        message,
      },
    });
    this.setState({ isModalOpen: true });
  };

  cleanModal = () => {
    const { setModalData: setModalDataAction } = this.props;

    setModalDataAction({}, null, null);
  };

  handleEdit = (role) => {
    const redirectUrl = `/configuration/approvals/${role.id}`;
    this.setState({ redirectUrl });
  };

  handleCreateSubmit = async (approvalRule) => {
    const { createApprovalRule: createApprovalRuleAction } = this.props;

    await createApprovalRuleAction(approvalRule);
    this.fetchApprovalRulesWithParams({ order_by: 'limit' });
  };

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
    this.cleanModal();
  };

  fetchApprovalRulesWithParams = (queryParams) => {
    const { fetchApprovalRules: fetchApprovalRulesAction } = this.props;

    fetchApprovalRulesAction(queryParams);
  };

  activateOrderApprovals = () => {
    const { activateOrderApprovals: activateOrderApprovalsAction, company } = this.props;

    activateOrderApprovalsAction(company.id);
  };

  render() {
    const { redirectUrl, isModalOpen } = this.state;

    $('#shared-modal').modal(isModalOpen ? 'show' : 'hide');

    if (redirectUrl) {
      return <Redirect to={redirectUrl} />;
    }

    const { approvalsActive, approvalRules } = this.props;

    return (
      <Fragment>
        <SectionHeader subtitle="Configuration" sectionLabel="Order Approval Rules" />

        <div className="content__container approval-rules">
          <BreadCrumbComponent>
            <BreadCrumbItem label="Configuration" link="/configuration" />
            <BreadCrumbItem label="Order Approvals Rules" />
          </BreadCrumbComponent>

          {approvalsActive ? (
            <Fragment>
              <div className="container-fluid">
                <div className="row">
                  <button
                    type="button"
                    onClick={this.displayCreationModal}
                    className="btn btn-primary"
                  >
                    <FontAwesomeIcon icon="address-card" /> Create Order Approval Rule
                  </button>
                  <button
                    type="button"
                    onClick={this.handleDisableOrderApprovals}
                    className="btn btn-danger"
                    style={{ marginLeft: 16 }}
                  >
                    Deactivate order approvals
                  </button>
                </div>
              </div>

              <hr />

              <ApprovalRulesList
                rules={approvalRules}
                handleEdit={this.handleEdit}
                handleDelete={this.displayDeleteConfirmationModal}
                fetchApprovalRulesWithParams={this.fetchApprovalRulesWithParams}
              />
            </Fragment>
          ) : (
            <EmptyState activateOrderApprovals={this.activateOrderApprovals} />
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.roles.roles.results,
  approvalsActive: Boolean(state.auth.user.company.hasOrderApproval),
  hasPendingApprovals: Boolean(
    state.orders.orders.results && state.orders.orders.results.length > 0,
  ),
  approvalRules: _.get(state, 'approvalRules.list.data', []),
  company: _.get(state, 'auth.user.company', null),
});

export default connect(mapStateToProps, {
  setModalData,
  fetchApprovalRules,
  createApprovalRule,
  deleteApprovalRule,
  resetErrors,
  activateOrderApprovals,
  deactivateOrderApprovals,
  fetchOrders,
})(ApprovalRulesConfiguration);
