export default class RoleSerializer {
  constructor(data) {
    const roleData = data || {};

    this.id = roleData.id;
    this.name = roleData.name;
    this.limit = roleData.limit ? parseInt(roleData.limit, 10) : null;
    this.canImpersonate = roleData.can_impersonate;
    this.canViewOrderTracking = roleData.can_view_order_tracking;
    this.hasApprovalRule = roleData.has_approval_rule;
  }
}
