import React from 'react';
import PropTypes from 'prop-types';

import LocationItem from './location-item';
import messages from './messages';

function AdminNavigation(props) {
  const { location } = props;
  let currentLocation = '';

  const getLinkUrl = (url) => {
    if (!url) return '';
    const formattedUrl = `/${url}`;
    currentLocation = currentLocation.concat(formattedUrl);
    return currentLocation;
  };

  const renderLocationItem = (item) => (
    <LocationItem {...item} url={getLinkUrl(item.url)} key={item.label} />
  );

  return <div className="admin-navigation-links">{location.map(renderLocationItem)}</div>;
}

AdminNavigation.locations = {
  companies: {
    url: 'companies',
    label: messages.companiesLabel,
  },
};

AdminNavigation.propTypes = {
  location: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default AdminNavigation;
