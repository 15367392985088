import React from 'react';
import $ from 'jquery';
import { PropTypes } from 'prop-types';

const ConfirmationModal = ({ onSubmit, message, warning }) => {
  const confirm = async () => {
    onSubmit();
    $('#shared-modal').modal('hide');
  };

  const cancel = () => {
    $('#shared-modal').modal('hide');
  };

  return (
    <div className="text-primary medium-text medium-bold container">
      <div className="row">
        <div className="col-sm-12">{message}</div>
      </div>

      <div className="row float-right confirmation-modal__buttons">
        <div className="col-sm-12">
          <button
            type="button"
            data-placement="right"
            className={`btn ${warning ? 'btn-primary' : 'btn-danger'}`}
            onClick={cancel}
          >
            No
          </button>

          <button
            type="button"
            data-placement="right"
            className={`btn ${
              warning ? 'btn-danger' : 'btn-primary'
            } confirmation-modal__confirm-btn`}
            onClick={confirm}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

ConfirmationModal.propTypes = {
  message: PropTypes.element.isRequired,
  onSubmit: PropTypes.func.isRequired,
  warning: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  warning: false,
};

export default ConfirmationModal;
